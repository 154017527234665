import React, { useEffect, useState } from 'react';
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import api from '../../services/api';
import CurrencyInput from 'react-currency-input';
import { useLocation } from 'react-router-dom';

const ContractForm = ({
  contract,
  disableInstallments,
  handleOnChange,
  handleOnChangeContractValue,
  handleOnChangeAmountCharged,
  disableInputValueKindPayment,
  disableInputValue,
  register,
  isUpdate,
  hasFgts,
  errors,
}) => {
  const query = new URLSearchParams(useLocation().search);
  const [partners, setPartners] = useState([]);

  async function getPartners() {
    try {
      const { data } = await api.get('partners');
      setPartners(data);
    } catch (error) {
      console.log('Algo deu errado ao buscar os parceiros');
    }
  }

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <>
      <hr className="my-4" />
      {/* Contract */}
      <h6 className="heading-small text-muted mb-4">Informações do Contrato</h6>
      <div className="pl-lg-4">
        <Row>
          {isUpdate && (
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="contract_number">
                  Nº do Contrato
                </label>

                <input
                  name="contract_number"
                  className="form-control form-control-alternative"
                  id="contract_number"
                  placeholder="Digite o Nº do Contrato"
                  type="text"
                  maxLength="45"
                  disabled
                  value={contract.contract_number}
                  onChange={handleOnChange}
                />
              </FormGroup>
            </Col>
          )}
          {/* {
            !query.get("kind") && (
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="payment_method">
                    Tipo de Pagamento
                  </label>
                  <select
                    className="form-control form-control-alternative"
                    id="payment_method"
                    name="payment_method"
                    disabled={disableInputValueKindPayment}
                    value={contract.payment_method}
                    ref={register({ required: true })}
                    onChange={handleOnChange}
                  >
                    <option value="">Selecione uma opção</option>
                    <option value="credit_card">
                      Venda Online
                    </option>
                    <option value="pos">
                      Venda presencial com máquina
                    </option>
                  </select>
                  {errors.payment_method && (
                    <small className="text-danger">Tipo de pagamento é obrigatório</small>
                  )}
                </FormGroup>
              </Col>
            )
          } */}
          {contract.payment_method == 'pos' && (
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="nsu">
                  NSU
                </label>
                <InputGroup className="input-group-alternative">
                  <input
                    name="nsu"
                    className="form-control form-control-alternative"
                    id="nsu"
                    disabled={disableInputValueKindPayment}
                    placeholder="Informe o NSU"
                    type="text"
                    value={contract.nsu}
                    inputRef={register({ required: true })}
                    onChange={handleOnChange}
                  />
                </InputGroup>
                {errors.nsu && (
                  <small className="text-danger">NSU é obrigatório</small>
                )}
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg="4">
            <label className="form-control-label" htmlFor="contract_value">
              {hasFgts
                ? query.get('isCdc')
                  ? 'Valor Liberado Estabelecimento'
                  : 'Valor Liberado'
                : 'Valor do Contrato'}
            </label>

            <InputGroup className="input-group-alternative">
              <CurrencyInput
                prefix="R$"
                decimalSeparator=","
                thousandSeparator="."
                name="contract_value"
                className="form-control form-control-alternative"
                id="contract_value"
                placeholder="999,99"
                type="text"
                disabled={disableInputValue}
                value={
                  query.get('isCdc') && contract.value_establishment
                    ? contract.value_establishment
                    : contract.contract_value
                }
                inputRef={register({ required: true })}
                onChange={handleOnChangeContractValue}
              />
            </InputGroup>
            {errors.contract_value && (
              <small className="text-danger">
                O Valor do Contrato é obrigatório
              </small>
            )}
          </Col>
          {query.get('isCdc') && contract.value_client ? (
            <Col lg="4">
              <label className="form-control-label" htmlFor="contract_value">
                Valor Liberado Cliente
              </label>

              <InputGroup className="input-group-alternative">
                <CurrencyInput
                  prefix="R$"
                  decimalSeparator=","
                  thousandSeparator="."
                  name="contract_value"
                  className="form-control form-control-alternative"
                  id="contract_value"
                  placeholder="999,99"
                  type="text"
                  disabled={disableInputValue}
                  value={contract.value_client}
                  inputRef={register({ required: true })}
                  onChange={handleOnChangeContractValue}
                />
              </InputGroup>
              {errors.value_client && (
                <small className="text-danger">
                  O Valor do Contrato é obrigatório
                </small>
              )}
            </Col>
          ) : null}
          {!hasFgts && (
            <Col lg="4">
              <label className="form-control-label" htmlFor="amount_charged">
                {hasFgts ? 'Valor Total' : 'Valor Cobrado'}
              </label>

              <InputGroup className="input-group-alternative">
                <CurrencyInput
                  prefix="R$"
                  decimalSeparator=","
                  thousandSeparator="."
                  name="amount_charged"
                  className="form-control form-control-alternative"
                  id="amount_charged"
                  placeholder="999,99"
                  type="text"
                  disabled={disableInputValue}
                  value={contract.amount_charged}
                  inputRef={register({ required: true })}
                  onChange={handleOnChangeAmountCharged}
                />
              </InputGroup>
              {errors.amount_charged && (
                <small className="text-danger">
                  O Valor Cobrado é obrigatório
                </small>
              )}
            </Col>
          )}
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="installments">
                Qtde. Parcelas
              </label>
              <input
                name="installments"
                className="form-control form-control-alternative"
                id="installments"
                value={contract.installments}
                disabled={disableInstallments}
                type="text"
                ref={register({
                  required: 'A parcela é obrigatório',
                  validate: (value) => true,
                  // eslint-disable-next-line radix
                  // parseInt(value) <= 12 ||
                  // 'A parcela não pode ser maior que 12',
                })}
              />
              {errors.installments && (
                <small className="text-danger">
                  {errors.installments.message}
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContractForm;
