import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError } from '../../util/toast';
import PaginationComponent from '../../components/PaginationComponent';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { VisibilityCreateStore, VisibilityEditStore } from '../../util/visibility';
import Search from '../../components/Search';
import { States } from '../../variables/assistants'
import { VisibilityByUserRoles } from '../../util/visibility';
import { AuthContext } from '../../context/AuthContext';
import MenuRankingTable from 'components/MenuRankingTable';


const RankingStoreTable = (props) => {
  const { sortContractValue, sortContractMargin, setSortContractMargin, setSortContractValue, handleToggleFilter, filterData, stores, bussinessDay, days  } = props

  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      {/* Page content */}
      <Container className="mt--7 pt-5" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row>
                  <Col lg="7">
                    <h3 className="text-white">Lojas</h3>
                  </Col>
                  <MenuRankingTable handleToggleFilter={handleToggleFilter} filterData={filterData}/>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Colocação</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Corban</th>
                    <th scope="col">Regional Responsável</th>
                    <th scope="col">Comercial Responsável</th>
                    <th scope="col">Numero de Contratos</th>
                    <th scope="col" onClick={() => setSortContractValue()}>
                      <span>
                        Valor Contrato
                      </span>
                      {
                        sortContractValue && sortContractValue == 'desc'
                        ?
                        <i className="ml-2 fas fa-arrow-down" />
                        :
                        <i className="ml-2 fas fa-arrow-up" />
                      }
                    </th>
                    <th scope="col">Valor Cobrado</th>
                    <th scope="col" onClick={() => setSortContractMargin()}>
                      <span>
                      Valor de Margem
                      </span>
                      {
                        sortContractMargin && sortContractMargin == 'desc'
                        ?
                        <i className="ml-2 fas fa-arrow-down" />
                        :
                        <i className="ml-2 fas fa-arrow-up" />
                      }
                    </th>
                    <th scope="col">Projeção</th>
                  </tr>
                </thead>
                <tbody>
                  {stores.map((store, index) => (
                    <tr key={store.id}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {index + 1}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{store.nome}</td>
                      <td>{store.corban_name}</td>
                      <td>{store.regional_manager_name}</td>
                      <td>{store.commercial_manager_name}</td>
                      <td>{store.total_contracts}</td>
                      <td>{store.value_contracts}</td>
                      <td>{store.value_charged}</td>
                      <td>{store.value_margin}</td>
                      <td>R$ {((store.value_margin_custom/bussinessDay) * days).toFixed(2).replace(".", ",")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default RankingStoreTable;
