import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add('bg-default');
    return () => {
      document.body.classList.remove('bg-default');
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div
        className="main-content h-100vh d-flex justify-content-center align-items-center"
        ref={mainContent}
      >
        <div className="header bg-gradient-info py-7 d-none">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container>
          <Row className="justify-content-center">{props.children}</Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;
