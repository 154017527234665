import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Button,
  Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Pagination from 'react-responsive-pagination';
import PaginationComponent from '../../components/PaginationComponent';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';

//import './style.css';
//import Search from '../../components/Search';

const LIMIT = 10;

const FgtsBasicInterests = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [numberRegister, setNumberRegister] = useState('')
  const [basicInterests, setBasicInterests] = useState([]);
  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterActive, setFilterActive] = useState('');
  const [searchFilter, setSearchFilter] = useState('&q[active_eq]=1');
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const handleClickToggle = async ({ id }) => {
    try {
      const { data } = await api.post(`fgts_basic_interests/${id}/switch_active_mode`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo deu errado ao mudar o status de Juros Básico.';
      }

      hideLoader();
      toastError(message);
    }
  };

  function handlerOnClickFilter() {

    let filterPath = '';

    if (filterActive) {
      filterPath += `&q[active_eq]=${filterActive}`;
    }

    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    filterActive('')
    setSearchFilter('')
    handleToggleFilter()
  }

  useEffect(() => {
    async function getBasicInterest() {
      showLoader();

      try {
        const response = await api.get(
          `fgts_basic_interests?${searchFilter}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        setBasicInterests(response.data);
        setNumberRegister(response.headers.total_count)

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os Juros Básicos.');
      }
    }

    getBasicInterest();
  }, [searchFilter]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, );

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <div className="text-right">
                <Link
                  className="btn btn-success mb-3 mt-2"
                  to="/admin/fgts-basic-interests/create"
                >
                  <i className="fas fa-plus" /> Cadastrar novo Conjunto de Juros Básico
                </Link>
              </div>
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col>
                  <h3 className="text-white">Juros Básicos Fgts</h3>
                    </Col>
                    <Col>
                      {(
                        <>
                          <div className="d-flex justify-content-end h-100 align-items-end">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleToggleFilter}
                            >
                              <i className="fas fa-filter" /> Filtrar
                            </button>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {
                    numberRegister > 0
                    ?
                    <div className="text-white">Registros encontrados: {numberRegister}</div>
                    :
                    <div className="text-white">Nenhum registro encontrado</div>
                  }
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Cod. Tabela</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Valor Padrão Corban</th>
                      <th scope="col">Criado em</th>
                      <th scope="col">Criado Por</th>
                      <th scope="col">Ativo?</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {basicInterests.map((basic_interest) => (
                      <tr key={basic_interest.id}>
                        <td>{basic_interest.name}</td>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {basic_interest.value}%
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {basic_interest.default_value}%
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{basic_interest.create}</td>
                        <td>{basic_interest.author}</td>
                        { basic_interest.active ?
                             <td>Ativo</td>
                            :
                            <td>Inativo</td>
                            }
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <Link
                                  to={`/admin/fgts-basic-interests/${basic_interest.id}`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-edit"/> Editar
                                  </span>
                                </Link>
                            <DropdownItem onClick={() => handleClickToggle({ id: basic_interest.id })}>
                              { basic_interest.active ?
                                <span>
                                <i className="fas fa-times" />{' '}
                                  Desativar Juros Básico
                                </span>
                              :
                                <span>
                                  <i className="fas fa-check" />{' '}
                                  Ativar Juros Básico
                                </span>
                              }
                            </DropdownItem>
                            </DropdownMenu>

                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setFilterActive(e.target.value)}
                    value={filterActive}
                  >
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default FgtsBasicInterests;
