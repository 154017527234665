import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import api from '../../services/api';
import img from '../../assets/img/logo_novo_saque.png';
import { toastError, toastSuccess } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';

const ResetPassword = (props) => {
  const params = new URLSearchParams(props.location.search);
  const [token, setToken] = useState();
  const { register, handleSubmit, errors, watch } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  useEffect(() => {
    if (params.get('token')) {
      setToken(params.get('token'));
    } else {
      toastError(
        'Você precisa primeiro solicitar uma Recuperação de Senha para pode recuperá-lá.'
      );
      props.history.push('/forgot');
    }
  }, []);

  async function onSubmit(dataCredentials) {
    showLoader();

    try {
      const { data } = await api.post(
        'reset',
        JSON.stringify({
          token,
          password: dataCredentials.password,
          password_confirmation: dataCredentials.password_repeat,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      hideLoader();

      toastSuccess(data.message);

      props.history.push('/login');
    } catch (error) {
      hideLoader();

      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo deu errado ao resetar sua senha.';
      }

      toastError(message);
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-novo-saque">
            <img src={img} className="img-fluid" alt="Logo Novo Saque" />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Criar uma Nova Senha</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Nova Senha"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    id="password"
                    className="form-control"
                    ref={register({
                      required: 'A senha é obrigatório',
                      minLength: {
                        value: 6,
                        message: 'A senha deve ter pelo menos 6 caracteres',
                      },
                    })}
                  />
                </InputGroup>
                {errors.password && (
                  <small className="text-danger">
                    {errors.password.message}
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Confirme a Nova Senha"
                    type="password"
                    autoComplete="new-password"
                    name="password_repeat"
                    id="password_repeat"
                    className="form-control"
                    ref={register({
                      validate: (value) =>
                        value === watch('password') ||
                        'As senha não são iguais',
                    })}
                  />
                </InputGroup>
                {errors.password_repeat && (
                  <small className="text-danger">
                    {errors.password_repeat.message}
                  </small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-3 btn-block"
                  color="primary"
                  type="submit"
                >
                  Redefinir Senha
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ResetPassword;
