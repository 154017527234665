/* eslint-disable no-undef */
import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { formatCPF } from '@brazilian-utils/brazilian-utils';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import extenso from 'extenso';
import publicIp from 'public-ip';
import api from '../../services/api';
import { formatarMoeda, removeAccents, capitalize } from '../../util/mask';
import { toastError, toastSuccess } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import img from '../../assets/img/logo_novosaque.png';
import token from '../../util/setAuthToken';
import LgpdComponent from '../../components/LgpdComponent';
import FinishComponent from '../../components/FinishComponent';

import './style.css';

const Payment = (props) => {
  const { id } = useParams();
  const { register, handleSubmit, errors, setError } = useForm();
  const [customerName, setCustomerName] = useState('');
  const [isPagSeguro, setIsPagSeguro] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardKind, setCardKind] = useState('');
  const [cardData, setCardData] = useState(null);
  const [contractId, setContractId] = useState(null);
  const [buttonSubmit, setButtonSubmit] = useState(false);
  const [lgpdAccepted, setLgpdAccepted] = useState(true);
  const handleToggleModal = () => setTermsModal(!termsModal);
  const [termsModal, setTermsModal] = useState(false);
  const [lgpdNotAccepted, setLgpdNotAccepted] = useState(false);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [contract, setContract] = useState({
    name: '',
    email: '',
    birth_date: '',
    cpf_cnpj: '',
    mobile: '',
    zipcode: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    contract_value: 0,
    amount_charged: 0,
    installments: 1,
    idcontract: '',
    customer_name: '',
  });

  function sendEvent() {
    if (
      typeof Konduto !== 'undefined' &&
      typeof Konduto.sendEvent !== 'undefined'
    ) {
      Konduto.sendEvent('page', 'checkout');
    }
  }

  function startSession(idcustomer) {
    var customerID = `${idcustomer}`;
    (function () {
      var period = 300;
      var limit = 20 * 1e3;
      var nTry = 0;
      var intervalID = setInterval(() => {
        var clear = limit / period <= ++nTry;
        if (
          typeof Konduto !== 'undefined' &&
          typeof Konduto.setCustomerID !== 'undefined'
        ) {
          window.Konduto.setCustomerID(customerID);
          clear = true;
        }
        if (clear) {
          clearInterval(intervalID);
        }
      }, period);
    })(customerID);
  }

  async function isPagseguroGatewayActive() {
    try {
      const { data } = await api.get(`gateway_settings/active`, {});
      setIsPagSeguro(data?.gateway === 'pagseguro');
    } catch (e) {
      console.log(e);
      setIsPagSeguro(false);
    }
  }

  useEffect(() => {
    showLoader();
    sendEvent();
    isPagseguroGatewayActive();

    async function getContractData() {
      try {
        const { data } = await api.get(`contracts/${id}/by_payment_link`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        setCustomerName(data.customer.name);

        startSession(data.customer_id);
        setContractId(data.id);

        setLgpdAccepted(data.lgpd_accepted);
        setContract({
          ...contract,
          id: data.id,
          contract_value: Number(data.contract_value),
          amount_charged: Number(data.amount_charged),
          installments: data.installments,
          name: data.customer.name,
          birth_date: data.customer.birth_date,
          cpf_cnpj: data.customer.cpf_cnpj,
          mobile: data.customer.mobile,
          email: data.customer.email,
          zipcode: data.customer.address.zip_code,
          street: data.customer.address.street,
          number: data.customer.address.number,
          district: data.customer.address.district,
          city: data.customer.address.city,
          state: data.customer.address.state,
          complement: data.customer.address.complement,
          simplified_payment: data.simplified_payment
        });

        hideLoader();
      } catch (e) {
        hideLoader();
        console.log(e);
        if (e && e.response && e.response.data) {
          toastError(e.response.data.message);
        } else {
          toastError('Contrato não encontrado.');
        }
      }
    }

    getContractData();
  }, []);

  function ucFirstAllWords(str) {
    var pieces = str.split(' ');
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(' ');
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setContract({ [name]: value });
  }

  function firstAndLastName(name) {
    const listName = name.trim().split(' ');
    return [listName[0], listName.slice(-1)[0]];
  }

  async function notSendLgpdData() {
    try {
      const { data, status } = await api.put(
        `contracts/${contract.id}/cancel_contract`,
        JSON.stringify({}),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      console.log(data);
      hideLoader();

      setLgpdNotAccepted(true);
    } catch (e) {
      hideLoader();

      let message = 'Algo deu errado ao cancelar o contrato.';

      if (e.response.data) {
        message = e.response.data.message;
      }

      toastError(message);
    }
  }

  async function sendLgpdData() {
    try {
      const ipUser = await publicIp.v4({
        fallbackUrls: ['https://ifconfig.co/ip'],
      });

      console.log(contractId);
      const response = await api.post(
        'lgpd_accepteds',
        JSON.stringify({
          contract_id: contract.id,
          ip: ipUser,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      hideLoader();

      toastSuccess(response.data.message);
      setLgpdAccepted(true);
    } catch (error) {
      hideLoader();
      let message = null;

      message =
        'Ops, não foi possível finalizar seu pagamento, por favor tente novamente mais tarde';

      if (error && error.response) {
        if (error.response.data && error.response.data.message) {
          message = error.response.data.message;
        } else if (typeof error.response.data.errors === 'object') {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.description);
          });
        } else if (typeof error.response.data.errors !== 'object') {
          message = error.response.data.errors;
        } else {
          message =
            'Ops, não foi possível finalizar seu pagamento, por favor tente novamente mais tarde';
        }
      }

      console.log(message);

      toastError(message);
    }
  }

  async function openModalTerms(data) {
    const [expiration_month, expiration_year] = data.cc_expiration.split('/');
    const dateNow = moment();
    const monthNow = dateNow.month() + 1;
    const yearNow = dateNow.year();

    if (expiration_month.replace('_', '').length < 2) {
      setError('cc_expiration', 'Data inválida');
      return;
    }

    //Validação de data do cartão de credito
    if (`20${expiration_year}` < yearNow) {
      setError('cc_expiration', 'Data inválida');
      return;
    }
    if (`20${expiration_year}` == yearNow) {
      if (expiration_month < monthNow) {
        setError('cc_expiration', 'Data inválida');
        return;
      }
    }
    let kindCard = 'Visa';
    if (data.brand == 3) {
      kindCard = 'Visa';
    } else if (data.brand == 1) {
      kindCard = 'Mastercard';
    } else if (data.brand == 5) {
      kindCard = 'Elo';
    } else if (data.brand == 7) {
      kindCard = 'Hipercard';
    } else if (data.brand == 9) {
      kindCard = 'American Express';
    }
    setCardData({ ...data, brandName: kindCard });
    // handleToggleModal()
    onSubmit({ ...data, brandName: kindCard });
  }

  async function handlerOnClickModal() {
    handleToggleModal();
    onSubmit(cardData);
  }

  async function onSubmit(data) {
    const [cardHolderFirst, cardHolderLast] = firstAndLastName(
      removeAccents(data.cc_name.trim())
    );
    const [customerNameFirst, customerNameLast] = firstAndLastName(
      removeAccents(contract.name.trim())
    );

    if (cardHolderFirst.toUpperCase() !== customerNameFirst.toUpperCase()) {
      toastError(
        `O Cartão de Crédito deve estar em nome de ${customerNameFirst}`
      );
      return;
    }
    const [expiration_month, expiration_year] = data.cc_expiration.split('/');
    let encrypted = null;
    if (isPagSeguro) {
      const card = PagSeguro.encryptCard({
        // publicKey:
        //   'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAi6KC+EKXI0EtY+jucpouCMuw5XITWhYAmfrrOVLNKV4JYlRjppZY46ld7SfOpgb6Bg/7MnJJdlvgT3F0i2dkZKHpx/UV4bDqXWxha143cU/YkHMrNAaLpA96gKaErgixZnTyckV8FrhPVwi/aWwwudAYwO7p44CapCHGn5aNMllvayrgVtB8VVJ1ipyaJd+rkSM3RvF4QbbeZBRFZ3WYyWvgtWuWZKl6O5yybeJxVkdEzVa2wJ7L+E0OD7x01LHkao9oXqHj5PYiUan0JagZuHGiUb8c/50E6xlyRs2ljwgtQrDCLWK/tysc+Rd1k1iWNdxpSJk9oEOK8sYn02iDawIDAQAB',
        publicKey:
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB',
        holder: data.cc_name.trim().toUpperCase(),
        number: data.cc_number.trim(),
        expMonth: expiration_month,
        expYear: `20${expiration_year}`,
        securityCode: data.cc_cvv,
      });

      encrypted = card.encryptedCard;
      const hasErrors = card.hasErrors;
      const errors = card.errors;

      if (hasErrors) {
        console.log(errors);
        toastError('Dados do cartão invalidos');
        return;
      }
      console.log(encrypted);
    }

    setButtonSubmit(true);

    showLoader();

    try {
      const ipUser = await publicIp.v4({
        fallbackUrls: ['https://ifconfig.co/ip'],
      });

      const response = await api.post(
        'payments',
        JSON.stringify({
          contract_id: contract.id,
          customer_name: data.customer_name,
          card_number: data.cc_number.trim(),
          card_holder: data.cc_name.trim().toUpperCase(),
          expiration_month,
          expiration_year,
          cvv: data.cc_cvv,
          brand: data.brand,
          card_encrypted: encrypted,
          ip: ipUser,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      hideLoader();

      sessionStorage.setItem(
        '@UGate:payment',
        JSON.stringify({
          contractId: contract.id,
          amount: response.data.amount,
          installments: response.data.installments,
          contractNumber: response.data.contractNumber,
          status: response.data.status,
          simplified_payment: contract.simplified_payment
        })
      );

      toastSuccess(response.data.message);

      props.history.push('/payment/success');
    } catch (error) {
      hideLoader();
      let message = null;

      message =
        'Ops, não foi possível finalizar seu pagamento, por favor tente novamente mais tarde';

      if (error && error.response) {
        if (error.response.data && error.response.data.message) {
          message = error.response.data.message;
        } else if (typeof error.response.data.errors === 'object') {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.description);
          });
        } else if (typeof error.response.data.errors !== 'object') {
          message = error.response.data.errors;
        } else {
          message =
            'Ops, não foi possível finalizar seu pagamento, por favor tente novamente mais tarde';
        }
      }

      console.log(error.response.data.message);

      toastError(message);

      setTimeout(() => {
        setButtonSubmit(false);
      }, 4000);
    }
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parseMaskCurrency(value) {
    return value.toString().replace('.', ',');
  }

  return (
    <Container className="pt-3 pb-5">
      <div className="text-center">
        <img
          src={img}
          alt="Logo Novo Saque"
          className="d-block mx-auto mb-5 img-fluid"
          width="250"
          height="250"
        />
      </div>
      {lgpdAccepted ? (
        <>
          <Row>
            <Col md="4" className="order-md-2 mb-4">
              <h3 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Seu Contrato</span>
                <span className="badge badge-dark badge-pill">1</span>
              </h3>

              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h4 className="my-0">Valor do Empréstimo</h4>
                    <span className="text-muted">
                      Valor a Pagar: {contract.installments}x de{' '}
                      {(
                        contract.amount_charged / contract.installments
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </span>
                  </div>
                  <span className="text-muted">
                    {contract.contract_value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total (R$)</span>
                  <strong>
                    {contract.amount_charged.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </strong>
                </li>
              </ul>
            </Col>
            <Col md="8" className="order-md-1">
              <h3 className="mb-3">Dados do Contrante</h3>
              <Form onSubmit={handleSubmit(openModalTerms)}>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Nome Completo
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control form-control-alternative form-control-custom"
                        placeholder="Nome completo"
                        value={contract.name}
                        onChange={handleInputChange}
                        disabled
                        ref={register({
                          required: '0 Nome é obrigatório',
                        })}
                        maxLength={35}
                      />
                      {errors.name && (
                        <small className="text-danger">
                          {errors.name.message}
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="email"
                        name="email"
                        placeholder="example@example.com"
                        value={contract.email}
                        disabled
                        onChange={handleInputChange}
                        // ref={register({
                        //   required: 'O email é obrigatório',
                        //   pattern: {
                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        //     message: 'Por favor digite um email válido',
                        //   },
                        // })}
                        ref={register({
                          required: '0 email é obrigatório',
                        })}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="cpf_cnpj">
                        CPF
                      </label>
                      <InputMask
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="cpf_cnpj"
                        name="cpf_cnpj"
                        placeholder="999.999.999-99"
                        value={contract.cpf_cnpj}
                        mask="999.999.999-99"
                        disabled
                        inputRef={register({ required: true })}
                        onChange={handleInputChange}
                      />
                      {errors.cpf_cnpj && (
                        <small className="text-danger">CPF é obrigatório</small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="birth_date"
                      >
                        Data de Nascimento
                      </label>
                      <input
                        type="date"
                        className="form-control form-control-alternative form-control-custom"
                        id="birth_date"
                        name="birth_date"
                        disabled
                        value={contract.birth_date}
                        onChange={handleInputChange}
                      />
                      {errors.birth_date && (
                        <small className="text-danger">
                          Data de Nascimento é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="mobile">
                        Celular
                      </label>
                      <InputMask
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="mobile"
                        name="mobile"
                        disabled
                        placeholder="(99) 99999-9999"
                        value={contract.mobile}
                        mask="(99) 99999-9999"
                        inputRef={register({ required: true })}
                        onChange={handleInputChange}
                      />
                      {errors.mobile && (
                        <small className="text-danger">
                          Celular é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="zipcode">
                        CEP residêncial
                      </label>
                      <InputMask
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="zipcode"
                        name="zipcode"
                        placeholder="99999-999"
                        value={contract.zipcode}
                        mask="99999-999"
                        disabled
                        inputRef={register({ required: true })}
                        onChange={handleInputChange}
                      />
                      {errors.zipcode && (
                        <small className="text-danger">CEP é obrigatório</small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="9">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="street">
                        Endereço
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="street"
                        name="street"
                        disabled
                        value={contract.street}
                        ref={register({ required: true })}
                        onChange={handleInputChange}
                      />
                      {errors.street && (
                        <small className="text-danger">
                          Endereço é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="number">
                        Número
                      </label>
                      <input
                        type="number"
                        name="number"
                        disabled
                        className="form-control form-control-alternative form-control-custom"
                        value={contract.number}
                        ref={register({ required: true })}
                        onChange={handleInputChange}
                      />
                      {errors.number && (
                        <small className="text-danger">
                          Número é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="district">
                        Bairro
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="district"
                        name="district"
                        disabled
                        value={contract.district}
                        ref={register({ required: true })}
                        onChange={handleInputChange}
                      />
                      {errors.district && (
                        <small className="text-danger">
                          Bairro é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="city">
                        Cidade
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="city"
                        name="city"
                        disabled
                        value={contract.city}
                        ref={register({ required: true })}
                        onChange={handleInputChange}
                      />
                      {errors.city && (
                        <small className="text-danger">
                          Cidade é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="state">
                        Estado
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="state"
                        name="state"
                        disabled
                        value={contract.state}
                        ref={register({ required: true })}
                        onChange={handleInputChange}
                      />
                      {errors.state && (
                        <small className="text-danger">
                          Estado é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="complement"
                      >
                        Complemento{' '}
                        <span className="text-muted">(Opcional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="complement"
                        name="complement"
                        disabled
                        value={contract.complement}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <hr className="mb-4" />

                <h3 className="mb-3">Pagamento</h3>
                <p>Informe os dados do portador do Cartão</p>

                <div className="d-block my-3">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="credit"
                      defaultChecked
                    />
                    <label htmlFor="credit" className="custom-control-label">
                      Cartão de Crédito
                    </label>
                  </div>
                </div>

                <FormGroup>
                  <label className="form-control-label" htmlFor="customer_name">
                    Nome completo do portador do cartão
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-alternative form-control-custom"
                    id="customer_name"
                    name="customer_name"
                    value={customerName}
                    disabled
                    onChange={(e) => setCustomerName(e.target.value)}
                    ref={register({
                      required:
                        'Nome completo do portador do cartão é obrigatório',
                      pattern: {
                        value:
                          /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ|\s][a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ|\s]+$/,
                        message:
                          'Nome completo do portador do cartão só pode conter letras',
                      },
                    })}
                  />
                  {errors.customer_name && (
                    <>
                      <small className="text-danger">
                        {errors.customer_name.message}
                      </small>
                    </>
                  )}
                </FormGroup>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="cc_name">
                        Seu nome impresso no cartão
                      </label>
                      <input
                        maxLength="25"
                        type="text"
                        className="form-control form-control-alternative form-control-custom"
                        id="cc_name"
                        name="cc_name"
                        ref={register({
                          required: 'Nome do portador do cartão é obrigatório',
                          maxLength: 25,
                          pattern: {
                            value: /^[a-zA-Z][a-zA-Z|\s]+$/,
                            message:
                              'Nome do portador do cartão só pode conter letras',
                          },
                        })}
                      />
                      {errors.cc_name && (
                        <>
                          <small className="text-danger">
                            {errors.cc_name.message}
                          </small>
                        </>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="cc_number">
                        Número do cartão
                      </label>
                      <input
                        type="tel"
                        className="form-control form-control-alternative form-control-custom"
                        id="cc_number"
                        name="cc_number"
                        ref={register({
                          required: 'Número do cartão é obrigatório',
                          maxLength: 16,
                          pattern: {
                            value: /^[0-9]+$/,
                            message: 'Número do cartão só pode ter números',
                          },
                        })}
                        maxLength="16"
                      />
                      {errors.cc_number && (
                        <small className="text-danger">
                          {errors.cc_number.message}
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="cc_expiration"
                      >
                        Data de expiração
                      </label>
                      <InputMask
                        type="tel"
                        className="form-control form-control-alternative form-control-custom"
                        id="cc_expiration"
                        name="cc_expiration"
                        mask="99/99"
                        inputRef={register({
                          required: true,
                        })}
                      />
                      {errors.cc_expiration && (
                        <small className="text-danger">
                          {Object.values(errors.cc_expiration)
                            .reduce((t, n) => t + n, '')
                            .split('[')[0] === 'required'
                            ? 'A data é obrigatório'
                            : `${
                                Object.values(errors.cc_expiration)
                                  .reduce((t, n) => t + n, '')
                                  .split('[')[0]
                              }`}
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="3">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="cc_cvv">
                        CVV
                      </label>
                      <input
                        type="tel"
                        className="form-control form-control-alternative form-control-custom"
                        id="cc_cvv"
                        name="cc_cvv"
                        ref={register({
                          required: 'CVV é obrigatório',
                          maxLength: 4,
                          pattern: {
                            value: /^[0-9]+$/,
                            message: 'CVV só pode ter números',
                          },
                        })}
                        maxLength="4"
                      />
                      {errors.cc_cvv && (
                        <small className="text-danger">
                          {errors.cc_cvv.message}
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="installments"
                      >
                        Parcelamento
                      </label>
                      <select
                        name="installments"
                        id="installments"
                        className="form-control form-control-alternative form-control-custom"
                        disabled
                      >
                        <option value={`${contract.installments}`}>
                          {contract.installments}x de{' '}
                          {(
                            contract.amount_charged / contract.installments
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <label className="form-control-label" htmlFor="brand">
                    Bandeira
                  </label>
                  <select
                    name="brand"
                    id="brand"
                    className="form-control form-control-alternative"
                    ref={register({
                      required: 'Bandeira é obrigatória',
                    })}
                  >
                    <option value="">Selecione uma opção</option>
                    <option value="3">Visa</option>
                    <option value="1">Mastercard</option>
                    <option value="5">Elo</option>
                    <option value="7">Hipercard</option>
                    <option value="9">American Express</option>
                  </select>
                  {errors.brand && (
                    <small className="text-danger">
                      {errors.brand.message}
                    </small>
                  )}
                </FormGroup>

                <hr className="mb-4" />

                <Button
                  disabled={buttonSubmit}
                  className="btn btn-success btn-block"
                  type="submit"
                >
                  Finalizar
                </Button>
              </Form>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={termsModal}
            toggle={handleToggleModal}
            className={props.className}
            backdrop="static"
          >
            <ModalHeader toggle={handleToggleModal}>
              CONTRATO DE PRESTAÇÃO DE SERVIÇO DE CONSULTORIA FINANCEIRA
            </ModalHeader>
            <ModalBody>
              <Container>
                <p>
                  Eu <strong>{contract.name.toUpperCase()}</strong>.
                </p>
                <p>
                  Portador (a) do CPF nº{' '}
                  <strong>{formatCPF(contract.cpf_cnpj)}</strong>.
                </p>
                <p>
                  Residente na <strong>{contract.street.toUpperCase()}</strong>{' '}
                  número <strong>{contract.number.toUpperCase()}</strong>,
                  bairro <strong>{contract.district.toUpperCase()}</strong> na
                  cidade de <strong>{contract.city.toUpperCase()}</strong> no
                  estado de <strong>{contract.state.toUpperCase()}</strong>.
                </p>
                <p>
                  Autorizo a cobrança dos serviços prestados pela empresa{' '}
                  <strong>PAYFORM SOLUCOES EM PAGAMENTOS E CARTOES LTDA</strong>
                  , com CNPJ nº <strong>30.787.392/0001-41</strong> pela
                  Prestação de Serviço de Consultoria em meu nome{' '}
                  <strong>{contract.name.toUpperCase()}</strong>, portador(a) do
                  CPF/MF sob o nº{' '}
                  <strong>{formatCPF(contract.cpf_cnpj)}</strong>.
                </p>
                <p>
                  O serviço(s) adquirido(s) foram devidamente
                  entregues/realizados em {moment().format('DD/MM/YYYY')}.
                </p>
                <p>
                  Pelo(s) serviço(s) adquirido(s), me comprometo com o pagamento
                  do valor de{' '}
                  <strong>
                    {Number(contract.amount_charged).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}{' '}
                    (
                    {ucFirstAllWords(
                      extenso(
                        parseMaskCurrency(contract.amount_charged.toFixed(2)),
                        { mode: 'currency' }
                      )
                    )}
                    )
                  </strong>
                  , dividido em{' '}
                  <strong>({contract.installments}) parcela(s) </strong>
                  no valor de{' '}
                  <strong>
                    {Number(
                      contract.amount_charged / contract.installments
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}{' '}
                    (
                    {ucFirstAllWords(
                      extenso(
                        parseMaskCurrency(
                          (
                            contract.amount_charged / contract.installments
                          ).toFixed(2)
                        ),
                        { mode: 'currency' }
                      )
                    )}
                    ){' '}
                  </strong>
                  com o cartão de crédito bandeira{' '}
                  <strong>{cardData ? cardData.brandName : ''}</strong>, número{' '}
                  <strong>{cardData ? cardData.cc_number : cardData}</strong>{' '}
                  sendo a última parcela em{' '}
                  {moment()
                    .add(contract.installments, 'months')
                    .format('DD/MM/YYYY')}
                  .
                </p>
                <p>
                  <strong>IMPORTANTE</strong>: Declaro que sou o titular do
                  cartão usado, comprovando através de fotos e desta declaração
                  devidamente assinada por mim. Estou ciente de que a falsidade
                  na prestação desta informação, sem prejuízo de serem aplicadas
                  as penalidades previstas neste instrumento, inclusive sua
                  rescisão e apuração de perdas e danos, sujeitará todas as
                  pessoas que para ela concorrem, às penalidades previstas na
                  legislação criminal relativas à falsidade ideológica (art.299
                  do Código Penal). Essa autorização somente será válida quando
                  apresentada com a foto nítida do titular do cartão portando o
                  cartão e seu documento de identidade.
                </p>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={handleToggleModal}>
                Recusar
              </Button>
              <Button color="success" onClick={handlerOnClickModal}>
                Aceitar
              </Button>
            </ModalFooter>
          </Modal>
        </>
      ) : lgpdNotAccepted ? (
        <FinishComponent />
      ) : (
        <LgpdComponent
          sendLgpdData={sendLgpdData}
          sendNotLgpdData={notSendLgpdData}
        />
      )}
    </Container>
  );
};

export default Payment;
