import React, { useState, useContext, useEffect, useCallback } from 'react';
import swal from 'sweetalert';
import { Container, Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { formatarMoeda } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import DocumentUpload from '../../components/DocumentUpload';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';

import img from '../../assets/img/logo_novo_saque.png';


const Authentication = (props) => {
  const { id } = useParams();
  const [onboardingLink, setOnboardingLink] = useState(null);
  const [clicksignLink, setClicksignLink] = useState(null);
  const [clicksignLinkDisabled, setClicksignLinkDisabled] = useState(true);

  const getOnboardingLink = async () => {
    const response = await api.get(`/customers/${id}/onboarding_link`)
    console.log(response.data)
    if(response.data){
      setOnboardingLink(response.data)
    }
  }

  const openOnboardingLink = async () => {
    var child = window.open(onboardingLink,'','toolbar=0,status=0,width=626,height=436');
    var timer = setInterval(checkChild, 500);

    function checkChild() {
        if (child.closed) {
            // alert("Child window closed");
            getClicksignLink()
            clearInterval(timer);
        }
    }
  }

  const openClicksignLink = async () => {
    var timer = setInterval(async () => {
      console.log("VERIFICANDO SCORE")
      const response = await api.get(`/customers/${id}/clicksign_link`)
      console.log(response)
      if(response.data){
        setClicksignLink(response.data)
        window.location.href = response.data
        clearInterval(timer);
      }
    }, 4000);

  }

  const getClicksignLink = async () => {
    const response = await api.get(`/customers/${id}/clicksign_link`)
    console.log(response)
    if(response.data){
      setClicksignLink(response.data)
      // openClicksignLink()
      window.location.href = response.data
    }else{
      swal({
        title: 'Vamos validar os dados',
        text:
          'Caso já tenha efetuado o processo de onboarding, é só aguardar a liberação para assinatura dos documentos',
        icon: 'success',
        buttons: ['Continuar', 'Fechar'],
        dangerMode: true,
      }).then(async (next) => {
      });

      openClicksignLink()
    }
  }

  useEffect(() => {
    getOnboardingLink()
  }, []);


  return (
    <Container>
      <Row className="mt-8 d-flex align-items-center">
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <p className="text-center font-weight-600">
            Para finalizar o processo de Saque FGTS siga as instruções abaixo
          </p>
          <Card className="shadow-lg card-payment-info">
            <CardHeader className="card-payment_header">
              <div className="card-payment-info_container">
                <img src={img} className="img-fluid" alt="Logo Novo Saque" />
              </div>
            </CardHeader>
            <CardBody>
              <div className="info-buy">
                <div className="d-flex justify-content-between align-items-center mt-3">
                  {
                    onboardingLink && (
                      <>
                      <Button
                        color='primary'
                        className="btn btn-success mb-3 mt-2"
                        onClick={() => openOnboardingLink()}
                      >
                        <i className="fas fa-plus" /> Processo de Onboarding
                      </Button>
                        {/*<a
                          className="btn btn-success mb-3 mt-2"
                          href={onboardingLink}
                          target="_blank"
                        >
                          <i className="fas fa-plus" />
                        </a> */}
                      </>
                    )
                  }


                  <Button
                    color='primary'
                    className="btn btn-primary mb-3 mt-2"
                    onClick={() => getClicksignLink()}
                  >
                    <i className="fas fa-plus" /> Processo de Assinatura Digital
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Authentication;
