import React, { useContext, useState } from 'react';
import { LoadingContext } from '../../context/loading/LoadingContext';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import FgtsBasicInterestForm from '../../components/FgtsBasicInterestForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';

const FgtsCreateBasicInterest = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [basicInterests, setBasicInterest] = useState([]);

  async function onSubmit(data) {
    showLoader();

    data = {
      fgts_basic_interests: {
        fgts_basic_interest: [
          ...basicInterests
        ]
      }
    }

    try {
      await api.post('fgts_basic_interests', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Taxas de juros criado com sucesso');

      props.history.push('/admin/fgts-basic-interests');
    } catch (error) {
      hideLoader();

      let message = '';

      if (error.response.data.errors) {
        message = error.response.data.errors;
        message.forEach((e) => {
          toastError(e.msg);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na criação do Taxas de Juros, por favor tente novamente!';
      }

      toastError(message);
    }
  }

  function handleOnChange(index, event) {
    const values = [...basicInterests]
    values[index][event.target.name] = event.target.value;

    setBasicInterest(values)
  }


  function handleClickAdd() {
    setBasicInterest([ ...basicInterests, { value: '' }])
  }

  function handleClickRemove(index){
    const values = [...basicInterests];
    values.splice(index, 1);
    setBasicInterest(values);
  }

  return (
    <>
      <CustomerHeader />
      <FgtsBasicInterestForm
        basicInterests={basicInterests}
        title="Criar Taxa de Juros"
        titleButton="Criar Taxa de Juros"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        handleClickAdd={handleClickAdd}
        handleClickRemove={handleClickRemove}
      />
    </>
  );
};

export default FgtsCreateBasicInterest;
