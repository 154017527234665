import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';

import InputMask from 'react-input-mask';
import UserHeader from '../../components/Headers/UserHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import axios from 'axios';

import { useForm } from 'react-hook-form';

const UpdatePassword = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const { register, handleSubmit, errors } = useForm();

  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);



  async function handleSubmitUser(event) {
    event.preventDefault();
    event.stopPropagation();

    showLoader();

    if(password != passwordConfirmation) {
      toastError("As senhas devem ser iguais");
      hideLoader();
      return
    }

    try {
      await api.put(
        'users',
        JSON.stringify({ user: {password} }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();
      setPassword("")
      setPasswordConfirmation("")

      toastSuccess('Senha atualizado com sucesso.');
    } catch (error) {
      hideLoader();

      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, não foi possível atualizar seus dados, por favor tente novamente';
      }
      toastError(message);
    }
  }


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Alterar Senha</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmitUser}>
                  <h6 className="heading-small text-muted mb-4">
                    Alterar Senha
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="password">
                            Senha
                          </label>
                          <Input
                            type="password"
                            className="form-control-alternative"
                            id="password"
                            name="password"
                            placeholder="Senha"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="passwordConfirmation">
                            Confirmar Senha
                          </label>
                          <Input
                            type="password"
                            className="form-control-alternative"
                            id="passwordConfirmation"
                            placeholder="Confirmar Senha"
                            name="passwordConfirmation"
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-right">
                      <Button type="submit" className="btn btn-success px-4">
                        <i className="fas fa-paper-plane" /> Enviar
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdatePassword;
