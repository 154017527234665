import React, { useEffect, useState, useContext } from 'react';
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import UsersHeader from '../../components/Headers/UsersHeader';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import Pagination from 'react-responsive-pagination';
import { Link } from 'react-router-dom';
import Search from '../../components/Search';
import { Roles } from '../../variables/assistants'
import { VisibilityByUserRoles } from '../../util/visibility';


const LIMIT = 20;

const Users = (props) => {
  const params = new URLSearchParams(props.location.search);
  const [users, setUsers] = useState([]);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState([]);

  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [cpf, setCpf] = useState('');
  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole ] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('')

  const [reportModal, setReportModal] = useState(false);
  const [emailReport, setEmailReport] = useState("");
  const [completeReportDisabled, setCompleteReportDisabled] = useState(false);


  async function getUsers() {
    showLoader();

    try {
      const response = await api.get(
        `users?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setUsers(response.data);
      setNumberRegister(response.headers.total_count)

      const totalPages = response.headers.total_pages

      setPages(totalPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/users',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro na busca dos usuários, por favor tente novamente!'
      );
    }
  }

  useEffect(() => {

    getUsers();
  }, [user, offset, searchFilter]);

  async function handleActiveOrDeactivate(status, iduser, position) {
    try {
      showLoader();
      setUsers((prevState) => {
        const newItems = [...prevState];
        newItems[position].active = status;
        return newItems;
      });

      const response = await api.post(
        `users/${iduser}/activate`,
        JSON.stringify({ active: status }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      toastSuccess(response.data.message);
      getUsers()
      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(e.message.message);
    }
  }

  async function handleDestroyUser(iduser) {
    try {
      showLoader();

      const response = await api.delete(
        `users/${iduser}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      toastSuccess(response.data.message);
      getUsers()
      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(e.message.message);
    }
  }

  async function handleOnClick() {

    if (cpf.length > 0) {
      try {
        const { data } = await api.get(
          `users?page=${offset}&per_page=${LIMIT}&q[cpf_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setUsers(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CPF.';
        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CPF.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(`users?q[cpf_cont]=${searchText}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      console.log(data);
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CPF');
    }
  }

  async function onChange(e) {
    setCpf(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  function onSelect(val) {
    setCpf(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item.cpf} - {item.name}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item.cpf}`;
  }

  function handlerOnClickFilter() {
    if (params.get('page')) {
      setOffset(1);
    }

    let filterPath = '';

    if (filterStatus) {
      filterPath += `&q[active_eq]=${filterStatus}`;
    }

    if (filterName) {
      filterPath += `&q[name_cont]=${filterName}`;
    }

    if (filterEmail) {
      filterPath += `&q[email_cont]=${filterEmail}`;
    }

    if (filterRole) {
      filterPath += `&q[roles_name_cont]=${filterRole}`;
    }

    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    setFilterStatus('')
    setFilterName('')
    setFilterEmail('')
    setSearchFilter('')
    setFilterRole('')
    handleToggleFilter()
  }

  const handleToggleReportModal = () => {
    setReportModal(!reportModal)
  }

  async function handlerOnClickReportModal() {

    try {
      await api.get(`/users/export_data_background?email=${emailReport}&${searchFilter}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      toastSuccess('Relatorio Solicitado com sucesso, aguarde o envio por email');

    } catch (error) {
      hideLoader();
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro ao solicitar relatorio completo, por favor tente novamente!';
      }

      toastError(message);
    }
    setReportModal(!reportModal)
  }

  return (
    <>
      <UsersHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="text-right">
              <Link
                className="btn btn-primary mb-3 mt-2"
                to="/admin/users/create"
              >
                <i className="fas fa-plus" /> Criar usuário
              </Link>
            </div>
            <Card className="shadow">
              <CardHeader className="border-0">
              <Row>
                <Col lg="7">
                  <h3 className="mb-0">Usuários cadastrados</h3>
                  <Search
                    getItemValue={getItemValue}
                    autoCompleteData={autocompleteData}
                    renderItem={renderItem}
                    cpf_cnpj={cpf}
                    onChange={onChange}
                    onSelect={onSelect}
                    handleOnClick={handleOnClick}
                    placeHolder={"Buscar pelo CPF"}
                  />
                </Col>
                <Col>
                  {(
                    <>
                      <div className="d-flex justify-content-end h-100 align-items-end">
                        <VisibilityByUserRoles roles={["master", "operational", "commercial_manager", "regional_manager", "supervisor_commmercial_internal", "commercial_manager_internal", "superintendent_commercial", "master_corban", "master_subest", "master_store"]}>
                          <>
                            <button
                              disabled={completeReportDisabled}
                              className="btn btn-primary"
                              onClick={() => handleToggleReportModal()}
                            >
                              Relatório email
                            </button>
                          </>
                        </VisibilityByUserRoles>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleToggleFilter}
                        >
                          <i className="fas fa-filter" /> Filtrar
                        </button>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              </CardHeader>
              <Row className="ml-4">
                {
                  numberRegister > 0
                  ?
                  <div className="text-black">Registros encontrados {LIMIT*(offset-1)+1} - {numberRegister <= LIMIT ?  numberRegister : LIMIT*offset} de {numberRegister}</div>
                  :
                  <div className="text-black">Nenhum registro encontrado</div>
                }
              </Row>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Email</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Status</th>
                    {/* <VisibilityByUserRoles roles={["master", "operational", "regional_manager"]}> */}
                      <th scope="col">Hierarquia</th>
                    {/* </VisibilityByUserRoles> */}
                    <th scope="col">Ações</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((item, index) => (
                      <tr key={item.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">{item.name}</span>
                            </Media>
                          </Media>
                        </th>
                        <td>{item.email}</td>
                        <td>{item.cpf}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={`${
                                item.active === 'active'
                                  ? 'bg-success'
                                  : item.active === 'wait'
                                  ? 'bg-warning'
                                  : 'bg-danger'
                                }`}
                                />
                            {item.active_i18n}
                          </Badge>
                        </td>
                        {/* <VisibilityByUserRoles roles={["master", "operational", "regional_manager"]}> */}
                          <td>{item.main_role}</td>
                        {/* </VisibilityByUserRoles> */}
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <VisibilityByUserRoles roles={["master", "operational", "regional_manager", "master_corban", "master_subest", "master_store"]} >
                                  <DropdownItem onClick={() => {
                                    handleActiveOrDeactivate(
                                      item.active === 'active' ? 'deleted' : 'active',
                                      item.id,
                                      index
                                    );
                                  }}>
                                    <span>
                                      <i className={`fas ${
                                    item.active === 'active'
                                      ? 'fa-times'
                                      : 'fa-check'
                                  }`} /> {item.active === 'active' ? 'Desativar' : 'Ativar'}
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                                <VisibilityByUserRoles roles={["master", "operational", "regional_manager"]} >
                                  <DropdownItem onClick={() => {
                                    handleDestroyUser(
                                      item.id
                                    );
                                  }}>
                                    <span>
                                      <i className='fas fa-times' /> Excluir Usuário
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                                <VisibilityByUserRoles roles={["master", "operational", "regional_manager"]}>
                                  <Link
                                    to={`/admin/users/${item.id}/logs`}
                                    className="dropdown-item"
                                  >
                                    <span>
                                      <i className="fas fa-file" /> Log de Alterações
                                    </span>
                                  </Link>
                                </VisibilityByUserRoles>
                                <VisibilityByUserRoles roles={["master", "operational", "regional_manager", "master_corban", "master_subest"]} >
                                  <Link
                                    to={`/admin/users/${item.id}/editar`}
                                    className="dropdown-item"
                                  >
                                    <span>
                                      <i className="fas fa-edit" /> Editar
                                    </span>
                                  </Link>
                                </VisibilityByUserRoles>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        {/* )} */}
                      </tr>
                    ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={Number(pages)}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setFilterStatus(e.target.value)}
                    value={filterStatus}
                  >
                    <option value="">Todos</option>
                    <option value="0">Ativo</option>
                    <option value="1">Excluido</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Digite o Email"
                    name="email"
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </FormGroup>
                {
                  user && (user.roles[0].name == "master" || user.roles[0].name == "operational") ?
                    <FormGroup>
                      <label className="form-control-label" htmlFor="role">
                        Role
                      </label>
                      <CustomInput
                        type="select"
                        id="role"
                        name="role"
                        onChange={(e) => setFilterRole(e.target.value)}
                        value={filterRole}
                      >
                        {Roles.map((role) => (
                          <option value={role.value}>{role.name}</option>
                        ))}
                      </CustomInput>
                    </FormGroup>
                  :
                    false
                }
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={reportModal}
        toggle={handleToggleReportModal}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleReportModal}>Insira o email para solicitar o relatorio</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email_report">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email_report"
                    name="email_report"
                    placeholder="Digite o Email"
                    onChange={(e) => setEmailReport(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleReportModal}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickReportModal}>
            Solicitar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Users;
