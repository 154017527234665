import MenuRankingTable from 'components/MenuRankingTable';
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';


const RankingSubTable = (props) => {
  const { sortContractValue, sortContractMargin, setSortContractMargin, setSortContractValue, subs, bussinessDay, handleToggleFilter, days, filterData  } = props

  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      {/* Page content */}
      <Container className="mt--7 pt-5" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row>
                  <Col lg="7">
                    <h3 className="text-white">Subestabelecimentos</h3>
                  </Col>
                  <MenuRankingTable handleToggleFilter={handleToggleFilter} filterData={filterData}/>
                </Row>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Colocação</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Corban</th>
                    <th scope="col">Numero de Contratos</th>
                    <th scope="col">Valor Contrato</th>
                    <th scope="col">Valor Cobrado</th>
                    <th scope="col">Valor de Margem</th>
                    <th scope="col">Projeção</th>
                  </tr>
                </thead>
                <tbody>
                  {subs.map((sub, index) => (
                    <tr key={sub.id}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {index + 1}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{sub.nome}</td>
                      <td>{sub.corban_name}</td>
                      <td>{sub.total_contracts}</td>
                      <td>{sub.value_contracts}</td>
                      <td>{sub.value_charged}</td>
                      <td>{sub.value_margin}</td>
                      <td>R$ {((sub.value_margin_custom/bussinessDay) * days).toFixed(2).replace(".", ",")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default RankingSubTable;
