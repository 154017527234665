import React from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Button,
  Badge
} from 'reactstrap';

const LogSubVersions = ({title, versions, changeField}) => {

  return (
    <>
      <div className="header bg-gradient-info pb-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">{title}</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Evento</th>
                      <th scope="col">Alterado Em</th>
                      <th scope="col">Modificado Por</th>
                      <th scope="col">Dados Alterados</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {versions.map((version) => (
                      version && version[changeField] && version[changeField].map((change) => (
                        <tr key={change.id}>
                          <td>{change.event}</td>
                          <td>{change.created_at}</td>
                          <td>{change.author}</td>
                          <td>
                            <Table
                              className="align-items-center table-dark table-flush"
                              responsive
                            >
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">Atributo</th>
                                  <th scope="col">Antes</th>
                                  <th scope="col">Depois</th>
                                </tr>
                              </thead>
                              <tbody>
                                {change.changes.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.attribute}</td>
                                    <td>{item.old_value || "-"}</td>
                                    <td>{item.new_value || "-"}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      ))
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </div>

    </>
  );
};

export default LogSubVersions;
