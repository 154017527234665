import React, { useState, useEffect, useContext } from 'react';
import {
  Badge,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { toastError } from '../../util/toast';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import PaginationComponent from '../../components/PaginationComponent';

const LIMIT = 10;

const ListContracts = (props) => {
  const params = new URLSearchParams(props.location.search);

  const { id } = useParams();
  const [contracts, setContracts] = useState([]);
  const [customer, setCustomer] = useState({
    name: '',
    cpf_cnpj: '',
  });

  const [pages, setPages] = useState([]);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });

  const { showLoader, hideLoader } = useContext(LoadingContext);

  useEffect(() => {
    async function getAllCustomerContracts() {
      showLoader();

      try {
        const { data, headers } = await api.get(
          `contracts?q[customer_id_eq]=${id}&page=${offset}&limit=${LIMIT}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        const { data: dataCustomer } = await api.get(
          `customers/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        console.log(data)
        const totalPages = headers.total_pages

        const arrayPages = [];
        for (let i = 1; i <= totalPages; i++) {
          arrayPages.push(i);
        }

        setPages(arrayPages);
        setContracts(data.contracts);
        setCustomer({
          ...customer,
          name: dataCustomer.name,
          cpf_cnpj: dataCustomer.cpf_cnpj,
        });

        hideLoader();
      } catch (error) {
        hideLoader();

        let message =
          'Ops, não foi possível obter todos os contratos do cliente.';

        if (error.response.data) {
          message = error.response.data.message;
        }
        toastError(message);
      }
    }

    getAllCustomerContracts();
  }, [offset]);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body" />
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--5 mb-5" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">Cliente: {customer.name}</h3>
                <p className="text-white mb-0 font-weight-bold">
                  CPF:{' '}
                  {customer.cpf_cnpj &&
                    customer.cpf_cnpj.replace(
                      /^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/,
                      '$1.$2.$3-$4'
                    )}
                </p>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Status</th>
                    <th scope="col">Valor do Contrato</th>
                    <th scope="col">Valor Cobrado</th>
                    <th scope="col">Parcelas</th>
                    <th scope="col">Ult.Envio</th>
                  </tr>
                </thead>
                <tbody>
                  {contracts.map((contract) => (
                    <tr key={contract.idcontract}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {contract.contract_number}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td className="d-flex justify-content-center align-items-center">
                        <span
                          className={`${contract.bg_status} text-white p-1 rounded w-100 text-center`}
                        >
                          {contract.status}
                          {contract.nsu && <span> - {contract.nsu}</span>}
                        </span>
                      </td>
                      <td>
                        {contract.contract_value.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {contract.amount_charged.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>{contract.installments}x</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-yellow" />
                          {moment(contract.notifications[0].created_at).format(
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {pages.length > 1 && (
                <CardFooter className="py-4 bg-transparent">
                  <PaginationComponent
                    pages={pages}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListContracts;
