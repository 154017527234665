import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { formatarMoeda } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import DocumentUpload from '../../components/DocumentUpload';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';

import img from '../../assets/img/logo_novo_saque.png';

const FinishComponent = (props) => {


  return (
    <Container>
      <Row className="mt-8 d-flex align-items-center">
      <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
        <Card className="shadow-lg card-payment-info">
          <CardHeader className="card-payment_header">
            <div className="card-payment-info_container">
              <img src={img} className="img-fluid" alt="Logo Novo Saque" />
            </div>
          </CardHeader>
          <CardBody>
            <div className="d-flex flex-column justify-content-center align-items-center mb-3">
              <div
                className={`bg-danger text-white rounded-circle shadow-sm p-3 icon-check mb-2`}
              >
                <i className="fas fa-times m-0 p-0" />
              </div>
              <h1 className="text-danger text-center font-weight-800">
                Operação Cancelada!
              </h1>

            </div>

          </CardBody>
        </Card>
      </Col>
    </Row>
    </Container>
  );
};

export default FinishComponent;
