import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:5000/api/v1',
  // baseURL: 'https://nsaquehomolo.jogarjunto.com.br/api/v1',
  baseURL: 'https://sistema.novosaque.com.br/api/v1',
  // baseURL: 'https://homolog.novosaque.com.br/api/v1',
  // baseURL: 'https://nsaquehomolo.ultragate.com.br/api/v1',
  // baseURL: process.env.REACT_APP_API_URL,,
});

export default api;
