import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Button,
  Badge
} from 'reactstrap';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import Pagination from 'react-responsive-pagination';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { VisibilityByUserRoles, VisibilityUserMasterButton, VisibilityCreateCorban, VisibilityEditCorban } from '../../util/visibility';
import { States } from '../../variables/assistants';
import './style.css';
import Search from '../../components/Search';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';

const LIMIT = 10;

const Corban = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const [corbans, setCorbans] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const handleToggle = () => setModal(!modal);
  const [dataList, setDataList] = useState([]);
  const [pages, setPages] = useState([]);
  const [cnpj, setCnpj] = useState('');
  const [modal, setModal] = useState(false);
  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterCommission, setFilterCommission] = useState('');
  const [filterCorbanType, setFilterCorbanType] = useState('');
  const [filterLegalRepresentative, setFilterLegalRepresentative] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('')
  const [typeList, setTypeList] = useState({
    type: '',
    name: '',
  })

  const exportStoreRef = useRef(exportStoreRef)
  const [exportStoreData, setExportStoreData] = useState([])
  const [storeReportDisabled, setStoreReportDisabled] = useState(false)


  useEffect(() => {
    async function getCorbans() {
      showLoader();

      try {
        const response = await api.get(
          `corbans?page=${offset}&per_page=${LIMIT}${searchFilter}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        setCorbans(response.data);
        setNumberRegister(response.headers.total_count)

        const totalPages = response.headers.total_pages

        setPages(totalPages);

        if (offset > 1) {
          props.history.push({
            pathname: '/admin/corbans',
            search: `?page=${offset}`,
          });
        } else {
          params.delete('page');
          props.history.replace({
            search: params.toString(),
          });
        }


        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os correspondentes bancários.');
      }
    }

    getCorbans();
  }, [offset, searchFilter]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  async function export_store_report() {
    setStoreReportDisabled(true)
    try {
      const response = await api.get(
        `corbans/corban_export?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        })
        console.log(response.data)
      setExportStoreData(response.data)
      exportStoreRef?.current?.link.click();
    } catch(e) {

    }
    setStoreReportDisabled(false)
  }

  async function handleOnClick() {

    if (cnpj.length > 0) {
      try {
        const { data } = await api.get(
          `corbans?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cnpj}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setCorbans(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';
        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CNPJ.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(`corbans?q[entity_cpf_cnpj_cont]=${searchText}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      console.log(data)
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CNPJ');
    }
  }

  async function onChange(e) {
    setCnpj(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  const handleClickToggle = async ({ idcorban, name, type }) => {
    try {
      const { data } = await api.get(`corbans/${idcorban}/search?type=${type}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

    handleToggle()
    setDataList(data)
    setTypeList({
      type: type,
      name: name,
    })

    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo deu errado ao buscar os pagamentos do contrato.';
      }

      hideLoader();
      toastError(message);
    }
  };

  function onSelect(val) {
    setCnpj(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item["entity"].cpf_cnpj} - {item["entity"].name}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item["entity"].cpf_cnpj}`;
  }

  async function handlerOnClickFilter() {
    showLoader();
    if (params.get('page')) {
      setOffset(1);
    }

    let filterPath = '';

    if (cnpj.length > 0) {
      try {
        const { data } = await api.get(
          `corbans?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cnpj}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setCorbans(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';
        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    }

    if (filterStatus) {
      filterPath += `&q[status_eq]=${filterStatus}`;
    }

    if (filterEmail) {
      filterPath += `&q[users_email_cont]=${filterEmail}`;
    }

    if (filterName) {
      filterPath += `&q[entity_name_cont]=${filterName}`;
    }

    if (filterState) {
      filterPath += `&q[entity_address_state_eq]=${filterState}`;
    }

    if (filterCommission) {
      filterPath += `&q[entity_has_commission_eq]=${filterCommission}`;
    }

    if (filterCorbanType) {
      filterPath += `&q[corban_type_eq]=${filterCorbanType}`;
    }

    if (filterLegalRepresentative) {
      filterPath += `&legal_representative=${filterLegalRepresentative}`;
    }

    hideLoader();
    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    setFilterStatus('')
    setFilterName('')
    setFilterEmail('')
    setFilterState('')
    setFilterCommission('')
    setFilterLegalRepresentative('')
    setSearchFilter('')
    handleToggleFilter()
  }

  function handleDeleteCorban(corbanID) {
    swal({
      title: 'Atenção',
      text: 'Esta operação é irreversível. Confirma a exclusão?',
      icon: 'warning',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (option) => {
      if (option) {
        showLoader();
        try {
          const { status } = await api.delete(
          `corbans/${corbanID}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        hideLoader();
        if(status === 200){
          const corbansFiltered = corbans.filter((corban) => corban.id !== corbanID);
          setCorbans(corbansFiltered);
          toastSuccess('Corban excluido com sucesso!');
        }
        } catch {
          hideLoader();
          let message =
            'Ops, não foi possível excluir o corban, por favor tente novamente mais tarde';

          toastError(message);
        }
      }
    });
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <div className="text-right">
                <VisibilityCreateCorban />
              </div>
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Correspondentes Bancários</h3>
                      {/* <Search
                        getItemValue={getItemValue}
                        autoCompleteData={autocompleteData}
                        renderItem={renderItem}
                        cpf_cnpj={cnpj}
                        onChange={onChange}
                        onSelect={onSelect}
                        handleOnClick={handleOnClick}
                        placeHolder={"Buscar pelo CNPJ"}
                      /> */}
                    </Col>
                    <Col>
                      <>
                        <div className="d-flex justify-content-end h-100 align-items-end">
                          {
                            <>
                              <button
                                disabled={storeReportDisabled}
                                className="btn btn-primary"
                                onClick={export_store_report}
                              >
                                Exportar Corbans
                              </button>
                              <CSVLink
                                data={exportStoreData}
                                filename={'corban_report.csv'}
                                separator={';'}
                                ref={exportStoreRef}
                              ></CSVLink>
                            </>
                          }
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleToggleFilter}
                          >
                            <i className="fas fa-filter" /> Filtrar
                          </button>
                        </div>
                      </>
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {numberRegister > 0 ? (
                    <div className="text-white">
                      Registros encontrados {LIMIT * (offset - 1) + 1} -{' '}
                      {numberRegister <= LIMIT
                        ? numberRegister
                        : LIMIT * offset}{' '}
                      de {numberRegister}
                    </div>
                  ) : (
                    <div className="text-white">Nenhum registro encontrado</div>
                  )}
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CNPJ</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Status</th>
                      <th />
                      <th />
                      <th />
                      <th scope="col">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {corbans.map((corban) => (
                      <tr key={corban.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {corban.entity.cpf_cnpj.replace(
                                  /^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/,
                                  '$1.$2.$3-$4'
                                )}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{corban.entity.name}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={`${
                                corban.status === 'active'
                                  ? 'bg-success'
                                  : corban.active === 'wait'
                                  ? 'bg-warning'
                                  : 'bg-danger'
                              }`}
                            />
                            {corban.status == 'active'
                              ? 'Ativo'
                              : corban.status == 'disabled'
                              ? 'Desativado'
                              : 'Em espera'}
                          </Badge>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {/* <Link
                              to={`/admin/contracts/corban/${corban.id}`}
                              className="dropdown-item"
                            >
                              <span>
                                <i className="fas fa-file" /> Contratos
                              </span>
                            </Link> */}
                              <VisibilityByUserRoles
                                roles={['master', 'operational']}
                              >
                                <Link
                                  to={`/admin/corbans/${corban.id}/logs`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-file" /> Log de
                                    Alterações
                                  </span>
                                </Link>
                              </VisibilityByUserRoles>
                              <VisibilityByUserRoles
                                roles={['master', 'operational']}
                              >
                                <Link
                                  to={`/admin/corbans/${corban.id}`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-edit" /> Editar
                                  </span>
                                </Link>
                              </VisibilityByUserRoles>
                              <VisibilityByUserRoles
                                roles={['master', 'operational']}
                              >
                                <Link
                                  onClick={() => handleDeleteCorban(corban.id)}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i
                                      className="fas fa-trash"
                                      aria-hidden="true"
                                    />{' '}
                                    Excluir
                                  </span>
                                </Link>
                              </VisibilityByUserRoles>
                              <DropdownItem
                                onClick={() =>
                                  handleClickToggle({
                                    idcorban: corban.id,
                                    name: corban.entity.name,
                                    type: 'subestablishments',
                                  })
                                }
                              >
                                <span>
                                  <i className="fas fa-file-invoice-dollar" />{' '}
                                  Listagem de Subestabelecimentos
                                </span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  handleClickToggle({
                                    idcorban: corban.id,
                                    name: corban.entity.name,
                                    type: 'stores',
                                  })
                                }
                              >
                                <span>
                                  <i className="fas fa-file-invoice-dollar" />{' '}
                                  Listagem de Lojas
                                </span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  handleClickToggle({
                                    idcorban: corban.id,
                                    name: corban.entity.name,
                                    type: 'sellers',
                                  })
                                }
                              >
                                <span>
                                  <i className="fas fa-file-invoice-dollar" />{' '}
                                  Listagem de Vendedores
                                </span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 bg-transparent">
                  <Row>
                    <Col
                      lg={{ size: 6, offset: 6 }}
                      className="d-flex justify-content-end"
                    >
                      <Pagination
                        current={offset}
                        total={Number(pages)}
                        onPageChange={setOffset}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="cnpj">
                    Cnpj
                  </label>
                  <InputMask
                    mask="99.999.999/9999-99"
                    type="text"
                    className="form-control"
                    id="cnpj"
                    name="cnpj"
                    placeholder="Digite o CNPJ"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setFilterStatus(e.target.value)}
                    value={filterStatus}
                  >
                    <option value="">Todos</option>
                    <option value="0">Ativo</option>
                    <option value="1">Inativo</option>
                    <option value="2">Em espera</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome Fantasia
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome Fantasia"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Email"
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="legal_representative"
                  >
                    Representante Legal
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="legal_representative"
                    name="legal_representative"
                    placeholder="Digite o Nome Fantasia"
                    value={filterLegalRepresentative}
                    onChange={(e) =>
                      setFilterLegalRepresentative(e.target.value)
                    }
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label className="form-control-label" htmlFor="state">
                    Estado
                  </Label>
                  <CustomInput
                    type="select"
                    id="state"
                    name="state"
                    onChange={(e) => setFilterState(e.target.value)}
                    value={filterState}
                  >
                    {States.map((state) => (
                      <option value={state.value}>{state.name}</option>
                    ))}
                  </CustomInput>
                </FormGroup>
                {/* <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="commission"
                  >
                    Comissão Ativa
                  </Label>
                  <CustomInput
                    type="select"
                    id="commission"
                    name="commission"
                    onChange={(e) => setFilterCommission(e.target.value)}
                    value={filterCommission}
                  >
                    <option value="">Selecione</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="corban_type"
                  >
                    Tipo de Corban
                  </Label>
                  <CustomInput
                    type="select"
                    id="corban_type"
                    name="corban_type"
                    onChange={(e) => setFilterCorbanType(e.target.value)}
                    value={filterCorbanType}
                  >
                    <option value="">Selecione</option>
                    <option value="0">Interno</option>
                    <option value="1">Externo</option>
                  </CustomInput>
                </FormGroup> */}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={handleToggle}>
          {typeList.type == 'subestablishments' ? (
            <h3>Informações sobre os Subestabelecimentos da Corban</h3>
          ) : (
            false
          )}
          {typeList.type == 'stores' ? (
            <h3>Informações sobre as Lojas da Corban</h3>
          ) : (
            false
          )}
          {typeList.type == 'sellers' ? (
            <h3>Informações sobre os Vendedores da Corban</h3>
          ) : (
            false
          )}
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <p className="mb-0">
                  <strong>Corban</strong>: {typeList.name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="bg-default shadow">
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" className="id">
                          ID
                        </th>
                        <th scope="col" className="name">
                          Nome
                        </th>
                        {typeList.type != 'sellers' ? (
                          <th scope="col" className="social_reason">
                            Razão Social
                          </th>
                        ) : (
                          false
                        )}
                        <th scope="col" className="CPF/CNPJ">
                          CPF/CNPJ
                        </th>
                        {typeList.type == 'sellers' ? (
                          <th scope="col" className="phone">
                            Telefone
                          </th>
                        ) : (
                          false
                        )}
                        {typeList.type != 'sellers' ? (
                          <th scope="col" className="status">
                            Status
                          </th>
                        ) : (
                          false
                        )}
                        <th scope="col" className="has_commission">
                          Tem Comissão?
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map((data) => (
                        <tr key={data.id}>
                          <td className="id">{data.id}</td>
                          <td className="name">{data.entity.name}</td>
                          {typeList.type != 'sellers' ? (
                            <td className="social_reason">
                              {data.entity.social_reason}
                            </td>
                          ) : (
                            false
                          )}
                          <td className="CPF_CNPJ">{data.entity.cpf_cnpj}</td>
                          {typeList.type == 'sellers' ? (
                            <td className="phone">{data.mobile}</td>
                          ) : (
                            false
                          )}
                          {typeList.type != 'sellers' ? (
                            <td className="status">{data.status_i18n}</td>
                          ) : (
                            false
                          )}
                          {data.entity.has_commission ? (
                            <td className="has_commision">sim</td>
                          ) : (
                            <td className="has_commision">não</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Corban;
