import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingContext } from '../../context/loading/LoadingContext';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import StoreForm from '../../components/StoreForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input
} from 'reactstrap';

import Select from 'react-select';
import { VisibilityByUserRoles } from 'util/visibility';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';

const StoreDetail = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [store, setStore] = useState({
    social_reason:'',
    name: '',
    cpf_cnpj: '',
    user_cpf: '',
    user_name: '',
    user_email: '',
    user_mobile: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    bank_name: '',
    agency_number: '',
    number_account: '',
    has_commission: '',
    active_base_trigger: '',
    commission_type: '',
    value: '',
    trigger: '',
    store_kind: 0,
    agency_digit: '',
    account_digit: '',
    internal_code: '',
  });
  const { register, handleSubmit, errors, setError } = useForm();
  const [ contactFields, setContactFields] = useState([
    {mobile: '', email: '', type_contact: ''}
  ])

  const [ banks, setBanks ] = useState([])
  const [ corbans, setCorbans ] = useState([])
  const [ subs, setSubs ] = useState([])
  const [ kind, setKind ] = useState('pj')

  async function getStore() {
    showLoader();
    try {
      const { data } = await api.get(`stores/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setContactFields(data.contact)
      // setStore(data);

      setStore({
        ...data,
        has_commission: data.entity.has_commission ? true : false,
        commission_type: data.entity.has_commission ? (data.commission[0].commission_type == "percentage" ? '0' : '1') : '',
        commission_fgts: data.entity.commission_fgts,
        value: data.entity.has_commission ? (data.commission[0].commission_type == "percentage" ? `${data.commission[0].value}%` : `R$ ${data.commission[0].value}`) : '',
        active_base_trigger: data.commission[0] ? data.commission[0].trigger != null && data.commission[0].trigger != 0 ? (data.entity.has_commission ? 'yes' : '') : 'no' : '',
        trigger: data.entity.has_commission && (data.commission[0].trigger != null && data.commission[0].trigger != 0) ? `${data.commission[0].trigger}%` : '',
        entity_id: data.entity.id,
        commission_id: data.entity.has_commission ? data.commission[0].id : '',
      });

      hideLoader();
    } catch (error) {
      hideLoader();
    }
  }


  useEffect(()=> {
    getStore()
  }, [])


  function parseCurrency(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace(".", "").replace(",", ".").replace("R$", ""))
    } else {
      return value
    }
  }

  function parsePercentage(value) {
    if(typeof value === 'string' || value instanceof String){
      return (value.replace("%", "").replace(",", "."))
    } else {
      return value
    }
  }

  function parseDigit(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "x")
    } else {
      return value
    }
  }

  function parseAccount(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "")
    } else {
      return value
    }
  }

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0"></h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit()}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações da Loja
                  </h6>

                  <div className="pl-lg-4">
                    <Row>
                      <VisibilityByUserRoles roles={["master", "operational", "commercial_manager", "regional_manager"]}>
                        <Col lg="6">
                          <label className="form-control-label" htmlFor="social_reason">
                            Corban
                          </label>
                          <p>
                            {store.corban_name}
                          </p>
                        </Col>

                        <Col lg="6">
                          <label className="form-control-label" htmlFor="social_reason">
                            Subestabelecimento
                          </label>
                          <p>
                            {store.sub_name}
                          </p>
                        </Col>
                      </VisibilityByUserRoles>
                    </Row>
                    <Row>
                      <VisibilityByUserRoles roles={["master", "operational", "master_corban", "user_corban_register", "user_corban"]}>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="internal_code"
                            >
                              Código interno
                            </label>
                            <p>
                              {store.internal_code}
                            </p>
                          </FormGroup>
                        </Col>
                      </VisibilityByUserRoles>
                    </Row>

                    <Row>
                      <Col lg="6 mb-4">
                        <label className="form-control-label" htmlFor="store_kind">
                          Tipo de Loja
                        </label>
                        <p>
                          {store.kind_i18n}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      {
                        store.kind == 'pj' && (
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="social_reason">
                                Razão social
                              </label>
                              <p>
                                {store.entity ? store.entity.social_reason : ''}
                              </p>
                            </FormGroup>
                          </Col>
                        )
                      }
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            {store.kind == 'pj' ? "Nome Fantasia" : "Nome"}
                          </label>
                          <p>
                            {store.entity ? store.entity.name : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="cpf_cnpj"
                          >
                            {store.kind == 'pj' ? "CNPJ" : "CPF"}
                          </label>
                          <p>
                            {store.entity ? store.entity.cpf_cnpj : ''}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Representante legal
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="user_cpf"
                          >
                          CPF
                          </label>
                          <p>
                            {store.legal_representate ? store.legal_representate.cpf : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="user_name">
                            Nome
                          </label>
                          <p>
                            {store.legal_representate ? store.legal_representate.name : ''}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="user_email">
                            Email do representante (Login de acesso da loja)
                          </label>
                          <p>
                            {store.legal_representate ? store.legal_representate.email : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="user_mobile"
                          >
                            Celular
                          </label>
                          <p>
                            {store.legal_representate ? store.legal_representate.mobile : ''}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Endereço da Loja
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="zip_code"
                          >
                            CEP
                          </label>
                          <p>
                            {store.address ? store.address.zip_code : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="street"
                          >
                            Endereço
                          </label>
                          <p>
                            {store.address ? store.address.street : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number"
                          >
                            Número
                          </label>
                          <p>
                            {store.address ? store.address.number : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="district"
                          >
                            Bairro
                          </label>
                          <p>
                            {store.address ? store.address.district : ''}
                          </p>

                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">
                            Cidade
                          </label>
                          <p>
                            {store.address ? store.address.city : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="state">
                            Estado
                          </label>
                          <p>
                            {store.address ? store.address.state : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="complement"
                          >
                            Complemento
                          </label>
                          <p>
                            {store.address ? store.address.complement : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Dados Bancários
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <p>
                            {store.bank_account ? `${store.bank_account.number_bank} ${store.bank_account.name_bank}` : ''}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Agência
                          </label>
                          <p>
                            {store.bank_account ? store.bank_account.agency_account : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <p>
                            {store.bank_account ? store.bank_account.agency_digit : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number_account"
                          >
                            Conta
                          </label>
                          <p>
                            {store.bank_account ? store.bank_account.number_account : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <p>
                            {store.bank_account ? store.bank_account.account_digit : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="account_kind">Tipo de conta</Label>
                        <p>
                          {store.bank_account ? store.bank_account.kind == 0 ? 'Conta Corrente' : 'Poupança' : ''}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Cadastro de comissão
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="2">
                        <Label for="has_commission">Definir comissão</Label>
                        <p>
                          {store.has_commission ? 'Sim' : 'Não'}
                        </p>
                      </Col>
                      {
                        store.entity && store.entity.has_commission ?

                          <Col lg="2">
                            <Label for="commission_type">Tipo de comissão</Label>
                            <p>
                              {store.commission_type == 0 ? 'Porcentagem' : 'Valor Fixo'}
                            </p>
                          </Col>
                        :
                          false
                      }
                      {
                        store.has_commission && store.commission_type == 0 ?
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="value">
                              Valor percentual
                            </label>
                            <p>
                              {store.value}
                            </p>
                          </FormGroup>
                        </Col>
                        :
                        store.has_commission && store.commission_type == 1 ?
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="value">
                                Valor Fixo
                              </label>
                              <p>
                                {store.value}
                              </p>
                            </FormGroup>
                          </Col>
                          :
                            false
                      }
                    </Row>
                    <Row>
                       {
                        store.has_commission && store.active_base_trigger?
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="trigger">
                                Bônus
                              </label>
                              <p>
                                {store.trigger}
                              </p>

                            </FormGroup>
                          </Col>
                        :
                          false
                      }
                    </Row>

                    <Row>
                      {
                        store.has_commission &&
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="commission_fgts">
                                Comissão Fgts
                              </label>
                              <p>
                                {store.commission_fgts}%
                              </p>
                            </FormGroup>
                          </Col>
                      }
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Contatos
                  </h6>
                  <div className="pl-lg-4">
                    { contactFields.map((contactField, index) => (
                      <div key={index}>
                        <Row className="mt-4">
                        <Col lg="2">
                            {index == 0 && (
                              <Label for="exampleselect">Tipo de contato</Label>
                            )}
                            <p>
                              {contactField.type_contact == 'email' ? "Email" : "Telefone"}
                            </p>

                          </Col>
                          {
                            contactField.type_contact == "mobile" ?
                            <Col lg="3">
                              <FormGroup>
                                {index == 0 && (

                                  <label className="form-control-label" htmlFor="mobile_contact">
                                    Descrição
                                  </label>
                                )}
                                <p>
                                  {contactField.mobile}
                                </p>

                              </FormGroup>
                            </Col>
                            :
                            contactField.type_contact == "email" ?
                            <Col lg="3">
                              <FormGroup>
                                {index == 0 && (
                                  <label className="form-control-label" htmlFor="email_contact">
                                    Descrição
                                  </label>
                                )}
                                <p>
                                  {contactField.email}
                                </p>

                              </FormGroup>
                            </Col>
                            :
                            false
                          }

                        </Row>
                      </div>
                    ))}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StoreDetail;
