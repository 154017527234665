import React from 'react';
import { Col, FormGroup, Row, Input } from 'reactstrap';

const FormCommissionFgts = ({
  handleOnChangeCommissionFgts,
  insertPercentage,
  register,
  errors,
  index,
  withoutLabel,
  commission,
  widthSecure,
}) => {
  return (
    <div key={index}>
      <Row>
        <Col lg="4">
          <FormGroup>
            {index == 0 && (
              <label className="form-control-label" htmlFor="basic">
                {withoutLabel ? (
                  false
                ) : (
                  <>TABELA {widthSecure ? 'COM SEGURO' : 'SEM SEGURO'}</>
                )}
              </label>
            )}
            <Input
              type="text"
              name="basic"
              className="form-control form-control-alternative"
              id="basic"
              value={`${
                widthSecure
                  ? commission.name.replace('| Juros ', '+ | Juros ')
                  : commission.name
              }`}
              inputRef={register({ required: true })}
              disabled
            />
          </FormGroup>
        </Col>
        <>
          {widthSecure ? (
            <Col lg="4">
              <FormGroup>
                {index == 0 && (
                  <label className="form-control-label" htmlFor="value">
                    Valor
                  </label>
                )}
                <Input
                  type="text"
                  name="value_greater_thousand"
                  className="form-control form-control-alternative"
                  id="value_greater_thousand"
                  // placeholder="99%"
                  value={commission && commission.value_greater_thousand}
                  inputRef={register({
                    required: true,
                  })}
                  onChange={(event) =>
                    handleOnChangeCommissionFgts(event, commission.id, index)
                  }
                  onBlur={(event) => insertPercentage(event, index)}
                />
                {errors.value && (
                  <small className="text-danger">Valor é obrigatório</small>
                )}
              </FormGroup>
            </Col>
          ) : (
            <Col lg="4">
              <FormGroup>
                {index == 0 && (
                  <label className="form-control-label" htmlFor="value">
                    Valor
                  </label>
                )}
                <Input
                  type="text"
                  name="value_percentage"
                  className="form-control form-control-alternative"
                  id="value_percentage"
                  // placeholder="99%"
                  value={commission && commission.value}
                  inputRef={register({
                    required: true,
                  })}
                  onChange={(event) =>
                    handleOnChangeCommissionFgts(event, commission.id, index)
                  }
                  onBlur={(event) => insertPercentage(event, index)}
                />
                {errors.value && (
                  <small className="text-danger">Valor é obrigatório</small>
                )}
              </FormGroup>
            </Col>
          )}
        </>

        {/* <Col lg="2">
          Habilitado?
          <Input
            type="select"
            id="enabled"
            value={commission && commission.enabled}
            name="enabled"
            onChange={(event) =>
              handleOnChangeCommissionFgts(event, commission.id, index)
            }
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </Input>
        </Col> */}
      </Row>
    </div>
  );
};

export default FormCommissionFgts;
