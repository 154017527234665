import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import Pagination from 'react-responsive-pagination';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { VisibilityByUserRoles, VisibilityCreateSubestablishment, VisibilityEditSubestablishment } from '../../util/visibility';
import './style.css';
import Search from '../../components/Search';
import { States } from '../../variables/assistants'
import swal from 'sweetalert';
import InputMask from 'react-input-mask';

const LIMIT = 10;

const Subestablishment = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const [subestablishments, setSubestablishments] = useState([]);
  const [corbans, setCorbans] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);
  const [cnpj, setCnpj] = useState('');
  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterCorban, setFilterCorban] = useState('');
  const [filterCommission, setFilterCommission] = useState('');
  const [filterLegalRepresentative, setFilterLegalRepresentative] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('')

  const sortByText = (a, b, order = 'ASC') => {
    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (order === 'ASC') {
        return diff;
    }

    return -1 * diff;
  }

  const exportStoreRef = useRef(exportStoreRef)
  const [exportStoreData, setExportStoreData] = useState([])
  const [storeReportDisabled, setStoreReportDisabled] = useState(false)


  async function getCorbans() {
    // showLoader()

    try {
      const response = await api.get(
        `/corbans/searchs`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if(response.data){
        setCorbans(response.data.sort((a, b) => sortByText(a, b, 'ASC')));
      }

      // hideLoader();

    } catch (error) {
      // hideLoader();
      // toastError('Ops, não foi possível lista as corbans.');
    }
  }

  async function export_store_report() {
    setStoreReportDisabled(true)
    try {
      const response = await api.get(
        `subestablishments/subestablishment_export?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        })
        console.log(response.data)
      setExportStoreData(response.data)
      exportStoreRef?.current?.link.click();
    } catch(e) {

    }
    setStoreReportDisabled(false)
  }


  async function getSubestablishments() {
    showLoader();

    try {
      const response = await api.get(
        `subestablishments?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setSubestablishments(response.data);
      setNumberRegister(response.headers.total_count)

      const totalPages = response.headers.total_pages

      setPages(totalPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/subestablishments',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os subestabelecimentos.');
    }
  }

  useEffect(() => {

    getCorbans()
    getSubestablishments();
  }, [offset, searchFilter]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  async function handleOnClick() {

    if (cnpj.length > 0) {
      try {
        const { data } = await api.get(
          `subestablishments?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cnpj}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setSubestablishments(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CNPJ.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(`subestablishments?q[entity_cpf_cnpj_cont]=${searchText}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CNPJ');
    }
  }

  async function onChange(e) {
    setCnpj(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  function onSelect(val) {
    setCnpj(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item["entity"].cpf_cnpj} - {item["entity"].name}
      </div>
    );
  }

  async function enableSub(sub_id) {
    try {
      const { data } = await api.put(`subestablishments/${sub_id}`, {subestablishment: {status: "active"}}, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      getSubestablishments();
    } catch (e) {
      console.error('Ops, houve um erro ao atualizar a Subestabelecimento');
    }

  }

  async function disabledSub(sub_id) {
    try {
      const { data } = await api.put(`subestablishments/${sub_id}`, {subestablishment: {status: "disabled"}}, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      getSubestablishments();
    } catch (e) {
      console.error('Ops, houve um erro ao atualizar a Subestabelecimento');
    }

  }

  async function removeSub(sub_id) {
    try {
      const { data } = await api.delete(`subestablishments/${sub_id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      getSubestablishments();
    } catch (e) {
      console.log(e.response)
      toastError(e.response.data.error);
      console.error('Ops, houve um erro ao excluir a Subestabelecimento');
    }

  }


  function getItemValue(item) {
    return `${item["entity"].cpf_cnpj}`;
  }

  async function handlerOnClickFilter() {
    showLoader();
    if (params.get('page')) {
      setOffset(1);
    }

    let filterPath = '';

    if (cnpj.length > 0) {
      try {
        const { data } = await api.get(
          `subestablishments?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cnpj}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setSubestablishments(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';
        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    }

    if (filterStatus) {
      filterPath += `&q[status_eq]=${filterStatus}`;
    }

    if (filterName) {
      filterPath += `&q[entity_name_cont]=${filterName}`;
    }

    if (filterEmail) {
      filterPath += `&q[users_email_cont]=${filterEmail}`;
    }

    if (filterState) {
      filterPath += `&q[entity_address_state_eq]=${filterState}`;
    }

    if (filterCommission) {
      filterPath += `&q[entity_has_commission_eq]=${filterCommission}`;
    }

    if (filterCorban) {
      filterPath += `&q[corban_id_eq]=${filterCorban}`;
    }

    if (filterLegalRepresentative) {
      filterPath += `&legal_representative=${filterLegalRepresentative}`;
    }
    hideLoader();
    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    setFilterStatus('')
    setFilterName('')
    setFilterEmail('')
    setFilterState('')
    setFilterCommission('')
    setFilterCorban('')
    setFilterLegalRepresentative('')
    setSearchFilter('')
    handleToggleFilter()
  }

  function handleDeleteSubestablishment(subestablishmentID) {
    swal({
      title: 'Atenção',
      text: 'Esta operação é irreversível. Confirma a exclusão?',
      icon: 'warning',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (option) => {
      if (option) {
        showLoader();
        try {
          const { status } = await api.delete(
          `subestablishments/${subestablishmentID}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        hideLoader();
        if(status === 200){
          const subestablishmentsFiltered = subestablishments.filter((subestablishment) => subestablishment.id !== subestablishmentID);
          setSubestablishments(subestablishmentsFiltered);
          toastSuccess('Subestablishment excluido com sucesso!');
        }
        } catch {
          hideLoader();
          let message =
            'Ops, não foi possível excluir o subestablishment, por favor tente novamente mais tarde';

          toastError(message);
        }
      }
    });
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <div className="text-right">
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban',
                    'user_corban_register',
                  ]}
                >
                  <Link
                    className="btn btn-success mb-3 mt-2"
                    to="/admin/subestablishments/create"
                  >
                    <i className="fas fa-plus" /> Criar novo Subestabelecimento
                  </Link>
                </VisibilityByUserRoles>
              </div>
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Subestabelecimento</h3>
                      {/* <Search
                        getItemValue={getItemValue}
                        autoCompleteData={autocompleteData}
                        renderItem={renderItem}
                        cpf_cnpj={cnpj}
                        onChange={onChange}
                        onSelect={onSelect}
                        handleOnClick={handleOnClick}
                        placeHolder="Buscar pelo CNPJ"
                      /> */}
                    </Col>
                    <Col>
                      {
                        <>
                          <div className="d-flex justify-content-end h-100 align-items-end">
                            {
                              <>
                                <button
                                  disabled={storeReportDisabled}
                                  className="btn btn-primary"
                                  onClick={export_store_report}
                                >
                                  Exportar Subestabelecimentos
                                </button>
                                <CSVLink
                                  data={exportStoreData}
                                  filename={'subestablishment_report.csv'}
                                  separator={';'}
                                  ref={exportStoreRef}
                                ></CSVLink>
                              </>
                            }
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleToggleFilter}
                            >
                              <i className="fas fa-filter" /> Filtrar
                            </button>
                          </div>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {numberRegister > 0 ? (
                    <div className="text-white">
                      Registros encontrados {LIMIT * (offset - 1) + 1} -{' '}
                      {numberRegister <= LIMIT
                        ? numberRegister
                        : LIMIT * offset}{' '}
                      de {numberRegister}
                    </div>
                  ) : (
                    <div className="text-white">Nenhum registro encontrado</div>
                  )}
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CNPJ</th>
                      <th scope="col">Nome</th>
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <th scope="col">Corban</th>
                      </VisibilityByUserRoles>
                      <th scope="col">Status</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subestablishments.map((subestablishment) => (
                      <tr key={subestablishment.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {subestablishment.entity.cpf_cnpj.replace(
                                  /^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/,
                                  '$1.$2.$3/$4-$5'
                                )}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{subestablishment.entity.name}</td>
                        <VisibilityByUserRoles
                          roles={['master', 'operational', 'regional_manager']}
                        >
                          <td>{subestablishment.corban_name}</td>
                        </VisibilityByUserRoles>
                        <td>{subestablishment.status_i18n}</td>

                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            {(subestablishment.status == 'waiting' ||
                              subestablishment.status == 'disabled') && (
                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'operational',
                                  'regional_manager',
                                ]}
                              >
                                <DropdownItem
                                  onClick={() => enableSub(subestablishment.id)}
                                >
                                  <span>
                                    <i className="fas fa-check" /> Aprovar
                                    subestabelecimento
                                  </span>
                                </DropdownItem>
                              </VisibilityByUserRoles>
                            )}
                            {subestablishment.status == 'active' && (
                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'operational',
                                  'regional_manager',
                                ]}
                              >
                                <DropdownItem
                                  onClick={() =>
                                    disabledSub(subestablishment.id)
                                  }
                                >
                                  <span>
                                    <i className="fas fa-times" /> Desativar
                                    subestabelecimento
                                  </span>
                                </DropdownItem>
                              </VisibilityByUserRoles>
                            )}
                            <VisibilityByUserRoles
                              roles={[
                                'master',
                                'operational',
                                'regional_manager',
                              ]}
                            >
                              <DropdownItem
                                onClick={() => removeSub(subestablishment.id)}
                              >
                                <span>
                                  <i className="fas fa-times" /> Excluir
                                  subestabelecimento
                                </span>
                              </DropdownItem>
                            </VisibilityByUserRoles>
                            <VisibilityByUserRoles
                              roles={[
                                'master',
                                'operational',
                                'regional_manager',
                              ]}
                            >
                              <Link
                                to={`/admin/subestablishments/${subestablishment.id}/logs`}
                                className="dropdown-item"
                              >
                                <span>
                                  <i className="fas fa-file" /> Log de
                                  Alterações
                                </span>
                              </Link>
                            </VisibilityByUserRoles>
                            <VisibilityByUserRoles
                              roles={[
                                'master',
                                'operational',
                                'master_corban',
                                'user_corban_register',
                                'regional_manager',
                              ]}
                            >
                              <Link
                                to={`/admin/subestablishments/${subestablishment.id}`}
                                className="dropdown-item"
                              >
                                <span>
                                  <i className="fas fa-edit" /> Editar
                                </span>
                              </Link>
                            </VisibilityByUserRoles>
                            <VisibilityByUserRoles
                              roles={['master', 'operational']}
                            >
                              <Link
                                onClick={() =>
                                  handleDeleteSubestablishment(
                                    subestablishment.id
                                  )
                                }
                                className="dropdown-item"
                              >
                                <span>
                                  <i
                                    className="fas fa-trash"
                                    aria-hidden="true"
                                  />{' '}
                                  Excluir
                                </span>
                              </Link>
                            </VisibilityByUserRoles>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 bg-transparent">
                  <Row>
                    <Col
                      lg={{ size: 6, offset: 6 }}
                      className="d-flex justify-content-end"
                    >
                      <Pagination
                        current={offset}
                        total={Number(pages)}
                        onPageChange={setOffset}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="cnpj">
                    Cnpj
                  </label>
                  <InputMask
                    mask="99.999.999/9999-99"
                    type="text"
                    className="form-control"
                    id="cnpj"
                    name="cnpj"
                    placeholder="Digite o CNPJ"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setFilterStatus(e.target.value)}
                    value={filterStatus}
                  >
                    <option value="">Todos</option>
                    <option value="0">Ativo</option>
                    <option value="1">Inativo</option>
                    <option value="2">Aguardando Ativação</option>
                  </CustomInput>
                </FormGroup>
                {/* <FormGroup>
                  <label className="form-control-label" htmlFor="legal_representative">
                    Representante Legal
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="legal_representative"
                    name="legal_representative"
                    placeholder="Digite o Nome do Representante Legal"
                    value={filterLegalRepresentative}
                    onChange={(e) => setFilterLegalRepresentative(e.target.value)}
                  />
                </FormGroup> */}
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome Fantasia
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome Fantasia"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Email"
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </FormGroup>
                <VisibilityByUserRoles
                  roles={['master', 'operational', 'regional_manager']}
                >
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="corban_id">
                      Corban
                    </Label>
                    <CustomInput
                      type="select"
                      id="corban_id"
                      name="corban_id"
                      onChange={(e) => setFilterCorban(e.target.value)}
                      value={filterCorban}
                    >
                      <option value="">Selecionar</option>
                      {corbans.map((corban) => (
                        <option value={corban.id}>{corban.name}</option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="state">
                    Estado
                  </Label>
                  <CustomInput
                    type="select"
                    id="state"
                    name="state"
                    onChange={(e) => setFilterState(e.target.value)}
                    value={filterState}
                  >
                    {States.map((state) => (
                      <option value={state.value}>{state.name}</option>
                    ))}
                  </CustomInput>
                </FormGroup>
                {/* <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="commission"
                  >
                    Comissão Ativa
                  </Label>
                  <CustomInput
                    type="select"
                    id="commission"
                    name="commission"
                    onChange={(e) => setFilterCommission(e.target.value)}
                    value={filterCommission}
                  >
                    <option value="">Selecione</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </CustomInput>
                </FormGroup> */}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Subestablishment;
