const setAuthToken = () => {
  let token = '';
  if (localStorage.getItem('@UGate:token')) {
    token = localStorage.getItem('@UGate:token');
  }

  return token;
};

export default setAuthToken;
