import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Button,
  Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import token from '../../util/setAuthToken';
import { toastError } from '../../util/toast';
import PaginationComponent from '../../components/PaginationComponent';
import LogTable from '../../components/LogTable';
import LogSubVersions from '../../components/LogTable/subVersions';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { States } from '../../variables/assistants';
import Search from '../../components/Search';

const LIMIT = 10;

const ContractLogs = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { id } = useParams();
  const [versions, setVersions] = useState([]);

  async function getVersions() {
    showLoader();

    try {
      const response = await api.get(
        `contracts/${id}/logs`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setVersions(response.data);

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os logs de usuarios.');
    }
  }

  useEffect(() => {
    getVersions();
  }, []);


  return (
    <>
      <LogTable
        versions={versions}
        changeField={"changes"}
      />
    </>
  );
};
export default ContractLogs;
