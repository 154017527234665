import React, { useState, useRef, useEffect } from 'react';
import { Col, Button } from 'reactstrap';
import { VisibilityByUserRoles } from 'util/visibility';
import { CSVLink } from 'react-csv';
import api from '../../services/api';
import token from '../../util/setAuthToken';

const MenuRankingTable = (props) => {
  const { handleToggleFilter, filterData, exportName } = props;
  const exportStoreRef = useRef(exportStoreRef);
  const [exportStoreData, setExportStoreData] = useState([]);
  const [storeReportDisabled, setStoreReportDisabled] = useState(false);
  const [url, setUrl] = useState('users/regional_manager_export');
  const sendTo = (address) => {
    window.location.href = address;
  };

  async function export_store_report() {
    setStoreReportDisabled(true);
    try {
      const response = await api.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });
      setExportStoreData(response.data);
      exportStoreRef?.current?.link.click();
    } catch (e) {}
    setStoreReportDisabled(false);
  }

  function handleUrlExport() {
    switch (exportName) {
      case 'G. Regionais':
        setUrl('contracts/ranking_export?kind=commercial&&q[kind_eq]=0');
        break;
      case 'G. Comerciais':
        setUrl('contracts/ranking_export?kind=store&&q[kind_eq]=0');
        break;
      default:
        setUrl('contracts/ranking_export?kind=commercial&&q[kind_eq]=0');
        break;
    }
  }

  useEffect(() => {
    handleUrlExport();
  }, [exportName]);

  return (
    <>
      <Col className="mt-3 mt-lg-0">
        <div className="d-flex justify-content-end">
          {exportName && (
            <VisibilityByUserRoles
              roles={[
                'master',
                'operational',
                'regional_manager',
                'commercial_manager',
              ]}
            >
              <button
                className="btn btn-primary"
                onClick={export_store_report}
                disabled={storeReportDisabled}
              >
                Exportar {exportName}
              </button>
              <CSVLink
                data={exportStoreData}
                filename={'ranking_production.csv'}
                separator={';'}
                ref={exportStoreRef}
              ></CSVLink>
            </VisibilityByUserRoles>
          )}

          <VisibilityByUserRoles roles={['master', 'operational']}>
            <Button
              color="primary"
              onClick={() =>
                sendTo(`/admin/rankings?kind=regional&${filterData}`)
              }
            >
              Gerentes Regionais
            </Button>
          </VisibilityByUserRoles>
          <VisibilityByUserRoles
            roles={['master', 'operational', 'regional_manager']}
          >
            <Button
              color="primary"
              onClick={() =>
                sendTo(`/admin/rankings?kind=commercial&${filterData}`)
              }
            >
              Gerentes Comerciais
            </Button>
          </VisibilityByUserRoles>
          <VisibilityByUserRoles
            roles={[
              'master',
              'operational',
              'regional_manager',
              'commercial_manager',
            ]}
          >
            <Button
              color="primary"
              onClick={() =>
                sendTo(`/admin/rankings?kind=corban&${filterData}`)
              }
            >
              Corbans
            </Button>
          </VisibilityByUserRoles>
          <VisibilityByUserRoles
            roles={[
              'master',
              'operational',
              'regional_manager',
              'commercial_manager',
              'user_corban_financial',
              'user_corban',
              'user_corban_financial',
              'user_corban_register',
            ]}
          >
            <Button
              color="primary"
              onClick={() => sendTo(`/admin/rankings?kind=sub&${filterData}`)}
            >
              Subestabelecimentos
            </Button>
          </VisibilityByUserRoles>
          <VisibilityByUserRoles
            roles={[
              'master',
              'operational',
              'regional_manager',
              'commercial_manager',
              'user_corban_financial',
              'user_corban',
              'user_corban_financial',
              'user_corban_register',
              'master_subest',
              'user_subest',
              'user_subest_financial',
              'Usuario financeiro do subestabelicimento',
              'user_subest_register',
            ]}
          >
            <Button
              color="primary"
              onClick={() => sendTo(`/admin/rankings?kind=store&${filterData}`)}
            >
              Lojas
            </Button>
          </VisibilityByUserRoles>
          <VisibilityByUserRoles
            roles={[
              'master',
              'operational',
              'regional_manager',
              'commercial_manager',
              'user_corban_financial',
              'user_corban',
              'user_corban_financial',
              'user_corban_register',
              'master_subest',
              'user_subest',
              'user_subest_financial',
              'Usuario financeiro do subestabelicimento',
              'user_subest_register',
              'master_store',
              'user_store',
              'user_store_financial',
              'user_store_register',
            ]}
          >
            <Button
              color="primary"
              onClick={() =>
                sendTo(`/admin/rankings?kind=seller&${filterData}`)
              }
            >
              Vendedores
            </Button>
          </VisibilityByUserRoles>

          <VisibilityByUserRoles
            roles={['regional_manager', 'commercial_manager_internal']}
          >
            <Button color="primary" onClick={() => handleToggleFilter()}>
              Filtrar
            </Button>
            {filterData && (
              <Button
                color="info"
                onClick={() => handleToggleFilter({ clear: true })}
              >
                Limpar Filtro
              </Button>
            )}
          </VisibilityByUserRoles>
        </div>
      </Col>
    </>
  );
};

export default MenuRankingTable;
