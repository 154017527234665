import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Input,
  Label,
  Col,
} from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import ContractForm from '../../components/ContractForm';
import token from '../../util/setAuthToken';
import { useLocation } from 'react-router-dom';
import getToken from '../../util/setAuthToken';
import { AuthContext } from '../../context/AuthContext';
import {
  handleBeforeUnloadDetail,
  handleBeforeUnloadUpload,
} from '../../components/Loading/index';

const DocumentUpload = (props) => {
  const [disabledRequest, setDisabledRequest] = useState(false);
  const [alreadyPageLog, setAlreadyPageLog] = useState('');
  const [pageType, setPageType] = useState('upload_de_documentos');
  const { user } = useContext(AuthContext);

  const {
    id,
    title,
    documentRg,
    setDocumentRg,
    documentFace,
    setDocumentFace,
    documentCreditCard,
    setDocumentCreditCard,
    documentRgPersist,
    setDocumentRgPersist,
    documentFacePersist,
    setDocumentFacePersist,
    documentCreditCardPersist,
    setDocumentCreditCardPersist,
    handleSubmit,
    sendDocument,
  } = props;

  async function onSubmit() {
    if (
      (documentRg.length == 0 && documentRgPersist == 0) ||
      (documentFace.length == 0 && documentFacePersist == 0) ||
      (documentCreditCard.length == 0 && documentCreditCardPersist.length == 0)
    ) {
      toastError('Selecione pelo menos uma foto para cada opção');
      return;
    }

    handleSubmit();
  }

  async function removeImagePersist(id, kind) {
    try {
      let { data } = await api.delete(`documents/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (kind == 'rg') {
        setDocumentRgPersist(documentRgPersist.filter((item) => item.id != id));
      } else if (kind == 'face') {
        setDocumentFacePersist(
          documentFacePersist.filter((item) => item.id != id)
        );
      } else if (kind == 'credit') {
        setDocumentCreditCardPersist(
          documentCreditCardPersist.filter((item) => item.id != id)
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function removeImage(id, kind) {
    if (kind == 'rg') {
      setDocumentRg(documentRg.filter((item, index) => index != id));
    } else if (kind == 'face') {
      setDocumentFace(documentFace.filter((item, index) => index != id));
    } else if (kind == 'credit') {
      setDocumentCreditCard(
        documentCreditCard.filter((item, index) => index != id)
      );
    }
  }

  //Dropzone RG
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setDocumentRg(
        documentRg.concat(
          acceptedFiles.map((pic) =>
            Object.assign(pic, {
              preview: URL.createObjectURL(pic),
              featured: false,
            })
          )
        )
      );
    },
  });

  //Dropzone Foto do rosto
  const {
    getRootProps: getRootPropsFace,
    getInputProps: getInputPropsFace,
    isDragActive: isDragActiveFace,
  } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setDocumentFace(
        documentFace.concat(
          acceptedFiles.map((pic) =>
            Object.assign(pic, {
              preview: URL.createObjectURL(pic),
              featured: false,
            })
          )
        )
      );
    },
  });

  //Dropzone Cartão de Credito
  const {
    getRootProps: getRootPropsCredit,
    getInputProps: getInputPropsCredit,
    isDragActive: isDragActiveCredit,
  } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setDocumentCreditCard(
        documentCreditCard.concat(
          acceptedFiles.map((pic) =>
            Object.assign(pic, {
              preview: URL.createObjectURL(pic),
              featured: false,
            })
          )
        )
      );
    },
  });

  useEffect(() => {
    async function getPageLog() {
      try {
        let { data } = await api.get(`page_log/${pageType}/${id}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        const res = data.filter(
          (item) => item.page_type === pageType && item.user_id !== user.id
        );

        if (res.length > 0) {
          setAlreadyPageLog(
            `Usuário ${res[0].name} está logado nesta página. Favor aguardar.`
          );
          // setDisabledRequest(true);
        }

        const result = data.filter((item) => item.page_type === pageType);
        if (result.length === 0) {
          await api.get(
            `contracts/${id}?name=${user.name}&page_type=${pageType}`,
            {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    }

    getPageLog();
    localStorage.setItem('page_log', pageType);

    if (user) {
      // document.addEventListener('visibilitychange', function logData() {
      //   if (document.visibilityState === 'hidden') {
      //     navigator.sendBeacon(
      //       `https://nsaque.ultragate.com.br/api/v1/page_log_destroy_open/${pageType}?user_id=${user.id}`,
      //       'Encerrar'
      //     );
      //   }
      // });
    }
  }, [user]);

  return (
    <>
      <CardBody>
        {/* {alreadyPageLog !== '' && (
          <h5 className="badge badge-warning">{alreadyPageLog}</h5>
        )} */}
        {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
        <h6 className="heading-small text-muted mb-4">
          Foto de documento que conste: cpf, rg, nascimento e foto do cliente
        </h6>
        <FormGroup className="mt-4 ml-4">
          <div {...getRootProps()}>
            <input {...getInputProps()} disabled={disabledRequest} />
            {isDragActive ? (
              <p className="dropzoneFile">Buscando arquivos ...</p>
            ) : (
              <p className="dropzoneFile">
                Arraste ou clique para selecionar as fotos
              </p>
            )}
          </div>
        </FormGroup>
        <div className="mb-4">
          <Row>
            {documentRgPersist.map((item) => (
              <>
                <Col lg="4" className="positionRelative">
                  <a href={item.file_url} target="_blank">
                    <img src={item.file_url} className="imagePreview" />
                  </a>
                  <Button
                    color="danger"
                    className="closeButtonImage"
                    onClick={() => removeImagePersist(item.id, 'rg')}
                    close
                  />
                </Col>
              </>
            ))}
            {documentRg.map((item, index) => (
              <>
                <Col lg="4" className="positionRelative">
                  <a href={item.preview} target="_blank">
                    <img src={item.preview} className="imagePreview" />
                  </a>
                  <Button
                    color="danger"
                    className="closeButtonImage"
                    onClick={() => removeImage(index, 'rg')}
                    close
                  />
                </Col>
              </>
            ))}
          </Row>
        </div>
        <h6 className="heading-small text-muted">
          Foto do Rosto com Documento em mãos
        </h6>
        <FormGroup className="mt-4 ml-4">
          <div {...getRootPropsFace()}>
            <input {...getInputPropsFace()} disabled={disabledRequest} />
            {isDragActiveFace ? (
              <p className="dropzoneFile">Buscando arquivos ...</p>
            ) : (
              <p className="dropzoneFile">
                Arraste ou clique para selecionar as fotos
              </p>
            )}
          </div>
        </FormGroup>
        <div className="mb-4">
          <Row>
            {documentFacePersist.map((item) => (
              <>
                <Col lg="4" className="positionRelative">
                  <a href={item.file_url} target="_blank">
                    <img src={item.file_url} className="imagePreview" />
                  </a>
                  <Button
                    color="danger"
                    className="closeButtonImage"
                    onClick={() => removeImagePersist(item.id, 'face')}
                    close
                  />
                </Col>
              </>
            ))}
            {documentFace.map((item, index) => (
              <>
                <Col lg="4" className="positionRelative">
                  <a href={item.preview} target="_blank">
                    <img src={item.preview} className="imagePreview" />
                  </a>
                  <Button
                    color="danger"
                    className="closeButtonImage"
                    onClick={() => removeImage(index, 'face')}
                    close
                  />
                </Col>
              </>
            ))}
          </Row>
        </div>
        <h6 className="heading-small text-muted mb-4">
          Foto do cartão de crédito (frente e verso, cubra o código de
          segurança)
        </h6>
        <FormGroup className="mt-4 ml-4">
          <div {...getRootPropsCredit()}>
            <input {...getInputPropsCredit()} disabled={disabledRequest} />
            {isDragActiveCredit ? (
              <p className="dropzoneFile">Buscando arquivos ...</p>
            ) : (
              <p className="dropzoneFile">
                Arraste ou clique para selecionar as fotos
              </p>
            )}
          </div>
        </FormGroup>
        <div className="mb-4">
          <Row>
            {documentCreditCardPersist.map((item) => (
              <>
                <Col lg="4" className="positionRelative">
                  <a href={item.file_url} target="_blank">
                    <img src={item.file_url} className="imagePreview" />
                  </a>
                  <Button
                    color="danger"
                    className="closeButtonImage"
                    onClick={() => removeImagePersist(item.id, 'credit')}
                    close
                  />
                </Col>
              </>
            ))}
            {documentCreditCard.map((item, index) => (
              <>
                <Col lg="4" className="positionRelative">
                  <a href={item.preview} target="_blank">
                    <img src={item.preview} className="imagePreview" />
                  </a>
                  <Button
                    color="danger"
                    className="closeButtonImage"
                    onClick={() => removeImage(index, 'face')}
                    close
                  />
                </Col>
              </>
            ))}
          </Row>
        </div>

        <div className="text-right">
          {sendDocument && (
            <Button
              type="button"
              onClick={() => sendDocument()}
              color="primary"
              className="px-4"
            >
              <i className="fas fa-paper-plane" /> Continuar sem enviar os
              documentos
            </Button>
          )}
          <Button
            type="button"
            onClick={() => onSubmit()}
            className="btn btn-success px-4"
            disabled={disabledRequest}
          >
            <i className="fas fa-paper-plane" /> Enviar
          </Button>
        </div>
        {/* </Form> */}
      </CardBody>
    </>
  );
};

export default DocumentUpload;
