import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { toastError } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input';

const CommissionCorbanForm = ({
  commissions,
  register,
  basicInterests,
  handleOnChangeInstallmentFields,
  installmentFields,
  setIntallmentFields,
  removeInstallmentFields,
  handleClickInstallmentFields,
  hasSecure,
}) => {
  // const [installments, setIntallments] = useState([
  //   { label: '6x', key: 6 },
  //   { label: '9x', key: 9 },
  //   { label: '12x', key: 12 },
  //   { label: '15x', key: 15 },
  //   { label: '18x', key: 18 },
  // ]);
  const [installments, setIntallments] = useState([
    { label: '4x', key: 4 },
    { label: '8x', key: 8 },
    { label: '12x', key: 12 },
  ]);
  const [commissionsData, setCommissionsData] = useState([]);

  // const arrays = {
  //   8: [15, 10],
  //   9: [20, 12, 9],
  //   10: [25, 15, 11, 2.75],
  //   11: [30, 17, 13, 3.25],
  //   12: [35, 19, 15, 3.75],
  //   13: [40, 21, 18, 4.5, 2.57],
  //   14: [45, 23, 21, 5.25, 3],
  //   15: [50, 25, 24, 6, 3.43],
  //   16: [55, 28, 27, 6.75, 3.86],
  //   17: [60, 31, 30, 7.5, 4.29],
  //   18: [65, 35, 33, 8.25, 4.71],
  //   19: [70, 39, 36, 9, 5.14],
  //   20: [75, 45, 39, 9.75, 5.57],
  //   21: [80, 50, 42, 10.5, 6],
  //   22: [85, 55, 45, 11.25, 6.43],
  //   23: [90, 60, 48, 12, 6.86],
  //   24: [95, 65, 51, 12.75, 7.29],
  //   25: [100, 70, 55, 13.75, 7.86],
  // };

  const arrays = {
    25: [55, 11, 6.1],
    24: [51, 10.2, 5.7],
    23: [48, 9.6, 5.3],
    22: [45, 9.0, 5.0],
    21: [42, 8.4],
    20: [39, 7.8],
    19: [36, 7.2],
    18: [33, 6.6],
    17: [30, 6.0],
    16: [27, 5.4],
    15: [24, 4.8],
    14: [21, 4.2],
    13: [18, 3.6],
    12: [15],
    11: [13],
    10: [11],
    9: [9],
    8: [9],
    7: [9],
    6: [9],
    5: [9],
  };

  useEffect(() => {
    let interestAux = [];
    console.log(commissions);

    setCommissionsData(
      commissions.map((commission, index) => {
        if (commission.installments) {
          interestAux = [
            ...interestAux,
            {
              interest: commission.basic_interest,
              interest_id: commission.basic_interest_id,
              has_secure: commission.has_secure
                ? commission.has_secure
                : commission?.installments[0]?.has_secure,
              installments: commission.installments,
              _destroy: commission._destroy,
            },
          ];

          return {
            ...commission,
            has_secure: commission.has_secure
              ? commission.has_secure
              : commission?.installments[0]?.has_secure,
          };
        } else {
          if (parseInt(commission.basic_interest) >= 5) {
            let installments = [];
            if (arrays[`${commission.basic_interest}`]) {
              arrays[`${commission.basic_interest}`].map((item, index) => {
                installments = [
                  ...installments,
                  { installment: 12 - index * 4, value: item, id: item.id },
                ];
              });
            }
            interestAux = [
              ...interestAux,
              {
                interest: commission.basic_interest,
                interest_id: commission.basic_interest_id,
                has_secure: commission.has_secure
                  ? commission.has_secure
                  : installments[0]?.has_secure,
                installments: installments,
              },
            ];
          }

          return {
            ...commission,
            has_secure: commission.has_secure
              ? commission.has_secure
              : installments[0]?.has_secure,
          };
        }
      })
    );

    setIntallmentFields(interestAux);
  }, [commissions]);

  return (
    <>
      <Row>
        <Col lg="12">
          <div className="">
            {commissionsData
              .filter((item) => item.basic_interest >= 5)
              .map((commission, index) => (
                <div key={index}>
                  <Row>
                    <Col lg="2">
                      <FormGroup>
                        {index == 0 && (
                          <label className="form-control-label" htmlFor="basic">
                            Juros Base
                          </label>
                        )}
                        <Input
                          type="text"
                          name="basic"
                          className="form-control form-control-alternative"
                          id="basic"
                          value={
                            commission.basic_interest
                              ? commission.basic_interest
                              : basicInterests[index].value
                          }
                          inputRef={register({ required: true })}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    {hasSecure && (
                      <Col lg="2  mb-4">
                        <Label for="has_secure">Operação com seguro?</Label>
                        <Input
                          type="select"
                          id="has_secure"
                          name="has_secure"
                          value={commission && commission.has_secure}
                          onChange={(e) =>
                            handleOnChangeInstallmentFields(index, index, e)
                          }
                        >
                          <option>Selecione o tipo</option>
                          <option value={true}>Sim</option>
                          <option value={false}>Não</option>
                        </Input>
                      </Col>
                    )}
                  </Row>
                  {installmentFields[index] &&
                    installmentFields[index].installments &&
                    installmentFields[index].installments
                      .filter((item) => item['_destroy'] != true)
                      .map((item, indexField) => {
                        return (
                          // {JSON.stringify(item)}
                          <Row class="ml-4">
                            <Col className="col-2">
                              <Label for="exampleselect">
                                Parcela{item.id}
                              </Label>
                              <Input
                                type="select"
                                name="installment"
                                id="installment"
                                value={item.installment}
                                inputRef={register({ required: true })}
                                onChange={(event) =>
                                  handleOnChangeInstallmentFields(
                                    index,
                                    indexField,
                                    event
                                  )
                                }
                              >
                                <option>Selecione a parcela</option>
                                {installments.map((item) => (
                                  <option value={item.key}>{item.label}</option>
                                ))}
                              </Input>
                            </Col>
                            <Col className="col-4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="basic"
                                >
                                  Valor
                                </label>
                                <Input
                                  type="text"
                                  name="value"
                                  className="form-control form-control-alternative"
                                  id="value"
                                  value={item.value}
                                  inputRef={register({ required: true })}
                                  onChange={(event) =>
                                    handleOnChangeInstallmentFields(
                                      index,
                                      indexField,
                                      event
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col className="col-4">
                              <div className="">
                                <Button
                                  color="danger"
                                  onClick={() =>
                                    removeInstallmentFields(index, indexField)
                                  }
                                >
                                  Remover
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                  <div className={`${index == 0 ? 'my-4' : 'my-2'}`}>
                    <Button
                      color="success"
                      onClick={() => handleClickInstallmentFields(index)}
                    >
                      Adicionar
                    </Button>
                  </div>
                  <hr />
                </div>
              ))}

            <br></br>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CommissionCorbanForm;
