import React, { useEffect, useContext, useState } from 'react';
import { LoadingContext } from '../../context/loading/LoadingContext';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import SellerForm from '../../components/SellerForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';

const CreateCustomer = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { register, handleSubmit, errors, setError } = useForm();
  const { user } = useContext(AuthContext);
  const [seller, setSeller] = useState({
    name: '',
    cpf_cnpj: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    has_commission: '',
    commission_type: '',
    value: '',
    trigger: '',
    base_trigger: '',
    active_base_trigger: '',
    password: 'novosaque123',
  });
  const [ stores, setStores ] = useState([])

  async function getStores() {
    showLoader()

    try {
      const response = await api.get(
        `/stores/searchs`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value,index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`
        }
      })


      setStores(values);

      hideLoader();

    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista as lojas.');
    }
  }

  useEffect(()=> {
    if(user && user.roles[0] && (user.roles[0].name == "master" || user.roles[0].name == "operational" || user.roles[0].name == "master_corban" || user.roles[0].name == "user_corban_register")){
      getStores()
    }
  }, [user])

  async function onSubmit(data) {
    showLoader();
    console.log("oii")
    let storeId = null
    if(user && user.roles[0] && (user.roles[0].name == "master" || user.roles[0].name == "operational" || user.roles[0].name == "master_corban" || user.roles[0].name == "user_corban_register")){
      if(seller.store_id){
        storeId = seller.store_id.split('-')[0]
      }
    }

    if(!seller.kind_pix){
      toastError("Selecione o tipo de chave pix")
      hideLoader();
      return
    }

    if(seller.kind_pix != 'random' && !seller.pix){
      toastError("Preencher campo pix")
      hideLoader();
      return
    }

    data = {
      seller: {
        kind_pix: seller.kind_pix,
        pix: seller.pix,
        store_id: storeId,
        mobile: seller.mobile,
        entity_attributes: {
          name: seller.name,
          cpf_cnpj: seller.cpf_cnpj,
          commission_fgts: seller.commission_fgts,
          has_commission: seller.has_commission == "yes" ? true : false,
          address_attributes: {
            zip_code: seller.zip_code,
            street: seller.street,
            number: seller.number,
            district: seller.district,
            city: seller.city,
            state: seller.state,
            complement: seller.complement,
          },
          commission_bases_attributes: [
            {
              commission_type: parseInt(seller.commission_type,10),
              value: seller.value ? seller.value.toString().replace('R$', '').replace(',', '.') : seller.value,
              trigger: seller.trigger,
              base_trigger: seller.base_trigger
            }
          ]
        },
        users_attributes: [
          {
            cpf: seller.cpf_cnpj,
            name: seller.name,
            mobile: seller.mobile,
            email: seller.email,
            password: seller.password
          }
        ]
      }
    }

    try {
      await api.post('sellers', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Vendedor criado com sucesso');

      props.history.push('/admin/sellers');
    } catch (error) {
      hideLoader();

      let message = '';

      if (error.response.data.error) {
        const responseErrors = error.response.data.error
        responseErrors.forEach((e) => {
          if (e.key == "Documento" && e.model == "users") {
            setError("cpf_cnpj", 'inválido')
          } else if(e.key == "Documento" && e.model == "entity"){
            setError("cpf_cnpj", 'inválido')
          } else if( e.key == "email" && e.model == "users") {
            setError("email", 'inválido')
          } else if( e.key == "trigger" && e.model == "entity") {
            setError("trigger", 'inválido')
          }
        });
      }
      window.scrollTo(0, 0)
      toastError("Há informações incorretas, por favor revise-as.");
    }
  }

  function parseCurrency(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace(".", "").replace(",", ".").replace("R$", ""))
    } else {
      return value
    }
  }

  function parsePercentage(value) {
    if(typeof value === 'string' || value instanceof String){
      return (value.replace("%", "").replace(",", "."))
    } else {
      return value
    }
  }

  function handleOnChangeStore(e){
    setSeller({ ...seller, 'store_id': e.value })
  }

  function handleOnChange(e) {
    const values = {...seller}

    if (e.target.name == "value_percentage") {
      setSeller({ ...seller, ["value"] : parsePercentage(e.target.value) });
    } else if (e.target.name == "trigger"){
      setSeller({ ...seller, ["trigger"] : parsePercentage(e.target.value) });
    } else if (e.target.name == "commission_type" && values["value"] != "") {
      setSeller({ ...seller, [e.target.name] : e.target.value, "value": ""});
    } else if (e.target.name == "cpf_cnpj") {
      if ( e.target.value.length < 15 ) {
        let cpf_cnpj = e.target.value
        if(cpf_cnpj.length >= 12) {
          cpf_cnpj=cpf_cnpj.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/,"$1.$2.$3-$4")
        } else {
          cpf_cnpj=cpf_cnpj.replace(/(\d{3})(\d)/,"$1.$2")
        }
        setSeller ({ ...seller,  [e.target.name]: cpf_cnpj});
      }
    } else if (e.target.name == "mobile") {
        if ( e.target.value.length < 16 ) {
          let mobile = e.target.value

          if(mobile.length > 10) {
            mobile=mobile.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{5})(\d)/,"($1) $2-$3")
          } else {
            mobile=mobile.replace(/^(\d{2})(\d)/,"($1) $2")
          }
          setSeller ({ ...seller,  [e.target.name]: mobile});
        }
    } else {
      setSeller({ ...seller, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeValueFix(e){
    setSeller({
      ...seller,
      value: parseCurrency(e)
    })
  }

  function dataAddress({ street, district, city, state }) {
    setSeller({
      ...seller,
      street: street,
      district: district,
      city: city,
      state: state
    });
  }

  function insertPercentage(e) {
    if(e.target.name == "value_percentage") {
      setSeller({ ...seller, ["value"]: `${e.target.value}%` });
    } else {
      if(e.target.name == "commission_fgts") {
        setSeller({ ...seller, ["commission_fgts"]: `${e.target.value}%` });
      }else{
        setSeller({ ...seller, ["trigger"]: `${e.target.value}%` });
      }
    }
  }

  function insertMoney(e) {
    if(e.target.value.charAt(0) != 'R') {
      setSeller({ ...seller, ["value"]: `R$ ${e.target.value}` });
    }
  }

  return (
    <>
      <CustomerHeader />
      <SellerForm
        seller={seller}
        stores={stores}
        title="Criar Vendedor"
        titleButton="Criar Vendedor"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        handleOnChangeStore={handleOnChangeStore}
        dataAddress={dataAddress}
        insertPercentage={insertPercentage}
        insertMoney={insertMoney}
        handleOnChangeValueFix={handleOnChangeValueFix}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
};

export default CreateCustomer;
