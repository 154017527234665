import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BasicInterestForm from '../../components/BasicInterestForm/update';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';

const UpdateBasicInterest = (props) => {
  const { id } = useParams();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { register, handleSubmit, errors, setError } = useForm();
  const { user } = useContext(AuthContext);
  const [BasicInterest, setBasicInterest] = useState({
    name: '',
  });

  useEffect(() => {
    async function getBasicInterest() {
      showLoader();
      try {
        const { data } = await api.get(`basic_interests/${id}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        console.log(data)
        setBasicInterest({
          name: data.name,
        });
        hideLoader();
      } catch (error) {
        hideLoader();
      }
    }
    getBasicInterest();
  }, []);

  async function onSubmit(data) {
    showLoader();

    data = {
      basic_interests : {
        name: data.name
      }
    }

    try {
      await api.put(`basic_interests/${id}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Juros básico atualizado com sucesso');

      props.history.push('/admin/basic-interests');
    } catch (error) {
      hideLoader();
      
      window.scrollTo(0, 0)
      toastError("Há informações incorretas, por favor revise-as.");
    }
  }

  function handleOnChange() {
    const values = {...BasicInterest}

    setBasicInterest(values)
  }

  return (
    <>
      <CustomerHeader />
      <BasicInterestForm
        BasicInterest={BasicInterest}
        hasEdit
        title="Atualização de Juros Básico"
        titleButton="Atualização de Juros Básico"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        controller={'edit'}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
};

export default UpdateBasicInterest;
