import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Nav,
  NavLink,
  NavItem,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { VisibilityCreateStore, VisibilityEditStore } from '../../util/visibility';
import Search from '../../components/Search';
import { States } from '../../variables/assistants'
import { VisibilityByUserRoles } from '../../util/visibility';
import { AuthContext } from '../../context/AuthContext';
import RankingStoreTable from 'components/RakingTable/store';
import RankingRegionalTable from 'components/RakingTable/regional';
import RankingManagerTable from 'components/RakingTable/manager';
import RankingCorbanTable from 'components/RakingTable/corban';
import RankingSubTable from 'components/RakingTable/sub';
import RankingSellerTable from 'components/RakingTable/seller';

const LIMIT = 10;

const Ranking = (props) => {
  const { user } = useContext(AuthContext);
  const exportStoreRef = useRef(exportStoreRef)
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [kind, setKind] = useState('');

  const [filterData, setFilterData] = useState("");
  const [filterManager, setFilterManager] = useState("");
  const [filterManagers, setFilterManagers] = useState([]);

  const [regionals, setRegionals] = useState([]);
  const [managers, setManagers] = useState([]);
  const [corbans, setCorbans] = useState([]);
  const [subs, setSubs] = useState([]);
  const [stores, setStores] = useState([]);
  const [sellers, setSellers] = useState([]);

  const [sortValue, setSortValue] = useState('desc');
  const [sortStores, setSortStores] = useState('desc');
  const [sortContractValue, setSortContractValue] = useState('desc');
  const [sortContractMargin, setSortContractMargin] = useState('desc');

  const [minFilterDate, setMinFilterDate] = useState('');
  const [maxFilterDate, setMaxFilterDate] = useState('');

  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);
  const handleToggleFilter = (clear = false) => clear ? handleClearFilter() : setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [days, setDays] = useState("")
  const [filterDate, setFilterDate] = useState("")
  const [submitModal, setSubmitModal] = useState(false)
  const [goal, setGoal] = useState("")
  const [id, setId] = useState(null)
  const [bussinessDay, setBussinessDay] = useState(0)

  const handleToggleSubmit = async (paramsId=null, goalsValue=null) => {
    setId(paramsId)
    setGoal(goalsValue)
    setSubmitModal(!submitModal)
  }

  const handlerOnClickSubmit = async () => {
    saveGoals()
  }

  const onChangeFilterDate = async (value) => {
    setFilterDate(value)
    // const year = value.split("-")[0]
    // const month = value.split("-")[1]
    // getDays(`month=${month}&year=${year}`)
    // setTimeout(() => {
    //   setFilter()
    // }, 500)
  }

  async function getManagers() {
    try {
      const response = await api.get(
        `users/user_managers`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if(response.data) {
        setFilterManagers(response.data.sort((a, b) => sortByText(a, b, 'ASC')));
      }

    } catch (error) {
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }


  const getDays = async (search='') => {
    try {
      let { data } = await api.get(`ranking_configs?${search}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setDays(data.days)
      setFilterDate(`${data.year}-${data.month}`)
    } catch (e) {
      console.log(e)
    }
  }

  const saveDays = async () => {
    const year = filterDate.split("-")[0]
    const month = filterDate.split("-")[1]

    try {
      let { data } = await api.post(`ranking_configs`, {
        ranking_config: {
          days: days,
          month: month,
          year: year,
        }
      }, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      console.log(data)
    } catch (e) {
      console.log(e)
    }
  }

  const saveGoals = async () => {
    const year = filterDate.split("-")[0]
    const month = filterDate.split("-")[1]

    try {
      let { data } = await api.post(`goals`, {
        goal: {
          value: goal,
          user_id: id,
          month: month,
          year: year,
        }
      }, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setFilter()
      handleToggleSubmit()

      console.log(data)
    } catch (e) {
      console.log(e)
    }
  }

  const sortByText = (a, b, order = 'ASC') => {
    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (order === 'ASC') {
        return diff;
    }

    return -1 * diff;
  }

  async function getRanking({search='', kind=null}) {
    const dataKind = kind || params.get("kind") || "regional"
    showLoader();

    try {
      const response = await api.get(
        `contracts/ranking?kind=${dataKind}&${search}&q[kind_eq]=0`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setBussinessDay(response.data.days)
      setRegionals(response.data.data)
      setManagers(response.data.data)
      setCorbans(response.data.data)
      setSubs(response.data.data)
      setStores(response.data.data)
      setSellers(response.data.data)
      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }

  const handleClearFilter = () => {
    let filterPath = '';
    if (kind) {
      filterPath += `&kind=${kind}`;
    }
    window.location.href = window.location.pathname+"?"+filterPath
  }

  async function handleSortContractValue() {
    let sortParam = ''
    setSortValue('desc')
    setSortContractMargin('desc')
    setSortStores('desc')
    if(sortContractValue == 'desc') {
      setSortContractValue('asc')
      sortParam = 'total%20asc'
    }else{
      sortParam = 'total%20desc'
      setSortContractValue('desc')
    }

    setFilter(sortParam)
  }

  async function handleSortValue() {
    let sortParam = ''
    setSortContractValue('desc')
    setSortContractMargin('desc')
    setSortStores('desc')
    if(sortValue == 'desc') {
      setSortValue('asc')
      sortParam = 'total_charged%20asc'
    }else{
      sortParam = 'total_charged%20desc'
      setSortValue('desc')
    }

    setFilter(sortParam)
  }

  async function handleSortStores() {
    let sortParam = ''
    setSortValue('desc')
    setSortContractMargin('desc')
    setSortContractValue('desc')
    console.log(sortStores)
    if(sortStores == 'desc') {
      setSortStores('asc')
      sortParam = 'total_stores%20asc'
    }else{
      sortParam = 'total_stores%20desc'
      setSortStores('desc')
    }

    setFilter(sortParam)
  }

  async function handleSortContractMargin() {
    let sortParam = ''
    setSortValue('desc')
    setSortContractValue('desc')
    setSortStores('desc')
    if(sortContractMargin == 'desc') {
      setSortContractMargin('asc')
      sortParam = 'total_margin%20asc'
    }else{
      sortParam = 'total_margin%20desc'
      setSortContractMargin('desc')
    }

    setFilter(sortParam)
  }

  const setFilter = (sortParam = null) => {

    const year = filterDate.split("-")[0]
    const month = filterDate.split("-")[1]

    let filterPath = '';

    console.log(sortParam)
    if(sortParam) {
      filterPath += `&sort=${sortParam}`;
    }

    if (kind) {
      filterPath += `&kind=${kind}`;
    }

    if (filterManager) {
      filterPath += `&q[seller_store_user_manager_id_eq]=${filterManager}`;
    }

    if(filterDate){
      if(month){
        filterPath += `&month=${month}`;
      }

      if(year){
        filterPath += `&year=${year}`;
      }
    }

    window.location.href = window.location.pathname+"?"+filterPath
    // getRanking({search: filterPath})

  }

  const resolveFilter = () => {
    let filterPath = ''

    if (params.get("sort")) {
      if(params.get("sort").split(" ")[0] == 'total_charged'){
        setSortValue(params.get("sort").split(" ")[1])
      }
      if(params.get("sort").split(" ")[0] == 'total_stores'){
        setSortStores(params.get("sort").split(" ")[1])
      }
      if(params.get("sort").split(" ")[0] == 'total'){
        setSortContractValue(params.get("sort").split(" ")[1])
      }
      if(params.get("sort").split(" ")[0] == 'total_margin'){
        setSortContractMargin(params.get("sort").split(" ")[1])
      }

      filterPath += `&sort=${params.get("sort")}`;
    }

    if (params.get("q[seller_store_user_manager_id_eq]")) {
      filterPath += `&q[seller_store_user_manager_id_eq]=${params.get("q[seller_store_user_manager_id_eq]")}`;
      setFilterManager(params.get("q[seller_store_user_manager_id_eq]"));
    }

    if(params.get("month") && params.get("year")){
      filterPath += `&month=${params.get("month")}`;
      filterPath += `&year=${params.get("year")}`;
      setFilterDate(`${params.get("year")}-${params.get("month")}`)
      getDays(`month=${params.get("month")}&year=${params.get("year")}`)
    }else {
      getDays()
    }
    setFilterData(filterPath)
    return filterPath
  }

  useEffect(() => {
    let data = "regional"
    if (params.get('kind')) {
      setKind(params.get('kind'))
      data = params.get('kind')
    }else{
      console.log(user)
      switch (user && user.roles[0].name) {
        case "regional_manager" || "commercial_manager_internal":
          setKind("commercial")
          data = "commercial"
          break;
        case "commercial_manager":
          setKind("store")
          data = "store"
          break;
        case "master_corban" || "user_corban":
          setKind("sub")
          data = "sub"
          break;
        case "master_corban" || "user_corban":
          setKind("sub")
          data = "sub"
          break;
        case "master_subest" || "user_subest":
          setKind("store")
          data = "store"
          break;
        case "master_store" || "user_store":
          setKind("seller")
          data = "seller"
          break;
        default:
          setKind("regional")
          data = "regional"
          break;
      }
    }

    getRanking({kind: data, search: resolveFilter()})
    getManagers()


  }, [user]);

  return (
    <>
       <div className="header bg-gradient-info pb-2 pt-5">
          <Container fluid>
            <div className="header-body">
              <Row className="mt-4">
                <Col lg="6">
                  <Col lg="6">
                    <FormGroup>
                      <Label
                        className="form-control-label text-white"
                        htmlFor="status"
                      >
                        Data inicial
                      </Label>
                      <CustomInput
                        type="month"
                        id="date_init_filter"
                        name="date_init_filter"
                        className="form-control form-control-alternative"
                        onChange={(e) => onChangeFilterDate(e.target.value)}
                        value={filterDate}
                      >
                      </CustomInput>
                    </FormGroup>
                    <Button color="primary" style={{height: 45}}
                      onClick={() => setFilter()}
                    >
                      Filtrar
                    </Button>
                  </Col>
                </Col>
                <Col lg="6">
                  <Col lg="6">
                    <FormGroup>
                      <Label
                        className="form-control-label text-white"
                        htmlFor="status"
                      >
                        Dias Úteis
                      </Label>
                      <CustomInput
                        type="text"
                        id="date_init_filter"
                        name="date_init_filter"
                        className="form-control form-control-alternative"
                        onChange={(e) => setDays(e.target.value)}
                        value={days}
                      >
                      </CustomInput>
                    </FormGroup>
                  </Col>
                  <Col lg="6" className="d-flex align-items-center">
                    <Button color="primary" style={{height: 45}}
                      onClick={() => saveDays()}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      {kind == 'regional' && (<RankingRegionalTable
        regionals={regionals}
        submitModal={submitModal}
        setSubmitModal={setSubmitModal}
        sortContractValue={sortContractValue}
        sortContractMargin={sortContractMargin}
        sortStores={sortStores}
        sortValue={sortValue}
        setSortValue={handleSortValue}
        setSortStores={handleSortStores}
        setSortContractValue={handleSortContractValue}
        setSortContractMargin={handleSortContractMargin}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        goal={goal}
        setGoal={setGoal}
        id={id}
        days={days}
        bussinessDay={bussinessDay}
        setId={setId}
        offset={offset}
        setOffset={setOffset}
        pages={pages}
        handleToggleFilter={handleToggleFilter}
        filterData={filterData}
        handleToggleSubmit={handleToggleSubmit}
        handlerOnClickSubmit={handlerOnClickSubmit}
      />)}
      {kind == 'commercial' && (<RankingManagerTable
        managers={managers}
        submitModal={submitModal}
        sortStores={sortStores}
        sortValue={sortValue}
        setSortValue={handleSortValue}
        setSortStores={handleSortStores}
        setSubmitModal={setSubmitModal}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        goal={goal}
        setGoal={setGoal}
        sortContractValue={sortContractValue}
        sortContractMargin={sortContractMargin}
        handleToggleFilter={handleToggleFilter}
        setSortContractValue={handleSortContractValue}
        setSortContractMargin={handleSortContractMargin}
        filterData={filterData}
        id={id}
        days={days}
        bussinessDay={bussinessDay}
        setId={setId}
        handleToggleSubmit={handleToggleSubmit}
        handlerOnClickSubmit={handlerOnClickSubmit}
      />)}
      {kind == 'corban' && (<RankingCorbanTable
        corbans={corbans}
        sortContractValue={sortContractValue}
        sortContractMargin={sortContractMargin}
        handleToggleFilter={handleToggleFilter}
        setSortContractValue={handleSortContractValue}
        setSortContractMargin={handleSortContractMargin}
        filterData={filterData}
        days={days}
        bussinessDay={bussinessDay}
      />)}
      {kind == 'sub' && (<RankingSubTable
        subs={subs}
        sortContractValue={sortContractValue}
        sortContractMargin={sortContractMargin}
        handleToggleFilter={handleToggleFilter}
        setSortContractValue={handleSortContractValue}
        setSortContractMargin={handleSortContractMargin}
        filterData={filterData}
        days={days}
        bussinessDay={bussinessDay}
      />)}
      {kind == 'store' && (<RankingStoreTable
        stores={stores}
        sortContractValue={sortContractValue}
        sortContractMargin={sortContractMargin}
        handleToggleFilter={handleToggleFilter}
        setSortContractValue={handleSortContractValue}
        setSortContractMargin={handleSortContractMargin}
        filterData={filterData}
        days={days}
        bussinessDay={bussinessDay}
      />)}
      {kind == 'seller' && (<RankingSellerTable
        sellers={sellers}
        sortContractValue={sortContractValue}
        sortContractMargin={sortContractMargin}
        handleToggleFilter={handleToggleFilter}
        setSortContractValue={handleSortContractValue}
        setSortContractMargin={handleSortContractMargin}
        filterData={filterData}
        days={days}
        bussinessDay={bussinessDay}
      />)}

      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <VisibilityByUserRoles roles={["master", "regional_manager"]}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="idpartner">
                      Gerente Comercial
                    </label>
                    <CustomInput
                      type="select"
                      id="idpartner"
                      name="idpartner"
                      value={filterManager}
                      onChange={(e) => setFilterManager(e.target.value)}
                    >
                      <option value="">Selecione uma opção</option>
                      {filterManagers.map((manager) => (
                        <option
                          key={manager.id}
                          value={manager.id}
                        >
                          {manager.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={() => setFilter()}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Ranking;
