import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  CustomInput,
} from 'reactstrap';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { InputRange } from '../../components/InputRange';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import moment from 'moment';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { VisibilityByUserRoles } from '../../util/visibility';
import './style.css';

let sendTime = null;

const CreateCustomerService = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const [creditLimitWithoutIof, setCreditLimitWithoutIof] = useState(null);
  const [diffIof, setDiffIof] = useState(null);
  const [minRate, setMinRate] = useState(null);
  const [maxRate, setMaxRate] = useState(null);
  const [kindSimulation, setKindSimulation] = useState(null);
  const [minCommission, setMinCommission] = useState(null);
  const [maxCommission, setMaxCommission] = useState(null);
  const [stepCommission, setStepCommission] = useState(null);
  const [customerId, setCustomerId] = useState('');
  const [deadlineDisabled, setDeadlineDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [disabledRate, setDisabledRate] = useState(false);
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [deadline, setDeadline] = React.useState([12]);
  const [valuesPortion, setValuesPortion] = React.useState([4, 8, 12]);
  const [rate, setRate] = React.useState(null);
  const [commissionSeller, setCommissionSeller] = React.useState(0);
  const [bonusSeller, setBonusSeller] = React.useState(null);
  const [commission, setCommission] = React.useState(null);
  const [stores, setStores] = React.useState([]);
  const [store, setStore] = React.useState('');
  const [sellers, setSellers] = React.useState([]);
  const [seller, setSeller] = React.useState(null);
  const [disabledStores, setDisabledStores] = React.useState(false);
  const [attendance, setAttendance] = useState({
    name: '',
    email: '',
    cpf: '',
    phone: '',
    deadline: 12,
    value_free: null,
    value_installment: null,
    card_limit: null,
    iof_value: null,
    date_credit: moment().format('YYYY-MM-DD'),
    date_first_installment: moment().add(30, 'days').format('YYYY-MM-DD'),
    phone_store: '',
    phone_seller: '',
  });

  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const getSimulatorSetting = async (installment = 12, simulate = false) => {
    try {
      let { data } = await api.get(
        `simulator_settings/active?installment=${installment}&${
          seller ? `seller_id=${seller}` : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (data) {
        setMinRate(parseFloat(data.min_rate));
        setMaxRate(parseFloat(data.max_rate));
        setRate([parseFloat(data.max_rate)]);
        setMinCommission(parseFloat(data.min_commission));
        setMaxCommission(parseFloat(data.max_commission));
        setCommission([parseFloat(data.min_commission)]);
        const commissionStep =
          parseFloat(data.max_commission) - parseFloat(data.min_commission);
        const rateStep = parseFloat(data.max_rate) - parseFloat(data.min_rate);

        if (simulate) {
          console.log(data.min_rate);
          console.log(installment);
          handleValueRange([data.max_rate], [installment]);
        }

        setStepCommission(commissionStep / rateStep);
        // setKindSelection('value_free')
      }
    } catch (e) {
      console.log(e);
      console.log('Ops, houve um erro na busca simulator config');
    }
  };

  useEffect(() => {
    if (user && user.roles && user.roles[0].name == 'master_store') {
      setStore(user.store_id);
      getSellers(user.store_id);
    }

    if (user && user.roles && user.roles[0].name == 'user_seller') {
      getSimulatorSetting();
    }

    if (user && user.roles && user.roles[0].name == 'master') {
      setDeadlineDisabled(false);
    }
  }, [user]);

  useEffect(() => {
    if (seller) {
      getSimulatorSetting();
    }
  }, [seller]);

  const sortByText = (a, b, order = 'ASC') => {
    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (order === 'ASC') {
      return diff;
    }

    return -1 * diff;
  };

  async function getSellers(store_id) {
    try {
      const { data } = await api.get(
        `sellers/searchs?q[store_id_eq]=${store_id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data) {
        setSellers(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os parceiros');
    }
  }

  function parseMoney(value) {
    if (value) {
      return value.toFixed(2).toString().replace('.', ',');
    } else {
      return 0;
    }
  }

  function getPercentValue(value, total) {
    if (value == 0 || value == null) {
      return 0;
    }
    if (total == 0 || total == null) {
      return 0;
    }
    return parseMaskCurrency(((100 * value) / parseCurrency(total)).toFixed(2));
  }

  async function getCommissionSeller(rate_params, value, installment = 12) {
    clearTimeout(sendTime);
    setLoading(true);
    sendTime = setTimeout(async function () {
      try {
        let { data } = await api.post(
          `simulations/commissions?${seller ? `seller_id=${seller}` : ''}`,
          { interest: rate_params, value, installment },
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (data) {
          // if(user && user.roles && (user.roles[0].name == 'master_store')){
          //   setCommissionSeller(data.commission_store_value)
          // }else{
          setCommissionSeller(data.commission_seller_value);

          // }
          if (
            data.commission_seller_trigger_value &&
            data.commission_seller_trigger_value != 0
          ) {
            setBonusSeller(data.commission_seller_trigger_value);
          } else {
            setBonusSeller(null);
          }
        }
      } catch (error) {
        console.log(error);
        let message = '';

        if (error.response.data.errors) {
          message = error.response.data.errors;
          message.forEach((e) => {
            toastError(e.msg);
          });
        } else if (error.response.data) {
          message = error.response.data.message;
        } else {
          message =
            'Ops, tivemos um erro na atualização dos dados, por favor tente novamente!';
        }

        toastError(message);
        window.scrollTo(0, 0);
      }
      setLoading(false);
    }, 1000);
  }

  function touchEnd() {
    console.log('SOLTANDO');
  }

  function handleOnChange(e) {
    setAttendance({ ...attendance, [e.target.name]: e.target.value });
  }

  async function simulationValues(
    kind,
    value,
    interest_rate,
    date_credit,
    date_first_installment,
    installments = 12
  ) {
    setLoadingSimulation(true);
    try {
      const { data } = await api.post(
        `/simulations/simulation_values/${kind}?${
          seller ? `seller_id=${seller}` : ''
        }`,
        {
          value,
          interest_rate,
          date_credit,
          date_first_installment,
          installments,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setLoadingSimulation(false);

      if (data) {
        console.log(data);

        if (data.credit_limit_without_iof) {
          setCreditLimitWithoutIof(data.credit_limit_without_iof);
        }

        if (data.diff_iof) {
          setDiffIof(data.diff_iof);
        }

        return data;
      }
    } catch (error) {
      setLoadingSimulation(false);

      let message = '';

      if (error.response.data.errors) {
        message = error.response.data.errors;
        message.forEach((e) => {
          toastError(e.msg);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na atualização dos dados, por favor tente novamente!';
      }

      toastError(message);
      window.scrollTo(0, 0);
    }
  }

  function setKindSelection(value) {
    setAttendance({
      ...attendance,
      value_free: 0,
      value_installment: 0,
      card_limit: 0,
    });
    setCommissionSeller(0);
    setBonusSeller(null);
    setKindSimulation(value);
  }

  function registerCustomer(attendanceId) {
    if (customerId) {
      swal({
        title: 'Atendimento',
        text: 'Confirme o limite disponível do cartão',
        icon: 'success',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          props.history.push(
            `/admin/customers/${customerId}?customerServiceId=${attendanceId}`
          );
          // props.history.push(`/admin/contracts/create?customerServiceId=${attendanceId}`);
        } else {
          props.history.push('/admin/customer-services');
        }
      });
    } else {
      swal({
        title: 'Atendimento',
        text: 'Confirme o limite disponível do cartão',
        icon: 'success',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          props.history.push(
            `/admin/customers/create?customerServiceId=${attendanceId}`
          );
        } else {
          props.history.push('/admin/customer-services');
        }
      });
    }
  }

  function handleOnChange(e) {
    if (e.target.name === 'cpf') {
      if (e.target.value === '' || e.target.value === '___.___.___-__') {
        setError('cpf', 'CPF obrigatório');
      } else if (!isValidCpf(e.target.value)) {
        setError('cpf', 'CPF inválido');
      } else {
        setError('cpf', '');
      }
    }
    setAttendance({ ...attendance, [e.target.name]: e.target.value });
  }

  async function handleOnChangeValueFree(
    e,
    rateParams = null,
    deadlineParams = null
  ) {
    const baseRate = rateParams || rate[0];
    const baseDeadline = deadlineParams || deadline[0];
    clearTimeout(sendTime);
    sendTime = setTimeout(async function () {
      // const formated = e.target.value
      const formated = parseCurrency(e); //Number(e.replace(",", "").replace("R$", ""))
      const data = await simulationValues(
        'value_free',
        formated,
        baseRate,
        attendance.date_credit,
        attendance.date_first_installment,
        baseDeadline
      );

      if (data.error) {
        toastError(data.message);
        setAttendance({
          ...attendance,
          value_free: e,
        });

        return;
      }
      // const installment = formated * ((Math.pow((1+(baseRate/100)), deadline[0])) * (baseRate/100)) / (Math.pow((1+(baseRate/100)), deadline[0])-1)
      // const total_rate = Number(installment * deadline[0]).toFixed(2)
      if (data) {
        setButtonDisabled(false);
        getCommissionSeller(baseRate, formated, baseDeadline);

        setAttendance({
          ...attendance,
          cet_monthly: data.cet_monthly,
          value_free_with_secure: data.value_free_with_secure,
          cet_anual: data.cet_anual,
          iof_value: data.iof_total,
          value_free: e,
          value_installment: parseMaskCurrency(data.installment_value),
          card_limit: parseMaskCurrency(data.credit_limit),
        });
      }
    }, 1000);
  }

  function handleOnChangeValueInstallment(
    e,
    rateParams = null,
    deadlineParams = null
  ) {
    const baseRate = rateParams || rate[0];
    const baseDeadline = deadlineParams || deadline[0];
    clearTimeout(sendTime);
    sendTime = setTimeout(async function () {
      // const formated = e.target.value
      const formated = parseCurrency(e); //Number(e.replace(",", "").replace("R$", ""))
      const data = await simulationValues(
        'installment_value',
        formated,
        baseRate,
        attendance.date_credit,
        attendance.date_first_installment,
        baseDeadline
      );
      // const installment = formated * ((Math.pow((1+(baseRate/100)), deadline[0])) * (baseRate/100)) / (Math.pow((1+(baseRate/100)), deadline[0])-1)
      // const total_rate = Number(installment * deadline[0]).toFixed(2)

      if (data.error) {
        toastError(data.message);

        setAttendance({
          ...attendance,
          value_installment: e,
        });

        return;
      }

      if (data) {
        setButtonDisabled(false);
        getCommissionSeller(baseRate, data.value_free, baseDeadline);

        setAttendance({
          ...attendance,
          cet_monthly: data.cet_monthly,
          cet_anual: data.cet_anual,
          iof_value: data.iof_total,
          value_free_with_secure: data.value_free_with_secure,
          value_free: parseMaskCurrency(data.value_free),
          value_installment: e,
          card_limit: parseMaskCurrency(data.credit_limit),
        });
      }
    }, 1000);
  }

  function handleOnChangeCardLimit(
    e,
    rateParams = null,
    deadlineParams = null
  ) {
    const baseRate = rateParams || rate[0];
    const baseDeadline = deadlineParams || deadline[0];
    clearTimeout(sendTime);
    sendTime = setTimeout(async function () {
      // const formated = e.target.value
      const formated = parseCurrency(e); //Number(e.replace(",", "").replace("R$", ""))
      const data = await simulationValues(
        'credit_limit',
        formated,
        baseRate,
        attendance.date_credit,
        attendance.date_first_installment,
        baseDeadline
      );
      // const installment = formated * ((Math.pow((1+(baseRate/100)), deadline[0])) * (baseRate/100)) / (Math.pow((1+(baseRate/100)), deadline[0])-1)
      // const total_rate = Number(installment * deadline[0]).toFixed(2)

      if (data.error) {
        toastError(data.message);
        setAttendance({
          ...attendance,
          card_limit: e,
        });
        return;
      }

      if (data) {
        console.log('DATAAAAAAA');
        setButtonDisabled(false);
        getCommissionSeller(baseRate, data.value_free, baseDeadline);
        setAttendance({
          ...attendance,
          cet_monthly: data.cet_monthly,
          cet_anual: data.cet_anual,
          iof_value: data.iof_total,
          value_free_with_secure: data.value_free_with_secure,
          value_free: parseMaskCurrency(data.value_free),
          value_installment: parseMaskCurrency(data.value_installment),
          card_limit: e,
        });
      }
    }, 1000);
  }

  const getRateValue = async (rate, values) => {
    await getSimulatorSetting(values[0], true);
  };

  function handleChangeDeadline(values) {
    setDisabledRate(true);
    setDeadline(values);
    setLoadingSimulation(true);
    clearTimeout(sendTime);
    sendTime = setTimeout(async function () {
      getRateValue(rate, values);
      setDisabledRate(false);
    }, 1000);
  }

  // function handleChangeCommission(values) {
  //   setCommission(values)
  // }

  function handleChangeRate(values) {
    setLoadingSimulation(true);
    setRate(values);
    clearTimeout(sendTime);
    sendTime = setTimeout(async function () {
      handleValueRange(values, deadline[0]);
    }, 1000);
  }

  function handleValueRange(rateParams, deadlineParams) {
    console.log(rateParams);
    console.log(deadlineParams);

    if (kindSimulation == 'value_free') {
      if (attendance.value_free) {
        const formated = Number(parseCurrency(attendance.value_free));
        handleOnChangeValueFree(formated, rateParams[0], deadlineParams[0]);
        // const installment = formated * ((Math.pow((1+(rateParams/100)), deadlineParams)) * (rateParams/100)) / (Math.pow((1+(rateParams/100)), deadlineParams)-1)
        // const total_rate = Number(installment * deadlineParams).toFixed(2)
        // getCommissionSeller(rateParams, formated)
        // setAttendance({
        //   ...attendance,
        //   value_installment: parseMaskCurrency(installment),
        //   card_limit: parseMaskCurrency(total_rate)
        // })
      }
    } else if (kindSimulation == 'value_installment') {
      if (attendance.value_installment) {
        const formated = Number(parseCurrency(attendance.value_installment));
        handleOnChangeValueInstallment(
          formated,
          rateParams[0],
          deadlineParams[0]
        );
        // const total_rate = Number(formated * deadlineParams).toFixed(2)
        // const value_free = formated * (Math.pow((1+(rateParams/100)), deadlineParams)-1) / ((Math.pow((1+(rateParams/100)), deadlineParams)) * (rateParams/100))

        // console.log(attendance.value_installment)
        // getCommissionSeller(rateParams, parseCurrency(value_free))
        // setAttendance({
        //   ...attendance,
        //   value_free: parseMaskCurrency(value_free),
        //   card_limit: parseMaskCurrency(total_rate)
        // });
      }
    } else if (kindSimulation == 'card_limit') {
      if (attendance.card_limit) {
        const formated = Number(parseCurrency(attendance.card_limit));
        handleOnChangeCardLimit(formated, rateParams[0], deadlineParams[0]);
        // const installment = Number((formated/deadlineParams).toFixed(2))
        // const value_free = installment * (Math.pow((1+(rateParams/100)), deadlineParams)-1) / ((Math.pow((1+(rateParams/100)), deadlineParams)) * (rateParams/100))

        // getCommissionSeller(rateParams, parseCurrency(value_free))
        // setAttendance({
        //   ...attendance,
        //   value_free: parseMaskCurrency(value_free),
        //   value_installment: parseMaskCurrency(installment),
        // });
      }
    }
  }

  async function handleOnBlurCpf(event) {
    const { value } = event.target;
    if (value.length > 0) {
      const cpf = value.replace(/[^0-9]+/g, '');

      try {
        let { data } = await api.get(
          `contracts/get_customer_by_cpf?q[entity_cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (data.length > 0) {
          data = data[0];
          console.log(data);
        }

        setCustomerId(data.id);

        setAttendance({
          ...attendance,
          name: data.name,
          phone: data.mobile,
          email: data.email,
        });
      } catch (e) {
        console.log(e);
        console.log('Ops, houve um erro na busca pelo CPF');
      }
    }
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parseMaskCurrency(value) {
    return value.toString().replace('.', ',');
  }

  async function onSubmit(event) {
    localStorage.setItem('credit_card_store_name', event.store);
    showLoader();
    try {
      let data = {
        simulation: {
          name: event.name,
          email: event.email,
          cpf_cnpj: event.cpf,
          phone: event.phone,
          cet_monthly: attendance.cet_monthly,
          cet_anual: attendance.cet_anual,
          value_iof: attendance.iof_value,
          date_credit: attendance.date_credit,
          date_first_installment: attendance.date_first_installment,
          liquid_value: parseCurrency(attendance.value_free),
          released_amount: parseCurrency(attendance.value_free_with_secure),
          card_limit: parseCurrency(attendance.card_limit),
          value_installment: parseCurrency(attendance.value_installment),
          interest_rate: rate[0],
          installments: deadline[0],
          phone_store: event.phone_store,
          phone_seller: event.phone_seller,
        },
      };

      if (customerId) {
        data = {
          simulation: {
            ...data.simulation,
            customer_id: customerId,
            status: 1,
          },
        };
      }

      if (seller) {
        data = { ...data, seller_id: seller };
      }

      data = { ...data, kind: kindSimulation };

      const response = await api.post('simulations', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      registerCustomer(response.data.simulation.id);
    } catch (error) {
      hideLoader();
      console.log(error);

      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na atualização dos dados, por favor tente novamente!';
      }

      toastError(message);
      window.scrollTo(0, 0);
    }
  }

  async function fetchData(e) {
    setStore(e.target.value);
    if (
      store &&
      store.length >= 3 &&
      user &&
      user.roles &&
      user.roles[0].name != 'user_seller' &&
      user.roles[0].name != 'master_store'
    ) {
      try {
        const { data } = await api.get(
          `stores/searchs?q[entity_name_cont]=${store}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (data) {
          setStores(data.sort((a, b) => sortByText(a, b, 'ASC')));
          setDisabledStores(false);
        }
      } catch (error) {
        console.log('Algo deu errado ao buscar os Lojas');
      }
    } else if (store === '') {
      setStores([]);
      setDisabledStores(false);
    }
  }

  function handleClickList(store) {
    setStore(store.name);
    setDisabledStores(true);
  }

  function isValidCpf(cpf) {
    // Remover a máscara, mantendo apenas os dígitos
    cpf = cpf.replace(/[^\d]/g, '');

    // Verificar se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }

    // Verificar se todos os dígitos são iguais (caso contrário, não é válido)
    if (/^(\d)\1{10}$/.test(cpf)) {
      return false;
    }

    // Calcular o primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = sum % 11;
    let digit1 = remainder < 2 ? 0 : 11 - remainder;

    // Verificar o primeiro dígito verificador
    if (parseInt(cpf.charAt(9)) !== digit1) {
      return false;
    }

    // Calcular o segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = sum % 11;
    let digit2 = remainder < 2 ? 0 : 11 - remainder;

    // Verificar o segundo dígito verificador
    if (parseInt(cpf.charAt(10)) !== digit2) {
      return false;
    }

    return true; // O CPF é válido
  }

  useEffect(() => {
    const sellerSelected = sellers.filter((item) => item.id === Number(seller));
    if (sellerSelected.length > 0) {
      setAttendance((prevState) => ({
        ...prevState,
        phone_seller: sellerSelected[0].mobile,
      }));
    }
  }, [seller]);

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Novo Atendimento</h3>
                    <h5 className="badge badge-warning">
                      CONFIRME O LIMITE DISPONÍVEL NO CARTÃO DO CLIENTE ANTES DE
                      SIMULAR
                    </h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            CPF
                          </label>
                          <InputMask
                            type="text"
                            name="cpf"
                            // placeholder="CPF"
                            className="form-control form-control-alternative"
                            id="cpf"
                            // placeholder="999.999.999-99"
                            mask="999.999.999-99"
                            onBlur={handleOnBlurCpf}
                            value={attendance.cpf}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.cpf && (
                            <small className="text-danger">
                              {Object.values(errors.cpf)
                                .reduce((t, n) => t + n, '')
                                .split('[')[0] === 'required'
                                ? 'CPF é obrigatório'
                                : `${
                                    Object.values(errors.cpf)
                                      .reduce((t, n) => t + n, '')
                                      .split('[')[0]
                                  }`}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome
                          </label>
                          <InputMask
                            type="text"
                            name="name"
                            // placeholder="Nome"
                            className="form-control form-control-alternative"
                            id="name"
                            value={attendance.name}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              O nome é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <InputMask
                            type="text"
                            name="email"
                            // placeholder="Email"
                            className="form-control form-control-alternative"
                            id="email"
                            value={attendance.email}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              O email é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="phone">
                            Telefone
                          </label>
                          <InputMask
                            type="text"
                            name="phone"
                            // placeholder="Celular"
                            className="form-control form-control-alternative"
                            id="phone"
                            mask="(99) 99999-9999"
                            value={attendance.phone}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.phone && (
                            <small className="text-danger">
                              O celular é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="date_credit"
                          >
                            Data do Crédito
                          </label>
                          <InputMask
                            type="date"
                            name="date_credit"
                           // placeholder="date_credit"
                            className="form-control form-control-alternative"
                            id="date_credit"
                            value={attendance.date_credit}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.date_credit && (
                            <small className="text-danger">
                              Data de crédito é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="date_first_installment"
                          >
                            Data da primeira parcela
                          </label>
                          <InputMask
                            type="date"
                            name="date_first_installment"
                           // placeholder="Celular"
                            className="form-control form-control-alternative"
                            id="date_first_installment"
                            value={attendance.date_first_installment}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.date_first_installment && (
                            <small className="text-danger">
                              O celular é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                     </Row> */}
                    {(!user?.corban_id ||
                      (user?.corban_id && user?.has_proposed_permission)) && (
                      <Row>
                        <VisibilityByUserRoles
                          roles={[
                            'master',
                            'operational',
                            'master_corban',
                            'user_corban',
                            'regional_manager',
                            'commercial_manager',
                          ]}
                        >
                          <Col md="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="store"
                              >
                                Loja
                              </label>
                              <input
                                className="form-control form-control-alternative"
                                id="store"
                                name="store"
                                value={store}
                                ref={register({ required: true })}
                                onChange={fetchData}
                                autoComplete="off"
                              />
                              <ul className="corban-list">
                                {stores &&
                                  stores.map((store) => (
                                    <li
                                      key={store.id}
                                      className="item-list"
                                      onClick={() => {
                                        setStores([]);
                                        handleClickList(store);
                                        getSellers(store.id);
                                        setAttendance((prevState) => ({
                                          ...prevState,
                                          phone_store: store.mobile,
                                        }));
                                      }}
                                    >
                                      {store.name}
                                    </li>
                                  ))}
                              </ul>

                              {errors.store && (
                                <small className="text-danger">
                                  Loja é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </VisibilityByUserRoles>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="phone-store"
                            >
                              Telefone Loja
                            </label>
                            <InputMask
                              type="text"
                              name="phone_store"
                              placeholder="Digite o número da loja"
                              className="form-control form-control-alternative"
                              id="phone_store"
                              mask="(99) 99999-9999"
                              value={attendance.phone_store}
                              inputRef={register({ required: true })}
                              onChange={handleOnChange}
                            />
                            {errors.phone_store && (
                              <small className="text-danger">
                                O número da loja é obrigatório
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        <VisibilityByUserRoles
                          roles={[
                            'master',
                            'operational',
                            'master_store',
                            'master_corban',
                            'user_corban',
                            'regional_manager',
                            'commercial_manager',
                          ]}
                        >
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="seller"
                              >
                                Vendedor
                              </label>
                              <CustomInput
                                type="select"
                                id="seller"
                                ref={register({ required: true })}
                                name="seller"
                                placeholder="Escolha o Vendedor"
                                onChange={(e) => setSeller(e.target.value)}
                                value={seller}
                              >
                                <option value="">Selecione uma opção</option>
                                {sellers.map((el) => (
                                  <option key={el.id} value={el.id}>
                                    {el.name}
                                  </option>
                                ))}
                              </CustomInput>
                              {errors.store && (
                                <small className="text-danger">
                                  O vendedor é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="phone"
                              >
                                Telefone Vendedor
                              </label>
                              <InputMask
                                type="text"
                                name="phone_seller"
                                placeholder="Digite o número do vendedor"
                                className="form-control form-control-alternative"
                                id="phone_seller"
                                mask="(99) 99999-9999"
                                value={attendance.phone_seller}
                                inputRef={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.phone_seller && (
                                <small className="text-danger">
                                  O número do vendedor é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </VisibilityByUserRoles>
                      </Row>
                    )}
                  </div>
                  <h6 className="heading-small text-muted mb-4">Simulador</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12 mb-4">
                        {user &&
                          user.roles &&
                          (user.roles[0].name == 'user_seller' ||
                            ([
                              'master',
                              'operational',
                              'master_store',
                              'regional_manager',
                              'commercial_manager',
                            ].includes(user.roles[0].name) &&
                              seller)) && (
                            <FormGroup tag="fieldset">
                              <Row>
                                <Col lg="4 text-center">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radio1"
                                        checked={kindSimulation == 'value_free'}
                                        onClick={() =>
                                          setKindSelection('value_free')
                                        }
                                      />{' '}
                                      Valor Liquido
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col lg="4 text-center">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="radio"
                                        checked={kindSimulation == 'card_limit'}
                                        onClick={() =>
                                          setKindSelection('card_limit')
                                        }
                                        name="radio1"
                                      />{' '}
                                      Limite do cartão
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col lg="4 text-center">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radio1"
                                        checked={
                                          kindSimulation == 'value_installment'
                                        }
                                        onClick={() =>
                                          setKindSelection('value_installment')
                                        }
                                      />{' '}
                                      Valor de parcela
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          )}
                      </Col>
                    </Row>
                    {kindSimulation && (
                      <>
                        {kindSimulation == 'value_free' && (
                          <Row className="d-flex justify-content-center">
                            <Col lg="4">
                              <FormGroup className="container-custom-simulator">
                                <label
                                  className="form-control-label text-center label-custom-simulator"
                                  htmlFor="name"
                                >
                                  Valor Liquido
                                </label>
                                <CurrencyInput
                                  prefix="R$"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  name="value_free"
                                  // placeholder="999.00"
                                  className="form-control input-custom-simulator"
                                  id="value_free"
                                  value={attendance.value_free}
                                  // inputRef={register({ required: true })}
                                  onChange={(e) => handleOnChangeValueFree(e)}
                                  // onChange={handleOnChangeValueFree}
                                />
                                {errors.name && (
                                  <small className="text-danger">
                                    O campo é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                        {kindSimulation == 'value_installment' && (
                          <Row className="d-flex justify-content-center">
                            <Col lg="4">
                              <FormGroup className="container-custom-simulator">
                                <label
                                  className="form-control-label label-custom-simulator text-center"
                                  htmlFor="value_installment"
                                >
                                  Parcela
                                </label>
                                <CurrencyInput
                                  prefix="R$"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  name="value_installment"
                                  // placeholder="999.00"
                                  className="form-control input-custom-simulator"
                                  id="value_installment"
                                  value={attendance.value_installment}
                                  // inputRef={register({ required: true })}
                                  onChange={(e) =>
                                    handleOnChangeValueInstallment(e)
                                  }
                                  // onChange={handleOnChangeValueFree}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                        {kindSimulation == 'card_limit' && (
                          <Row className="d-flex justify-content-center">
                            <Col lg="4">
                              <FormGroup className="container-custom-simulator">
                                <label
                                  className="form-control-label label-custom-simulator text-center"
                                  htmlFor="name"
                                >
                                  Limite do Cartão
                                </label>
                                <CurrencyInput
                                  prefix="R$"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  name="card_limit"
                                  // placeholder="999.00"
                                  className="form-control input-custom-simulator"
                                  id="card_limit"
                                  value={attendance.card_limit}
                                  // inputRef={register({ required: true })}
                                  onChange={(e) => handleOnChangeCardLimit(e)}
                                  // onChange={handleOnChangeValueFree}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col lg="8 mt-4">
                            <Col lg="12 mt-4">
                              <div className="collumn-container">
                                <label
                                  className="form-control-label mb-6 label-range-input"
                                  htmlFor="name"
                                >
                                  Prazo
                                </label>
                                <div className="marks-container">
                                  <div className="between-container">
                                    {valuesPortion &&
                                      valuesPortion.map((val, index) => {
                                        return (
                                          <div
                                            className={`item-container item-${index}`}
                                          >
                                            {val}x
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                <InputRange
                                  label="x"
                                  values={deadline}
                                  setValues={handleChangeDeadline}
                                  touchEnd={touchEnd}
                                  isInteger
                                  step={4}
                                  min={4}
                                  max={12}
                                />
                              </div>
                            </Col>
                            {minRate && maxRate && rate ? (
                              minRate == maxRate ? (
                                <div className="col-lg-12 mt-4">
                                  <label className="form-control-label mb-6 label-range-input" htmlFor="name">Taxa de Juros</label>
                                  <div style={{ transform: 'scale(1)', cursor: 'pointer', height: '36px', display: 'flex', width: '100%' }}>
                                    <div style={{ height: '5px', width: '100%', borderRadius: '4px', background: 'rgb(204, 204, 204)', alignSelf: 'center' }}>
                                      <div style={{
                                        position: 'absolute', zIndex: 0, cursor: 'grab', userSelect: 'none', touchAction: 'none', height: '42px', width: '42px', borderRadius: '4px', backgroundColor: 'rgb(255, 255, 255)', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: 'rgb(170, 170, 170) 0px 2px 6px', transform: 'translate(501px, -18.5px)'
                                      }} tabIndex="0" aria-valuemax="10" aria-valuemin="5" aria-valuenow="10" draggable="false" role="slider">
                                        <div style={{
                                          position: 'absolute', top: '-28px', color: 'rgb(255, 255, 255)', width: 'auto', fontWeight: 'bold', fontSize: '16px', fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif', padding: '4px 18px', borderRadius: '4px', backgroundColor: 'rgb(84, 139, 244)'
                                        }}>
                                          {`${maxRate}.00%`}
                                        </div>
                                        <div style={{ height: '16px', width: '5px', backgroundColor: 'rgb(204, 204, 204)' }}></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <Col lg="12 mt-4">
                                  <label
                                    className="form-control-label mb-6 label-range-input"
                                    htmlFor="name"
                                  >
                                    Taxa de Juros
                                  </label>
                                  <InputRange
                                    label="%"
                                    touchEnd={touchEnd}
                                    values={rate}
                                    setValues={handleChangeRate}
                                    step={1}
                                    disabled={disabledRate}
                                    min={minRate}
                                    max={maxRate}
                                  />
                                </Col>
                              )
                            ): (
                              <h5 className="badge badge-warning" style={{ fontSize: 14, marginTop: 18 }}>
                                Quantidade de parcelas não configuradas pra este estabelecimento
                              </h5>
                            )}
                          </Col>
                          {kindSimulation == 'value_free' && (
                            <Col lg="3 mt-4" className="container-results ml-4">
                              <div className="">
                                <div className="title-result-section">
                                  Comissão
                                </div>
                                {loading ? (
                                  <div className="result-custom-simulator">
                                    Carregando...
                                  </div>
                                ) : (
                                  <div>
                                    {/* <CurrencyInput
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        prefix="R$"
                                        name="commission_seller"
                                        placeholder="999.00"
                                        disabled
                                        className="form-control result-custom-simulator"
                                        id="commission_seller"
                                        value={commissionSeller}
                                        // inputRef={register({ required: true })}
                                        onChange={(e) => setCommissionSeller(e.target.value)}
                                        // onChange={handleOnChangeValueFree}
                                      /> */}
                                    <div className="result-custom-simulator">
                                      R$ {parseMoney(commissionSeller)} (
                                      {getPercentValue(
                                        commissionSeller,
                                        attendance.value_free
                                      )}
                                      %)
                                    </div>
                                  </div>
                                )}
                              </div>
                              {/* <div className="">
                                <div className="title-result-section">IOF</div>

                                <div className="result-custom-simulator">
                                  R$ {parseMoney(attendance.iof_value)}
                                </div>
                              </div> */}
                              {bonusSeller && bonusSeller > 0 && (
                                <div className="">
                                  <div className="title-result-section">
                                    Bônus
                                  </div>
                                  {loading ? (
                                    <div className="result-custom-simulator">
                                      Carregando...
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="result-custom-simulator">
                                        R$ {parseMoney(bonusSeller)} (
                                        {getPercentValue(
                                          bonusSeller,
                                          attendance.value_free
                                        )}
                                        %)
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="">
                                <div className="title-result-section">
                                  Parcelas
                                </div>
                                <div className="value-result-section installment-section">
                                  <span className="installment-result-section">
                                    {deadline[0]}x
                                  </span>
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_installment"
                                    // placeholder="999.00"
                                    disabled
                                    className="form-control result-custom-simulator"
                                    id="value_installment"
                                    value={attendance.value_installment}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) =>
                                      handleOnChangeValueInstallment(e)
                                    }
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                              {/* {
                                creditLimitWithoutIof && (
                                  <div className="mt-4">
                                    <div className="title-result-section">Limite necessario no cartão</div>
                                    <div className="value-result-section">
                                      <CurrencyInput
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        prefix="R$"
                                        name=""
                                        // placeholder="999.00"
                                        className="form-control result-custom-simulator"
                                        id=""
                                        disabled
                                        value={creditLimitWithoutIof}
                                        // inputRef={register({ required: true })}
                                        // onChange={(e) => handleOnChangeCardLimit(e)}
                                        // onChange={handleOnChangeValueFree}
                                      />
                                    </div>
                                  </div>
                                )
                              } */}
                              {/* {
                                diffIof && (
                                  <div className="mt-4">
                                    <div className="title-result-section">Valor do IOF</div>
                                    <div className="value-result-section">
                                      <CurrencyInput
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        prefix="R$"
                                        name="diffIof"
                                        // placeholder="999.00"
                                        className="form-control result-custom-simulator"
                                        id="diffIof"
                                        disabled
                                        value={diffIof}
                                        // inputRef={register({ required: true })}
                                        // onChange={(e) => handleOnChangeCardLimit(e)}
                                        // onChange={handleOnChangeValueFree}
                                      />
                                    </div>
                                  </div>
                                )
                              } */}
                              <div className="mt-4">
                                <div className="title-result-section">
                                  Limite necessario no cartão
                                </div>
                                <div className="value-result-section">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="card_limit"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator"
                                    id="card_limit"
                                    disabled
                                    value={attendance.card_limit}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) => handleOnChangeCardLimit(e)}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="title-result-section">
                                  Valor Liquido
                                </div>
                                <div className="value-result-section">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_free"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator"
                                    id="value_free"
                                    disabled
                                    value={attendance.value_free}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) => handleOnChangeCardLimit(e)}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="title-result-section">
                                  Valor Bruto
                                </div>
                                <div className="value-result-section">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_free"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator"
                                    id="value_free"
                                    disabled
                                    value={attendance.value_free_with_secure}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) => handleOnChangeCardLimit(e)}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                          {kindSimulation == 'value_installment' && (
                            <Col lg="3 mt-4" className="container-results ml-4">
                              <div className="">
                                <div className="title-result-section">
                                  Comissão
                                </div>
                                {loading ? (
                                  <div className="result-custom-simulator">
                                    Carregando...
                                  </div>
                                ) : (
                                  // <CurrencyInput
                                  //   decimalSeparator=","
                                  // thousandSeparator="."
                                  //   prefix="R$"
                                  //   name="commission_seller"
                                  //   placeholder="999.00"
                                  //   disabled
                                  //   className="form-control result-custom-simulator"
                                  //   id="commission_seller"
                                  //   value={commissionSeller}
                                  //   // inputRef={register({ required: true })}
                                  //   onChange={(e) => setCommissionSeller(e.target.value)}
                                  //   // onChange={handleOnChangeValueFree}
                                  // />
                                  <div className="result-custom-simulator">
                                    R$ {parseMoney(commissionSeller)} (
                                    {getPercentValue(
                                      commissionSeller,
                                      attendance.value_free
                                    )}
                                    %)
                                  </div>
                                )}
                              </div>
                              {bonusSeller && bonusSeller > 0 && (
                                <div className="">
                                  <div className="title-result-section">
                                    Bônus
                                  </div>
                                  {loading ? (
                                    <div className="result-custom-simulator">
                                      Carregando...
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="result-custom-simulator">
                                        R$ {parseMoney(bonusSeller)} (
                                        {getPercentValue(
                                          bonusSeller,
                                          attendance.value_free
                                        )}
                                        %)
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="">
                                <div className="title-result-section">
                                  Limite necessario no cartão
                                </div>
                                <div className="value-result-section">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="card_limit"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator"
                                    id="card_limit"
                                    disabled
                                    value={attendance.card_limit}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) => handleOnChangeCardLimit(e)}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="title-result-section">
                                  Valor Liquido
                                </div>
                                <div className="value-result-section">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_free"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator"
                                    id="value_free"
                                    disabled
                                    value={attendance.value_free}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) => handleOnChangeCardLimit(e)}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="title-result-section">
                                  Valor Bruto
                                </div>
                                <div className="value-result-section">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_free"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator"
                                    id="value_free"
                                    disabled
                                    value={attendance.value_free_with_secure}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) => handleOnChangeCardLimit(e)}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                          {kindSimulation == 'card_limit' && (
                            <Col lg="3 mt-4" className="container-results ml-4">
                              <div className="">
                                <div className="title-result-section">
                                  Comissão
                                </div>
                                {loading ? (
                                  <div className="result-custom-simulator">
                                    Carregando...
                                  </div>
                                ) : (
                                  // <CurrencyInput
                                  //   decimalSeparator=","
                                  // thousandSeparator="."
                                  //   prefix="R$"
                                  //   name="commission_seller"
                                  //   placeholder="999.00"
                                  //   disabled
                                  //   className="form-control result-custom-simulator"
                                  //   id="commission_seller"
                                  //   value={commissionSeller}
                                  //   // inputRef={register({ required: true })}
                                  //   onChange={(e) => setCommissionSeller(e.target.value)}
                                  //   // onChange={handleOnChangeValueFree}
                                  // />
                                  <div className="result-custom-simulator">
                                    R$ {parseMoney(commissionSeller)} (
                                    {getPercentValue(
                                      commissionSeller,
                                      attendance.value_free
                                    )}
                                    %)
                                  </div>
                                )}
                              </div>
                              {bonusSeller && bonusSeller > 0 && (
                                <div className="">
                                  <div className="title-result-section">
                                    Bônus
                                  </div>
                                  {loading ? (
                                    <div className="result-custom-simulator">
                                      Carregando...
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="result-custom-simulator">
                                        R$ {parseMoney(bonusSeller)} (
                                        {getPercentValue(
                                          bonusSeller,
                                          attendance.value_free
                                        )}
                                        %)
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="">
                                <div className="title-result-section">
                                  Parcelas
                                </div>
                                <div className="value-result-section installment-section">
                                  <span className="installment-result-section">
                                    {deadline[0]}x
                                  </span>
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_installment"
                                    // placeholder="999.00"
                                    disabled
                                    className="form-control result-custom-simulator"
                                    id="value_installment"
                                    value={attendance.value_installment}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) =>
                                      handleOnChangeValueInstallment(e)
                                    }
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="title-result-section">
                                  Valor Liquido
                                </div>
                                <div className="value-result-section">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_free"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator"
                                    id="value_free"
                                    disabled
                                    value={attendance.value_free}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) => handleOnChangeCardLimit(e)}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="title-result-section">
                                  Valor Bruto
                                </div>
                                <div className="value-result-section">
                                  <CurrencyInput
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    name="value_free"
                                    // placeholder="999.00"
                                    className="form-control result-custom-simulator"
                                    id="value_free"
                                    disabled
                                    value={attendance.value_free_with_secure}
                                    // inputRef={register({ required: true })}
                                    onChange={(e) => handleOnChangeCardLimit(e)}
                                    // onChange={handleOnChangeValueFree}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </>
                    )}
                  </div>
                  <div className="text-right mt-6">
                    <Button
                      disabled={loading || loadingSimulation || buttonDisabled}
                      type="submit"
                      className="btn btn-success px-4"
                    >
                      <i className="fas fa-paper-plane" /> Continuar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateCustomerService;
