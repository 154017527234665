import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Badge,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import { CSVLink } from "react-csv";
import Pagination from 'react-responsive-pagination';
import { Link, useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import swal from 'sweetalert';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';
import Header from '../../components/Headers/Header';
import Search from '../../components/Search';
import token from '../../util/setAuthToken';
import { AuthContext } from '../../context/AuthContext';
import { VisibilityUserMasterButton } from '../../util/visibility';

const LIMIT = 10;

const UpdateNsuContract = ({
    open,
    toogleModal,
    className,
    nsu,
    handleNsu,
    submit
  }) => {
  return (
    <Modal
      isOpen={open}
      toggle={toogleModal}
      className={className}
      backdrop="static"
    >
      <ModalHeader toggle={toogleModal}>Adicionar NSU</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="nsu">
                  NSU
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nsu"
                  name="nsu"
                  placeholder="Digite o nsu"
                  onChange={(e) => handleNsu(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toogleModal}>
          Fechar
        </Button>
        <Button color="success" onClick={submit}>
          Alterar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateNsuContract
