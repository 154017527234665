import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';
import { CSVLink } from "react-csv";

import InputMask from 'react-input-mask';
import UserHeader from '../Headers/UserHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import axios from 'axios';

import { useForm } from 'react-hook-form';

const CommissionProfile = (props) => {
  const { commissions, commissionEntity, entityId, hasFgts, hasCommission } = props

  const { register, handleSubmit, errors } = useForm();
  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [exportList, setExportList] = useState([])

  const [csvDisabled, setCsvDisabled] = useState(false)
  const csvLinkRef = useRef(csvLinkRef)

  const getInstallmentValue = (rate, installments=12) => {
    return (((1000 * ((Math.pow((1+(rate/100)),installments)) * (rate/100)) / (Math.pow((1+(rate/100)),installments)-1)).toFixed(2))/1000).toFixed(5)
  }

  async function export_commission() {
    setCsvDisabled(true)
    try {
      const response = await api.get(
        hasFgts ? `users/fgts_export_commissions?${ entityId && `id=${entityId}` }` : `users/export_commissions?${ entityId && `id=${entityId}` }`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        })
      setExportList(response.data)
      csvLinkRef?.current?.link.click();
    } catch(e) {

    }
    setCsvDisabled(false)
  }


  function getPercentValue(value, total) {
    if(value == 0 || value == null) {
      return 0
    }
    if(total == 0 || total == null) {
      return 0
    }

    return ((value * total)/(100))
  }


  return (
   <>
      {
      props.kind && (
      <>
        <hr className="my-4" />
        <Row>
          <Col lg="10">
            <h6 className="heading-small text-muted mb-4">
              Valor da Comissão
            </h6>
          </Col>
          <Col lg="2">
            <button
              disabled={csvDisabled}
              className="btn btn-primary"
              onClick={export_commission}
            >
              Exportar
            </button>
            <CSVLink
              data={exportList}
              filename={"commissions.csv"}
              separator={";"}
              ref={csvLinkRef}
            ></CSVLink>
          </Col>
        </Row>
        <div className="pl-lg-4">
        <Row>

              {/* <Col lg="2">
                <FormGroup>
                  <label className="form-control-label" htmlFor="basic">
                    Comissão
                  </label>
                </FormGroup>
              </Col> */}
              {/* {
                props.kind == 'seller' && (
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="basic">
                        Base Bônus
                      </label>
                    </FormGroup>
                  </Col>
                )
              } */}
              {/* {
                (props.kind == 'seller' || props.kind == 'corban'|| props.kind == 'store' || props.kind == 'subestablishment') && (
                  <Col lg="2">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="basic">
                        Bônus
                      </label>
                    </FormGroup>
                  </Col>
                )
              } */}
              {
                // (props.kind == 'corban'|| props.kind == 'store' || props.kind == 'subestablishment' || props.kind == 'seller') && (
                //   <Col lg="2">
                //     <FormGroup>
                //       <label className="form-control-label" htmlFor="basic">
                //         Coeficiente
                //       </label>
                //     </FormGroup>
                //   </Col>
                // )
              }
            </Row>
            {
              commissions && commissions.map((commission, index) => (
                <div key={index}>
                  <Row>
                    {
                      (
                      <Col lg="2">
                        <label className="form-control-label" htmlFor="basic">
                          Juros Base
                        </label>
                        <FormGroup>
                          <Input
                            type="text"
                            name="basic"
                            className="form-control form-control-alternative mt-4"
                            id="basic"
                            value={`${commission.basic_interest}%`}
                            inputRef={register({ required: true })}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      )
                    }
                    {
                      (
                      <Col lg="2">
                        <label className="form-control-label" htmlFor="basic">
                          Cod. Tabela
                        </label>

                        <FormGroup>
                          <Input
                            type="text"
                            name="name"
                            className="form-control form-control-alternative mt-4"
                            id="name"
                            value={`${commission.basic_interest_name}`}
                            inputRef={register({ required: true })}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      )
                    }
                  </Row>
                    {
                          commission.installments && commission.installments.map((item, indexField) => {
                          return (
                            // {JSON.stringify(item)}
                            <Row className="ml-4">
                              <Col className="col-lg-2">
                                <label className="form-control-label" htmlFor="basic">
                                  Parcelas
                                </label>
                                <Input
                                  type="select"
                                  name="installment"
                                  id="installment"
                                  disabled
                                  value={item.installment}
                                >
                                  <option value={item.key}>{item.installment}</option>
                                </Input>
                              </Col>

                              {
                                commissionEntity
                                ?
                                (
                                  <Col className='col-lg-2'>
                                    <FormGroup>
                                      <label className="form-control-label" htmlFor="basic">
                                      Comissão
                                      </label>
                                      <Input
                                      disabled
                                        type="text"
                                        name="value"
                                        className="form-control form-control-alternative"
                                        id="value"
                                        value={`${commissionEntity.commission_type == 'fix' ? 'R$' : ''} ${getPercentValue(commissionEntity.value, item.value).toFixed(2)}${commissionEntity.commission_type == 'percentage' ? '%' : ''}`}

                                      />
                                    </FormGroup>
                                  </Col>
                                )
                                :
                                (
                                  hasCommission && (
                                    <Col className='col-lg-2'>
                                      <FormGroup>
                                        <label className="form-control-label" htmlFor="basic">
                                        Comissão
                                        </label>
                                        <Input
                                          type="text"
                                          name="basic"
                                          className="form-control form-control-alternative"
                                          id="basic"
                                          mask="99%"
                                          value={`${item.value != null ? item.value.toFixed(2) : 0}%`}
                                          inputRef={register({ required: true })}
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  )
                                )
                              }
                              {
                                (props.kind == 'corban' || props.kind == 'store' || props.kind == 'subestablishment' || props.kind == 'seller') && hasCommission && (
                                  <Col className='col-lg-2'>
                                    <FormGroup>
                                      <label className="form-control-label" htmlFor="basic">
                                      Coeficiente
                                      </label>
                                      <Input
                                      disabled
                                        type="text"
                                        name="value"
                                        className="form-control form-control-alternative"
                                        id="value"
                                        value={`${getInstallmentValue(commission.basic_interest, item.installment)}`}
                                      />
                                    </FormGroup>
                                  </Col>
                                )
                              }
                            </Row>
                          )
                        })
                      }
                  <hr/>
                </div>
              ))
            }
          </div>

      </>
    )}
   </>
  )

}

export default CommissionProfile
