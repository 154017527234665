import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { toastError, toastSuccess } from '../../util/toast';
import { AuthContext } from '../../context/AuthContext';
import { LoadingContext } from '../../context/loading/LoadingContext';

const Register = (props) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const {
    registerUser,
    error,
    clearErrors,
    clearSuccess,
    success,
  } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  useEffect(() => {
    if (success) {
      hideLoader();
      toastSuccess(success);
      clearSuccess();
      props.history.push('/login');
    }

    if (error) {
      hideLoader();
      if (typeof error === 'object') {
        error.forEach((e) => {
          toastError(e.msg);
        });
      } else {
        toastError(error);
      }

      clearErrors();
    }
  }, [error, success]);

  function onSubmit(data) {
    showLoader();
    const phoneNumber = data.mobile.replace(/[^0-9]+/g, '');

    registerUser({
      ...data,
      mobile: phoneNumber,
      birthDate: data.birth_date,
    });
  }

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Crie sua conta</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Nome Completo"
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    ref={register({
                      required: 'O nome é obrigatório',
                    })}
                    maxLength={35}
                  />
                </InputGroup>
                {errors.name && (
                  <small className="text-danger">{errors.name.message}</small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    id="email"
                    className="form-control"
                    ref={register({
                      required: 'O email é obrigatório',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Por favor digite um email válido',
                      },
                    })}
                  />
                </InputGroup>
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Senha"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    id="password"
                    className="form-control"
                    ref={register({
                      required: 'A senha é obrigatório',
                      minLength: {
                        value: 6,
                        message: 'A senha deve ter pelo menos 6 caracteres',
                      },
                    })}
                  />
                </InputGroup>
                {errors.password && (
                  <small className="text-danger">
                    {errors.password.message}
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Confirme a senha"
                    type="password"
                    autoComplete="new-password"
                    name="password_repeat"
                    id="password_repeat"
                    className="form-control"
                    ref={register({
                      validate: (value) =>
                        value === watch('password') ||
                        'As senha não são iguais',
                    })}
                  />
                </InputGroup>
                {errors.password_repeat && (
                  <small className="text-danger">
                    {errors.password_repeat.message}
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-birthday-cake" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Data de nascimento"
                    type="date"
                    autoComplete="new-password"
                    name="birth_date"
                    id="birth_date"
                    className="form-control"
                    ref={register({
                      required: 'O data de nascimento é obrigatório',
                    })}
                  />
                </InputGroup>
                {errors.birth_date && (
                  <small className="text-danger">
                    {errors.birth_date.message}
                  </small>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-mobile" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    placeholder="Celular"
                    type="text"
                    autoComplete="new-password"
                    name="mobile"
                    id="mobile"
                    mask="(99) 99999-9999"
                    className="form-control"
                    inputRef={register({
                      required: 'O celular é obrigatório',
                    })}
                  />
                </InputGroup>
                {errors.mobile && (
                  <small className="text-danger">{errors.mobile.message}</small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  className="mt-1 mb-2 btn-block"
                  color="primary"
                  type="submit"
                >
                  Criar conta
                </Button>

                <Link className="text-dark" to="/login">
                  <small>Tem uma conta? Efetue o login já!</small>
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
