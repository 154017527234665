import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Col,
  Row,
  Container,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Form,
  Button,
} from 'reactstrap';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';

const FormCancel = (props) => {
  const { id } = useParams();
  const [idpayment, setIdpayment] = useState(0);
  const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  useEffect(() => {
    if (id) {
      setIdpayment(Number(id));
    }
  }, []);

  async function onSubmit(contract) {
    showLoader();

    try {
      const { data } = await api.post(
        'contracts/cancel',
        JSON.stringify({
          idpayment,
          refund_reason: contract.refund_reason,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      toastSuccess(data.message);

      props.history.push('/admin/contracts');
    } catch (error) {
      hideLoader();

      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Algo deu de errado quando foi enviar o formulário, por favor tente novamente.';
      }
      toastError(message);
    }
  }

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Cancelamento do Contrato</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Descreva abaixo o Motivo do Cancelamento do Contrato
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="refund_reason"
                          >
                            Motivo do Cancelamento
                          </label>
                          <textarea
                            name="refund_reason"
                            className="form-control form-control-alternative"
                            id="refund_reason"
                            placeholder="Digite aqui"
                            type="textarea"
                            rows="8"
                            ref={register({ required: true })}
                          />
                          {errors.refund_reason && (
                            <small className="text-danger">
                              O Motivo do Cancelamento é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> Enviar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FormCancel;
