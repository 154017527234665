import { toast } from 'react-toastify';

toast.configure();

/**
 * @param {string} message
 */
function toastError(message) {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

/**
 * @param {string} message
 */
function toastSuccess(message) {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export { toastError, toastSuccess };
