import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Input,
  Label,
  Col,
} from 'reactstrap';
import {useDropzone} from 'react-dropzone'
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import ContractForm from '../../components/ContractForm';
import token from '../../util/setAuthToken';
import { useLocation } from 'react-router-dom';

const DocumentUploadCustomer = (props) => {
  const {
    id,
    title,
    documentRg,
    setDocumentRg,
    documentFace,
    setDocumentFace,
    documentCreditCard,
    setDocumentCreditCard,
    documentRgPersist,
    setDocumentRgPersist,
    documentFacePersist,
    setDocumentFacePersist,
    documentCreditCardPersist,
    setDocumentCreditCardPersist,
    handleSubmit,
    sendDocument
  } = props;

  async function onSubmit() {

    if ((documentRg.length == 0 && documentRgPersist == 0)){
      toastError("Selecione pelo menos uma foto para cada opção");
      return
    }

    handleSubmit()
  }

  async function removeImagePersist(id, kind) {
    try {
      let { data } = await api.delete(`documents/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if(kind == 'rg') {
        setDocumentRgPersist(documentRgPersist.filter(item => item.id != id))
      } else if (kind == 'face') {
        setDocumentFacePersist(documentFacePersist.filter(item => item.id != id))
      } else if (kind == 'credit') {
        setDocumentCreditCardPersist(documentCreditCardPersist.filter(item => item.id != id))
      }

    } catch (e) {
      console.log(e)
    }

  }

  async function removeImage(id, kind) {
    if(kind == 'rg') {
      setDocumentRg(documentRg.filter((item, index) => index != id))
    } else if (kind == 'face') {
      setDocumentFace(documentFace.filter((item, index) => index != id))
    } else if (kind == 'credit') {
      setDocumentCreditCard(documentCreditCard.filter((item, index) => index != id))
    }
  }

  //Dropzone RG
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setDocumentRg(
        documentRg.concat(
          acceptedFiles.map(pic =>
            Object.assign(pic, {
              preview: URL.createObjectURL(pic),
              featured: false
            })
          )
        )
      )
    }
  })

  return (
    <>
      <CardBody>
        {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
          <h6 className="heading-small text-muted mb-4">
            Foto de documento de identificação: rg
          </h6>
          <FormGroup className="mt-4 ml-4">
            <div {...getRootProps()}>
              <input {...getInputProps()}  />
              {
                isDragActive ?
                  <p className="dropzoneFile">Buscando arquivos ...</p> :
                  <p className="dropzoneFile">Arraste ou clique para selecionar as fotos</p>
              }
            </div>
          </FormGroup>
          <div className="mb-4">
            <Row>
            {documentRgPersist.map(item =>
              <>
                <Col lg="4" className="positionRelative">
                  <a href={item.file_url} target="_blank">
                    <img src={item.file_url} className="imagePreview"/>
                  </a>
                  <Button color="danger" className="closeButtonImage" onClick={() => removeImagePersist(item.id, 'rg')} close />
                </Col>
              </>
            )}
            {documentRg.map((item, index) =>
              <>
                <Col lg="4" className="positionRelative">
                  <a href={item.preview} target="_blank">
                    <img src={item.preview} className="imagePreview"/>
                  </a>
                  <Button color="danger" className="closeButtonImage" onClick={() => removeImage(index, 'rg')} close />
                </Col>
              </>
            )}
            </Row>
          </div>
          <div className="text-right">
            <Button type="button" onClick={() => onSubmit()} className="btn btn-success px-4">
              <i className="fas fa-paper-plane" /> Enviar
            </Button>
          </div>
        {/* </Form> */}
      </CardBody>
    </>
  );
};

export default DocumentUploadCustomer;
