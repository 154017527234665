import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
} from 'reactstrap';
import Pagination from 'react-responsive-pagination';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { AuthContext } from '../../context/AuthContext';
import { VisibilityByUserRoles } from '../../util/visibility';
import { toastError, toastSuccess } from '../../util/toast';

import './style.css';
import Search from '../../components/Search';

const LIMIT = 12;

const Referrers = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [referrers, setReferrers] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState(1);
  const [cpf, setCpf] = useState('');

  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterEmail, setFilterEmail] = useState('');
  const [filterName, setFilterName] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('');

  async function getReferrers() {
    showLoader();

    try {
      const response = await api.get(
        `referrers?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      console.log(response.data);
      setReferrers(response.data);
      setNumberRegister(response.headers.total_count);

      const totalPages = response.headers.total_pages;

      setPages(totalPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/referrers',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os indicador.');
    }
  }

  useEffect(() => {
    getReferrers();
  }, [offset, searchFilter]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  function titleCase(str) {
    if (!str) {
      return;
    }
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  async function handleOnClick() {
    if (cpf.length > 0) {
      try {
        const { data } = await api.get(
          `referrers?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        console.log(data);
        setReferrers(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CPF.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CPF.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(
        `referrers?q[entity_cpf_cnpj_cont]=${searchText}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CPF');
    }
  }

  async function onChange(e) {
    setCpf(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  function onSelect(val) {
    setCpf(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item.cpf_cnpj} - {item.name}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item.cpf_cnpj}`;
  }

  function handlerOnClickFilter() {
    if (params.get('page')) {
      setOffset(1);
    }

    let filterPath = '';

    if (filterEmail) {
      filterPath += `&q[email_cont]=${filterEmail}`;
    }

    if (filterName) {
      filterPath += `&q[entity_name_cont]=${filterName}`;
    }

    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    setFilterName('');
    setFilterEmail('');
    setSearchFilter('');
    handleToggleFilter();
  }

  async function deleteReferrer(idReferrer) {
    try {
      showLoader()
      await api.delete(`referrers/${idReferrer}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Cliente deletado com sucesso');
      window.location.reload();
    } catch (error) {
      hideLoader();

      let message =
        'Ops, tivemos um erro ao deletar o indicador, por favor tente novamente!';

      if (error.response) {
        if (error.response.data.error) {
          message = error.response.data.error;
          console.log(message);
          message.forEach((e) => {
            toastError(e);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }

        if (typeof message === 'undefined') {
          message =
            'Ops, tivemos um erro ao deletar o indicador, por favor tente novamente!';
        }
      }

      toastError(message);
    }
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <VisibilityByUserRoles roles={['master', 'operational']}>
                <div className="text-right">
                  <Link
                    className="btn btn-success mb-3 mt-2"
                    to="/admin/referrers/create"
                  >
                    <i className="fas fa-plus" /> Criar novo indicador
                  </Link>
                </div>
              </VisibilityByUserRoles>

              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Indicadores</h3>
                      <Search
                        getItemValue={getItemValue}
                        autoCompleteData={autocompleteData}
                        renderItem={renderItem}
                        cpf_cnpj={cpf}
                        onChange={onChange}
                        onSelect={onSelect}
                        handleOnClick={handleOnClick}
                        placeHolder={'Buscar pelo CPF'}
                      />
                    </Col>
                    <Col>
                      {
                        <>
                          <div className="d-flex justify-content-end h-100 align-items-end">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleToggleFilter}
                            >
                              <i className="fas fa-filter" /> Filtrar
                            </button>
                          </div>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {numberRegister > 0 ? (
                    <div className="text-white">
                      Registros encontrados {LIMIT * (offset - 1) + 1} -{' '}
                      {numberRegister <= LIMIT
                        ? numberRegister
                        : LIMIT * offset}{' '}
                      de {numberRegister}
                    </div>
                  ) : (
                    <div className="text-white">Nenhum registro encontrado</div>
                  )}
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CPF</th>
                      <th scope="col">Nome</th>
                      <th scope="col">E-Mail</th>
                      <th scope="col">Telefone</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referrers.map((referrer) => (
                      <tr key={referrer.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {referrer.entity.cpf_cnpj.replace(
                                  /^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/,
                                  '$1.$2.$3-$4'
                                )}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{titleCase(referrer.entity.name)}</td>
                        <td>{referrer.email}</td>
                        <td scope="row">
                          {referrer.mobile.replace(
                            /^([\d]{2})([\d]{1})([\d]{4})([\d]{4})$/,
                            '($1) $2 $3-$4'
                          )}
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <Link
                                to={`/admin/referrers/${referrer.id}`}
                                className="dropdown-item"
                              >
                                <span>
                                  <i className="fas fa-edit" /> Editar indicador
                                </span>
                              </Link>
                              <Link
                                className="dropdown-item"
                                onClick={() => deleteReferrer(referrer.id)}
                              >
                                <span>
                                  <i className="fas fa-trash" /> Excluir
                                  indicador
                                </span>
                              </Link>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 bg-transparent">
                  <Row>
                    <Col
                      lg={{ size: 6, offset: 6 }}
                      className="d-flex justify-content-end"
                    >
                      <Pagination
                        current={offset}
                        total={Number(pages)}
                        onPageChange={setOffset}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Dgite o Email"
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Referrers;
