function phoneMask(value) {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');
}

function formatarMoeda(inputValue) {
  let valor = inputValue;

  valor += '';
  // eslint-disable-next-line radix
  valor = parseInt(valor.replace(/[\D]+/g, ''));
  valor += '';
  valor = valor.replace(/([0-9]{2})$/g, '.$1');

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}).([0-9]{2}$)/g, ',$1.$2');
  }

  if (valor === 'NaN') valor = '';

  return valor;
}

function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function formatCurrency(currency) {
  let formattedCurrency = currency;
  console.log(currency)
  if (formattedCurrency && formattedCurrency.includes(',')) {
    formattedCurrency = formattedCurrency.replace(',', '');
  }

  // if (formattedCurrency && formattedCurrency.includes('.')) {
  //   formattedCurrency = formattedCurrency.replace('.', '');
  // }

  return formattedCurrency;
}

function capitalize(sentence) {
  const finalSentence = sentence
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

  return finalSentence;
}

export { phoneMask, formatarMoeda, removeAccents, formatCurrency, capitalize };
