import React from 'react';

const UsersHeader = () => (
  <>
    <div className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      {/* Mask */}
      <span className="mask bg-gradient-default opacity-8" />
    </div>
  </>
);

export default UsersHeader;
