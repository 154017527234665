import { createContext, useReducer } from 'react';
import LoadingReducer from './LoadingReducer';

export const LoadingContext = createContext({});

export function LoadingContextProvider({ children }) {
  const initialState = {
    loading: false,
  };

  const [state, dispatch] = useReducer(LoadingReducer, initialState);

  const showLoader = () => dispatch({ type: 'SHOW_LOADER' });

  const hideLoader = () => dispatch({ type: 'HIDE_LOADER' });

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <LoadingContext.Provider
      value={{
        loading: state.loading,
        showLoader,
        hideLoader,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}
