import React, { useContext, useState, useEffect } from 'react';
import { LoadingContext } from '../../context/loading/LoadingContext';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import StoreForm from '../../components/StoreForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';
import swal from 'sweetalert';
import './style.css';

const CreateEstablishment = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [fgtsCommissions, setFgtsCommissions] = useState([
    {
      id: 1,
      tac: 12,
      name: 'Tabela Light CDC',
      commission_type: 0,
      value: 6,
      rate: 1.55,
      value_greater_thousand: 6,
      enabled: true,
      kind: 'base',
    },
    {
      id: 2,
      tac: 18,
      name: 'Tabela Gold CDC',
      commission_type: 0,
      value: 12,
      rate: 1.79,
      value_greater_thousand: 12,
      enabled: true,
      kind: 'base',
    },
    {
      id: 3,
      tac: 30,
      name: 'Tabela Gold CDC',
      commission_type: 0,
      value: 12,
      rate: 1.79,
      value_greater_thousand: 12,
      enabled: true,
      kind: 'base',
    },
    {
      id: 4,
      tac: 30,
      name: 'Tabela Light CDC',
      commission_type: 0,
      value: 6,
      rate: 1.55,
      value_greater_thousand: 6,
      enabled: true,
      kind: 'base',
    },
  ]);

  const { user } = useContext(AuthContext);
  const [store, setStore] = useState({
    social_reason: '',
    name: '',
    cpf_cnpj: '',
    user_cpf: '',
    user_name: '',
    user_email: '',
    user_mobile: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    bank_name: '',
    agency_number: '',
    number_account: '',
    has_commission: '',
    active_base_trigger: '',
    commission_type: '',
    value: '',
    trigger: '',
    store_kind: 0,
    agency_digit: '',
    account_digit: '',
    internal_code: '',
    password: 'novosaque123',
    support_whats: true,
  });
  const { register, handleSubmit, errors, setError } = useForm();
  const [contactFields, setContactFields] = useState([
    { mobile: '', email: '', type_contact: '' },
  ]);

  const [banks, setBanks] = useState([]);
  const [corbans, setCorbans] = useState([]);
  const [subs, setSubs] = useState([]);
  const [kind, setKind] = useState('pj');
  const [managers, setManagers] = useState([]);
  const [disabledSubestablishment, setDisabledSubestablishment] =
    useState(false);
  const [corbanName, setCorbanName] = useState('');

  async function getManagers() {
    showLoader();

    try {
      const response = await api.get(`/users/user_managers`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      // const values = response.data.map((value,index) => {
      //   return {
      //     value: `${value.id} - ${value.name}`,
      //     label: `${value.id} - ${value.name}`
      //   }
      // })

      setManagers(response.data);

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os managers.');
    }
  }

  async function getSubs(paramsId = null) {
    // showLoader()

    try {
      const response = await api.get(
        `/subestablishments/searchs?${
          paramsId ? `q[corban_id_eq]=${paramsId}` : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value, index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`,
        };
      });

      console.log(values);

      setSubs([
        {
          value: `0 - Não selecionar`,
          label: `0 - Não selecionar`,
        },
        ...values,
      ]);

      // hideLoader();
    } catch (error) {
      // hideLoader();
      // toastError('Ops, não foi possível lista as corbans.');
    }
  }

  useEffect(() => {
    async function getBanks() {
      showLoader();

      try {
        const response = await api.get(`banks?q[active_eq]=true`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        const values = response.data.map((value, index) => {
          return {
            value: `${value.code} - ${value.name}`,
            label: `${value.code} - ${value.name}`,
          };
        });

        setBanks(values);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os bancos.');
      }
    }

    getBanks();
  }, []);

  useEffect(() => {
    if (
      user &&
      user.roles[0] &&
      (user.roles[0].name == 'master' || user.roles[0].name == 'operational')
    ) {
      getManagers();
    }
    if (
      user &&
      user.roles[0] &&
      (user.roles[0].name == 'master_corban' ||
        user.roles[0].name == 'user_corban_register')
    ) {
      setCorbanName('Nome do corban');
      getSubs();
    }
  }, [user]);

  async function onSubmit(data) {
    showLoader();

    let corbanId = null;
    let subId = null;
    if (
      user &&
      user.roles[0] &&
      (user.roles[0].name == 'master' ||
        user.roles[0].name == 'operational' ||
        user.roles[0].name == 'master_corban')
    ) {
      if (store.sub_id && store.sub_id.split('-')[0] != 0) {
        subId = store.sub_id.split('-')[0];
      } else if (store.corban_id) {
        corbanId = store.corban_id.split('-')[0];
      }
    }

    // if(!store.bank_name){
    //   toastError("Selecione o banco")
    //   hideLoader();
    //   return
    // }

    // if(!store.account_kind){
    //   toastError("Selecione o tipo de conta")
    //   hideLoader();
    //   return
    // }
    if (!store.kind_pix) {
      toastError('Selecione o tipo de chave pix');
      hideLoader();
      return;
    }

    if (store.kind_pix != 'random' && !store.pix) {
      toastError('Preencher campo pix');
      hideLoader();
      return;
    }

    data = {
      store: {
        user_regional_id: store.regional_manager_id,
        auto: store.auto,
        subestablishment_id: subId,
        code_qista: store.code_qista,
        // attendance_method: store.attendance_method,
        // support_whats: store.support_whats === 'true' ? true : false,
        corban_id: corbanId,
        kind: 'pj',
        entity_attributes: {
          cpf_cnpj: store.cpf_cnpj,
          name: store.name,
          commission_fgts: store.commission_fgts,
          social_reason: store.social_reason,
          has_commission: store.has_commission,
          address_attributes: {
            zip_code: store.zip_code,
            street: store.street,
            number: store.number,
            district: store.district,
            city: store.city,
            state: store.state,
            complement: store.complement,
          },
          bank_account_attributes: {
            number_bank: store.bank_name.split('-')[0],
            name_bank: store.bank_name.split('-')[1],
            agency_account: store.agency_account,
            agency_digit: store.agency_digit,
            number_account: store.number_account,
            account_digit: store.account_digit,
            kind: store.account_kind,
            kind_pix: store.kind_pix,
            pix: store.pix,
          },
          contacts_attributes: [...contactFields],
          commission_bases_attributes: [
            {
              commission_type: parseInt(store.commission_type, 10),
              value: store.value
                ? store.value.toString().replace('R$', '').replace(',', '')
                : store.value,
              trigger: store.trigger,
            },
          ],
        },
        users_attributes: [
          {
            cpf: store.user_cpf,
            name: store.user_name,
            mobile: store.user_mobile,
            email: store.user_email,
            password: store.password,
          },
        ],
      },
      user: {
        cpf: store.user_cpf,
        name: store.user_name,
        mobile: store.user_mobile,
        email: store.user_email,
        password: store.password,
      },
    };

    if (!corbanId) {
      data['store']['entity_attributes']['fgts_commissions_attributes'] = [
        ...fgtsCommissions.map((item) => {
          return {
            ...item,
            id: null,
            value:
              item.commission_type == '0'
                ? item.value.toString().replace(',', '.')
                : item.value,
          };
        }),
      ];
    }

    try {
      await api.post('stores', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Estabelecimento criado com sucesso');

      props.history.push('/admin/establishments');
    } catch (error) {
      hideLoader();

      let message = '';

      if (error.response.data.error) {
        const responseErrors = error.response.data.error;
        responseErrors.forEach((e) => {
          // console.log(e.key)
          // console.log(e.model)
          if (e.key == 'Documento' && e.model == 'users') {
            setError('user_cpf', 'inválido');
          } else if (e.key == 'Documento' && e.model == 'entity') {
            setError('cpf_cnpj', 'inválido');
          } else if (e.key == 'email' && e.model == 'users') {
            setError('user_email', 'inválido');
          } else if (e.key == 'trigger' && e.model == 'entity') {
            setError('trigger', 'inválido');
          }
        });
      }
      window.scrollTo(0, 0);
      toastError('Há informações incorretas, por favor revise-as.');
    }
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parsePercentage(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace('%', '').replace(',', '.');
    } else {
      return value;
    }
  }

  function parseDigit(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, 'x');
    } else {
      return value;
    }
  }

  function parseAccount(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, '');
    } else {
      return value;
    }
  }

  function handleOnChange(e) {
    const values = { ...store };

    if (e.target.name == 'value_percentage') {
      setStore({ ...store, ['value']: parsePercentage(e.target.value) });
    } else if (e.target.name == 'trigger') {
      setStore({ ...store, ['trigger']: parsePercentage(e.target.value) });
    } else if (e.target.name == 'commission_type' && values['value'] != '') {
      setStore({ ...store, [e.target.name]: e.target.value, value: '' });
    } else if (e.target.name == 'agency_digit') {
      setStore({ ...store, ['agency_digit']: parseDigit(e.target.value) });
    } else if (e.target.name == 'account_digit') {
      setStore({ ...store, ['account_digit']: parseDigit(e.target.value) });
    } else if (e.target.name == 'number_account') {
      setStore({ ...store, ['number_account']: parseAccount(e.target.value) });
    } else if (e.target.name == 'cpf_cnpj') {
      let cpf_cnpj = e.target.value;

      if (store.store_kind == 0) {
        if (e.target.value.length < 19) {
          cpf_cnpj = cpf_cnpj.replace(/^(\d{2})(\d)/, '$1.$2');

          cpf_cnpj = cpf_cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

          cpf_cnpj = cpf_cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');

          cpf_cnpj = cpf_cnpj.replace(/(\d{4})(\d)/, '$1-$2');
          setStore({ ...store, [e.target.name]: cpf_cnpj });
        }
      } else {
        if (e.target.value.length < 15) {
          if (cpf_cnpj.length >= 12) {
            cpf_cnpj = cpf_cnpj
              .replace(/[^0-9]/g, '')
              .replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
          } else {
            cpf_cnpj = cpf_cnpj.replace(/(\d{3})(\d)/, '$1.$2');
          }
          setStore({ ...store, [e.target.name]: cpf_cnpj });
        }
      }
    } else if (e.target.name == 'user_cpf') {
      if (e.target.value.length < 15) {
        let user_cpf = e.target.value;
        if (user_cpf.length >= 12) {
          user_cpf = user_cpf
            .replace(/[^0-9]/g, '')
            .replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
        } else {
          user_cpf = user_cpf.replace(/(\d{3})(\d)/, '$1.$2');
        }
        setStore({ ...store, [e.target.name]: user_cpf });
      }
    } else if (e.target.name == 'user_mobile') {
      if (e.target.value.length < 16) {
        let user_mobile = e.target.value;

        if (user_mobile.length > 10) {
          user_mobile = user_mobile
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3');
        } else {
          user_mobile = user_mobile.replace(/^(\d{2})(\d)/, '($1) $2');
        }
        setStore({ ...store, [e.target.name]: user_mobile });
      }
    } else {
      setStore({ ...store, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeValueFix(e) {
    setStore({
      ...store,
      value: parseCurrency(e),
    });
  }

  function handleOnAgencyDigits(e) {
    setStore({
      ...store,
      agency_digit: parseDigit(e.target.agency_digit),
    });
  }

  function handleOnAccountDigits(e) {
    setStore({
      ...store,
      account_digit: parseDigit(e.target.account_digit),
    });
  }

  function dataAddress({ street, district, city, state }) {
    setStore({
      ...store,
      street: street,
      district: district,
      city: city,
      state: state,
    });
  }

  function handleOnChangeContact(index, event) {
    const values = [...contactFields];
    if (event.target.name == 'email_contact') {
      values[index]['email'] = event.target.value;
    } else if (event.target.name == 'mobile_contact') {
      values[index]['mobile'] = event.target.value;
    }

    setContactFields(values);
  }

  function handleClickContact() {
    setContactFields([
      ...contactFields,
      { mobile: '', email: '', type_contact: '' },
    ]);
  }

  function handleClickContactRemove(index) {
    const values = [...contactFields];
    values.splice(index, 1);
    setContactFields(values);
  }

  function handleOnChangeTypeContact(event, index) {
    const values = [...contactFields];
    console.log(index);
    console.log(values[index]);
    if (event.target.value == 'email') {
      values[index].type_contact = event.target.value;
    } else if (event.target.value == 'mobile') {
      values[index].type_contact = event.target.value;
    }

    setContactFields(values);
  }

  function handleOnChangeBank(e) {
    setStore({ ...store, bank_name: e.value });
  }

  function extratNumber(str) {
    const match = str.match(/^\d+/); // Encontra a sequência de números no início da string
    return match ? match[0] : ''; // Retorna a sequência encontrada, ou uma string vazia se nada for encontrado
  }

  async function handleOnChangeCorban(e) {
    getSubs(extratNumber(e.target.value));
    setCorbanName(e.target.value);
    if (
      e.target.value.length > 4 &&
      user.roles[0] &&
      (user.roles[0].name == 'master' || user.roles[0].name == 'operational')
    ) {
      const response = await api.get(
        `/corbans/searchs?q[entity_name_cont]=${e.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      const values = response.data.map((value, index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`,
        };
      });
      setCorbans(values);
    } else if (e.target.value === '') {
      setCorbans([]);
    }
  }

  function handleClickItemListFilter(value) {
    setCorbanName(value);
    setCorbans([]);
    swal({
      text: 'Deseja copiar os dados do CORBAN?',
      icon: 'success',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (next) => {
      if (next) {
        const response = await api.get(`/get_corban/${extratNumber(value)}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        setDisabledSubestablishment(true);
        setStore({
          ...store,
          corban_id: value,
          cpf_cnpj: response.data.info_sub.cpf_cnpj,
          social_reason: response.data.info_sub.social_reason,
          name: response.data.info_sub.name,
          user_cpf: response.data.legal_representative.cpf,
          user_name: response.data.legal_representative.name,
          user_email: response.data.legal_representative.email,
          user_mobile: response.data.legal_representative.mobile,
          zip_code: response.data.address.zip_code,
          street: response.data.address.street,
          number: response.data.address.number,
          district: response.data.address.district,
          city: response.data.address.city,
          state: response.data.address.state,
          complement: response.data.address.complement,
          internal_code: response.data.internal_code,
        });
      } else {
        setDisabledSubestablishment(false);
        setStore({ ...store, corban_id: value });
      }
    });
  }

  function handleOnChangeSub(e) {
    if (e.value !== '' && !disabledSubestablishment) {
      swal({
        text: 'Deseja copiar os dados do Subestabelecimento?',
        icon: 'success',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          const response = await api.get(
            `/get_subestablishment/${extratNumber(e.value)}`,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          );
          setStore({
            ...store,
            cpf_cnpj: response.data.info_sub.cpf_cnpj,
            social_reason: response.data.info_sub.social_reason,
            name: response.data.info_sub.name,
            user_cpf: response.data.legal_representative.cpf,
            user_name: response.data.legal_representative.name,
            user_email: response.data.legal_representative.email,
            user_mobile: response.data.legal_representative.mobile,
            zip_code: response.data.address.zip_code,
            street: response.data.address.street,
            number: response.data.address.number,
            district: response.data.address.district,
            city: response.data.address.city,
            state: response.data.address.state,
            complement: response.data.address.complement,
            internal_code: response.data.internal_code,
            sub_id: e.value,
          });
        } else {
          setStore({ ...store, sub_id: e.value });
        }
      });
    }
    setStore({ ...store, sub_id: e.value });
  }

  function insertPercentage(e) {
    console.log(e.target.name);
    if (e.target.name == 'value_percentage') {
      setStore({ ...store, ['value']: `${e.target.value}%` });
    } else {
      if (e.target.name == 'commission_fgts') {
        setStore({ ...store, ['commission_fgts']: `${e.target.value}%` });
      } else {
        setStore({ ...store, ['trigger']: `${e.target.value}%` });
      }
    }
  }

  function insertMoney(e) {
    if (e.target.value.charAt(0) != 'R') {
      setStore({ ...store, ['value']: `R$ ${e.target.value}` });
    }
  }

  function handleOnChangeCommissionFgts(event, id, index) {
    const values = [...fgtsCommissions];
    index = values.indexOf(values.filter((item) => item.id === id)[0]);

    if (
      event.target.name === 'value_percentage' ||
      event.target.name == 'trigger' ||
      event.target.name == 'value_greater_thousand'
    ) {
      if (event.target.name === 'value_percentage') {
        values[index]['value'] = event.target.value;
      } else if (event.target.name === 'value_greater_thousand') {
        values[index]['value_greater_thousand'] = event.target.value;
      } else {
        values[index]['trigger'] = event.target.value;
      }
    } else if (
      event.target.name == 'commission_type' &&
      values[index]['value'] != ''
    ) {
      values[index]['value'] = '';
    }

    values[index][event.target.name] = event.target.value;
    console.log('SETANDO TUDO');
    setFgtsCommissions(values);
  }

  console.log('COMISSION');
  console.log(fgtsCommissions);
  return (
    <>
      <CustomerHeader />
      <StoreForm
        store={store}
        contactFields={contactFields}
        managers={managers}
        banks={banks}
        isQista
        handleClickItemListFilter={handleClickItemListFilter}
        corbans={corbans}
        corbanName={corbanName}
        fgtsCommissions={fgtsCommissions}
        handleOnChangeCommissionFgts={handleOnChangeCommissionFgts}
        subs={subs}
        kind={kind}
        title="Criar Estabelecimento"
        titleButton="Criar Estabelecimento"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        handleOnAccountDigits={handleOnAccountDigits}
        handleOnAgencyDigits={handleOnAgencyDigits}
        handleOnChangeValueFix={handleOnChangeValueFix}
        handleOnChangeContact={handleOnChangeContact}
        handleClickContact={handleClickContact}
        handleClickContactRemove={handleClickContactRemove}
        handleOnChangeTypeContact={handleOnChangeTypeContact}
        handleOnChangeBank={handleOnChangeBank}
        handleOnChangeCorban={handleOnChangeCorban}
        handleOnChangeSub={handleOnChangeSub}
        dataAddress={dataAddress}
        controller={'new'}
        insertPercentage={insertPercentage}
        insertMoney={insertMoney}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
};

export default CreateEstablishment;
