import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { formatarMoeda } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import DocumentUpload from '../../components/DocumentUpload';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';

import img from '../../assets/img/logo_novo_saque.png';

import './style.css';

const FinishPayment = (props) => {
  const [amount, setAmount] = useState(0);
  const [installments, setInstallments] = useState(1);
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [contractNumber, setContractNumber] = useState('');
  const [status, setStatus] = useState(3);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [contractId, setContractId] = useState(null);
  const [documentSend, setDocumentSend] = useState(false);
  const [documentRg, setDocumentRg] = useState([]);
  const [documentFace, setDocumentFace] = useState([]);
  const [documentCreditCard, setDocumentCreditCard] = useState([]);
  const [documentRgPersist, setDocumentRgPersist] = useState([]);
  const [documentFacePersist, setDocumentFacePersist] = useState([]);
  const [documentCreditCardPersist, setDocumentCreditCardPersist] = useState([]);

  const [simplifiedPayment, setSimplifiedPayment] = useState(false);
  const [signatureKey, setSignatureKey] = useState(false);

  async function onSubmit() {

    if ((documentRg.length == 0 && documentRgPersist == 0) || (documentFace.length == 0 && documentFacePersist == 0) || (documentCreditCard.length == 0 && documentCreditCardPersist.length == 0)){
      toastError("Selecione pelo menos uma foto para cada opção");
      return
    }

    showLoader();
    try {
      const contractData = new FormData()
      let index = 0
      documentRg.map((item) => {
        contractData.append(`contract[documents_attributes][${index}][file]`, item)
        contractData.append(`contract[documents_attributes][${index}][kind]`, "rg")
        index += 1
        console.log(index)
      })
      documentFace.map((item) => {
        contractData.append(`contract[documents_attributes][${index}][file]`, item)
        contractData.append(`contract[documents_attributes][${index}][kind]`, "face")
        index += 1
        console.log(index)
      })
      documentCreditCard.map((item) => {
        contractData.append(`contract[documents_attributes][${index}][file]`, item)
        contractData.append(`contract[documents_attributes][${index}][kind]`, "credit_card")
        index += 1
        console.log(index)
      })

      const response = await api.put(`contracts/${contractId}/update_images`, contractData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response)

      hideLoader();

      toastSuccess('Documentos Enviados com sucesso');
      setDocumentSend(true)
    } catch (error) {
      hideLoader();
      console.log(error)
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um erro ao enviar documentos, por favor tente novamente!';
      }
      toastError(message);
    }
  }

  async function getContract(id) {
    try {
      const response = await api.get(`contracts/${id}/show_payment_contract`);
      setSimplifiedPayment(response.data.simplified_payment)
      setSignatureKey(response.data.request_signature_key)

      let { data } = await api.get(`contracts/${id}/docs_infos`);
      if(data.documents.filter(item => item.kind == 'rg').length > 0 && data.documents.filter(item => item.kind == 'face').length > 0 && data.documents.filter(item => item.kind == 'credit_card').length > 0) {
        setDocumentSend(true)
      }
      setDocumentRgPersist(data.documents.filter(item => item.kind == 'rg'))
      setDocumentFacePersist(data.documents.filter(item => item.kind == 'face'))
      setDocumentCreditCardPersist(data.documents.filter(item => item.kind == 'credit_card'))
      hideLoader();
    } catch (e) {
      console.log(e)
      hideLoader();
    }
  }

  async function sendWithoutDocument() {
    setDocumentSend(true)
    try {


      // const response = await api.put(`pipelines/${contractId}/set_document_pending`, {}, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // });

      hideLoader();

    } catch (error) {
      hideLoader();

    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('@UGate:payment')) {
      const data = JSON.parse(sessionStorage.getItem('@UGate:payment'));
      setStatus(data.status);
      setInstallments(data.installments);
      setContractNumber(data.contractNumber);
      setContractId(data.contractId)
      let amountCharged = data.amount //.replace(/[^0-9]+/g, '');
      //amountCharged /= 100;

      // setAmount(
      //   parseMoney(parseFloat(amountCharged))
      // );
      setAmount(
        parseFloat(amountCharged).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
      );

      setInstallmentAmount(
        (amountCharged / data.installments).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
      );
      getContract(data.contractId)
    }
  }, []);

  function parseMoney(value) {
    return value.toFixed(2).toString().replace(".", ",")
  }

  function redirectToSignedLink() {
    if(simplifiedPayment) {
      window.location.href = 'https://wa.me/551142000444';
    } else {
      window.location.href = signatureKey;
    }
  }

  return (
    <Container>
      {(documentSend || simplifiedPayment) && (
        <Row className="mt-8 d-flex align-items-center">
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Card className="shadow-lg card-payment-info">
            <CardHeader className="card-payment_header">
              <div className="card-payment-info_container">
                <img src={img} className="img-fluid" alt="Logo Novo Saque" />
              </div>
            </CardHeader>
            <CardBody>
              <div className="d-flex flex-column justify-content-center align-items-center mb-3">
                <div
                  className={`bg-${
                    status === 3 ? 'success' : 'primary'
                  } text-white rounded-circle shadow-sm p-3 icon-check mb-2`}
                >
                  <i className="fas fa-check m-0 p-0" />
                </div>
                {status === 3 || simplifiedPayment ? (
                  <h1 className="text-success text-center font-weight-800">
                    Falta pouco!
                  </h1>
                ) : (
                  <h1 className="text-primary text-center font-weight-800">
                    Operação em Análise!
                  </h1>
                )}
                {status === 1 && !simplifiedPayment ? (
                  <p className="text-center font-weight-600">
                    Estamos validando seu pagamento junto ao banco.
                    <br />
                    Aguarde uma resposta em breve.
                  </p>
                ) : (
                  <p className="text-center font-weight-600">
                    Agora você receberá no seu Whatsapp um link para assinar seu contrato.
                    <br />
                    Após assinar, o valor cairá na sua conta em até 5min!
                  </p>
                )}
              </div>
              {!simplifiedPayment ? (
                <div className="info-buy">
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <p className="font-weight-bold">Valor cobrado</p>
                  <p className="font-weight-900">{amount}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="font-weight-bold">Parcelado em</p>
                  <p className="font-weight-900">
                    {installments}x {installmentAmount}
                  </p>
                </div>
              </div>
              ): (
                <div className='d-flex justify-content-center'>
                  <Button
                    type="button"
                    onClick={redirectToSignedLink}
                    className="btn btn-success px-4"
                  >
                    Assinar Contrato
                  </Button>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      )}

      {
        contractId && !documentSend && !simplifiedPayment && (
          <Row className="mt-8 d-flex align-items-center">
            <Col md={{ size: 8, offset: 2 }} lg={{ size: 8, offset: 2 }}>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col sm="12">
                      <h3 className="mb-0 text-center">Envie as fotos dos seguintes documentos para finalizar o processo de aprovação</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <DocumentUpload
                  id={contractId}
                  title="Selecione os arquivos indicados"
                  documentRg={documentRg}
                  setDocumentRg={setDocumentRg}
                  documentFace={documentFace}
                  setDocumentFace={setDocumentFace}
                  documentCreditCard={documentCreditCard}
                  setDocumentCreditCard={setDocumentCreditCard}
                  documentRgPersist={documentRgPersist}
                  setDocumentRgPersist={setDocumentRgPersist}
                  documentFacePersist={documentFacePersist}
                  setDocumentFacePersist={setDocumentFacePersist}
                  documentCreditCardPersist={documentCreditCardPersist}
                  setDocumentCreditCardPersist={setDocumentCreditCardPersist}
                  handleSubmit={onSubmit}
                  sendDocument={() => sendWithoutDocument()}
                />
              </Card>
            </Col>
          </Row>
        )
      }

    </Container>
  );
};

export default FinishPayment;
