import React, { useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import api from '../../services/api';
import img from '../../assets/img/logo_novo_saque.png';
import { toastError, toastSuccess } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';

const ForgotPassword = () => {
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const { register, handleSubmit, errors } = useForm();

  async function onSubmit(dataUser) {
    showLoader();

    try {
      const { data } = await api.post('forgot', JSON.stringify(dataUser), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      hideLoader();

      toastSuccess(data.message);
    } catch (error) {
      hideLoader();

      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo não deu certo, esse email existe?';
      }

      toastError(message);
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-novo-saque">
            <img src={img} className="img-fluid" alt="Logo Novo Saque" />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Recuperação de Senha</small>
            </div>
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Digite seu email"
                    type="email"
                    autoComplete="new-email"
                    id="email"
                    name="email"
                    className="form-control"
                    ref={register({
                      required: 'O email é obrigatório',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Por favor digite um email válido',
                      },
                    })}
                  />
                </InputGroup>
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-3 btn-block"
                  color="primary"
                  type="submit"
                >
                  Recuperar senha
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ForgotPassword;
