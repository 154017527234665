export default (state, action) => {
  switch (action.type) {
    case 'USER_LOADED':
      localStorage.setItem('@UGate:user', JSON.stringify(action.payload));
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };
    case 'REGISTER_SUCCESS':
      return {
        ...state,
        success: action.payload,
      };
    case 'LOGIN_SUCCESS':
      localStorage.setItem('@UGate:user', JSON.stringify(action.payload));
      localStorage.setItem('@UGate:token', action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
      };
    case 'REGISTER_FAIL':
    case 'AUTH_ERROR':
    case 'LOGIN_FAIL':
    case 'LOGOUT':
      localStorage.removeItem('@UGate:token');
      localStorage.removeItem('@UGate:user');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload,
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        error: null,
      };
    case 'CLEAR_SUCCESS':
      return {
        ...state,
        success: null,
      };
    default:
      return state;
  }
};
