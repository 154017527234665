import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import {
  Button,
  Badge,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Input,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import Pagination from 'react-responsive-pagination';
import { Link, useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import swal from 'sweetalert';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';
import Header from '../../components/Headers/Header';
import Search from '../../components/Search';
import token from '../../util/setAuthToken';
import { AuthContext } from '../../context/AuthContext';
import {
  VisibilityUserMasterButton,
  VisibilityByUserRoles,
} from '../../util/visibility';

import './style.css';
import UpdateContractNumber from 'components/UpdateContractNumber';
import UpdateBankAccount from 'pages/UpdateBankAccount';
import { HandleBgPipelinesColor } from '../../util/visibility';
import { isValid, parseISO } from 'date-fns';
import { AsyncPaginate } from "react-select-async-paginate";

const LIMIT = 25;

let arrayReport = [];
const Contracts = (props) => {
  const params = new URLSearchParams(props.location.search);
  const { user } = useContext(AuthContext);
  const [representatives, setRepresentatives] = useState([]);
  const [copied, setCopied] = useState(false);
  const [totalPageReport, setTotalPageReport] = useState(1);
  const [requestFinish, setRequestFinish] = useState(true);
  const [pageReport, setPageReport] = useState(1);
  const [dataReport, setDataReport] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [corbans, setCorbans] = useState([]);
  const [subs, setSubs] = useState([]);
  const [sort, setSort] = useState('desc');
  const [filterBlack, setFilterBlack] = useState(null);
  const [filterDispute, setFilterDispute] = useState(null);
  const [bankKind, setBankKind] = useState(null);
  const [sortPaymentDate, setSortPaymentDate] = useState('desc');
  const [sortCommissionPaymentDate, setSortCommissionPaymentDate] =
    useState('desc');
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState("");
  const [sellers, setSellers] = useState([]);
  const [getAllCustomers, setGetAllCustomers] = useState(() => {
    if (params.get('cpf')) {
      return true;
    }
    return false;
  });
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });

  const [pages, setPages] = useState(0);
  const [cpf, setCpf] = useState(() => {
    if (params.get('cpf')) {
      return params.get('cpf');
    }

    return '';
  });
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const [autocompleteDataIdContract, setAutocompleteDataIdContract] = useState(
    []
  );
  const [paymentInformation, setPaymentInformation] = useState({
    idcontract: 0,
    contractNumber: '',
    name: '',
    payments: [],
  });
  const [pipeline, setPipeline] = useState({
    status: '',
    reason: '',
    contract_id: '',
  });
  const [disputeStatusAction, setdisputeStatusAction] = useState({
    status: '',
    contract_id: '',
  });
  const [numberContract, setNumberContract] = useState({
    reason: '',
  });
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [pipelineContracts, setPipelineContracts] = useState([]);
  const [pipelineIndex, setPipelineIndexModal] = useState(false);
  const [contractId, setContractId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [bankAccountModal, setBankAccountModal] = useState(false);

  const [reportModal, setReportModal] = useState(false);
  const [emailReport, setEmailReport] = useState('');

  const [modal, setModal] = useState(false);
  const [contractNumberModal, setContractNumberModal] = useState(false);
  const [pipelineModal, setPipelineModal] = useState(false);
  const [disputeStatusModal, setDisputeStatusModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [modalDate, setModalDate] = useState(false);
  const [pipeStatus, setPipeStatus] = useState('');
  const [disputeStatus, setDisputeStatus] = useState('');
  const [pipeFilterStatus, setPipeFilterStatus] = useState('');
  const [filterId, setFilterId] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterCorban, setFilterCorban] = useState('');
  const [filterCorbanType, setFilterCorbanType] = useState('');
  const [filterSub, setFilterSub] = useState('');
  const [filterAuto, setFilterAuto] = useState('');
  const [filterStore, setFilterStore] = useState('');
  const [filterSeller, setFilterSeller] = useState('');
  const [filterContract, setFilterContract] = useState('');
  const [filterFrom, setFilterFrom] = useState('');
  const [filterEnd, setFilterEnd] = useState('');
  const [filterManager, setFilterManager] = useState('');
  const [filterRegionalManager, setFilterRegionalManager] = useState('');
  const [url, setUrl] = useState(`${window.location.href.split('?')[1]}`);
  const [disabledFilter, setDisabledFilter] = useState(false);
  const [filterDateType, setFilterDateType] = useState('created_at_custom');
  const [maxDate, setMaxDate] = useState('');
  const [minDate, setMinDate] = useState('');
  const [minFilterDate, setMinFilterDate] = useState(
    `${new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ).getFullYear()}-${
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getMonth() + 1
    }-${new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ).getDate()}`
  );
  const today = new Date();
  // const[ minFilterDate, setMinFilterDate] = useState(`${new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).getFullYear()}-${new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).getMonth()+1}-${new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).getDate()}`)
  const [maxFilterDate, setMaxFilterDate] = useState(() => {
    return `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  });
  const [searchFilter, setSearchFilter] = useState(
    `&q[${filterDateType}_gteq]=${minFilterDate}`
  );
  const [minRegisterDate, setMinRegisterDate] = useState('');
  const [maxRegisterDate, setMaxRegisterDate] = useState('');
  const [minPaymentDate, setMinPaymentDate] = useState('');
  const [maxPaymentDate, setMaxPaymentDate] = useState('');
  const [minCommissionDate, setMinCommissionDate] = useState('');
  const [maxCommissionDate, setMaxCommissionDate] = useState('');
  const [regionalManagers, setRegionalManagers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalCommissionValue, setTotalCommissionValue] = useState(0);
  const [totalContractValue, setTotalContractValue] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [operateSimulator, setOperateSimulator] = useState(0);
  const [documentPending, setDocumentPending] = useState(0);
  const [sendLink, setSendLink] = useState(0);
  const [paymentReturned, setPaymentReturned] = useState(0);
  const [waitingDocumentosAnalitcs, setWaitingDocumentosAnalitcs] = useState(0);
  const [waitingSignature, setWaitingSignature] = useState(0);
  const [contractSigned, setContractSigned] = useState(0);
  const [contractPaid, setContractPaid] = useState(0);
  const [totalValuePaid, setValuePaid] = useState(0);
  const [totalValueCanceled, setValueCanceled] = useState(0);
  const [totalValue, setValueTotal] = useState(0);
  const [commissionPaid, setCommissionPaid] = useState(0);
  const [canceled, setCanceled] = useState(0);
  const [chargebacked, setChargebacked] = useState(0);
  const [numberRegister, setNumberRegister] = useState(0);
  const [exportList, setExportList] = useState([]);
  const [analyticalReport, setAnalyticalReport] = useState([]);
  const [syntheticReport, setSyntheticReport] = useState([]);
  const [exportCompleteList, setExportCompleteList] = useState([]);
  const [filterDashboard, setFilterDashboard] = useState([]);
  const [filterIdContract, setFilterIdContract] = useState('');
  const [applyDashFilter, setApplyDashFilter] = useState(false);
  const [csvDisabled, setCsvDisabled] = useState(false);
  const [completeReportDisabled, setCompleteReportDisabled] = useState(false);
  const [syntheticReportDisabled, setSyntheticReportDisabled] = useState(false);
  const csvLinkRef = useRef(csvLinkRef);
  const completeReportLinkRef = useRef(completeReportLinkRef);
  const analyticalLinkRef = useRef(analyticalLinkRef);
  const syntheticLinkRef = useRef(syntheticLinkRef);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [corbanName, setCorbanName] = useState('');

  async function getAllCustomersByCpf() {
    return await api.get(
      `contracts?q[customer_entity_cpf_cnpj_eq]=${cpf}&page=${offset}&limit=${LIMIT}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
  }

  async function getAllCustomersIdContract() {
    return await api.get(
      `contracts?sort=created_at%20desc&q[kind_eq]=0&page=1&q[id_eq]=${filterIdContract}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
  }

  function handleClickItemListFilter(value) {
    setCorbanName(value);
    setFilterCorban(value);
    setCorbans([]);
  }

  async function handleOnChangeCorban(e) {
    setCorbanName(e.target.value);
    if (
      e.target.value.length > 4 &&
      user.roles[0] &&
      (user.roles[0].name == 'master' || user.roles[0].name == 'operational')
    ) {
      const response = await api.get(
        `/corbans/searchs?q[entity_name_cont]=${e.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      const values = response.data.map((value, index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`,
        };
      });
      setCorbans(values);
    } else if (e.target.value === '') {
      setCorbans([]);
    }
  }

  async function getRegionalManagers() {
    // showLoader();

    try {
      const response = await api.get(`users/managers?page=${offset}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.data) {
        setRegionalManagers(
          response.data.sort((a, b) => sortByText(a, b, 'ASC'))
        );
      }

      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }

  const loadStoreOptions = useCallback(
    async (search, prevOptions, { page }) => {
      try {
        let url = `stores?page=${page}`
  
        if (search) {
          url += `&q[entity_name_cont]=${search}`;
        }
  
        if(corbanName) {
          url += `&q[corban_id_eq]=${corbanName}`
        }
  
        const { data } = await api.get(url, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
  
        const filteredOptions = data.filter(({ entity }) =>
          entity.name.toLowerCase().includes(search.toLowerCase())
        );
  
        const options = filteredOptions.map((store) => ({
          value: store.id,
          label: store.entity.name,
        }));
  
        return {
          options,
          hasMore: data.length > 0,
          additional: {
            page: page + 1,
          },
        };
      } catch (error) {
        console.log("Algo deu errado ao buscar as Lojas", error);
        return {
          options: [],
          hasMore: false,
        };
      }
    }, [corbanName]
  );

  async function getManagers() {
    // showLoader();

    try {
      const response = await api.get(`users/user_managers?page=${offset}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.data) {
        setManagers(response.data.sort((a, b) => sortByText(a, b, 'ASC')));
      }

      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }

  async function handleModalBankAccountOpen(id, customer_id) {
    setContractId(id);
    setCustomerId(customer_id);
    setBankAccountModal(true);
  }

  async function getRepresentative() {
    try {
      const { data } = await api.get('sellers/searchs', {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (data) {
        setRepresentatives(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os parceiros');
    }
  }

  const sortByText = (a, b, order = 'ASC') => {
    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (order === 'ASC') {
      return diff;
    }

    return -1 * diff;
  };

  async function getSub() {
    try {
      const { data } = await api.get('subestablishments/searchs', {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (data) {
        setSubs(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os Subestabelecimentos');
    }
  }

  async function getDocumentSign(id) {
    try {
      const { data } = await api.get(
        `/contracts/${id}/get_contract_clicksign`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      window.open(data, '_blank');
    } catch (error) {
      console.log('Algo deu errado ao buscar documento');
    }
  }

  async function getStores(page) {
    try {
      const { data } = await api.get(`stores/searchs?q[page]=${page}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (data) {
        setStores(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os Lojas');
    }
  }

  async function getSellers(store_id = null) {
    console.log(store_id)
    try {
      const { data } = await api.get(
        `sellers/searchs?${store_id ? `q[store_id_eq]=${store_id}` : ''}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (data) {
        setSellers(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os vendedores', error);
    }
  }

  async function sortCommissionPaymentUpdatedAt() {
    setSortPaymentDate('desc');
    setSort('desc');
    let sortParam = 'sort=commission_payment_date%20desc';
    if (sortCommissionPaymentDate == 'desc') {
      setSortCommissionPaymentDate('asc');
      sortParam = 'sort=commission_payment_date%20asc';
    } else {
      setSortCommissionPaymentDate('desc');
    }

    getCustomers(sortParam);
  }

  async function sortPaymentUpdatedAt() {
    setSortCommissionPaymentDate('desc');
    setSort('desc');
    let sortParam = 'sort=payment_date%20desc';
    if (sortPaymentDate == 'desc') {
      setSortPaymentDate('asc');
      sortParam = 'sort=payment_date%20asc';
    } else {
      setSortPaymentDate('desc');
    }

    getCustomers(sortParam);
  }

  async function sortUpdatedAt() {
    setSortCommissionPaymentDate('desc');
    setSortPaymentDate('desc');
    let sortParam = 'sort=created_at%20desc';
    if (sort == 'desc') {
      setSort('asc');
      sortParam = 'sort=created_at%20asc';
    } else {
      setSort('desc');
    }

    getCustomers(sortParam);
  }

  async function getCustomers(sortParam = 'sort=created_at%20desc') {
    showLoader();

    if (url != '' && url != undefined && url != 'undefined') {
      setSearchFilter(url);
      setUrl('');
    }

    try {
      let response = '';

      if (params.get('cpf')) {
        response = await getAllCustomersByCpf();
      } else {
        let filterSearch = searchFilter;
        if (params.get('customer_id')) {
          filterSearch += '&q[customer_id_eq]=' + params.get('customer_id');
        }
        response = await api.get(
          `contracts?${sortParam}&q[kind_eq]=0&page=${offset}${filterSearch}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
      }
      if (searchFilter != '') {
        setApplyDashFilter(true);
        setFilterDashboard(response.data.contracts.map((value) => value.id));
      }

      const totalPages = response.headers.total_pages;

      setPages(totalPages);
      setCustomers(response.data.contracts);
      setPipeline(response.data.contracts);
      //setExportList(response.data.exportList);
      setNumberRegister(response.headers.total_count);

      // const dashboard = response.data.dashboard
      // if(dashboard && !pipeStatus) {
      //   setTotalCustomers(dashboard.total_customers);
      //   setTotalCommissionValue(dashboard.totalCommissionValue);
      //   setTotalContractValue(dashboard.totalContractValue);
      //   setTotalPayments(dashboard.total_payments);
      //   setOperateSimulator(dashboard.totaloperateSimulator)
      //   setDocumentPending(dashboard.totaldocumentPending)
      //   setSendLink(dashboard.totalSendLink)
      //   setWaitingDocumentosAnalitcs(dashboard.totalwaitingDocumentosAnalitcs)
      //   setWaitingSignature(dashboard.totalwaitingSignature)
      //   setContractSigned(dashboard.totalcontractSigned)
      //   setContractPaid(dashboard.totalcontractPaid)
      //   setValuePaid(dashboard.totalValuePaid)
      //   setValueCanceled(dashboard.totalValueCanceled)
      //   setValueTotal(dashboard.totalValue)
      //   setCommissionPaid(dashboard.totalcommissionPaid)
      //   setCanceled(dashboard.totalcanceled)
      // }

      if (offset > 1) {
        let search = '';

        if (params.get('cpf')) {
          search = `?q[customer_entity_cpf_cnpj_eq]=${cpf}&page=${offset}`;
        } else if (params.get('filter')) {
          search = `?filter=${searchFilter}&page=${offset}`;
        } else {
          search = `?page=${offset}`;
        }

        props.history.push({
          pathname: '/admin/contracts',
          search,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro na busca dos clientes, por favor tente novamente!'
      );
    }
  }

  useEffect(() => {
    if (user) {
      if (
        user &&
        (user.role_default == 'user_store_register' ||
          user.role_default == 'user_corban_register' ||
          user.role_default == 'user_subest_register')
      ) {
        props.history.push({
          pathname: '/admin/profile',
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (!disabledFilter) {
      getCustomers();
      dashboard();
    }
  }, [offset, searchFilter]);

  useEffect(() => {
    handlerOnClickFilter(false, false);
  }, [pipeStatus, disputeStatus]);

  async function handleResendLink(idnotification) {
    showLoader();

    try {
      const response = await api.post(
        `notifications/${idnotification}/resend_notification`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const { data } = response;
      getCustomers();
      toastSuccess(data.message);

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro no reenvio do link, por favor tente novamente!'
      );
    }
  }

  async function reactivatePaymentLink(idnotification) {
    showLoader();

    try {
      const response = await api.post(
        `notifications/${idnotification}/reactivate_notification`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const { data } = response;
      getCustomers();
      toastSuccess(data.message);

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro no reenvio do link, por favor tente novamente!'
      );
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(
        `contracts/customers?q[entity_cpf_cnpj_cont]=${searchText}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CPF');
    }
  }

  async function onChange(e) {
    setCpf(e.target.value);
    if (e.target.value.length >= 4) {
      await retrieveDataAsynchronously(e.target.value);
    }
  }

  function onSelect(val) {
    setCpf(val);
  }

  function renderItem(item) {
    return (
      <div
        onClick={() => {
          setCpf(item.cpf_cnpj);
          setAutoCompleteData([]);
        }}
      >
        {item.cpf_cnpj}
      </div>
    );
  }

  function renderItemIdContract(item) {
    return (
      <div
        style={{ paddingLeft: 12 }}
        onClick={() => {
          setFilterIdContract(item.id);
          setAutocompleteDataIdContract([]);
        }}
      >
        {item.id}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item.id}`;
  }

  function cancelTransation(idpayment) {
    swal({
      title: 'Atenção',
      text: 'Esta operação é irreversível. Seu cliente receberá um estorno no Cartão. Confirma o cancelamento?',
      icon: 'warning',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        showLoader();
        try {
          const { data, status } = await api.post(
            `payments/${idpayment}/cancel_payment`,
            {},
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token()}`,
              },
            }
          );

          hideLoader();

          if (status === 204) {
            toastError(
              'O pagamento não pode ser cancelado, pois está fora do prazo, solicite o reembolso pelo formulário abaixo.'
            );
            props.history.push(`/admin/contracts/cancel/${idpayment}`);
          } else {
            toastSuccess(data.message);
            const newCustomers = customers.map((customer) => {
              if (customer.idpayment === idpayment) {
                return {
                  ...customer,
                  bg: 'bg-danger',
                  status: 'Cancelado',
                  statusCode: 7,
                };
              }

              return customer;
            });

            setCustomers(newCustomers);

            getCustomers();
          }
        } catch (e) {
          hideLoader();
          let message =
            'Ops, não foi possível cancelar o pagamento, por favor tente novamente mais tarde';

          if (e.response.data) {
            message = e.response.data.message;
          }

          toastError(message);
        }
      }
    });
  }

  async function dashboard() {
    setRequestFinish(false);
    let token = '';
    if (localStorage.getItem('@UGate:token')) {
      token = localStorage.getItem('@UGate:token');
    }

    try {
      const { data } = await api.get(`dashboard?q[kind_eq]=0&${searchFilter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalCustomers(data.total_customers);
      setTotalCommissionValue(data.totalCommissionValue);
      setTotalContractValue(data.totalContractValue);
      setTotalPayments(data.total_payments);
      setOperateSimulator(data.totaloperateSimulator);
      setDocumentPending(data.totaldocumentPending);
      setSendLink(data.totalSendLink);
      setPaymentReturned(data.totalcontractPaymentReturned);
      setWaitingDocumentosAnalitcs(data.totalwaitingDocumentosAnalitcs);
      setWaitingSignature(data.totalwaitingSignature);
      setContractSigned(data.totalcontractSigned);
      setContractPaid(data.totalcontractPaid);
      setCommissionPaid(data.totalcommissionPaid);
      setCanceled(data.totalcanceled);
      setChargebacked(data.totalchargebacked);
      setValuePaid(data.totalValuePaid);
      setValueCanceled(data.totalValueCanceled);
      setValueTotal(data.totalValue);

      setRequestFinish(true);
      if (applyDashFilter) {
        setApplyDashFilter(false);
        setFilterDashboard([]);
      }
    } catch (e) {
      setRequestFinish(true);
      toastError(
        'Ops, tivemos um erro na busca dos dados do dashboard, por favor tente novamente'
      );
    }
  }

  function cancelContract(idcontract) {
    swal({
      title: 'Atenção',
      text: 'Esta operação é irreversível. Confirma o cancelamento?',
      icon: 'warning',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        showLoader();
        try {
          const userParams = user && user.id ? user.id : null;
          const { data, status } = await api.put(
            `contracts/${idcontract}/cancel_contract?user_id=${userParams}`,
            JSON.stringify({}),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token()}`,
              },
            }
          );

          hideLoader();

          toastSuccess(data.message);
          getCustomers();
        } catch (e) {
          hideLoader();

          let message = 'Algo deu errado ao cancelar o contrato.';

          if (e.response.data) {
            message = e.response.data.message;
          }

          toastError(message);
        }
      }
    });
  }

  function checkRefund(idcontract) {
    swal({
      title: 'Atenção',
      text: 'Esta operação é irreversível. Confirma o reembolso?',
      icon: 'warning',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        showLoader();
        try {
          const { data, status } = await api.put(
            `contracts/${idcontract}/refund_contract`,
            JSON.stringify({}),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token()}`,
              },
            }
          );

          hideLoader();

          toastSuccess(data.message);
          getCustomers();
        } catch (e) {
          hideLoader();

          let message = 'Algo deu errado ao cancelar o contrato.';

          if (e.response.data) {
            message = e.response.data.message;
          }

          toastError(message);
        }
      }
    });
  }

  async function handleOnClick() {
    if (cpf.length > 0) {
      try {
        showLoader();
        const { data, headers } = await getAllCustomersByCpf();

        const totalPages = headers.total_pages;

        const arrayPages = [];
        for (let i = 1; i <= totalPages; i++) {
          arrayPages.push(i);
        }

        setPages(arrayPages);
        setCustomers(data.contracts);
        setGetAllCustomers(true);
        hideLoader();
        props.history.push({
          pathname: '/admin/contracts',
          search: `?cpf=${cpf}`,
        });
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CPF.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CPF.');
    }
  }

  function handleOnPipeline(e) {
    setPipeline({ ...pipeline, [e.target.name]: e.target.value });
  }

  function handleOnDisputeStatus(e) {
    setdisputeStatusAction({
      ...disputeStatusAction,
      [e.target.name]: e.target.value,
    });
  }

  function handleOnContractNumber(e) {
    setNumberContract({ ...numberContract, [e.target.name]: e.target.value });
  }

  async function handleGetAllCustomers() {
    setDisabledFilter(false);
    showLoader();
    try {
      const { data, headers } = await api.get(
        `contracts?page=1&limit=${LIMIT}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      const totalPages = headers.total_pages;

      const arrayPages = [];
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i);
      }

      setPages(arrayPages);
      setCustomers(data.contracts);
      setGetAllCustomers(false);
      setCpf('');

      params.delete('page');
      params.delete('cpf');
      props.history.replace({
        search: params.toString(),
      });
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro na busca dos clientes, por favor tente novamente!'
      );
    }

    hideLoader();
  }

  async function handleResendCCB(contract_id) {
    try {
      await api.post(
        `contracts/${contract_id}/resend_ccb`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      toastSuccess('Operação executada com sucesso!');
    } catch (e) {
      hideLoader();
      toastError('Ops, ocorreu um erro durante o reenvio da CCB!');
    }
  }

  const handleToggle = () => setModal(!modal);

  const handleToggleFilter = () => {
    if (!filterModal) {
      // if(representatives && representatives.length <= 0){
      //   getRepresentative();
      // }
      if (managers && managers.length <= 0) {
        getManagers();
      }
      if (regionalManagers && regionalManagers.length <= 0) {
        getRegionalManagers();
      }
      if (subs && subs.length <= 0) {
        getSub();
      }
      if (stores && stores.length <= 0) {
        // getStore();
      }
      if (sellers && sellers.length <= 0) {
        getSellers();
      }
    }
    setFilterModal(!filterModal);
  };

  const handleTogglePipeline = ({ idcontract }) => {
    setPipeline({ ...pipeline, contract_id: idcontract });
    setPipelineModal(!pipelineModal);
  };

  const handleToggleDisputeStatus = ({ idcontract }) => {
    setdisputeStatusAction({ ...disputeStatusAction, contract_id: idcontract });
    setDisputeStatusModal(!disputeStatusModal);
  };

  const handleToggleReportModal = () => {
    setReportModal(!reportModal);
  };

  const handleToggleIndexPipeline = async ({ idcontract }) => {
    try {
      const { data } = await api.get(`pipelines?contract_id=${idcontract}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setPipelineContracts(data);
      setPipelineIndexModal(!pipelineIndex);
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo deu errado ao buscar os pagamentos do contrato.';
      }

      hideLoader();
      toastError(message);
    }
  };

  const handleClickToggle = async ({ idcontract, contractNumber, name }) => {
    try {
      const { data } = await api.get(`contracts/${idcontract}/payments`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setPaymentInformation({
        idcontract: 0,
        contractNumber: '',
        name: '',
        payments: [],
      });

      setModal(!modal);
      setPaymentInformation({
        contractNumber,
        name,
        payments: data,
      });
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo deu errado ao buscar os pagamentos do contrato.';
      }

      hideLoader();
      toastError(message);
    }
  };

  function copyToClipboard() {
    setCopied(true);
    toastSuccess('Link copiado');
  }

  function handleOnChangeRangeDate(e) {
    if (e.target.name == 'max') {
      setMaxDate(e.target.value);
    } else {
      setMinDate(e.target.value);
    }
  }

  function handleOnClickCard(pipeStatusParams, limitDate) {
    // let filter = "";

    // if (pipeStatus != null) {
    //   filter += `&pipeFilterStatus=${pipeStatus}`
    // }
    // if (limitDate != null) {
    //   filter += `&limitDate=${limitDate}`
    // }

    // setSearchFilter(filter);
    setPipeStatus(pipeStatusParams);
    setDisputeStatus('');
  }

  function handleOnClickChargebackCard() {
    setPipeStatus('');
    setDisputeStatus([0, 1, 2]);
  }

  function handleClearFilterRegisterDate() {
    setMinRegisterDate('');
    setMaxRegisterDate('');
  }

  function handleClearFilterPaymentDate() {
    setMinPaymentDate('');
    setMaxPaymentDate('');
  }

  function handleClearFilterCommissionDate() {
    setMinCommissionDate('');
    setMaxCommissionDate('');
  }

  function handlerOnClickFilter(closeModal = true, isDisabledFilter) {
    setDisabledFilter(isDisabledFilter);
    if (
      minFilterDate !==
      `${new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getFullYear()}-${
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ).getMonth() + 1
      }-${new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getDate()}`
    ) {
      const parsedDateMinDate = parseISO(minFilterDate);
      if (!isValid(parsedDateMinDate)) {
        toastError('Data inicial inválida');
        return;
      }
    }

    if (
      maxFilterDate !==
      `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`
    ) {
      const parsedDateMaxDate = parseISO(maxFilterDate);
      if (!isValid(parsedDateMaxDate)) {
        toastError('Data final inválida');
        return;
      }
    }

    if (params.get('page')) {
      setOffset(1);
    }

    let filterPath = '';

    if (cpf.length > 0) {
      handleOnClick();
    }

    if (filterId) {
      filterPath += `&q[id_eq]=${filterId}`;
    }

    if (filterSeller) {
      filterPath += `&seller_id=${filterSeller}`;
    }

    if (filterName) {
      filterPath += `&q[customer_entity_name_cont]=${filterName.replace(
        /\s+/g,
        ''
      )}`;
    }

    if (filterEmail) {
      filterPath += `&q[customer_email_cont]=${filterEmail}`;
    }

    if (filterCorban) {
      filterPath += `&corban_id=${filterCorban}`;
    }

    if (filterCorbanType) {
      filterPath += `&q[seller_store_corban_corban_type_or_seller_store_subestablishment_corban_corban_type_eq]=${filterCorbanType}`;
    }

    if (filterAuto) {
      filterPath += `&q[seller_store_auto_eq]=${filterAuto}`;
    }

    if (filterSub) {
      filterPath += `&sub_id=${filterSub}`;
    }

    if (filterStore) {
      filterPath += `&store_id=${filterStore}`;
    }

    if (filterContract) {
      filterPath += `&q[contract_number_or_ccb_number_or_code_proposal_cont]=${filterContract}`;
    }

    if (filterManager) {
      filterPath += `&user_manager_id_eq=${filterManager}`;
      // filterPath += `&q[user_manager_id_eq]=${filterManager}`;
      // filterPath += `&q[seller_store_user_manager_id_eq]=${filterManager}`;
    }

    if (filterRegionalManager) {
      filterPath += `&q[seller_store_user_manager_manager_id_eq]=${filterRegionalManager}`;
    }

    if (filterBlack) {
      filterPath += `&q[customer_blacklist_eq]=${filterBlack}`;
    }

    if (pipeFilterStatus) {
      filterPath += `&pipeFilterStatus=${pipeFilterStatus}`;
    }

    if (pipeStatus) {
      filterPath += `&pipeStatus=${pipeStatus}`;
    }

    if (disputeStatus) {
      filterPath += `&disputeStatus=${disputeStatus}`;
    }

    if (filterDateType != '') {
      if (minFilterDate) {
        filterPath += `&q[${filterDateType}_gteq]=${minFilterDate}`;
      }
      if (maxFilterDate) {
        filterPath += `&q[${filterDateType}_lteq]=${maxFilterDate}`;
      }
      if (filterDateType == 'credit_card_payment_date') {
        let newMaxFilterDate = maxFilterDate;
        if (maxFilterDate) {
          newMaxFilterDate = `${newMaxFilterDate} 23:59:00`;
        }
        filterPath += `&q[payments_payment_statuses_status_in]=1,3`;
        if (minFilterDate) {
          filterPath += `&q[${filterDateType}_gteq]=${minFilterDate}`;
        }
        if (maxFilterDate) {
          filterPath += `&q[${filterDateType}_lteq]=${newMaxFilterDate}`;
        }
      }
    }

    if (filterDispute) {
      if (filterDispute == 'no_dispute') {
        filterPath += `&q[dispute_status_null]=1`;
      } else {
        filterPath += `&q[dispute_status_eq]=${filterDispute}`;
      }
    }

    setSearchFilter(filterPath);

    if (closeModal) {
      handleToggleFilter();
    }
  }

  function clearFilterModal() {
    setDisabledFilter(true);
    setFilterSeller('');
    setFilterName('');
    setFilterEmail('');
    setFilterCorban('');
    setFilterCorbanType('');
    setFilterSub('');
    setFilterStore('');
    setFilterContract('');
    setFilterFrom('');
    setFilterManager('');
    setFilterEnd('');

    setSearchFilter(`&q[${filterDateType}_gteq]=${minFilterDate}`);

    setMinRegisterDate('');
    setMaxRegisterDate('');
    setMinPaymentDate('');
    setMaxPaymentDate('');
    setMinCommissionDate('');
    setMaxCommissionDate('');
  }

  function handleClearFilter() {
    setFilterId('');
    setFilterSeller('');
    setFilterName('');
    setFilterEmail('');
    setFilterCorban('');
    setFilterCorbanType('');
    setFilterSub('');
    setFilterAuto('');
    setFilterStore('');
    setFilterContract('');
    setFilterFrom('');
    setFilterManager('');
    setFilterEnd('');
    setSearchFilter(`&q[${filterDateType}_gteq]=${minFilterDate}`);

    setMinRegisterDate('');
    setMaxRegisterDate('');
    setMinPaymentDate('');
    setMaxPaymentDate('');
    setMinCommissionDate('');
    setMaxCommissionDate('');

    handleToggleFilter();
  }

  function handleClearAllFilter() {
    setFilterId('');
    setFilterSeller('');
    setFilterName('');
    setFilterEmail('');
    setFilterCorban('');
    setFilterCorbanType('');
    setFilterSub('');
    setFilterAuto('');
    setFilterStore('');
    setFilterContract('');
    setFilterFrom('');
    setFilterManager('');
    setFilterEnd('');
    setMinRegisterDate('');
    setMaxRegisterDate('');
    setMinPaymentDate('');
    setMaxPaymentDate('');
    setMinCommissionDate('');
    setMaxCommissionDate('');
    setPipeFilterStatus('');
    setPipeStatus('');
    setDisputeStatus('');
    setMinFilterDate(
      `${new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getFullYear()}-${
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ).getMonth() + 1
      }-${new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getDate()}`
    );
    setMaxFilterDate('');
    setSearchFilter(`&q[${filterDateType}_gteq]=${minFilterDate}`);
  }

  const handleToggleNumberModal = (contract_id) => {
    setNumberContract({ ...numberContract, contractId: contract_id });
    setContractNumberModal(!contractNumberModal);
  };

  function titleCase(str) {
    if (!str) {
      return;
    }

    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  async function handlerOnClickNumberModal() {
    showLoader();

    const data = {
      contract: {
        contract_number: numberContract.reason,
      },
    };

    try {
      await api.put(
        `contracts/${numberContract.contractId}`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();

      toastSuccess('Contrato alterada com sucesso');
      dashboard();
      getCustomers();
    } catch (error) {
      hideLoader();
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na atualização da contrato, por favor tente novamente!';
      }

      toastError(message);
    }
    setContractNumberModal(!contractNumberModal);
  }

  async function handlerOnClickReportModal() {
    try {
      showLoader();
      await api.get(
        `/contracts/export_complete_contract_background?q[kind_eq]=0&email=${emailReport}&${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();
      toastSuccess(
        'Relatorio Solicitado com sucesso, aguarde o envio por email'
      );
    } catch (error) {
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro ao solicitar relatorio completo, por favor tente novamente!';
      }

      toastError(message);
    }
    setReportModal(!reportModal);
  }

  async function handlerOnClickPipeline() {
    let data = {
      pipeline: {
        status: parseInt(pipeline.status, 10),
        reason: pipeline.reason,
        contract_id: pipeline.contract_id,
      },
    };

    if (pipeline.status == '3') {
      if (!bankKind) {
        toastError('Selecione o banco');
        return;
      } else {
        data = { ...data, bank_kind: bankKind };
      }
    }

    if (pipeline.status == '5') {
      if (!pipeline.payment_date) {
        toastError('Selecione uma data de o pagamento');
        return;
      } else {
        data = { ...data, payment_date: pipeline.payment_date };
      }
    }

    if (pipeline.status == '8') {
      if (!pipeline.commission_payment_date) {
        toastError('Selecione uma data de o pagamento');
        return;
      } else {
        data = {
          ...data,
          commission_payment_date: pipeline.commission_payment_date,
        };
      }
    }

    showLoader();

    try {
      await api.post(
        selectedContracts.length > 0 ? `pipelines/create_all` : 'pipelines',
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();
      setSelectedContracts([]);
      toastSuccess('Pipeline alterada com sucesso');
      dashboard();
      getCustomers();
    } catch (error) {
      hideLoader();
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na criação da pipeline, por favor tente novamente!';
      }

      toastError(message);
    }
    setPipelineModal(!pipelineModal);
  }

  async function handlerOnClickDisputeStatus() {
    let data = {
      status: disputeStatusAction.status,
    };

    showLoader();

    try {
      await api.put(
        `contracts/${disputeStatusAction.contract_id}/change_dispute_status`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();
      setSelectedContracts([]);
      toastSuccess('Status da disputa alterada com sucesso');
      dashboard();
      getCustomers();
    } catch (error) {
      hideLoader();
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na alteração do status da disputa, por favor tente novamente!';
      }

      toastError(message);
    }
    setDisputeStatusModal(!disputeStatusModal);
  }

  async function handleOnClickBlackList(idcustomer, active) {
    showLoader();
    try {
      const { data } = await api.put(
        `customers/${idcustomer}`,
        { customer: { blacklist: !active } },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      getCustomers();

      hideLoader();

      toastSuccess(data.message);
    } catch (e) {
      hideLoader();
      let message = 'Algo deu errado ao incluir ou retirar da BlackList';
      if (e.response.data) {
        message = e.response.data.message;
      }
      toastError(message);
    }
  }

  async function exportExcel() {
    showLoader();
    try {
      const response = await api.get(`contracts/download?page${searchFilter}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `contratos_${moment().format('YYYY-MM-DD_HH:mm:ss')}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      hideLoader();
    } catch (e) {
      hideLoader();
      toastError('Algo deu errado ao exportar os dados');
    }
  }

  async function export_contract() {
    setCsvDisabled(true);
    try {
      const response = await api.get(
        `contracts/export_contract?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setExportList(response.data);
      csvLinkRef?.current?.link.click();
    } catch (e) {}
    setCsvDisabled(false);
  }

  async function export_complete_report() {
    setCompleteReportDisabled(true);
    requestReport(pageReport, 500);
    // setExportCompleteList(response.data)
  }

  const requestReport = async (pageParams, perPage) => {
    showLoader();
    try {
      const response = await api.get(
        `contracts/export_complete_contract?q[kind_eq]=0&${searchFilter}&page=${pageParams}&per_page=${perPage}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setTotalPageReport(response.headers.total_pages);
      /* setPageReport(pageParams + 1); */
      let array = [];
      if (dataReport.length > 0) {
        array = [...dataReport, ...response.data];
      } else {
        array = response.data;
      }
      arrayReport = [...arrayReport, ...response.data];
      setDataReport(arrayReport);

      if (pageParams + 1 > response.headers.total_pages) {
        setExportCompleteList(arrayReport);
        completeReportLinkRef?.current?.link.click();

        setCompleteReportDisabled(false);
      } else {
        requestReport(pageParams + 1, 500);
      }
      hideLoader();
    } catch (e) {}
  };

  async function analytical_report(id) {
    try {
      const response = await api.get(
        `contracts/${id}/analytical_report?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setAnalyticalReport(response.data);
      analyticalLinkRef?.current?.link.click();
    } catch (e) {}
  }

  async function synthetic_report() {
    setSyntheticReportDisabled(true);
    try {
      const response = await api.get(
        `contracts/synthetic_report?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setSyntheticReport(response.data);
      syntheticLinkRef?.current?.link.click();
    } catch (e) {}
    setSyntheticReportDisabled(false);
  }

  function handleClickModalDate() {
    setModalDate(!modalDate);
  }

  function handleClickDateSearch() {
    let filter = '';
    if (maxDate != '' || minDate != '') {
      filter += `&max_date=${maxDate}&min_date=${minDate}`;
      setSearchFilter(filter);
    }

    setModalDate(!modalDate);
  }

  const selectAllContracts = (checked) => {
    if (checked) {
      setSelectedContracts(customers.map((item) => item.id));
    } else {
      setSelectedContracts([]);
    }
  };

  const selectContract = (id, checked) => {
    if (checked) {
      setSelectedContracts([...selectedContracts, id]);
    } else {
      setSelectedContracts(selectedContracts.filter((item) => item != id));
    }
  };

  async function retrieveDataAsynchronouslyIdContract(searchText) {
    try {
      const { data } = await api.get(
        `contracts?sort=created_at%20desc&q[kind_eq]=1&page=1&per_page=20&q[contract_id_eq]=${filterIdContract}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setAutocompleteDataIdContract(data.contracts);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo Id do contrato');
    }
  }

  async function onChangeIdContract(e) {
    setFilterIdContract(e.target.value);
    if (e.target.value.length >= 5) {
      await retrieveDataAsynchronouslyIdContract(e.target.value);
    }
  }

  function onSelectIdContract(val) {
    setFilterIdContract(val);
  }

  async function handleOnClickIdContract() {
    showLoader();
    if (filterIdContract.length > 0) {
      try {
        const { data, headers } = await getAllCustomersIdContract();

        const totalPages = headers.total_pages;

        const arrayPages = [];
        for (let i = 1; i <= totalPages; i++) {
          arrayPages.push(i);
        }

        setCustomers(data.contracts);
        setGetAllCustomers(true);
        setPages(totalPages);
        setNumberRegister(headers.total_count);

        props.history.push({
          pathname: '/admin/contracts',
          search: `?cpf=${cpf}`,
        });
        hideLoader();
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CPF.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
        hideLoader();
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CPF.');
      hideLoader();
    }
  }

  return (
    <>
      <Header
        gradient="bg-gradient-cartao"
        pipeStatus={pipeStatus}
        disputeStatus={disputeStatus}
        requestFinish={requestFinish}
        maxFilterDate={maxFilterDate}
        minFilterDate={minFilterDate}
        setMaxFilterDate={setMaxFilterDate}
        setMinFilterDate={setMinFilterDate}
        setFilterDateType={setFilterDateType}
        filterDateType={filterDateType}
        handlerOnClickFilter={handlerOnClickFilter}
        handleClearFilterRegisterDate={handleClearFilterRegisterDate}
        handleClearFilterPaymentDate={handleClearFilterPaymentDate}
        handleClearFilterCommissionDate={handleClearFilterCommissionDate}
        minRegisterDate={minRegisterDate}
        maxRegisterDate={maxRegisterDate}
        minPaymentDate={minPaymentDate}
        maxPaymentDate={maxPaymentDate}
        minCommissionDate={minCommissionDate}
        maxCommissionDate={maxCommissionDate}
        setMinRegisterDate={setMinRegisterDate}
        setMaxRegisterDate={setMaxRegisterDate}
        setMinPaymentDate={setMinPaymentDate}
        setMaxPaymentDate={setMaxPaymentDate}
        setMinCommissionDate={setMinCommissionDate}
        setMaxCommissionDate={setMaxCommissionDate}
        totalCustomers={totalCustomers}
        totalCommissionValue={totalCommissionValue}
        totalContractValue={totalContractValue}
        totalPayments={totalPayments}
        operateSimulator={operateSimulator}
        documentPending={documentPending}
        sendLink={sendLink}
        waitingDocumentosAnalitcs={waitingDocumentosAnalitcs}
        waitingSignature={waitingSignature}
        contractSigned={contractSigned}
        contractPaid={contractPaid}
        commissionPaid={commissionPaid}
        handleOnClickCard={handleOnClickCard}
        handleOnClickChargebackCard={handleOnClickChargebackCard}
        handleClickModalDate={handleClickModalDate}
        canceled={canceled}
        chargebacked={chargebacked}
        paymentReturned={paymentReturned}
        totalValue={totalValue}
        totalValueCanceled={totalValueCanceled}
        totalValuePaid={totalValuePaid}
      />
      {/* Page content */}
      <Container className="mt--7 mb" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            {/* <div className="text-right">
              <Link
                className="btn btn-default mb-3 mt-2"
                to="/admin/contracts/create"
              >
                <i className="fas fa-plus" /> Criar contrato
              </Link>
            </div> */}

            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row>
                  <Col lg="5">
                    <h3 className="text-white">Contratos</h3>
                    <Search
                      getItemValue={getItemValue}
                      autoCompleteData={autocompleteDataIdContract}
                      renderItem={renderItemIdContract}
                      cpf_cnpj={filterIdContract}
                      onChange={onChangeIdContract}
                      onSelect={onSelectIdContract}
                      handleOnClick={handleOnClickIdContract}
                      placeHolder="Buscar pelo ID do contrato"
                    />
                  </Col>

                  <Col lg="7" className="mt-3 mt-lg-0">
                    {getAllCustomers && handleGetAllCustomers && (
                      <div className="d-flex align-items-end h-100">
                        <Button color="info" onClick={handleGetAllCustomers}>
                          Buscar todos os Contratos
                        </Button>
                      </div>
                    )}
                    {!getAllCustomers && (
                      <>
                        <div className="d-flex justify-content-end h-100 align-items-end">
                          {/* <CSVLink
                            data={analyticalReport}
                            filename={"RelatorioAnalitico.csv"}
                            separator={";"}
                            ref={analyticalLinkRef}
                          ></CSVLink> */}
                          {selectedContracts.length > 0 && (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                handleTogglePipeline({
                                  idcontract: selectedContracts,
                                })
                              }
                            >
                              Alterar Status
                            </button>
                          )}
                          <VisibilityByUserRoles
                            roles={[
                              'master',
                              'operational',
                              'commercial_manager',
                              'regional_manager',
                              'supervisor_commmercial_internal',
                              'commercial_manager_internal',
                              'superintendent_commercial',
                              'master_corban',
                              'master_subest',
                              'user_corban_financial',
                              'master_store',
                            ]}
                          >
                            <>
                              <button
                                disabled={completeReportDisabled}
                                className="btn btn-primary"
                                /* onClick={export_complete_report} */
                                onClick={() => handleToggleReportModal()}
                              >
                                Relatório Completo por email
                              </button>
                              {pipeStatus && (
                                <button
                                  disabled={completeReportDisabled}
                                  className="btn btn-primary"
                                  onClick={export_complete_report}
                                  // onClick={() => handleToggleReportModal()}
                                >
                                  Relatório Simples
                                </button>
                              )}
                            </>
                          </VisibilityByUserRoles>
                          <CSVLink
                            data={exportCompleteList}
                            filename={'complete_report.csv'}
                            separator={';'}
                            ref={completeReportLinkRef}
                          ></CSVLink>
                          {/* {
                            user && (user.kind != "commercial_manager" || user.kind != "supervisor_commmercial_internal") ?
                              <>
                                <button
                                  disabled={syntheticReportDisabled}
                                  className="btn btn-primary"
                                  onClick={synthetic_report}
                                >
                                  Relatório Sintético
                                </button>
                                <CSVLink
                                  data={syntheticReport}
                                  filename={"RelatorioSintetico.csv"}
                                  separator={";"}
                                  ref={syntheticLinkRef}
                                ></CSVLink>
                              </>
                            :
                              false
                          } */}
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              handleToggleFilter();
                              clearFilterModal();
                            }}
                          >
                            <i className="fas fa-filter" /> Filtrar
                          </button>
                          {searchFilter && (
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={handleClearAllFilter}
                            >
                              Limpar Filtros
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <Row className="ml-4">
                {numberRegister > 0 ? (
                  <div className="text-white">
                    Registros encontrados {LIMIT * (offset - 1) + 1} -{' '}
                    {numberRegister <= LIMIT ? numberRegister : LIMIT * offset}{' '}
                    de {numberRegister}
                  </div>
                ) : (
                  <div className="text-white">Nenhum registro encontrado</div>
                )}
              </Row>
              <Table
                className="align-items-center table-dark table-flush table-responsive-custom"
                id="table-responsive-custom"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <VisibilityByUserRoles
                      roles={['master', 'operational', 'regional_manager']}
                    >
                      <th
                        scope="col"
                        className="align-items-center d-flex justify-content-center flex-column"
                      >
                        <div className="mb-1">Selecionar Tudo</div>
                        <div className="mb-1">
                          <Input
                            type="checkbox"
                            className="form-check-custom"
                            name="select_checkbox"
                            defaultChecked={false}
                            onChange={(e) =>
                              selectAllContracts(e.target.checked)
                            }
                          />{' '}
                        </div>
                      </th>
                    </VisibilityByUserRoles>
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'regional_manager',
                        'commercial_manager',
                        'master_store',
                        'user_store',
                        'user_store_financial',
                        'user_store_register',
                        'master_seller',
                        'user_seller',
                        'user_seller_financial',
                        'user_seller_register',
                      ]}
                    >
                      <th scope="col">ID</th>
                    </VisibilityByUserRoles>
                    <th scope="col" className="text-right">
                      Ações
                    </th>
                    <th scope="col">Contrato</th>
                    {/*  <VisibilityByUserRoles roles={["master", "operational"]}>
                      <th scope="col">Banco</th>
                    </VisibilityByUserRoles> */}
                    {/* <VisibilityByUserRoles roles={["master", "operational"]}>
                      <th scope="col">Gateway</th>
                    </VisibilityByUserRoles> */}
                    <th scope="col">Cliente</th>
                    <th scope="col">R$ Bruto</th>
                    <th scope="col">R$ Liquido</th>
                    <th scope="col">R$ Cobrado</th>
                    <VisibilityByUserRoles
                      roles={[
                        'master_corban',
                        'user_corban',
                        'user_corban_financial',
                        'user_corban_register',
                        'master_subest',
                        'user_subest',
                        'user_subest_financial',
                        'user_subest_register',
                        'master_store',
                        'user_store_financial',
                        'user_store_register',
                        'user_seller',
                        'user_seller',
                        'user_seller_financial',
                        'user_seller_register',
                      ]}
                    >
                      <th scope="col">R$ Comissão</th>
                    </VisibilityByUserRoles>
                    <th scope="col">Status</th>
                    <th scope="col">Tx. Juros</th>
                    <th scope="col">Parcelas</th>
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'commercial_manager',
                        'regional_manager',
                      ]}
                    >
                      <th scope="col">Corban</th>
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <th scope="col">Comissão Corban</th>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                          'user_corban',
                          'user_corban_financial',
                          'user_corban_register',
                        ]}
                      >
                        <th scope="col">Sub</th>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <th scope="col">Comissão Sub</th>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                          'user_corban',
                          'user_corban_financial',
                          'user_corban_register',
                          'user_subest',
                          'user_subest_financial',
                          'user_subest_register',
                        ]}
                      >
                        <th scope="col">Loja</th>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <th scope="col">Comissão Loja</th>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                          'supervisor_commmercial_internal',
                          'commercial_manager_internal',
                          'superintendent_commercial',
                        ]}
                      ></VisibilityByUserRoles>
                      <th scope="col">
                        {user &&
                        (user.kind == 'commercial_manager' ||
                          user.kind == 'supervisor_commmercial_internal')
                          ? 'Vendedor'
                          : 'Gerente Comercial'}
                      </th>
                    </VisibilityByUserRoles>
                    {user && user.kind == 'corban' ? (
                      <>
                        <th scope="col">Sub</th>
                        <th scope="col">Loja</th>
                      </>
                    ) : (
                      false
                    )}
                    {user && user.kind == 'subestablishment' ? (
                      <>
                        <th scope="col">Loja</th>
                      </>
                    ) : (
                      false
                    )}
                    {user &&
                    (user.kind == 'store' ||
                      user.kind == 'corban' ||
                      user.kind == 'subestablishment') ? (
                      <>
                        <th scope="col">Vendedor</th>
                      </>
                    ) : (
                      false
                    )}
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'commercial_manager',
                        'regional_manager',
                        'supervisor_commmercial_internal',
                        'commercial_manager_internal',
                        'superintendent_commercial',
                        'user_corban',
                        'user_corban_financial',
                        'user_corban_register',
                        'master_corban',
                      ]}
                    >
                      <th
                        scope="col"
                        onClick={() => sortCommissionPaymentUpdatedAt()}
                      >
                        <span>Pagamento do contrato</span>
                        {sortCommissionPaymentDate &&
                        sortCommissionPaymentDate == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col" onClick={() => sortPaymentUpdatedAt()}>
                        <span>Pagamento da comissão</span>
                        {sortPaymentDate && sortPaymentDate == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                    </VisibilityByUserRoles>
                    <th scope="col" onClick={() => sortUpdatedAt()}>
                      <span>Ult. Atualização</span>
                      {sort && sort == 'desc' ? (
                        <i className="ml-2 fas fa-arrow-down" />
                      ) : (
                        <i className="ml-2 fas fa-arrow-up" />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer) => (
                    <tr
                      key={customer.id}
                      className={customer.customer.blacklist ? 'blacklist' : ''}
                    >
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <td className="align-items-center d-flex justify-content-center">
                          <div className="mt-3">
                            <Input
                              type="checkbox"
                              name="select_checkbox"
                              defaultChecked={false}
                              checked={selectedContracts.includes(customer.id)}
                              className="form-check-custom"
                              onChange={(e) =>
                                selectContract(customer.id, e.target.checked)
                              }
                            />{' '}
                          </div>
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'regional_manager',
                          'commercial_manager',
                          'master_store',
                          'user_store',
                          'user_store_financial',
                          'user_store_register',
                          'master_seller',
                          'user_seller',
                          'user_seller_financial',
                          'user_seller_register',
                        ]}
                      >
                        <th scope="row" className="word-wrap">
                          <Media className="align-items-center w-100">
                            <Media className="w-100">
                              <span className="mb-0 text-sm w-100">
                                {customer.id ? customer.id : '-'}
                              </span>
                            </Media>
                          </Media>
                        </th>
                      </VisibilityByUserRoles>
                      <td className="text-right">
                        {selectedContracts && selectedContracts.length <= 0 && (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <Link
                                to={`/admin/contracts/${customer.id}/detail`}
                                className="dropdown-item"
                              >
                                <span>
                                  <i className="fas fa-info-circle" /> Detalhes
                                  Contrato
                                </span>
                              </Link>
                              {customer.signed_file_url && (
                                <VisibilityByUserRoles
                                  roles={['master', 'operational']}
                                >
                                  <DropdownItem
                                    onClick={() => getDocumentSign(customer.id)}
                                  >
                                    <span>
                                      <i className="fas fa-paper-plane" />{' '}
                                      Detalhes da CCB
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                              )}
                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'operational',
                                  'regional_manager',
                                ]}
                              >
                                <Link
                                  to={`/admin/contracts/${customer.id}/logs`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-file" /> Log de
                                    Alterações
                                  </span>
                                </Link>
                              </VisibilityByUserRoles>
                              <DropdownItem
                                onClick={() =>
                                  handleResendLink(customer.notifications[0].id)
                                }
                                disabled={
                                  customer.status_code === 1 ||
                                  customer.status_code === 3 ||
                                  customer.status_code === 7 ||
                                  customer.active !== 'created'
                                }
                              >
                                <span>
                                  <i className="fas fa-paper-plane" /> Reenviar
                                  Link de Pagamento
                                </span>
                              </DropdownItem>
                              {user &&
                                (user.roles[0].name == 'master' ||
                                  user.roles[0].name == 'operational') && (
                                  <DropdownItem
                                    onClick={() =>
                                      reactivatePaymentLink(
                                        customer.notifications[0].id
                                      )
                                    }
                                    disabled={
                                      customer.status_code === 1 ||
                                      customer.status_code === 3 ||
                                      customer.active !== 'created'
                                    }
                                  >
                                    <span>
                                      <i className="fas fa-paper-plane" />{' '}
                                      Reativar Link de Pagamento
                                    </span>
                                  </DropdownItem>
                                )}
                              {/* {
                                  (user && (user.roles[0].name == 'master' || user.roles[0].name == 'operational'))
                                  ? (
                                    <>
                                      <Link
                                        to={`/admin/contracts/${customer.id}`}
                                        className="dropdown-item"
                                      >
                                        <span>
                                          <i className="fas fa-edit" /> Editar
                                          Contrato
                                        </span>
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <DropdownItem disabled>
                                        <span>
                                          <i className="fas fa-edit" /> Editar
                                          Contrato
                                        </span>
                                      </DropdownItem>
                                    </>
                                  )
                                } */}
                              {((customer.active == 'created' &&
                                customer.status_original ==
                                  'document_pending') ||
                                (user &&
                                  (user.roles[0].name == 'master' ||
                                    user.roles[0].name == 'operational' ||
                                    user.roles[0].name == 'commercial_manager' ||
                                    user.roles[0].name == 'regional_manager' ||
                                    user.roles[0].name == 'commercial_manager_internal' ||
                                    user.roles[0].name == 'superintendent_commercial'))) && (
                                <>
                                  <Link
                                    to={`/admin/contracts/${customer.id}/upload`}
                                    className="dropdown-item"
                                  >
                                    <span>
                                      <i className="fas fa-file " /> Upload de
                                      Documentos
                                    </span>
                                  </Link>
                                </>
                              )}
                              {user &&
                              (user.roles[0].name == 'master' ||
                                user.roles[0].name == 'operational') ? (
                                <DropdownItem
                                  onClick={() =>
                                    handleOnClickBlackList(
                                      customer.customer_id,
                                      customer.customer.blacklist
                                    )
                                  }
                                >
                                  <span>
                                    <i className="fas fa-user-lock" />{' '}
                                    {customer.customer.blacklist
                                      ? 'Retirar'
                                      : 'Incluir'}{' '}
                                    BlackList
                                  </span>
                                </DropdownItem>
                              ) : (
                                false
                              )}
                              {/* {
                                  user && (user.kind != "commercial_manager" || user.kind != "supervisor_commmercial_internal") ?
                                    <DropdownItem
                                      onClick={()=> analytical_report(customer.customer_id)}
                                      >
                                        <span>
                                          <i className="fas fa-file" /> Relatório Analítico
                                        </span>
                                    </DropdownItem>
                                  :
                                    false
                                } */}
                              {/* {(customer.status_code === 3 || customer.status_code === 1) && (
                                  <>
                                    <DropdownItem
                                      onClick={() =>
                                        cancelTransation(customer.payment_id)
                                      }
                                    >
                                      <span>
                                        <i className="fas fa-times" /> Cancelar
                                        Pagamento
                                      </span>
                                    </DropdownItem>
                                  </>
                                )} */}
                              {user &&
                                user.kind == 'master' &&
                                customer.active === 'wait_refound' && (
                                  <>
                                    <DropdownItem
                                      onClick={() => checkRefund(customer.id)}
                                    >
                                      <span>
                                        <i className="fas fa-check" /> Marcar
                                        como Reembolsado
                                      </span>
                                    </DropdownItem>
                                  </>
                                )}
                              {/* <VisibilityByUserRoles roles={["master", "operational", "regional_manager"]}> */}
                              {(customer.status_code === 1 ||
                                customer.status_code === 3 ||
                                customer.status_code === 7) && (
                                <DropdownItem
                                  onClick={() =>
                                    handleClickToggle({
                                      idcontract: customer.id,
                                      contractNumber: customer.contract_number,
                                      name: customer.name,
                                    })
                                  }
                                >
                                  <span>
                                    <i className="fas fa-file-invoice-dollar" />{' '}
                                    Informações do Pagamento
                                  </span>
                                </DropdownItem>
                              )}
                              {/* </VisibilityByUserRoles> */}
                              {/* <VisibilityByUserRoles roles={["master", "operational", "regional_manager", "commercial_manager"]}> */}
                              {!customer?.status_code &&
                                customer?.active === 'created' &&
                                customer?.notifications?.length > 0 && (
                                  <CopyToClipboard
                                    text={
                                      customer?.notifications[0] &&
                                      customer?.notifications[0].payment_link
                                    }
                                    onCopy={() => copyToClipboard()}
                                  >
                                    <DropdownItem>
                                      <span>
                                        <i className="fas fa-copy" /> Copiar
                                        link
                                      </span>
                                    </DropdownItem>
                                  </CopyToClipboard>
                                )}
                              {/* </VisibilityByUserRoles> */}
                              {/* {!customer.status_code && customer.active === 'created' && (
                                  <>
                                    <DropdownItem
                                      onClick={() =>
                                        cancelContract(customer.id)
                                      }
                                    >
                                      <span>
                                        <i className="fas fa-times" /> Cancelar
                                        Contrato
                                      </span>
                                    </DropdownItem>
                                  </>
                                )} */}
                              {/* {
                                  user && (user.roles[0].name == 'master' || user.roles[0].name == 'operational') &&
                                    <>
                                      <DropdownItem
                                        onClick={() => handleToggleNumberModal(customer.id)}
                                      >
                                        <span>
                                          <i className="fas fa-file" /> Alterar Número do Contrato
                                        </span>
                                      </DropdownItem>
                                    </>
                                } */}

                              {customer.status_original ==
                                'waiting_signature' &&
                                customer.request_signature_key && (
                                  <CopyToClipboard
                                    text={customer.request_signature_key}
                                    onCopy={() => copyToClipboard()}
                                  >
                                    <DropdownItem>
                                      <span>
                                        <i className="fas fa-copy" /> Copiar
                                        link assinatura
                                      </span>
                                    </DropdownItem>
                                  </CopyToClipboard>
                                )}
                              {user &&
                                (user.roles[0].name == 'master' ||
                                  user.roles[0].name == 'operational' ||
                                  user.roles[0].name == 'commercial_manager' ||
                                  user.roles[0].name == 'regional_manager' ||
                                  user.roles[0].name ==
                                    'supervisor_commmercial_internal' ||
                                  user.roles[0].name ==
                                    'commercial_manager_internal') &&
                                customer.bank_kind == 'money_plus' &&
                                customer.status_original ==
                                  'payment_returned' && (
                                  <>
                                    <DropdownItem
                                      onClick={() =>
                                        handleModalBankAccountOpen(
                                          customer.id,
                                          customer.customer_id
                                        )
                                      }
                                    >
                                      <span>
                                        <i className="fas fa-file" /> Alterar
                                        Dados de Pagamento
                                      </span>
                                    </DropdownItem>
                                  </>
                                )}
                              {customer.status_original != 'canceled' && (
                                <VisibilityByUserRoles
                                  roles={['master', 'operational']}
                                >
                                  <Link
                                    target={'_blank'}
                                    to={`/admin/contracts/${customer.id}/transfers`}
                                    className="dropdown-item"
                                  >
                                    <span>
                                      <i className="fas fa-edit" />{' '}
                                      Transferências
                                    </span>
                                  </Link>
                                </VisibilityByUserRoles>
                              )}
                              {customer.status_original != 'canceled' && (
                                <VisibilityByUserRoles
                                  roles={[
                                    'master',
                                    'operational',
                                    'regional_manager',
                                  ]}
                                >
                                  <DropdownItem
                                    onClick={() =>
                                      handleTogglePipeline({
                                        idcontract: customer.id,
                                      })
                                    }
                                  >
                                    <span>
                                      <i className="fas fa-file" /> Alterar
                                      status
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                              )}
                              {customer.dispute_status_original != '2' &&
                                (customer.status_code === 1 ||
                                  customer.status_code === 3) && (
                                  <VisibilityByUserRoles
                                    roles={['master', 'operational']}
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        handleToggleDisputeStatus({
                                          idcontract: customer.id,
                                        })
                                      }
                                    >
                                      <span>
                                        <i className="fas fa-file" /> Chargeback
                                      </span>
                                    </DropdownItem>
                                  </VisibilityByUserRoles>
                                )}
                              <DropdownItem
                                onClick={() =>
                                  handleToggleIndexPipeline({
                                    idcontract: customer.id,
                                    contractNumber: customer.contract_number,
                                  })
                                }
                              >
                                <span>
                                  <i className="fas fa-file" /> Movimentação de
                                  status
                                </span>
                              </DropdownItem>
                              {customer.resend_ccb && (
                                <DropdownItem
                                  onClick={() => handleResendCCB(customer.id)}
                                >
                                  <span>
                                    <i className="fas fa-file" /> Reenviar CCB
                                  </span>
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </td>
                      <td>
                        {customer.contract_number
                          ? customer.contract_number
                          : '-'}
                      </td>
                      {/*  <VisibilityByUserRoles roles={["master", "operational"]}>
                        <td>{customer.bank_kind_i18n ? customer.bank_kind_i18n : "-"}</td>
                      </VisibilityByUserRoles> */}
                      {/* <VisibilityByUserRoles roles={["master", "operational"]}>
                        <th scope="col">{customer.gateway}</th>
                      </VisibilityByUserRoles> */}
                      <td>{titleCase(customer.name)}</td>
                      <td>
                        {Number(customer.contract_value).toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          }
                        )}
                      </td>

                      <td>
                        {Number(customer.liquid_value).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(customer.amount_charged).toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          }
                        )}
                      </td>
                      <VisibilityByUserRoles
                        roles={[
                          'master_corban',
                          'user_corban',
                          'user_corban_financial',
                          'user_corban_register',
                        ]}
                      >
                        <td>
                          {customer.corban_commission
                            ? customer.corban_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master_subest',
                          'user_subest',
                          'user_subest_financial',
                          'user_subest_register',
                        ]}
                      >
                        <td>
                          {customer.sub_commission
                            ? customer.sub_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master_store',
                          'user_store',
                          'user_store_financial',
                          'user_store_register',
                        ]}
                      >
                        <td>
                          {customer.store_commission
                            ? customer.store_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master_seller',
                          'user_seller',
                          'user_seller_financial',
                          'user_seller_register',
                        ]}
                      >
                        <td>
                          {customer.seller_commission
                            ? customer.seller_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      {/* <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                        ]}
                      > */}
                      <td>
                        <div>
                          {customer.status_original != 'send_link' ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: customer.status_pipeline
                                  ? customer.status_pipeline.replace(
                                      ' ',
                                      '<br />'
                                    )
                                  : '',
                              }}
                              className={`${HandleBgPipelinesColor(
                                customer.status_bg_pipeline
                              )} text-white p-1 rounded text-center`}
                            ></div>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: customer.status_pipeline,
                              }}
                              className={`${HandleBgPipelinesColor(
                                customer.status_bg_pipeline
                              )} text-white p-1 rounded text-center`}
                            ></div>
                          )}
                        </div>
                      </td>
                      {/* </VisibilityByUserRoles> */}
                      <td>{customer.basic_interest}</td>
                      <td>{Number(customer.installments)}</td>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                        ]}
                      >
                        <td>{customer.corban_name}</td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <td>
                          {customer.corban_commission
                            ? customer.corban_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                          'user_corban',
                          'user_corban_financial',
                          'user_corban_register',
                        ]}
                      >
                        <td>{customer.sub_name}</td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <td>
                          {customer.sub_commission
                            ? customer.sub_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                          'user_corban',
                          'user_corban_financial',
                          'user_corban_register',
                          'user_subest',
                          'user_subest_financial',
                          'user_subest_register',
                        ]}
                      >
                        <td>
                          <Link
                            to={`/admin/stores/${customer.store_id}/detail`}
                            style={{ color: 'white' }}
                          >
                            {customer.store_name}
                          </Link>
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles roles={['master', 'operational']}>
                        <td>
                          {customer.store_commission
                            ? customer.store_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                        ]}
                      >
                        {user &&
                        (user.kind == 'commercial_manager' ||
                          user.kind == 'supervisor_commmercial_internal') ? (
                          <td>{customer.seller_name}</td>
                        ) : (
                          <td>{customer.user_manager_name}</td>
                        )}
                      </VisibilityByUserRoles>
                      {user && user.kind == 'corban' ? (
                        <>
                          <td>{customer.sub_name}</td>
                          <td>{customer.store_name}</td>
                          <td>{customer.seller_name}</td>
                        </>
                      ) : (
                        false
                      )}
                      {user && user.kind == 'subestablishment' ? (
                        <>
                          <td>{customer.store_name}</td>
                          <td>{customer.seller_name}</td>
                        </>
                      ) : (
                        false
                      )}
                      {user && user.kind == 'store' ? (
                        <>
                          <td>{customer.seller_name}</td>
                        </>
                      ) : (
                        false
                      )}
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                          'user_corban',
                          'user_corban_financial',
                          'user_corban_register',
                          'master_corban',
                        ]}
                      >
                        <td>
                          {customer.payment_date && (
                            <Badge color="" className="badge-dot mr-4">
                              <i className="bg-yellow" />
                              {moment(customer.payment_date).format(
                                'DD/MM/YYYY'
                              )}
                            </Badge>
                          )}
                        </td>
                        <td>
                          {customer.commission_payment_date && (
                            <Badge color="" className="badge-dot mr-4">
                              <i className="bg-yellow" />
                              {moment(customer.commission_payment_date).format(
                                'DD/MM/YYYY'
                              )}
                            </Badge>
                          )}
                        </td>
                      </VisibilityByUserRoles>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-yellow" />
                          {moment(customer.updated_at).format(
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={Number(pages)}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={handleToggle}>
          Informações do Pagamento
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <p className="mb-0">
                  <strong>Nº do Contrato</strong>:{' '}
                  {paymentInformation.contractNumber}
                </p>
                <p>
                  <strong>Cliente</strong>: {paymentInformation.name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="bg-default shadow">
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" className="id">
                          ID
                        </th>
                        <th scope="col" className="amount">
                          Valor (R$)
                        </th>
                        <th scope="col" className="installments">
                          Parcelas
                        </th>
                        <th scope="col" className="installments">
                          Autorização
                        </th>
                        <th scope="col" className="created_at">
                          Criado
                        </th>
                        <th scope="col" className="created_at">
                          Número do Cartão
                        </th>
                        <th scope="col" className="status">
                          NSU
                        </th>
                        <th scope="col" className="acquirer">
                          Adquirente
                        </th>
                        <th scope="col" className="status">
                          Status
                        </th>
                        <th scope="col" className="message">
                          Mensagem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentInformation.payments.map((payment) => (
                        <tr key={payment.id}>
                          <td className="id">{payment.id}</td>
                          <td className="amount">
                            {Number(payment.amount_paid).toLocaleString(
                              'pt-br',
                              {
                                style: 'currency',
                                currency: 'BRL',
                              }
                            )}
                          </td>
                          <td className="installments">
                            {payment.installments}
                          </td>
                          <td className="installments">
                            {payment.merchant_transaction_id}
                          </td>
                          <td className="created_at">{payment.created_at}</td>
                          <td className="status">
                            {payment.first_four &&
                              `${payment.first_four}********${payment.last_four}`}
                          </td>
                          <td className="status">{payment.nsu}</td>
                          <td className="status">{payment.acquirer_name}</td>
                          <td className="status">{payment.return_code}</td>
                          <td className="status">
                            {payment.definition
                              ? payment.definition.replace(
                                  ' Suspeita de fraude.',
                                  ''
                                )
                              : ''}
                          </td>
                          {/* <td className="message">{payment.status_code == 7 ? "Transação Recusada" : "Transação Aceita"}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="filterCpf">
                    Buscar por CPF
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="filterCpf"
                    name="filterCpf"
                    placeholder="Digite o Nº do CPF"
                    value={cpf}
                    onChange={onChange}
                  />
                  <ul className="corban-list">
                    {autocompleteData &&
                      autocompleteData.map((item) => (
                        <li key={item.id} className="item-list">
                          {renderItem(item, false)}
                        </li>
                      ))}
                  </ul>
                </FormGroup>
                {/* <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban_financial',
                    'user_corban',
                    'master_subest',
                    'user_subest',
                    'user_subest_financial',
                    'master_store',
                    'user_store_financial',
                    'user_seller',
                    'user_store',
                  ]}
                >
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleCustomSelect"
                    >
                      Status
                    </Label>
                    <CustomInput
                      type="select"
                      id="exampleCustomSelect"
                      name="customSelect"
                      onChange={(e) => setPipeFilterStatus(e.target.value)}
                      value={pipeFilterStatus}
                    >
                      <option value="">Todos</option>
                      <option value="0">Operações no Simulador</option>
                      <option value="7">Link Enviado</option>
                      <option value="1">Pendente de Documentos</option>
                      <option value="2">Aguardando Análise Documental</option>
                      <option value="3">Aguardando Assinatura</option>
                      <option value="4">Contratos Assinados</option>
                      <option value="5">Contratos Pagos</option>
                      <option value="8">Comissão Paga</option>
                      <option value="9">Pagamento Devolvido</option>
                      <option value="6">Cancelado</option>
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles> */}
                {/* <VisibilityByUserRoles roles={['master', 'operational']}>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="blacklist">
                      Blacklist
                    </Label>
                    <CustomInput
                      type="select"
                      id="blacklist"
                      name="blacklist"
                      onChange={(e) => setFilterBlack(e.target.value)}
                      value={filterBlack}
                    >
                      <option value="">Todos</option>
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles> */}
                <VisibilityByUserRoles roles={['master', 'operational']}>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="dispute">
                      Status da Disputa
                    </Label>
                    <CustomInput
                      type="select"
                      id="dispute"
                      name="dispute"
                      onChange={(e) => setFilterDispute(e.target.value)}
                      value={filterDispute}
                    >
                      <option value="">Todos</option>
                      <option value="no_dispute">Sem disputa aberta</option>
                      <option value="0">Aberta</option>
                      <option value="1">Ganha pelo estabelecimento</option>
                      <option value="2">Chargebacked</option>
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                {/* <VisibilityByUserRoles roles={["commercial_manager", "commercial_manager_internal", "master_store"]}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="idpartner">
                      Promotor
                    </label>
                    <CustomInput
                      type="select"
                      id="idpartner"
                      name="idpartner"
                      value={filterSeller}
                      onChange={(e) => setFilterSeller(e.target.value)}
                    >
                      <option value="">{representatives.length > 0 ? "Selecione uma opção" : "Carregando dados"}</option>
                      {representatives.map((representative) => (
                        <option
                          key={representative.id}
                          value={representative.id}
                        >
                          {representative.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles> */}
                <FormGroup>
                  <label className="form-control-label" htmlFor="id">
                    ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="id"
                    name="id"
                    placeholder="Digite o ID do Contrato"
                    value={filterId}
                    onChange={(e) => setFilterId(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="contract">
                    Nº de Contrato
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contract"
                    name="contract"
                    placeholder="Digite o Nº do Contrato"
                    value={filterContract}
                    onChange={(e) => setFilterContract(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban_financial',
                    'user_corban',
                    'master_subest',
                    'user_subest',
                    'user_subest_financial',
                    'master_store',
                    'user_store_financial',
                    'user_seller',
                    'user_store',
                  ]}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Digite o Email"
                      value={filterEmail}
                      onChange={(e) => setFilterEmail(e.target.value)}
                    />
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles roles={['master', 'operational']}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="regional_manager"
                    >
                      Gerente Regional
                    </label>
                    <CustomInput
                      type="select"
                      id="regional_manager"
                      name="regional_manager"
                      value={filterRegionalManager}
                      onChange={(e) => setFilterRegionalManager(e.target.value)}
                    >
                      <option value="">
                        {regionalManagers.length > 0
                          ? 'Selecione uma opção'
                          : 'Carregando dados'}
                      </option>
                      {regionalManagers.map((manager) => (
                        <option key={manager.id} value={manager.id}>
                          {manager.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles
                  roles={['master', 'operational', 'regional_manager']}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="idpartner">
                      Gerente Comercial
                    </label>
                    <CustomInput
                      type="select"
                      id="idpartner"
                      name="idpartner"
                      value={filterManager}
                      onChange={(e) => setFilterManager(e.target.value)}
                    >
                      <option value="">
                        {managers.length > 0
                          ? 'Selecione uma opção'
                          : 'Carregando dados'}
                      </option>
                      {managers.map((manager) => (
                        <option key={manager.id} value={manager.id}>
                          {manager.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                {user &&
                (user.roles[0].name == 'master' ||
                  user.roles[0].name == 'operational') ? (
                  <>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="corban">
                        Corban
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="corban"
                        name="corban"
                        placeholder="Pesquise a Corban"
                        onChange={(e) => handleOnChangeCorban(e)}
                        value={corbanName}
                      />
                      <ul className="corban-list">
                        {corbans &&
                          corbans.map((item) => {
                            return (
                              <li
                                className="item-list"
                                onClick={() =>
                                  handleClickItemListFilter(item.value)
                                }
                              >
                                {item.value}
                              </li>
                            );
                          })}
                      </ul>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleCustomSelect"
                      >
                        Tipo de Corban
                      </Label>
                      <CustomInput
                        type="select"
                        id="exampleCustomSelect"
                        name="customSelect"
                        onChange={(e) => setFilterCorbanType(e.target.value)}
                        value={filterCorbanType}
                      >
                        <option value="">Todos</option>
                        <option value="0">Interno</option>
                        <option value="1">Externo</option>
                      </CustomInput>
                    </FormGroup>
                  </>
                ) : (
                  false
                )}
                <VisibilityByUserRoles
                  roles={['master', 'operational', 'regional_manager']}
                >
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleCustomSelectFilterAuto"
                    >
                      Auto Contratação?
                    </Label>
                    <CustomInput
                      type="select"
                      id="exampleCustomSelectFilterAuto"
                      name="filterAuto"
                      onChange={(e) => setFilterAuto(e.target.value)}
                      value={filterAuto}
                    >
                      <option value="">Todos</option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban',
                    'user_corban_financial',
                  ]}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="sub">
                      Subestabelecimento
                    </label>
                    <CustomInput
                      type="select"
                      id="sub"
                      name="sub"
                      placeholder="Escolha o Subestabelecimento"
                      onChange={(e) => setFilterSub(e.target.value)}
                      value={filterSub}
                    >
                      <option value="">
                        {subs.length > 0
                          ? 'Selecione uma opção'
                          : 'Carregando dados'}
                      </option>
                      {subs.map((sub) => (
                        <option key={sub.id} value={sub.id}>
                          {sub.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban_financial',
                    'user_corban',
                    'master_subest',
                    'user_subest',
                    'user_subest_financial',
                    'master_store',
                    'user_store_financial',
                    'user_seller',
                    'user_store',
                    'commercial_manager',
                    'commercial_manager_internal',
                  ]}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="store">
                      Loja
                    </label>
                    <AsyncPaginate
                      value={store ? store.id : null}
                      loadOptions={loadStoreOptions}
                      onChange={(item) => {
                        setStore(item);
                        setFilterStore(item.value);
                        getSellers(item.value);
                      }}
                      id="store"
                      name="store"
                      placeholder="Selecione uma Loja"
                      additional={{ page: 1 }}
                      cacheUniqs={[corbanName]}
                      shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => {
                        return scrollHeight - scrollTop < 1000;
                      }}
                    />
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban_financial',
                    'user_corban',
                    'master_subest',
                    'user_subest',
                    'user_subest_financial',
                    'master_store',
                    'user_store_financial',
                    'user_seller',
                    'user_store',
                    'commercial_manager',
                    'commercial_manager_internal',
                    'master_store',
                  ]}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="store">
                      Vendedor
                    </label>
                    <CustomInput
                      type="select"
                      id="seller"
                      name="seller"
                      placeholder="Escolha a Loja"
                      onChange={(e) => setFilterSeller(e.target.value)}
                      value={filterSeller}
                    >
                      <option value="">
                        {sellers.length > 0
                          ? 'Selecione uma opção'
                          : 'Carregando dados'}
                      </option>
                      {sellers.map((seller) => (
                        <option key={seller.id} value={seller.id}>
                          {seller.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button
            color="success"
            onClick={() => handlerOnClickFilter(true, false)}
          >
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={pipelineModal}
        toggle={handleTogglePipeline}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleTogglePipeline}>
          Alteração da pipeline
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="status">
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="status"
                    name="status"
                    onChange={handleOnPipeline}
                    value={pipeline && pipeline.status}
                  >
                    <option value="">Selecionar</option>
                    <option value="0">Operações no Simulador</option>
                    <option value="7">Link Enviado</option>
                    <option value="1">Pendente de Documentos</option>
                    <option value="2">Aguardando Análise Documental</option>
                    {selectedContracts.length <= 0 && (
                      <option value="3">Aguardando Assinatura</option>
                    )}
                    <option value="4">Contratos Assinados</option>
                    <option value="5">Contratos Pagos</option>
                    <option value="8">Comissões Pagas</option>
                    <option value="9">Pagamento Devolvido</option>
                    {/* {
                      selectedContracts.length <= 0 && ( */}
                    <option value="6">Cancelado</option>
                    {/* )
                    } */}
                  </CustomInput>
                </FormGroup>
                {
                  pipeline && (pipeline.status == '9' || pipeline.status == '6') ? (
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="bank_kind">
                        Razão
                      </Label>
                      <CustomInput
                        type="select"
                        id="bank_kind"
                        name="bank_kind"
                        onChange={(e) => setPipeline({ ...pipeline, reason: e.target.value })}
                        value={bankKind}
                      >
                        <option value="">Selecionar</option>
                        {
                          pipeline.status == '9' ? (
                            <>
                              <option value="CPF informado para pagamento diferente da CCB. Favor enviar RG ou CNH do cliente.">CPF informado para pagamento diferente da CCB. Favor enviar RG ou CNH do cliente.</option>
                              <option value="Dados incorretos, favor informar conta bancária válida em nome da(o) cliente.">Dados incorretos, favor informar conta bancária válida em nome da(o) cliente.</option>
                            </>
                          ) : (
                            <>
                              <option value="Cartão utilizado pertence a outra pessoa.">Cartão utilizado pertence a outra pessoa.</option>
                              <option value="Cliente devolveu o valor recebido em menos de 7 dias.">Cliente devolveu o valor recebido em menos de 7 dias.</option>
                              <option value="Cliente fará nova operação, favor corrigir e refazer proposta.">Cliente fará nova operação, favor corrigir e refazer proposta.</option>
                              <option value="Dados incorretos, favor corrigir e refazer proposta.">Dados incorretos, favor corrigir e refazer proposta.</option>
                              <option value="Desistência do cliente.">Desistência do cliente.</option>
                              <option value="Falha de sistema, favor repetir operação.">Falha de sistema, favor repetir operação.</option>
                              <option value="Pagamento recusado, verifique com nosso suporte comercial.">Pagamento recusado, verifique com nosso suporte comercial.</option>
                              <option value="Prazo expirado para finalização.">Prazo expirado para finalização.</option>
                              <option value="Reprovado após análise documental.">Reprovado após análise documental.</option>
                              <option value="Solicitado pelo setor comercial.">Solicitado pelo setor comercial.</option>
                              <option value="Teste/treinamento.">Teste/treinamento.</option>
                              <option value="OUTRO (verifique com nosso suporte comercial)">OUTRO (verifique com nosso suporte comercial)</option>
                            </>
                          )
                        }
                      </CustomInput>
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <label className="form-control-label" htmlFor="reason">
                        Razão
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="reason"
                        name="reason"
                        placeholder="Digite a razão"
                        onChange={handleOnPipeline}
                      />
                    </FormGroup>
                  )
                }
                {pipeline.status == '3' && (
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="bank_kind">
                      Selecionar Banco
                    </Label>
                    <CustomInput
                      type="select"
                      id="bank_kind"
                      name="bank_kind"
                      onChange={(e) => setBankKind(e.target.value)}
                      value={bankKind}
                    >
                      <option value="">Selecionar</option>
                      <option value="money_plus">Money Plus</option>
                      {/* <option value="fiducia">Fidúcia</option> */}
                      <option value="giro">Giro</option>
                    </CustomInput>
                  </FormGroup>
                )}
                {pipeline.status == '8' && (
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="commission_payment_date"
                    >
                      Data de pagamento da comissão
                    </label>
                    <input
                      type="date"
                      name="commission_payment_date"
                      className="form-control form-control-alternative"
                      id="commission_payment_date"
                      value={pipeline && pipeline.commission_payment_date}
                      onChange={handleOnPipeline}
                    />
                  </FormGroup>
                )}
                {pipeline.status == '5' && (
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="payment_date"
                    >
                      Data de pagamento do contrato
                    </label>
                    <input
                      type="date"
                      name="payment_date"
                      className="form-control form-control-alternative"
                      id="payment_date"
                      value={pipeline && pipeline.payment_date}
                      onChange={handleOnPipeline}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleTogglePipeline}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickPipeline}>
            Alterar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={disputeStatusModal}
        toggle={handleToggleDisputeStatus}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleDisputeStatus}>Chargeback</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="status">
                    Status da Disputa
                  </Label>
                  <CustomInput
                    type="select"
                    id="dispute_status"
                    name="status"
                    onChange={handleOnDisputeStatus}
                    value={disputeStatusAction && disputeStatusAction.status}
                  >
                    <option value="">Selecionar</option>
                    <option value="open">Aberta</option>
                    <option value="establishment_win">
                      Ganha pelo estabelecimento
                    </option>
                    <option value="charged_back">Chargeback</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleDisputeStatus}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickDisputeStatus}>
            Alterar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={reportModal}
        toggle={handleToggleReportModal}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleReportModal}>
          Insira o email para solicitar o relatorio
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email_report">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email_report"
                    name="email_report"
                    placeholder="Digite o Email"
                    onChange={(e) => setEmailReport(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleReportModal}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickReportModal}>
            Solicitar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={pipelineIndex}
        toggle={handleToggleIndexPipeline}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleIndexPipeline}>
          Movimentações
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <p>
                  <strong></strong>
                </p>
                <p className="mb-0">
                  {/* <strong>Nº do Contrato</strong>:{' '}
                  {paymentInformation.contractNumber} */}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="bg-default shadow">
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" className="id">
                          ID
                        </th>
                        <th scope="col" className="status">
                          Modificação
                        </th>
                        <th scope="col" className="user_change">
                          Quem realizou
                        </th>
                        <th scope="col" className="reason">
                          Razão
                        </th>
                        <th scope="col" className="created_at">
                          Data da mudança
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pipelineContracts.map((pipe, index) => (
                        <tr key={pipe.id}>
                          <td className="id">{pipe.id}</td>
                          <td className="status">
                            {pipe.status}
                            {/* {
                              index == 0 ?
                                `Criação da pipeline ${pipe.status}`
                              :
                                `Modificação do status de ${pipelineContracts[index-1].status} para ${pipe.status}`
                            } */}
                          </td>
                          <td className="user_change">
                            {pipe.user ? pipe.user.name : 'Sistema'}
                          </td>
                          <td className="reason">{pipe.reason}</td>
                          <td className="created_at">{pipe.created_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleIndexPipeline}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalDate}
        toggle={handleClickModalDate}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleClickModalDate}>
          Pesquisar por data personalisada
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="status">
                    Data inicial
                  </Label>
                  <input
                    type="date"
                    id="min"
                    name="min"
                    className="form-control form-control-alternative"
                    onChange={handleOnChangeRangeDate}
                    value={minDate}
                  ></input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="status">
                    Data final
                  </Label>
                  <CustomInput
                    type="date"
                    id="max"
                    name="max"
                    className="form-control form-control-alternative"
                    onChange={handleOnChangeRangeDate}
                    value={maxDate}
                  ></CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleClickModalDate}>
            Fechar
          </Button>
          <Button color="success" onClick={handleClickDateSearch}>
            Pesquisar
          </Button>
        </ModalFooter>
      </Modal>
      <UpdateContractNumber
        className={props.className}
        contractNumberModal={contractNumberModal}
        handleToggleNumberModal={handleToggleNumberModal}
        handleOnContractNumber={handleOnContractNumber}
        handlerOnClickNumberModal={handlerOnClickNumberModal}
      />
      <UpdateBankAccount
        className={props.className}
        toggleModalOpen={() => setBankAccountModal(!bankAccountModal)}
        modalOpen={bankAccountModal}
        id={customerId}
        contractId={contractId}
      />
    </>
  );
};

export default Contracts;
