import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
  Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import PaginationComponent from '../../components/PaginationComponent';
import LogTable from '../../components/LogTable';
import LogSubVersions from '../../components/LogTable/subVersions';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { States } from '../../variables/assistants';
import Search from '../../components/Search';

const LIMIT = 10;

const ContractTransfer = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { id } = useParams();
  const [transfers, setTransfers] = useState([]);
  const [transferModal, setTransferModal] = useState(false);
  const [transferValue, setTransferValue] = useState("");
  const [balanceValue, setBalanceValue] = useState("");
  const [contract, setContract] = useState("");

  async function getContracts() {
    showLoader();

    try {
      const response = await api.get(
        `/contracts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      console.log(response.data)
      setContract(response.data);
      setTransferValue(response.data.contract_value)

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível encontrar contrato');
    }
  }

  async function getBalanceValue() {
    showLoader();

    try {
      const response = await api.get(
        `/accounts/${id}/balance`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      console.log(response.data)
      setBalanceValue(response.data.vlrSaldo)


      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível consultar saldo');
    }
  }

  async function getTransfers() {
    showLoader();

    try {
      const response = await api.get(
        `/accounts/${id}/transfers`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setTransfers(response.data);

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista as transferencias');
    }
  }

  async function updateTransfer(id) {
    showLoader();

    try {
      const response = await api.get(
        `/accounts/${id}/status_transfer`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      getTransfers()

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível atualizar o status');
    }
  }

  async function executeTransfer() {
    showLoader();

    try {
      const response = await api.post(
        `/accounts/${id}/transfer`,
        {
          value: transferValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      getTransfers()

      handleToggleTransfer()
      toastSuccess("Solicitação de transferência efetuada")
      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível atualizar o status');
    }
  }

  const handleToggleTransfer = () => {
    setTransferModal(!transferModal)
  }

  const handlerOnClickTransfer = () => {
    executeTransfer()
  }

  useEffect(() => {
    getTransfers();
    getContracts();
    getBalanceValue();
  }, []);


  return (
    <>
      <div className={`header bg-gradient-info pb-8 pt-5 pt-md-8`}>
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Transferências</h3>
                    </Col>
                    {
                      contract.status_original == "contract_signed" && (
                        <Col lg="5" className="mt-3 mt-lg-0">
                          <div className="d-flex justify-content-end h-100 align-items-end">
                            <Button color="primary" onClick={() => handleToggleTransfer()}>
                              Nova Transferência
                            </Button>
                          </div>
                        </Col>
                      )
                    }
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">-</th>
                      <th scope="col">Código</th>
                      <th scope="col">Status</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Data</th>
                      <th scope="col">Mensagem</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transfers.map((transfer) => (
                      <tr key={transfer.id}>
                        <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => updateTransfer(transfer.id)}>
                              <span>
                                <i className="fa fa-refresh" /> Atualizar Status
                              </span>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        </td>
                        <td>{transfer.code_transaction}</td>
                        <td>{transfer.status_i18n}</td>
                        <td>{transfer.value}</td>
                        <td>{transfer.created_at}</td>
                        <td>{transfer.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          isOpen={transferModal}
          toggle={handleToggleTransfer}
          className={props.className}
          backdrop="static"
        >
          <ModalHeader toggle={handleToggleTransfer}>Nova Transferência</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="reason">
                      Saldo
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="reason"
                      name="reason"
                      placeholder=""
                      value={balanceValue}
                      disabled
                      />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="reason">
                      Valor a ser transferido
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="reason"
                      name="reason"
                      placeholder=""
                      value={transferValue}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleToggleTransfer}>
              Fechar
            </Button>
            <Button color="success" onClick={handlerOnClickTransfer}>
              Transferir
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};
export default ContractTransfer;
