import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';

import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';

const CreateSimulatorSetting = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [simulatorSetting, setSimulatorSetting] = useState({
    min_rate: '',
    max_rate: '',
    min_commission: '',
    max_commission: '',
  });

  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  function handleOnChange(e) {
    setSimulatorSetting({ ...simulatorSetting, [e.target.name]: e.target.value });
  }


  async function onSubmit(event) {
    // event.preventDefault();
    // event.stopPropagation();

    showLoader();
    console.log(simulatorSetting)
    try {
      let data = {
        simulator_setting: simulatorSetting
      }

      await api.post(
        'simulator_settings',
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();

      toastSuccess('Dados atualizado com sucesso.');

      props.history.push('/admin/simulator-settings');
    } catch (error) {
      hideLoader();

      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, não foi possível atualizar seus dados, por favor tente novamente';
      }
      toastError(message);
    }
  }

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Cadastrar Simulação de Atendimento</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Taxas
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="min_rate"
                          >
                            Taxa Mínima
                          </label>
                          <InputMask
                            type="text"
                            name="min_rate"
                            placeholder="Taxa Mínima"
                            className="form-control form-control-alternative"
                            id="min_rate"
                            value={simulatorSetting.min_rate}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              O taxa mínima é obrigatória
                            </small>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="max_rate"
                          >
                            Taxa Máxima
                          </label>
                          <InputMask
                            type="text"
                            name="max_rate"
                            placeholder="Taxa Máxima"
                            className="form-control form-control-alternative"
                            id="max_rate"
                            value={simulatorSetting.max_rate}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              O taxa máxima é obrigatória
                            </small>
                          )}
                        </FormGroup>
                      </Col>

                    </Row>

                  </div>
                  {/* <h6 className="heading-small text-muted mb-4">
                    Comissão
                  </h6>
                  <div className="pl-lg-4"> */}
                    {/* <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="min_commission"
                          >
                            Comissão Mínima
                          </label>
                          <InputMask
                            type="text"
                            name="min_commission"
                            placeholder="Comissão Mínima"
                            className="form-control form-control-alternative"
                            id="min_commission"
                            value={simulatorSetting.min_commission}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              A comissão mínima é obrigatória
                            </small>
                          )}
                        </FormGroup>
                      </Col> */}
{/*
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="max_commission"
                          >
                            Comissão Máxima
                          </label>
                          <InputMask
                            type="text"
                            name="max_commission"
                            placeholder="Comissão Máxima"
                            className="form-control form-control-alternative"
                            id="max_commission"
                            value={simulatorSetting.max_commission}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              A comissão máxima é obrigatória
                            </small>
                          )}
                        </FormGroup>
                      </Col> */}

                    {/* </Row>

                  </div> */}

                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> Enviar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateSimulatorSetting;
