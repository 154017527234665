import MenuRankingTable from 'components/MenuRankingTable';
import React from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';
import Pagination from 'react-responsive-pagination';

const RankingRegionalTable = (props) => {
  const {
    sortContractValue,
    sortValue,
    setSortValue,
    sortContractMargin,
    setSortContractMargin,
    setSortContractValue,
    setSortStores,
    sortStores,
    offset,
    handleToggleFilter,
    filterData,
    setOffset,
    pages,
    bussinessDay,
    regionals,
    submitModal,
    setSubmitModal,
    filterDate,
    setFilterDate,
    goal,
    setGoal,
    id,
    setId,
    handleToggleSubmit,
    handlerOnClickSubmit,
    days,
  } = props;

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Gerentes Regionais</h3>
                    </Col>
                    <MenuRankingTable
                      handleToggleFilter={handleToggleFilter}
                      filterData={filterData}
                      exportName={'G. Regionais'}
                    />
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Colocação</th>
                      <th scope="col">Nome</th>
                      <th scope="col" onClick={() => setSortStores()}>
                        <span>Lojas Com Produção</span>
                        {sortStores && sortStores == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col" onClick={() => setSortContractValue()}>
                        <span>Valor Contrato</span>
                        {sortContractValue && sortContractValue == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col" onClick={() => setSortValue()}>
                        <span>Valor Cobrado</span>
                        {sortValue && sortValue == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col" onClick={() => setSortContractMargin()}>
                        <span>Valor de Margem</span>
                        {sortContractMargin && sortContractMargin == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col">Meta</th>
                      <th scope="col">Projeção</th>
                    </tr>
                  </thead>
                  <tbody>
                    {regionals.map((regional, index) => (
                      <tr key={regional.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">{index + 1}</span>
                            </Media>
                          </Media>
                        </th>
                        <td>{regional.nome}</td>
                        <td>{regional.total_stores}</td>
                        <td>{regional.total}</td>
                        <td>{regional.total_charged}</td>
                        <td>{regional.total_margin}</td>
                        <td
                          onClick={() =>
                            handleToggleSubmit(
                              regional.id,
                              regional.goals_value
                            )
                          }
                        >
                          {regional.goals_value} <i className="fas fa-edit" />
                        </td>
                        <td
                          style={{
                            color: `${
                              regional.goals_value_custom >
                              (regional.total_margin_custom / bussinessDay) *
                                days
                                ? 'red'
                                : ''
                            }`,
                          }}
                        >
                          R${' '}
                          {(
                            (regional.total_margin_custom / bussinessDay) *
                            days
                          )
                            .toFixed(2)
                            .replace('.', ',')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 bg-transparent">
                  <Row>
                    <Col
                      lg={{ size: 6, offset: 6 }}
                      className="d-flex justify-content-end"
                    >
                      <Pagination
                        current={offset}
                        total={Number(pages)}
                        onPageChange={setOffset}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={submitModal}
        toggle={handleToggleSubmit}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleSubmit}>Adicionar Meta</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Setar Meta
                  </Label>
                  <CustomInput
                    type="text"
                    id="exampleCustomSelect"
                    name="customSelect"
                    className="form-control form-control-alternative"
                    onChange={(e) => setGoal(e.target.value)}
                    value={goal}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleSubmit}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickSubmit}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default RankingRegionalTable;
