import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
} from 'reactstrap';

import InputMask from 'react-input-mask';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import getToken from '../../util/setAuthToken';
import ContractForm from '../../components/ContractForm';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { AuthContext } from '../../context/AuthContext';

const UpdateContract = (props) => {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [submit, setSubmit] = useState(true);

  const [ banks, setBanks ] = useState([])
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    birth_date: '',
    cpf_cnpj: '',
    mobile: '',
    zipcode: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    contract_number: '',
    contract_value: '',
    last_contract_value: '',
    amount_charged: '',
    last_amount_charged: '',
    installments: '',
    idcustomer: '',
    idpartner: '',
  });

  useEffect(() => {
    showLoader();

    async function getCustomerData() {
      try {
        let { data } = await api.get(`contracts/${id}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        console.log(data)

        setCustomer({
          ...customer,
          status_original: data.status_original,
          contract_number: data.contract_number,
          contract_value: parseMaskCurrency(data.contract_value),
          last_contract_value: parseMaskCurrency(data.contract_value),
          amount_charged: parseMaskCurrency(data.amount_charged),
          last_amount_charged: parseMaskCurrency(data.amount_charged),
          idcustomer: data.id,
          installments: data.installments,
          name: data.customer.name,
          birth_date: data.customer.birth_date,
          cpf_cnpj: data.customer.cpf_cnpj,
          mobile: data.customer.mobile,
          email: data.customer.email,
          zipcode: data.customer.address.zip_code,
          street: data.customer.address.street,
          number: data.customer.address.number,
          district: data.customer.address.district,
          city: data.customer.address.city,
          state: data.customer.address.state,
          complement: data.customer.address.complement,
          bank_id: data.customer.bank_account ? data.customer.bank_account.id : null,
          bank_name: data.customer.bank_account ? `${data.customer.bank_account.number_bank ? data.customer.bank_account.number_bank : "001"} - ${data.customer.bank_account.name_bank ? data.customer.bank_account.name_bank : "Banco não selecionado"}` : "Banco não selecionado",
          agency_account: data.customer.bank_account ? data.customer.bank_account.agency_account ? data.customer.bank_account.agency_account : "0000" : "0000",
          agency_digit: data.customer.bank_account ? data.customer.bank_account.agency_digit ? data.customer.bank_account.agency_digit : "0" : "0",
          account_digit: data.customer.bank_account ? data.customer.bank_account.account_digit ? data.customer.bank_account.account_digit : "0" : "0",
          number_account: data.customer.bank_account ? data.customer.bank_account.number_account ? data.customer.bank_account.number_account : "0000" : "0000",

        });
        hideLoader();
      } catch (e) {
        console.log(e)
        hideLoader();
      }
    }

    getCustomerData();
  }, []);

  function onChange(e) {
    if (
      e.target.name === 'contract_value' ||
      e.target.name === 'amount_charged'
    ) {
      setCustomer({
        ...customer,
        [e.target.name]: e.target.value,
      });
    } else {
      setCustomer({ ...customer, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeContractValue(e) {
    setCustomer({
      ...customer,
      contract_value: parseCurrency(e),
    });
  }

  function handleOnChangeAmountCharged(e) {
    setCustomer({
      ...customer,
      amount_charged: parseCurrency(e),
    });
  }

  function parseCurrency(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace(".", "").replace(",", ".").replace("R$", ""))
    } else {
      return value
    }
  }

  function parseMaskCurrency(value) {
    return value.toString().replace(".", ",")
  }


  async function onSubmit(data) {
    const amountCharged = Number(parseCurrency(data.amount_charged));
    const contractValue = Number(parseCurrency(data.contract_value));

    if (amountCharged <= contractValue) {
      toastError(
        'Valor Cobrado não pode ser menor ou igual ao Valor do Contrato.'
      );
      return;
    }

    if (submit) {
      if (amountCharged <= contractValue) {
        toastError(
          'Valor Cobrado não pode ser menor ou igual ao Valor do Contrato.'
        );
        return;
      }

      showLoader();

      try {
        await api.put(`contracts/${id}`, JSON.stringify(customer), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          },
        });

        hideLoader();

        toastSuccess('Contrato atualizado com sucesso');

        props.history.push('/admin/contracts');
      } catch (error) {
        hideLoader();

        let message =
          'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';

        if (error.response) {
          if (error.response.data.errors) {
            message = error.response.data.errors;

            message.forEach((e) => {
              toastError(e.msg);
            });

            return;
          }
          if (error.response.data) {
            message = error.response.data.message;
          }

          if (typeof message === 'undefined') {
            message =
              'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';
          }
        }

        toastError(message);
      }
    }
  }

  async function handleOnClick() {
    const amountCharged = Number(formatCurrency(customer.amount_charged.toString()));
    const lastAmountCharged = Number(
      formatCurrency(customer.last_amount_charged.toString())
    );

    const lastContractValue = Number(
      formatCurrency(customer.last_contract_value.toString())
    );
    const contractValue = Number(formatCurrency(customer.contract_value.toString()));

    if (
      amountCharged !== lastAmountCharged ||
      lastContractValue !== contractValue
    ) {
      setSubmit(false);

      swal({
        title: 'Atenção',
        text: `Contrato alterado. Vamos enviar um novo link de pagamento para o Cliente, confirma a alteração?`,
        icon: 'warning',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSubmit(true);
          onSubmit(customer);
        }
      });
    }
  }

  async function handleBlurZipCode(event) {
    let cep = event.target.value;
    try {
      if (cep.length > 0) {
        showLoader();

        cep = cep.replace('-', '');

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!('erro' in data)) {
          setCustomer({
            ...customer,
            street: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            state: data.uf,
          });
        } else {
          setCustomer({
            ...customer,
            street: '',
            district: '',
            city: '',
            state: '',
          });
          toastError('CEP inválido');
        }

        hideLoader();
      }
    } catch (e) {
      hideLoader();
    }
  }

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Atualizar Contrato</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome Completo
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            id="name"
                            name="name"
                            disabled
                            value={customer.name}
                            placeholder="Nome completo"
                            ref={register({ required: true })}
                            onChange={onChange}
                            maxLength={35}
                          />

                          {errors.name && (
                            <small className="text-danger">
                              O nome é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control form-control-alternative"
                            id="email"
                            disabled
                            value={customer.email}
                            placeholder="example@example.com"
                            ref={register({ required: true })}
                            onChange={onChange}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              O email é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="birth_date"
                          >
                            Data de nascimento
                          </label>
                          <input
                            type="date"
                            name="birth_date"
                            className="form-control form-control-alternative"
                            id="birth_date"
                            disabled
                            value={customer.birth_date}
                            ref={register({ required: true })}
                            onChange={onChange}
                          />
                          {errors.birth_date && (
                            <small className="text-danger">
                              O data de nascimento é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="cpf_cnpj"
                          >
                            CPF
                          </label>
                          <InputMask
                            type="text"
                            name="cpf_cnpj"
                            placeholder="999.999.999-99"
                            className="form-control form-control-alternative"
                            id="cpf_cnpj"
                            value={customer.cpf_cnpj}
                            mask="999.999.999-99"
                            disabled
                            inputRef={register({ required: true })}
                            onChange={onChange}
                          />
                          {errors.cpf_cnpj && (
                            <small className="text-danger">
                              O cpf é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="mobile"
                          >
                            Celular
                          </label>
                          <InputMask
                            type="text"
                            name="mobile"
                            className="form-control form-control-alternative"
                            id="mobile"
                            placeholder="(99) 99999-9999"
                            mask="(99) 99999-9999"
                            disabled
                            value={customer.mobile}
                            inputRef={register({ required: true })}
                            onChange={onChange}
                          />
                          {errors.mobile && (
                            <small className="text-danger">
                              O celular é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Endereço do Cliente
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="zipcode"
                          >
                            CEP
                          </label>
                          <InputMask
                            name="zipcode"
                            className="form-control form-control-alternative"
                            id="zipcode"
                            placeholder="CEP"
                            type="text"
                            mask="99999-999"
                            disabled
                            onBlur={handleBlurZipCode}
                            value={customer.zipcode}
                            inputRef={register({ required: true })}
                            onChange={onChange}
                          />
                          {errors.zipcode && (
                            <small className="text-danger">
                              O cep é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="street"
                          >
                            Endereço
                          </label>
                          <input
                            name="street"
                            className="form-control form-control-alternative"
                            id="street"
                            placeholder="Endereço"
                            type="text"
                            disabled
                            value={customer.street}
                            ref={register({ required: true })}
                            onChange={onChange}
                          />
                          {errors.street && (
                            <small className="text-danger">
                              O endereço é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number"
                          >
                            Número
                          </label>
                          <input
                            name="number"
                            className="form-control form-control-alternative"
                            id="number"
                            type="number"
                            disabled
                            value={customer.number}
                            onChange={onChange}
                            ref={register({ required: true })}
                          />
                          {errors.number && (
                            <small className="text-danger">
                              O número é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="district"
                          >
                            Bairro
                          </label>
                          <input
                            name="district"
                            className="form-control form-control-alternative"
                            id="district"
                            placeholder="Bairro"
                            type="text"
                            disabled
                            value={customer.district}
                            onChange={onChange}
                            ref={register({ required: true })}
                          />
                          {errors.district && (
                            <small className="text-danger">
                              O bairro é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">
                            Cidade
                          </label>
                          <input
                            name="city"
                            className="form-control form-control-alternative"
                            id="city"
                            placeholder="Cidade"
                            type="text"
                            disabled
                            value={customer.city}
                            onChange={onChange}
                            ref={register({ required: true })}
                          />
                          {errors.city && (
                            <small className="text-danger">
                              A cidade é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="state">
                            Estado
                          </label>
                          <input
                            name="state"
                            className="form-control form-control-alternative"
                            id="state"
                            placeholder="Estado"
                            type="text"
                            disabled
                            value={customer.state}
                            onChange={onChange}
                            ref={register({ required: true })}
                          />
                          {errors.state && (
                            <small className="text-danger">
                              O estado é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="complement"
                          >
                            Complemento
                          </label>
                          <input
                            name="complement"
                            className="form-control form-control-alternative"
                            id="complement"
                            placeholder="Complemento"
                            type="text"
                            disabled
                            value={customer.complement}
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>


                  <h6 className="heading-small text-muted mb-4">
                    Dados Bancários
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="color"
                            value={{value: customer.bank_name, label: customer.bank_name}}
                            options={banks}
                            isDisabled={true}
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Agência
                          </label>
                          <InputMask
                            type="text"
                            name="agency_account"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            placeholder="9999"
                            mask="9999"
                            maskChar=""
                            disabled
                            value={customer && customer.agency_account}
                            onChange={onChange}
                          />
                          {errors.agency_account && (
                            <small className="text-danger">
                              A conta é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        {/* <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <InputMask
                            type="text"
                            name="agency_digit"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            placeholder="9"
                            mask="*"
                            maskChar=""
                            disabled
                            value={customer && customer.agency_digit}
                            inputRef={register({required: false })}
                            onChange={onChange}
                          />
                        </FormGroup> */}
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number_account"
                          >
                            Conta
                          </label>
                          <InputMask
                            type="text"
                            name="number_account"
                            className="form-control form-control-alternative"
                            id="number_account"
                            placeholder="9999999"
                            // mask="9999999"
                            // maskChar=""
                            disabled
                            value={customer && customer.number_account}
                            onChange={onChange}
                          />
                          {errors.number_account && (
                            <small className="text-danger">
                              A agência é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <InputMask
                            type="text"
                            name="account_digit"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            placeholder="9"
                            mask="*"
                            maskChar=""
                            disabled
                            value={customer && customer.account_digit}
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <ContractForm
                    contract={customer}
                    isUpdate
                    disableInputValueKindPayment={!(user && (user.roles[0].name == 'master' || user.roles[0].name == 'operational'))}
                    disableInputValue={!(user && (user.roles[0].name == 'master' || user.roles[0].name == 'operational'))}
                    disableInstallments
                    handleOnChange={onChange}
                    handleOnChangeContractValue={handleOnChangeContractValue}
                    handleOnChangeAmountCharged={handleOnChangeAmountCharged}
                    register={register}
                    errors={errors}
                  />

                  <div className="text-right">
                    <Button
                      type="submit"
                      className="btn btn-success px-4"
                      onClick={handleOnClick}
                    >
                      <i className="fas fa-paper-plane" /> Atualizar Contrato
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateContract;
