import { Link } from 'react-router-dom';
import React from 'react';
import routes from '../routes';

function VisibilityCreateCorban() {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));

  return (
    <>
      {user &&
      user.roles &&
      (user.roles[0].name == 'master' ||
        user.roles[0].name == 'operational') ? (
        <Link className="btn btn-success mb-3 mt-2" to="/admin/corbans/create">
          <i className="fas fa-plus" /> Criar novo CorBan
        </Link>
      ) : (
        false
      )}
    </>
  );
}

function VisibilityCreateSubestablishment() {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));
  return (
    <>
      {user && user.roles && user.roles[0].name == 'master_corban' ? (
        <Link
          className="btn btn-success mb-3 mt-2"
          to="/admin/subestablishments/create"
        >
          <i className="fas fa-plus" /> Criar novo Subestabelecimento
        </Link>
      ) : (
        false
      )}
    </>
  );
}

function VisibilityCreateStore() {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));

  return (
    <>
      {user &&
      user.roles &&
      (user.roles[0].name == 'master_corban' ||
        user.roles[0].name == 'master_subest') ? (
        <Link className="btn btn-success mb-3 mt-2" to="/admin/stores/create">
          <i className="fas fa-plus" /> Criar nova Loja
        </Link>
      ) : (
        false
      )}
    </>
  );
}

function VisibilityUserMasterButton(props) {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));

  return (
    <>
      {user &&
      user.roles &&
      (user.roles[0].name == 'master' ||
        user.roles[0].name == 'operational' ||
        user.roles[0].name == 'master_corban' ||
        user.roles[0].name == 'master_subest' ||
        user.roles[0].name == 'master_store')
        ? props.children
        : false}
    </>
  );
}

function VisibilityByUserRoles(props) {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));
  return (
    <>
      {user && user.roles && props.roles.includes(user.roles[0].name)
        ? props.children
        : false}
    </>
  );
}

function VisibilityNotByUserRoles(props) {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));
  return (
    <>
      {user && user.roles && !props.roles.includes(user.roles[0].name)
        ? props.children
        : false}
    </>
  );
}

function VisibilityCreateSeller() {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));

  return (
    <>
      {user && user.roles && user.roles[0].name == 'master_store' ? (
        <Link className="btn btn-success mb-3 mt-2" to="/admin/sellers/create">
          <i className="fas fa-plus" /> Cadastrar novo vendedor
        </Link>
      ) : (
        false
      )}
    </>
  );
}

function VisibilityCreateSimulation() {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));

  return (
    <>
      {user && user.roles && user.roles[0].name == 'user_seller' ? (
        <Link
          className="btn btn-success mb-3 mt-2"
          to="/admin/customer-services/create"
        >
          <i className="fas fa-plus" /> Novo Atendimento
        </Link>
      ) : (
        false
      )}
    </>
  );
}

function VisibilityEditCorban({ corban_id }) {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));

  return (
    <>
      {user &&
      user.roles &&
      (user.roles[0].name == 'master' ||
        user.roles[0].name == 'operational') ? (
        <Link
          to={`/admin/corbans/${corban_id}`}
          className="btn btn-success btn-block"
        >
          Editar
        </Link>
      ) : (
        false
      )}
    </>
  );
}

function VisibilityEditSubestablishment({ subestablishment_id }) {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));

  return (
    <>
      {user && user.roles && user.roles[0].name == 'master_corban' ? (
        <Link
          to={`/admin/subestablishments/${subestablishment_id}`}
          className="btn btn-success btn-block"
        >
          Editar
        </Link>
      ) : (
        false
      )}
    </>
  );
}

function VisibilityEditStore({ store_id }) {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));

  return (
    <>
      {(user && user.roles && user.roles[0].name == 'master_corban') ||
      user.roles[0].name == 'master_subest' ? (
        <Link
          to={`/admin/stores/${store_id}`}
          className="btn btn-success btn-block"
        >
          Editar
        </Link>
      ) : (
        false
      )}
    </>
  );
}

function VisibilityEditSeller({ seller_id }) {
  const user = JSON.parse(localStorage.getItem('@UGate:user'));

  return (
    <>
      {user && user.roles && user.roles[0].name == 'master_store' ? (
        <Link
          to={`/admin/sellers/${seller_id}`}
          className="btn btn-success btn-block"
        >
          Editar
        </Link>
      ) : (
        false
      )}
    </>
  );
}

function HandleBgPipelinesColor(status_bg_pipeline, kind_fgts) {
  switch (status_bg_pipeline) {
    case 'send_link':
      return 'bg-yellow';
    case 'operate_simulator':
      return 'bg-yellow';
    case 'document_pending':
      return kind_fgts ? 'bg-info-custom' : 'bg-info';
    case 'waiting_documentos_analitcs':
      return 'bg-info';
    case 'waiting_signature':
      return 'bg-primary';
    case 'refused':
      return 'bg-danger';
    case 'pending':
      return kind_fgts ? 'bg-warning-custom' : 'bg-info';
    case 'reanalysis':
      return 'bg-info-custom';
    case 'biometric_analysis':
      return 'bg-info-custom';
    case 'payment_returned':
      return 'bg-primary';
    case 'contract_signed':
      return 'bg-primary';
    case 'contract_paid':
      return 'bg-success';
    case 'commission_paid':
      return 'bg-success';
    case 'request_cancel':
      return 'bg-danger';
    case 'risk_analysis':
      return 'bg-warning-custom';
    case 'canceled':
      return 'bg-danger';
    default:
      return 'bg-primary';
  }
}

export {
  VisibilityNotByUserRoles,
  VisibilityCreateCorban,
  VisibilityCreateSubestablishment,
  VisibilityCreateStore,
  VisibilityCreateSeller,
  VisibilityCreateSimulation,
  VisibilityEditSeller,
  VisibilityEditCorban,
  VisibilityEditSubestablishment,
  VisibilityEditStore,
  VisibilityUserMasterButton,
  VisibilityByUserRoles,
  HandleBgPipelinesColor,
};
