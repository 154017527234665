import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Row
} from 'reactstrap';
import api from '../../services/api';
import token from '../../util/setAuthToken';

const Report = (props) => {
  const params = new URLSearchParams(props.location.search);
  const [file, setFile] = useState({name: "", url: ""})

  async function getReport(report_id) {
    await api.get(
      `/contracts/export_complete_contract_url?report_id=${report_id}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    ).then(response => {
      setFile(response.data)
    })
    .catch(err => {
        console.log(err.status);
        console.log(err.response.status)
    });
  }
  
  useEffect(() => {
    if (!file['url'] && params.get('report_id')) {
      getReport(params.get('report_id'))
    }

  }, [file['url']])

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container className="mt--7 pt-5" fluid>
          <Row>
            <div className="col">
              <Card className="bg-white shadow">
                <CardHeader className="bg-transparent border-0">
                  Relatório
                </CardHeader>
                <Row className="m-4">
                  { file["url"] && (
                    <>
                      <a href={file['url']}>
                        {file['name']} <i className="fas fa-download" />
                      </a>
                    </>
                  )}
                </Row>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Report;