import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input
} from 'reactstrap';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { toastError } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input';
import { useLocation } from 'react-router-dom';

const ReferrerForm = ({
  referrer,
  banks,
  titleButton,
  title,
  onSubmit,
  handleOnChange,
  dataAddress,
  register,
  handleSubmit,
  errors,
  handleOnChangeBank,
  onlyBank,
  insertMoney,
  handleOnChangeValueFix,
}) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [ addressDisabled, setAddressDisabled ] = useState(true);
  const query = new URLSearchParams(useLocation().search);
  async function handleBlurZipCode(event) {
    let cep = event.target.value;
    try {
      if (cep.length > 0) {
        showLoader();

        cep = cep.replace('-', '');

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!('erro' in data)) {
          dataAddress({
            street: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            state: data.uf,
          });
          setAddressDisabled(true)
        } else {
          dataAddress({
            street: '',
            district: '',
            city: '',
            state: '',
          });
          toastError('CEP inválido');
          setAddressDisabled(false)
        }

        hideLoader();
      }
    } catch (e) {
      hideLoader();
    }
  }

  return (
    <>
      <Container className={onlyBank ? '' : 'mt--7'} fluid={!onlyBank}>
        <Row>
          <Col sm="12">
            <Card className={onlyBank ? '' : 'bg-secondary shadow'}>
              <CardHeader className="bg-white border-0">
                {!onlyBank && (
                  <Row className="align-items-center">
                    <Col sm="12">
                      <h3 className="mb-0">{title}</h3>
                    </Col>
                  </Row>
                )}
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {!onlyBank && (
                    <>
                      <h6 className="heading-small text-muted mb-4">
                        Informações pessoais
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="name"
                              >
                                Nome Completo
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                id="name"
                                name="name"
                                // placeholder="Nome completo"
                                value={referrer && referrer.name}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                maxLength={35}
                              />

                              {errors.name && (
                                <small className="text-danger">
                                  O nome é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="email"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                name="email"
                                className="form-control form-control-alternative"
                                id="email"
                                value={referrer && referrer.email}
                                // placeholder="example@example.com"
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.email && (
                                <small className="text-danger">
                                  O email é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="mother_name"
                              >
                                Nome da Mãe
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                id="mother_name"
                                name="mother_name"
                                // placeholder="Nome da Mãe"
                                value={referrer && referrer.mother_name}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />

                              {errors.mother_name && (
                                <small className="text-danger">
                                  O nome da mãe é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="father_name"
                              >
                                Nome do Pai
                              </label>
                              <input
                                type="text"
                                name="father_name"
                                className="form-control form-control-alternative"
                                id="father_name"
                                value={referrer && referrer.father_name}
                                onChange={handleOnChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="birth_date"
                              >
                                Data de nascimento
                              </label>
                              <input
                                type="date"
                                name="birth_date"
                                className="form-control form-control-alternative"
                                id="birth_date"
                                value={referrer && referrer.birth_date}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.birth_date && (
                                <small className="text-danger">
                                  {Object.values(errors.birth_date)
                                    .reduce((t, n) => t + n, '')
                                    .split('[')[0] === 'required'
                                    ? 'Data de nascimento é obrigatório'
                                    : `${
                                        Object.values(errors.birth_date)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0]
                                      }`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="cpf_cnpj"
                              >
                                CPF
                              </label>
                              <Input
                                type="text"
                                name="cpf_cnpj"
                                // placeholder="999.999.999-99"
                                className="form-control form-control-alternative"
                                id="cpf_cnpj"
                                value={referrer && referrer.cpf_cnpj}
                                inputRef={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.cpf_cnpj && (
                                <small className="text-danger">
                                  {Object.values(errors.cpf_cnpj)
                                    .reduce((t, n) => t + n, '')
                                    .split('[')[0] === 'required'
                                    ? 'CPF é obrigatório'
                                    : `CPF ${
                                        Object.values(errors.cpf_cnpj)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0]
                                      }`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="rg"
                              >
                                RG
                              </label>
                              <InputMask
                                type="number"
                                name="rg"
                                // placeholder="9.999.999"
                                className="form-control form-control-alternative"
                                id="rg"
                                value={referrer && referrer.rg}
                                inputRef={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.rg && (
                                <small className="text-danger">
                                  {Object.values(errors.rg)
                                    .reduce((t, n) => t + n, '')
                                    .split('[')[0] === 'required'
                                    ? 'RG é obrigatório'
                                    : `RG ${
                                        Object.values(errors.rg)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0]
                                      }`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="mobile"
                              >
                                Celular
                              </label>
                              <Input
                                type="text"
                                name="mobile"
                                className="form-control form-control-alternative"
                                id="mobile"
                                // placeholder="(99) 99999-9999"
                                value={referrer && referrer.mobile}
                                inputRef={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.mobile && (
                                <small className="text-danger">
                                  O celular é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="social_reason"
                              >
                                Razão social
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                id="social_reason"
                                name="social_reason"
                                // placeholder="Razão Social"
                                value={referrer && referrer.social_reason}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />

                              {errors.social_reason && (
                                <small className="text-danger">
                                  A razão social é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small text-muted mb-4">
                        Endereço do Indicador
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="zip_code"
                              >
                                CEP
                              </label>
                              <InputMask
                                name="zip_code"
                                className="form-control form-control-alternative"
                                id="zip_code"
                                // placeholder="CEP"
                                type="text"
                                mask="99999-999"
                                maskChar=""
                                value={referrer && referrer.zip_code}
                                inputRef={register({ required: true })}
                                onChange={handleOnChange}
                                onBlur={handleBlurZipCode}
                              />
                              {errors.zip_code && (
                                <small className="text-danger">
                                  O cep é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="10">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="street"
                              >
                                Endereço
                              </label>
                              <input
                                name="street"
                                className="form-control form-control-alternative"
                                id="street"
                                // placeholder="Endereço"
                                type="text"
                                value={referrer && referrer.street}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.street && (
                                <small className="text-danger">
                                  O endereço é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="number"
                              >
                                Número
                              </label>
                              <input
                                name="number"
                                className="form-control form-control-alternative"
                                id="number"
                                type="number"
                                value={referrer && referrer.number}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.number && (
                                <small className="text-danger">
                                  O número é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="district"
                              >
                                Bairro
                              </label>
                              <input
                                name="district"
                                className="form-control form-control-alternative"
                                id="district"
                                // placeholder="Bairro"
                                type="text"
                                value={referrer && referrer.district}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.district && (
                                <small className="text-danger">
                                  O bairro é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="city"
                              >
                                Cidade
                              </label>
                              <input
                                name="city"
                                className="form-control form-control-alternative"
                                id="city"
                                // placeholder="Cidade"
                                type="text"
                                value={referrer && referrer.city}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={addressDisabled}
                              />
                              {errors.city && (
                                <small className="text-danger">
                                  A cidade é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="state"
                              >
                                UF
                              </label>
                              <input
                                name="state"
                                className="form-control form-control-alternative"
                                id="state"
                                // placeholder="Estado"
                                type="text"
                                value={referrer && referrer.state}
                                ref={register({ required: true, maxLength: 2 })}
                                onChange={handleOnChange}
                                disabled={addressDisabled}
                              />
                              {errors.state && (
                                <small className="text-danger">
                                  O estado é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="complement"
                              >
                                Complemento
                              </label>
                              <input
                                name="complement"
                                className="form-control form-control-alternative"
                                id="complement"
                                // placeholder="Complemento"
                                type="text"
                                value={referrer && referrer.complement}
                                ref={register({ required: false })}
                                onChange={handleOnChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  <h6 className="heading-small text-muted mb-4">
                    Dados Bancários
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg={onlyBank ? '6 mb-4' : '2 mb-4'}>
                        <label
                          className="form-control-label"
                          htmlFor="kind_pix"
                        >
                          Tipo Pix
                        </label>
                        <Input
                          type="select"
                          id="kind_pix"
                          name="kind_pix"
                          value={referrer && referrer.kind_pix}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="cpf_cnpj">CPF/CNPJ</option>
                          <option value="email">E-mail</option>
                          <option value="phone">Telefone</option>
                          <option value="random">
                            {'Dados Bancários (conforme abaixo)'}
                          </option>
                        </Input>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="pix">
                            Pix
                          </label>
                          <InputMask
                            type={
                              referrer &&
                              (referrer.kind_pix == 'cpf_cnpj' ||
                                referrer.kind_pix == 'phone')
                                ? 'number'
                                : 'text'
                            }
                            name="pix"
                            className="form-control form-control-alternative"
                            id="pix"
                            placeholder="Pix"
                            value={referrer && referrer.pix}
                            // inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.pix && (
                            <small className="text-danger">
                              Pix é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={onlyBank ? '8' : '6'}>
                        <FormGroup>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="color"
                            value={{
                              value: referrer.bank_name,
                              label: referrer.bank_name,
                            }}
                            options={banks}
                            onChange={handleOnChangeBank}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={onlyBank ? '8' : '6'}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Agência
                          </label>
                          <InputMask
                            type="text"
                            name="agency_account"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            // placeholder="9999"
                            mask="9999"
                            maskChar=""
                            value={referrer && referrer.agency_account}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.agency_account && (
                            <small className="text-danger">
                              A agência é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        {/* <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <InputMask
                            type="text"
                            name="agency_digit"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            // placeholder=""
                            mask="*"
                            maskChar=""
                            value={referrer && referrer .agency_digit}
                            inputRef={register({required: false })}
                            onChange={handleOnChange}
                          />
                        </FormGroup> */}
                      </Col>
                      <Col lg={onlyBank ? '8' : '6'}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number_account"
                          >
                            Conta
                          </label>
                          <InputMask
                            type="text"
                            name="number_account"
                            className="form-control form-control-alternative"
                            id="number_account"
                            // placeholder="9999999"
                            // mask="9999999"
                            // maskChar=""
                            value={referrer && referrer.number_account}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.number_account && (
                            <small className="text-danger">
                              A conta é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <InputMask
                            type="text"
                            name="account_digit"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            // placeholder="9"
                            mask="*"
                            maskChar=""
                            value={referrer && referrer.account_digit}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.account_digit && (
                            <small className="text-danger">
                              O digito da conta é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={onlyBank ? '6 mb-4' : '2 mb-4'}>
                        <label
                          className="form-control-label"
                          htmlFor="account_kind"
                        >
                          Tipo de conta
                        </label>
                        <Input
                          type="select"
                          id="account_kind"
                          name="account_kind"
                          value={referrer && referrer.account_kind}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="0">Conta Corrente</option>
                          <option value="1">Poupança</option>
                        </Input>
                      </Col>

                      {/* {
                        query.get("referrerServiceId") && (
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="month"
                              >
                                Vencimento da fatura do cartão
                              </label>
                              <Input
                                type="select"
                                id="month"
                                name="month"
                                value={referrer && referrer.month}
                                onChange={handleOnChange} >
                                <option>Selecione</option>
                                {
                                  ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'].map(item => (
                                    <option value={item}>{item}</option>
                                  ))
                                }
                              </Input>
                            </FormGroup>
                          </Col>
                        )
                      } */}
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Cadastro de comissão
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <Label for="has_commission">Existe uma comissão</Label>
                        <Input
                          type="select"
                          id="has_commission"
                          name="has_commission"
                          value={referrer && referrer.has_commission}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="yes">Sim</option>
                          <option value="no">Não</option>
                        </Input>
                      </Col>
                      {referrer.has_commission == 'yes' ? (
                        <Col lg="2">
                          <Label for="commission_type">Tipo de comissão</Label>
                          <Input
                            type="select"
                            id="commission_type"
                            name="commission_type"
                            value={referrer && referrer.commission_type}
                            onChange={handleOnChange}
                          >
                            <option>Selecione o tipo</option>
                            <option value="0">Percentual</option>
                            <option value="1">Fixo</option>
                          </Input>
                        </Col>
                      ) : (
                        false
                      )}
                      {referrer.has_commission == 'yes' &&
                      referrer.commission_type == '0' ? (
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="value_percentage"
                            >
                              Valor percentual
                            </label>
                            <Input
                              type="text"
                              name="value_percentage"
                              decimalSeparator=","
                              className="form-control form-control-alternative"
                              id="value_percentage"
                              // placeholder="99%"
                              value={referrer && referrer.value}
                              inputRef={register({ required: true })}
                              onChange={handleOnChange}
                            />
                            {errors.value && (
                              <small className="text-danger">
                                Valor é obrigatório
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      ) : referrer.has_commission == 'yes' &&
                        referrer.commission_type == '1' ? (
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="value_fix"
                            >
                              Valor Fixo
                            </label>
                            <CurrencyInput
                              prefix="R$"
                              decimalSeparator=","
                              thousandSeparator="."
                              type="text"
                              name="value_fix"
                              className="form-control form-control-alternative"
                              id="value_fix"
                              // placeholder="R$999,99"
                              value={referrer && referrer.value}
                              inputRef={register({ required: true })}
                              onChange={handleOnChangeValueFix}
                              onBlur={insertMoney}
                            />
                            {errors.value && (
                              <small className="text-danger">
                                Valor é obrigatório
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      ) : (
                        false
                      )}
                    </Row>
                  </div>
                  {!onlyBank && (
                    <div className="text-right">
                      <Button type="submit" className="btn btn-success px-4">
                        <i className="fas fa-paper-plane" /> {titleButton}
                      </Button>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReferrerForm;
