import React, { useContext, useState, useEffect } from 'react';
import { LoadingContext } from '../../context/loading/LoadingContext';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import SignerForm from '../../components/SignerForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';

const CreateSigner = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const { user } = useContext(AuthContext);
  const [signer, setSigner] = useState({
    name: "",
    email: "",
    kind: "novo_saque",
    phone_number: "",
    documentation: "",
    birthdate: "",
  });

  async function onSubmit(data) {
    showLoader();

    data = {
      signer: {
        name: signer.name,
        email: signer.email,
        kind: signer.kind,
        phone_number: signer.phone_number,
        documentation: signer.documentation,
        birthdate: signer.birthdate,
      }
    }

    try {
      await api.post('signers', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Signatario criado com sucesso');

      props.history.push('/admin/signers');
    } catch (error) {
      hideLoader();

      let message = '';

      if (error.response.data.error) {
        const responseErrors = error.response.data.error

      }
      window.scrollTo(0, 0)
      toastError("Há informações incorretas, por favor revise-as.");
    }
  }


  function handleOnChange(e) {
    console.log(e)
    setSigner({ ...signer, [e.target.name]: e.target.value });
  }



  return (
    <>
      <CustomerHeader />
      <SignerForm
        signer={signer}
        handleOnChange={handleOnChange}
        onSubmit={onSubmit}
        title="Cadastrar Signatario"
        titleButton="Cadastrar"
      />
    </>
  );
};

export default CreateSigner;
