import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingContext } from '../../context/loading/LoadingContext';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import StoreForm from '../../components/StoreForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input
} from 'reactstrap';

import Select from 'react-select';
import { VisibilityByUserRoles } from 'util/visibility';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';

const SellerDetail = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [sub, setSub] = useState({
    social_reason:'',
    name: '',
    cpf_cnpj: '',
    user_cpf: '',
    user_name: '',
    user_email: '',
    user_mobile: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    bank_name: '',
    agency_number: '',
    number_account: '',
    has_commission: '',
    active_base_trigger: '',
    commission_type: '',
    value: '',
    trigger: '',
    store_kind: 0,
    agency_digit: '',
    account_digit: '',
    internal_code: '',
  });
  const { register, handleSubmit, errors, setError } = useForm();
  const [ contactFields, setContactFields] = useState([
    {mobile: '', email: '', type_contact: ''}
  ])

  const [ banks, setBanks ] = useState([])
  const [ corbans, setCorbans ] = useState([])
  const [ subs, setSubs ] = useState([])
  const [ kind, setKind ] = useState('pj')

  async function getSub() {
    showLoader();
    try {
      const { data } = await api.get(`sellers/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setContactFields(data.contact)
      // setStore(data);

      setSub({
        ...data,
        bank_account: data.entity && data.entity.bank_account ? data.entity.bank_account : '',
        has_commission: data.entity.has_commission ? true : false,
        commission_fgts: data.entity.commission_fgts,
        commission_type: data.entity.has_commission ? (data.commission[0].commission_type == "percentage" ? '0' : '1') : '',
        value: data.entity.has_commission ? (data.commission[0].commission_type == "percentage" ? `${data.commission[0].value}%` : `R$ ${data.commission[0].value}`) : '',
        active_base_trigger: data.commission[0] ? data.commission[0].trigger != null && data.commission[0].trigger != 0 ? (data.entity.has_commission ? 'yes' : '') : 'no' : '',
        trigger: data.entity.has_commission && (data.commission[0].trigger != null && data.commission[0].trigger != 0) ? `${data.commission[0].trigger}%` : '',
        entity_id: data.entity.id,
        commission_id: data.entity.has_commission ? data.commission[0].id : '',
      });

      hideLoader();
    } catch (error) {
      hideLoader();
    }
  }


  useEffect(()=> {
    getSub()
  }, [])


  function parseCurrency(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace(".", "").replace(",", ".").replace("R$", ""))
    } else {
      return value
    }
  }

  function parsePercentage(value) {
    if(typeof value === 'string' || value instanceof String){
      return (value.replace("%", "").replace(",", "."))
    } else {
      return value
    }
  }

  function parseDigit(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "x")
    } else {
      return value
    }
  }

  function parseAccount(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "")
    } else {
      return value
    }
  }

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0"></h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit()}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações do vendedor
                  </h6>

                  <div className="pl-lg-4">
                    <Row>
                      <VisibilityByUserRoles roles={["master", "operational"]}>
                        <Col lg="6">
                          <label className="form-control-label" htmlFor="social_reason">
                            Corban
                          </label>
                          <p>
                            {sub.corban_name}
                          </p>
                        </Col>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles roles={["master", "operational"]}>
                        <Col lg="6">
                          <label className="form-control-label" htmlFor="social_reason">
                            Subestabelecimento
                          </label>
                          <p>
                            {sub.sub_name}
                          </p>
                        </Col>
                      </VisibilityByUserRoles>
                    </Row>
                    <Row>
                      <VisibilityByUserRoles roles={["master", "operational", "master_corban", "user_corban_register", "user_corban"]}>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="internal_code"
                            >
                              Loja
                            </label>
                            <p>
                              {sub.store_name}
                            </p>
                          </FormGroup>
                        </Col>
                      </VisibilityByUserRoles>
                    </Row>
                  </div>
                <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Representante legal
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="user_cpf"
                          >
                          CPF
                          </label>
                          <p>
                            {sub.entity ? sub.entity.cpf_cnpj : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="user_name">
                            Nome
                          </label>
                          <p>
                            {sub.entity ? sub.entity.name : ''}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="user_email">
                            Email do representante (Login de acesso do vendedor)
                          </label>
                          <p>
                            {sub ? sub.email : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="user_mobile"
                          >
                            Celular
                          </label>
                          <p>
                            {sub ? sub.mobile_user : ''}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Endereço da Loja
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="zip_code"
                          >
                            CEP
                          </label>
                          <p>
                            {sub.address ? sub.address.zip_code : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="street"
                          >
                            Endereço
                          </label>
                          <p>
                            {sub.address ? sub.address.street : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number"
                          >
                            Número
                          </label>
                          <p>
                            {sub.address ? sub.address.number : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="district"
                          >
                            Bairro
                          </label>
                          <p>
                            {sub.address ? sub.address.district : ''}
                          </p>

                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">
                            Cidade
                          </label>
                          <p>
                            {sub.address ? sub.address.city : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="state">
                            Estado
                          </label>
                          <p>
                            {sub.address ? sub.address.state : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="complement"
                          >
                            Complemento
                          </label>
                          <p>
                            {sub.address ? sub.address.complement : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Dados Bancários
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <p>
                            {sub.bank_account ? `${sub.bank_account.number_bank} ${sub.bank_account.name_bank}` : ''}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Agência
                          </label>
                          <p>
                            {sub.bank_account ? sub.bank_account.agency_account : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <p>
                            {sub.bank_account ? sub.bank_account.agency_digit : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number_account"
                          >
                            Conta
                          </label>
                          <p>
                            {sub.bank_account ? sub.bank_account.number_account : ''}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_number"
                          >
                            Dígito
                          </label>
                          <p>
                            {sub.bank_account ? sub.bank_account.account_digit : ''}
                          </p>

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="account_kind">Tipo de conta</Label>
                        <p>
                          {sub.bank_account ? sub.bank_account.kind == 0 ? 'Conta Corrente' : 'Poupança' : ''}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Cadastro do pix
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6 mb-4">
                        <label className="form-control-label" htmlFor="store_kind">
                          Tipo de Pix
                        </label>
                        <p>
                          {sub.kind_pix_i18n}
                        </p>
                      </Col>
                      <Col lg="6 mb-4">
                        <label className="form-control-label" htmlFor="store_kind">
                          Pix
                        </label>
                        <p>
                          {sub.pix}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Cadastro de comissão
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="2">
                        <Label for="has_commission">Definir comissão</Label>
                        <p>
                          {sub.has_commission ? 'Sim' : 'Não'}
                        </p>
                      </Col>
                      {
                        sub.entity && sub.entity.has_commission ?

                          <Col lg="2">
                            <Label for="commission_type">Tipo de comissão</Label>
                            <p>
                              {sub.commission_type == 0 ? 'Porcentagem' : 'Valor Fixo'}
                            </p>
                          </Col>
                        :
                          false
                      }
                      {
                        sub.has_commission && sub.commission_type == 0 ?
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="value">
                              Valor percentual
                            </label>
                            <p>
                              {sub.value}
                            </p>
                          </FormGroup>
                        </Col>
                        :
                        sub.has_commission && sub.commission_type == 1 ?
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="value">
                                Valor Fixo
                              </label>
                              <p>
                                {sub.value}
                              </p>
                            </FormGroup>
                          </Col>
                          :
                            false
                      }
                    </Row>
                    <Row>
                       {
                        sub.has_commission && sub.active_base_trigger?
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="trigger">
                                Bônus
                              </label>
                              <p>
                                {sub.trigger}
                              </p>

                            </FormGroup>
                          </Col>
                        :
                          false
                      }
                    </Row>
                    <Row>
                      {
                        sub.has_commission &&
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="commission_fgts">
                              Commissão Fgts
                            </label>
                            <p>
                              {sub.commission_fgts}%
                            </p>
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SellerDetail;
