import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Badge,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import { CSVLink } from "react-csv";
import Pagination from 'react-responsive-pagination';
import { Link, useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import swal from 'sweetalert';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';
import Header from '../../components/Headers/Header';
import Search from '../../components/Search';
import token from '../../util/setAuthToken';
import { AuthContext } from '../../context/AuthContext';
import { VisibilityUserMasterButton } from '../../util/visibility';

const LIMIT = 10;

const UpdateContractNumber = ({
    contractNumberModal,
    handleToggleNumberModal,
    className,
    handleOnContractNumber,
    handlerOnClickNumberModal
  }) => {
  return (
    <Modal
      isOpen={contractNumberModal}
      toggle={handleToggleNumberModal}
      className={className}
      backdrop="static"
    >
      <ModalHeader toggle={handleToggleNumberModal}>Alteração do Nº de Contrato</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="reason">
                  Nº do Contrato
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="reason"
                  name="reason"
                  placeholder="Digite o número do contrato"
                  onChange={handleOnContractNumber}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleToggleNumberModal}>
          Fechar
        </Button>
        <Button color="success" onClick={handlerOnClickNumberModal}>
          Alterar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateContractNumber
