import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';

import InputMask from 'react-input-mask';
import UserHeader from '../../components/Headers/UserHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import axios from 'axios';

import EntityProfile from '../../components/Profile/EntityProfile';

import { useForm } from 'react-hook-form';

const Profile = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [mobile, setMobile] = useState('');
  const [entity, setEntity] = useState('');
  const [address, setAddress] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [commissions, setCommissions] = useState([]);
  const [contactFields, setContactFields] = useState([]);

  const { register, handleSubmit, errors } = useForm();


  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  function handleClickContact() {
    setContactFields([ ...contactFields, { mobile: '', email: '', type_contact: '' }])
  }

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setBirthDate(user.birth_date);
      setMobile(user.mobile);
    }
  }, [user]);

  async function handleSubmitUser(event) {
    event.preventDefault();
    event.stopPropagation();

    showLoader();

    try {
      await api.put(
        'users',
        JSON.stringify({ name, mobile, birth_date: birthDate }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();

      toastSuccess('Dados atualizado com sucesso.');
    } catch (error) {
      hideLoader();

      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, não foi possível atualizar seus dados, por favor tente novamente';
      }
      toastError(message);
    }
  }


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Minha conta</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmitUser}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome Completo
                          </label>
                          <Input
                            type="text"
                            className="form-control-alternative"
                            id="name"
                            name="name"
                            placeholder="Nome completo"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            maxLength={35}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <Input
                            type="email"
                            className="form-control-alternative"
                            id="email"
                            placeholder="example@example.com"
                            name="email"
                            value={email}
                            disabled
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="birth_date"
                          >
                            Data de nascimento
                          </label>
                          <Input
                            type="date"
                            className="form-control-alternative"
                            id="birth_date"
                            name="birth_date"
                            value={birthDate}
                            onChange={(e) => setBirthDate(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="mobile"
                          >
                            Celular
                          </label>
                          <InputMask
                            type="text"
                            className="form-control form-control-alternative"
                            id="mobile"
                            name="mobile"
                            value={mobile}
                            mask="(99) 99999-9999"
                            onChange={(e) => setMobile(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-right">
                      <Button type="submit" className="btn btn-success px-4">
                        <i className="fas fa-paper-plane" /> Enviar
                      </Button>
                    </div>
                  </div>
                </Form>

                {
                  // (user && user.roles[0] && user.roles[0].name == "master_store")
                  // || (user && user.roles[0] && user.roles[0].name == "master_corban")
                  // || (user && user.roles[0] && user.roles[0].name == "master_subest")
                  // || (user && user.roles[0] && user.roles[0].name == "user_seller")
                  user && user.kind && (user && user.roles[0] && user.roles[0].name != "master" && user.roles[0].name != "operational") && (<EntityProfile kind={user.kind}/>)
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
