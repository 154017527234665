import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Input,
  Label
} from 'reactstrap';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { LoadingContext } from '../../context/loading/LoadingContext';

const SignerForm = ({
  titleButton,
  title,
  onSubmit,
  handleOnChange,
  signer
}) => {
  const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">{title}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Signatario
                  </h6>
                  <div className="pl-lg-4">
                    <div >
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Row>
                              <Col>
                                <label className="form-control-label" htmlFor="name">
                                  Nome
                                </label>
                                <InputMask
                                  type="text"
                                  name="name"
                                  className="form-control form-control-alternative mt-4"
                                  id="name"
                                  placeholder="Nome"
                                  value={signer && signer.name}
                                  inputRef={register({ required: true })}
                                  onChange={event => handleOnChange(event)}
                                />
                                {errors.value && (
                                  <small className="text-danger">
                                    O nome é obrigatório
                                  </small>
                                )}
                              </Col>
                              <Col>
                                <label className="form-control-label" htmlFor="email">
                                  Email
                                </label>
                                <InputMask
                                  type="text"
                                  name="email"
                                  className="form-control form-control-alternative mt-4"
                                  id="email"
                                  placeholder="Email"
                                  value={signer && signer.email}
                                  inputRef={register({ required: true })}
                                  onChange={event => handleOnChange(event)}
                                />
                                {errors.value && (
                                  <small className="text-danger">
                                    O email é obrigatório
                                  </small>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label className="form-control-label" htmlFor="phone_number">
                                  Celular
                                </label>
                                <InputMask
                                  type="text"
                                  name="phone_number"
                                  className="form-control form-control-alternative mt-4"
                                  id="phone_number"
                                  placeholder="Celular"
                                  value={signer && signer.phone_number}
                                  inputRef={register({ required: true })}
                                  onChange={event => handleOnChange(event)}
                                />
                                {errors.value && (
                                  <small className="text-danger">
                                    O Celular é obrigatório
                                  </small>
                                )}
                              </Col>
                              <Col>
                                <label className="form-control-label" htmlFor="documentation">
                                  CPF
                                </label>
                                <InputMask
                                  type="text"
                                  name="documentation"
                                  mask="999.999.999-99"
                                  className="form-control form-control-alternative mt-4"
                                  id="documentation"
                                  value={signer && signer.documentation}
                                  inputRef={register({ required: true })}
                                  onChange={event => handleOnChange(event)}
                                />
                                {errors.value && (
                                  <small className="text-danger">
                                    O cpf é obrigatório
                                  </small>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <label className="form-control-label" htmlFor="birthdate">
                                  Data de nascimento
                                </label>
                                <input
                                  type="date"
                                  name="birthdate"
                                  className="form-control form-control-alternative"
                                  id="birthdate"
                                  ref={register({ required: true })}
                                  value={signer.birthdate}
                                  onChange={handleOnChange}
                                />
                                {errors.value && (
                                  <small className="text-danger">
                                    O Data de nascimento é obrigatório
                                  </small>
                                )}
                              </Col>
                              <Col lg="2  mb-4">
                                <Label for="kind">Tipo de Signatário</Label>
                                <Input
                                  type="select"
                                  id="kind"
                                  name="kind"
                                  value={signer && signer.kind}
                                  onChange={handleOnChange} >
                                  <option>Selecione o tipo</option>
                                  {/* <option value="fiducia">Fidúcia</option> */}
                                  <option value="money_plus">Money Plus</option>
                                  <option value="giro">Giro</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <br></br>
                  </div>
                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> {titleButton}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignerForm;
