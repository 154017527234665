import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  CustomInput,
  Button,
  Input,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import Pagination from 'react-responsive-pagination';
import { LoadingContext } from '../../context/loading/LoadingContext';
import {
  VisibilityCreateStore,
  VisibilityEditStore,
} from '../../util/visibility';
import './style.css';
import Search from '../../components/Search';
import { States } from '../../variables/assistants';
import { VisibilityByUserRoles } from '../../util/visibility';
import { AuthContext } from '../../context/AuthContext';
import InputMask from 'react-input-mask';

const LIMIT = 10;

const Establishment = (props) => {
  const { user } = useContext(AuthContext);
  const exportStoreRef = useRef(exportStoreRef);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [managers, setManagers] = useState([]);
  const [corbans, setCorbans] = useState([]);
  const [subs, setSubs] = useState([]);
  const [submitModal, setSubmitModal] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [storeStatus, setStoreStatus] = useState('');
  const [managerStore, setManagerStore] = useState('');
  const handleToggleSubmit = (id = null, managerId = null, status = null) => {
    setStoreId(id);
    setManagerStore(managerId);
    setStoreStatus(status);
    setSubmitModal(!submitModal);
  };

  const [stores, setStores] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [selectedStores, setSelectedStores] = useState([]);
  const [pages, setPages] = useState([]);
  const [cnpj, setCnpj] = useState('');
  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterCommission, setFilterCommission] = useState('');
  const [filterManager, setFilterManager] = useState('');
  const [filterAuto, setFilterAuto] = useState('');
  const [filterCorban, setFilterCorban] = useState('');
  const [filterSub, setFilterSub] = useState('');
  const [filterSellers, setFilterSellers] = useState('');
  const [filterLegalRepresentative, setFilterLegalRepresentative] =
    useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('');
  const [exportStoreData, setExportStoreData] = useState([]);
  const [storeReportDisabled, setStoreReportDisabled] = useState(false);

  const sortByText = (a, b, order = 'ASC') => {
    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (order === 'ASC') {
      return diff;
    }

    return -1 * diff;
  };

  async function getCorbans() {
    // showLoader();

    try {
      const response = await api.get(`/corbans/searchs`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.data) {
        setCorbans(response.data.sort((a, b) => sortByText(a, b, 'ASC')));
      }

      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível listar os corbans.');
    }
  }

  async function getSubs() {
    // showLoader();

    try {
      const response = await api.get(`/subestablishments/searchs`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.data) {
        setSubs(response.data.sort((a, b) => sortByText(a, b, 'ASC')));
      }

      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível listar os subs.');
    }
  }

  async function getManagers() {
    // showLoader();

    try {
      const response = await api.get(`users/user_managers`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.data) {
        setManagers(response.data.sort((a, b) => sortByText(a, b, 'ASC')));
      }

      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível listar os gerentes.');
    }
  }

  async function getStores() {
    showLoader();

    try {
      const response = await api.get(
        `stores?q[code_qista_not_null]=1&page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setStores(response.data);
      setNumberRegister(response.headers.total_count);

      const totalPages = response.headers.total_pages;

      setPages(totalPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/establishments',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }

  useEffect(() => {
    getStores();
  }, [offset, searchFilter]);

  useEffect(() => {
    if (
      user &&
      user.roles[0] &&
      (user.roles[0].name == 'master' ||
        user.roles[0].name == 'operational' ||
        user.roles[0].name == 'regional_manager' ||
        user.roles[0].name == 'master_corban')
    ) {
      getSubs();
    }

    if (
      user &&
      user.roles[0] &&
      (user.roles[0].name == 'master' ||
        user.roles[0].name == 'operational' ||
        user.roles[0].name == 'regional_manager')
    ) {
      getManagers();
      getCorbans();
    }
  }, [user]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  async function handlerOnClickSubmit() {
    let url = `stores/${storeId}/active`;

    if (selectedStores.length > 0) {
      url = `stores/${storeId}/update_all`;
    }
    try {
      const { data } = await api.put(
        url,
        { status: 'active', user_manager: managerStore },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setManagerStore(null);
      setStoreId(null);
      getStores();

      getManagers();
      setSelectedStores([]);
      toastSuccess('Atualizado com sucesso');
      handleToggleSubmit();
    } catch (e) {
      toastError('Ops, houve um erro ao atualizar a estabelecimento');
      console.error('Ops, houve um erro ao atualizar a estabelecimento');
    }
  }

  async function updateManagerStore() {
    let url = `stores/${storeId}/update_manager`;

    if (selectedStores.length > 0) {
      url = `stores/${storeId}/update_all_manager`;
    }

    try {
      const { data } = await api.put(
        url,
        { user_manager: managerStore },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setManagerStore(null);
      setStoreId(null);
      getStores();
      getManagers();
      setSelectedStores([]);
      handleToggleSubmit();
    } catch (e) {
      console.error('Ops, houve um erro ao atualizar a estabelecimento');
    }
  }

  async function updateSelectedsStore(store_id, status) {
    try {
      const { data } = await api.put(
        `stores/${store_id}/active_inactive_all`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setManagerStore(null);
      setStoreId(null);
      setSelectedStores([]);
      getStores();
      getManagers();
    } catch (e) {
      console.error('Ops, houve um erro ao atualizar a estabelecimento');
    }
  }

  async function disabledStore(store_id) {
    try {
      const { data } = await api.put(
        `stores/${store_id}`,
        { store: { status: 'disabled' } },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setManagerStore(null);
      setStoreId(null);
      getStores();
      getManagers();
    } catch (e) {
      console.error('Ops, houve um erro ao atualizar a estabelecimento');
    }
  }

  async function removeStore(store_id) {
    try {
      const { data } = await api.delete(`stores/${store_id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setManagerStore(null);
      setStoreId(null);
      getStores();
      getManagers();
    } catch (e) {
      console.log(e.response);
      toastError(e.response.data.error);
      console.error('Ops, houve um erro ao excluir a estabelecimento');
    }
  }

  async function handleOnClick() {
    if (cnpj.length > 0) {
      try {
        const { data } = await api.get(
          `stores?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cnpj}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setStores(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CNPJ.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(
        `stores?q[entity_cpf_cnpj_cont]=${searchText}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CNPJ');
    }
  }

  async function onChange(e) {
    setCnpj(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  function onSelect(val) {
    setCnpj(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item['entity'].cpf_cnpj} - {item['entity'].name}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item['entity'].cpf_cnpj}`;
  }

  async function handlerOnClickFilter() {
    showLoader();
    if (params.get('page')) {
      setOffset(1);
    }

    if (cnpj.length > 0) {
      try {
        const { data } = await api.get(
          `stores?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cnpj}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setStores(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';
        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    }

    let filterPath = '';

    if (filterStatus) {
      filterPath += `&q[status_eq]=${filterStatus}`;
    }

    if (filterName) {
      filterPath += `&q[entity_name_cont]=${filterName}`;
    }

    if (filterEmail) {
      filterPath += `&q[users_email_cont]=${filterEmail}`;
    }

    if (filterState) {
      filterPath += `&q[entity_address_state_eq]=${filterState}`;
    }

    if (filterCommission) {
      filterPath += `&q[entity_has_commission_eq]=${filterCommission}`;
    }

    if (filterManager) {
      filterPath += `&q[user_manager_id_eq]=${filterManager}`;
    }

    if (filterAuto) {
      filterPath += `&q[auto_eq]=${filterAuto}`;
    }

    if (filterCorban) {
      filterPath += `&q[corban_id_or_subestablishment_corban_id_eq]=${filterCorban}`;
    }

    if (filterSub) {
      filterPath += `&q[subestablishment_id_eq]=${filterSub}`;
    }

    if (filterSellers) {
      filterPath += `&q[sellers_id_null]=${filterSellers}`;
    }

    if (filterLegalRepresentative) {
      filterPath += `&legal_representative=${filterLegalRepresentative}`;
    }
    hideLoader();
    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    setFilterStatus('');
    setFilterName('');
    setFilterEmail('');
    setFilterState('');
    setFilterCommission('');
    setFilterManager('');
    setFilterCorban('');
    setFilterSub('');
    setFilterSellers('');
    setFilterLegalRepresentative('');
    setSearchFilter('');
    handleToggleFilter();
  }

  async function export_store_report() {
    showLoader();
    setStoreReportDisabled(true);
    try {
      const response = await api.get(`stores/store_export?${searchFilter}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });
      console.log(response.data);
      setExportStoreData(response.data);
      exportStoreRef?.current?.link.click();
    } catch (e) {}
    setStoreReportDisabled(false);
    hideLoader();
  }

  const selectAllStores = (checked) => {
    if (checked) {
      setSelectedStores(stores.map((item) => item.id));
    } else {
      setSelectedStores([]);
    }
  };

  const selectStore = (id, checked) => {
    if (checked) {
      setSelectedStores([...selectedStores, id]);
    } else {
      setSelectedStores(selectedStores.filter((item) => item != id));
    }
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <div className="text-right">
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban',
                    'master_subest',
                    'user_subest',
                    'user_corban_register',
                    'user_subest_register',
                  ]}
                >
                  <Link
                    className="btn btn-success mb-3 mt-2"
                    to="/admin/establishment/create"
                  >
                    <i className="fas fa-plus" /> Criar novo estabelecimento
                  </Link>
                </VisibilityByUserRoles>
              </div>
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Estabelecimentos</h3>
                      {/*  <Search
                        getItemValue={getItemValue}
                        autoCompleteData={autocompleteData}
                        renderItem={renderItem}
                        cpf_cnpj={cnpj}
                        onChange={onChange}
                        onSelect={onSelect}
                        handleOnClick={handleOnClick}
                        placeHolder="Buscar pelo CNPJ"
                      /> */}
                    </Col>
                    <Col lg="5" className="mt-3 mt-lg-0">
                      <>
                        <div className="d-flex justify-content-end h-100 align-items-end">
                          {selectedStores && selectedStores.length > 0 && (
                            <>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() =>
                                  handleToggleSubmit(
                                    selectedStores,
                                    null,
                                    'waiting'
                                  )
                                }
                              >
                                Ativar Estabelecimentos
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() =>
                                  updateSelectedsStore(
                                    selectedStores,
                                    'disabled'
                                  )
                                }
                              >
                                Desativar Estabelecimentos
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  handleToggleSubmit(selectedStores, null)
                                }
                              >
                                Alterar Gerente Comercial
                              </button>
                            </>
                          )}
                          {
                            <>
                              <button
                                disabled={storeReportDisabled}
                                className="btn btn-primary"
                                onClick={export_store_report}
                              >
                                Exportar Estabelecimentos
                              </button>
                              <CSVLink
                                data={exportStoreData}
                                filename={'store_report.csv'}
                                separator={';'}
                                ref={exportStoreRef}
                              ></CSVLink>
                            </>
                          }
                          {
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleToggleFilter}
                            >
                              <i className="fas fa-filter" /> Filtrar
                            </button>
                          }
                        </div>
                      </>
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {numberRegister > 0 ? (
                    <div className="text-white">
                      Registros encontrados {LIMIT * (offset - 1) + 1} -{' '}
                      {numberRegister <= LIMIT
                        ? numberRegister
                        : LIMIT * offset}{' '}
                      de {numberRegister}
                    </div>
                  ) : (
                    <div className="text-white">Nenhum registro encontrado</div>
                  )}
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <VisibilityByUserRoles roles={['master', 'operational']}>
                        <th
                          scope="col"
                          className="align-items-center d-flex justify-content-center flex-column"
                        >
                          <div className="mb-1">Selecionar Tudo</div>
                          <div className="mb-1">
                            <Input
                              type="checkbox"
                              className="form-check-custom"
                              name="select_checkbox"
                              defaultChecked={false}
                              checked={selectedStores.length == stores.length}
                              onChange={(e) =>
                                selectAllStores(e.target.checked)
                              }
                            />{' '}
                          </div>
                        </th>
                      </VisibilityByUserRoles>
                      <th scope="col">CPF/CNPJ</th>
                      <th scope="col">Nome</th>
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <th scope="col">Gerente</th>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                        ]}
                      >
                        <th scope="col">Corban</th>
                        <th scope="col">Subestabelecimento</th>
                      </VisibilityByUserRoles>
                      <th scope="col">Status</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stores.map((store) => (
                      <tr key={store.id}>
                        <VisibilityByUserRoles
                          roles={['master', 'operational']}
                        >
                          <td className="align-items-center d-flex justify-content-center">
                            <div className="mt-3">
                              <Input
                                type="checkbox"
                                name="select_checkbox"
                                defaultChecked={false}
                                checked={selectedStores.includes(store.id)}
                                className="form-check-custom"
                                onChange={(e) =>
                                  selectStore(store.id, e.target.checked)
                                }
                              />{' '}
                            </div>
                          </td>
                        </VisibilityByUserRoles>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {store.entity.cpf_cnpj.replace(
                                  /^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/,
                                  '$1.$2.$3/$4-$5'
                                )}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{store.entity.name}</td>
                        <VisibilityByUserRoles
                          roles={['master', 'operational', 'regional_manager']}
                        >
                          <td>{store.user_manager_name}</td>
                        </VisibilityByUserRoles>
                        <VisibilityByUserRoles
                          roles={[
                            'master',
                            'operational',
                            'commercial_manager',
                            'regional_manager',
                          ]}
                        >
                          <td>{store.corban_name}</td>
                          <td>{store.sub_name}</td>
                        </VisibilityByUserRoles>
                        <td>{store.status_i18n}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {(store.status == 'waiting' ||
                                store.status == 'disabled') && (
                                <VisibilityByUserRoles
                                  roles={[
                                    'master',
                                    'operational',
                                    'regional_manager',
                                  ]}
                                >
                                  <DropdownItem
                                    onClick={() =>
                                      handleToggleSubmit(
                                        store.id,
                                        store.user_manager
                                          ? store.user_manager.id
                                          : null,
                                        store.status
                                      )
                                    }
                                  >
                                    <span>
                                      <i className="fas fa-check" /> Aprovar
                                      estabelecimento
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                              )}

                              {(store.status == 'active' ||
                                store.status == 'disabled') && (
                                <VisibilityByUserRoles
                                  roles={[
                                    'master',
                                    'operational',
                                    'regional_manager',
                                  ]}
                                >
                                  <DropdownItem
                                    onClick={() =>
                                      handleToggleSubmit(
                                        store.id,
                                        store.user_manager
                                          ? store.user_manager.id
                                          : null
                                      )
                                    }
                                  >
                                    <span>
                                      <i className="fas fa-edit" /> Alterar
                                      Gerente Comercial
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                              )}

                              {(store.status == 'waiting' ||
                                store.status == 'active') && (
                                <VisibilityByUserRoles
                                  roles={[
                                    'master',
                                    'operational',
                                    'regional_manager',
                                  ]}
                                >
                                  <DropdownItem
                                    onClick={() => disabledStore(store.id)}
                                  >
                                    <span>
                                      <i className="fas fa-times" /> Desativar
                                      estabelecimento
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                              )}
                              <Link
                                to={`/admin/stores/${store.id}/detail`}
                                target="_blank"
                                className="dropdown-item"
                              >
                                <span>
                                  <i className="fas fa-info-circle" /> Detalhes
                                  da estabelecimento
                                </span>
                              </Link>

                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'regional_manager',
                                  'operational',
                                ]}
                              >
                                <DropdownItem
                                  onClick={() => removeStore(store.id)}
                                >
                                  <span>
                                    <i className="fas fa-trash" /> Excluir
                                    estabelecimento
                                  </span>
                                </DropdownItem>
                              </VisibilityByUserRoles>
                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'operational',
                                  'regional_manager',
                                ]}
                              >
                                <Link
                                  to={`/admin/contracts/${store.id}/logs`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-file" /> Log de
                                    Alterações
                                  </span>
                                </Link>
                              </VisibilityByUserRoles>
                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'operational',
                                  'regional_manager',
                                  'master_corban',
                                  'user_corban_register',
                                  'master_subest',
                                  'user_subest_register',
                                ]}
                              >
                                <Link
                                  to={`/admin/establishments/${store.id}`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-edit" /> Editar
                                  </span>
                                </Link>
                              </VisibilityByUserRoles>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>

                        {/* <td>
                          <Link
                            to={`/admin/contracts/store/${store.id}`}
                            className="btn btn-warning btn-block"
                          >
                            Contratos
                          </Link>
                        </td> */}

                        {/* <td>
                          <VisibilityEditStore
                          store_id={store.id}/>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 bg-transparent">
                  <Row>
                    <Col
                      lg={{ size: 6, offset: 6 }}
                      className="d-flex justify-content-end"
                    >
                      <Pagination
                        current={offset}
                        total={Number(pages)}
                        onPageChange={setOffset}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="cnpj">
                    Cnpj
                  </label>
                  <InputMask
                    mask="99.999.999/9999-99"
                    type="text"
                    className="form-control"
                    id="cnpj"
                    name="cnpj"
                    placeholder="Digite o CNPJ"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setFilterStatus(e.target.value)}
                    value={filterStatus}
                  >
                    <option value="">Todos</option>
                    <option value="0">Ativo</option>
                    <option value="1">Inativo</option>
                    <option value="2">Aguardando Ativação</option>
                  </CustomInput>
                </FormGroup>
                {/* <FormGroup>
                  <label className="form-control-label" htmlFor="legal_representative">
                    Representante Legal
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="legal_representative"
                    name="legal_representative"
                    placeholder="Digite o Nome do Representante Legal"
                    value={filterLegalRepresentative}
                    onChange={(e) => setFilterLegalRepresentative(e.target.value)}
                  />
                </FormGroup> */}
                <VisibilityByUserRoles
                  roles={['master', 'operational', 'regional_manager']}
                >
                  <>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="commercial_manager"
                      >
                        Gerente Comercial
                      </Label>
                      <CustomInput
                        type="select"
                        id="commercial_manager"
                        name="commercial_manager"
                        onChange={(e) => setFilterManager(e.target.value)}
                        value={filterManager}
                      >
                        <option value="">Selecionar</option>
                        {managers.map((manager) => (
                          <option value={manager.id}>{manager.name}</option>
                        ))}
                      </CustomInput>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="auto">
                        Auto Contratação?
                      </Label>
                      <CustomInput
                        type="select"
                        id="auto"
                        name="auto"
                        onChange={(e) => setFilterAuto(e.target.value)}
                        value={filterAuto}
                      >
                        <option value="">Selecionar</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </CustomInput>
                    </FormGroup>
                  </>
                </VisibilityByUserRoles>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="auto">
                    Possui Vendedor?
                  </Label>
                  <CustomInput
                    type="select"
                    id="auto"
                    name="auto"
                    onChange={(e) => setFilterSellers(e.target.value)}
                    value={filterSellers}
                  >
                    <option value="">Selecionar</option>
                    <option value="false">Sim</option>
                    <option value="true">Não</option>
                  </CustomInput>
                </FormGroup>
                <VisibilityByUserRoles
                  roles={['master', 'operational', 'regional_manager']}
                >
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="corban">
                      Corban
                    </Label>
                    <CustomInput
                      type="select"
                      id="corban"
                      name="corban"
                      onChange={(e) => setFilterCorban(e.target.value)}
                      value={filterCorban}
                    >
                      <option value="">Selecionar</option>
                      {corbans.map((corban) => (
                        <option value={corban.id}>{corban.name}</option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                  ]}
                >
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="subs">
                      Subestabelecimento
                    </Label>
                    <CustomInput
                      type="select"
                      id="subs"
                      name="subs"
                      onChange={(e) => setFilterSub(e.target.value)}
                      value={filterSub}
                    >
                      <option value="">Selecionar</option>
                      {subs.map((sub) => (
                        <option value={sub.id}>{sub.name}</option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome Fantasia
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome Fantasia"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Email"
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="state">
                    Estado
                  </Label>
                  <CustomInput
                    type="select"
                    id="state"
                    name="state"
                    onChange={(e) => setFilterState(e.target.value)}
                    value={filterState}
                  >
                    {States.map((state) => (
                      <option value={state.value}>{state.name}</option>
                    ))}
                  </CustomInput>
                </FormGroup>
                {/* <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="commission"
                  >
                    Comissão Ativa
                  </Label>
                  <CustomInput
                    type="select"
                    id="commission"
                    name="commission"
                    onChange={(e) => setFilterCommission(e.target.value)}
                    value={filterCommission}
                  >
                    <option value="">Selecione</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </CustomInput>
                </FormGroup> */}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={submitModal}
        toggle={handleToggleSubmit}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleSubmit}>
          Selecione o Gerente Comercial
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Selecionar Gerente Comercial
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setManagerStore(e.target.value)}
                    value={managerStore}
                  >
                    <option value="">Selecione o gerente</option>
                    {managers.map((manager) => (
                      <option value={manager.id}>{manager.name}</option>
                    ))}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleSubmit}>
            Fechar
          </Button>
          {storeStatus &&
          (storeStatus == 'waiting' || storeStatus == 'disabled') ? (
            <Button color="success" onClick={handlerOnClickSubmit}>
              Ativar
            </Button>
          ) : (
            <Button color="success" onClick={updateManagerStore}>
              Enviar
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Establishment;
