import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Button,
  Badge,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Form,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import Pagination from 'react-responsive-pagination';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import swal from 'sweetalert';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';
import Header from '../../components/Headers/Header';
import Search from '../../components/Search';
import Select from 'react-select';
import token from '../../util/setAuthToken';
import { AuthContext } from '../../context/AuthContext';
import { VisibilityByUserRoles } from '../../util/visibility';
import UpdateCustomerForm from '../../components/UpdateCustomerForm';
import './style.css';
import { useLocation } from 'react-router-dom';
import UpdateContractNumber from 'components/UpdateContractNumber';
import UpdateBankAccount from 'pages/UpdateBankAccount';
import DocumentUploadContract from 'components/DocumentUploadContract';
import DocumentUploadCustomerFgts from 'components/DocumentUploadCustomerFgts';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import DocumentUploadCustomerQista from 'components/DocumentUploadCustomerQista';
import { HandleBgPipelinesColor } from '../../util/visibility';
import { isValid, parseISO } from 'date-fns';

const LIMIT = 20;

let arrayReport = [];
const ContractsFgts = (props) => {
  const params = new URLSearchParams(props.location.search);
  const query = new URLSearchParams(useLocation().search);
  const kindSearch = query.get('isCdc') ? 2 : 1;
  const { user } = useContext(AuthContext);
  const { register, handleSubmit, errors, setError } = useForm();
  const [representatives, setRepresentatives] = useState([]);
  const [copied, setCopied] = useState(false);
  const [totalPageReport, setTotalPageReport] = useState(1);
  const [requestFinish, setRequestFinish] = useState(true);
  const [pageReport, setPageReport] = useState(1);
  const [dataReport, setDataReport] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [corbans, setCorbans] = useState([]);
  const [subs, setSubs] = useState([]);
  const [sort, setSort] = useState('desc');
  const [kind, setKind] = useState('OCR');
  const [statusContractSelected, setStatusContractSelected] = useState(null);
  const [filterBlack, setFilterBlack] = useState(null);
  const [bankKind, setBankKind] = useState(null);
  const [sortPaymentDate, setSortPaymentDate] = useState('desc');
  const [sortCommissionPaymentDate, setSortCommissionPaymentDate] =
    useState('desc');
  const [stores, setStores] = useState([]);
  const [sellers, setSellers] = useState([]);

  const [documentRg, setDocumentRg] = useState([]);
  const [documentRgPersist, setDocumentRgPersist] = useState([]);
  const [optionsAccount, setOptionsAccount] = useState([
    {
      value: 'Conta Corrente',
      label: 'Conta Corrente',
    },
    {
      value: 'Poupança',
      label: 'Poupança',
    },
  ]);
  const [documentFace, setDocumentFace] = useState([]);
  const [documentFacePersist, setDocumentFacePersist] = useState([]);
  const [disabledFilter, setDisabledFilter] = useState(false);
  const [getAllCustomers, setGetAllCustomers] = useState(() => {
    if (params.get('cpf')) {
      return true;
    }
    return false;
  });
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });

  const [pages, setPages] = useState(0);
  const [cpf, setCpf] = useState(() => {
    if (params.get('cpf')) {
      return params.get('cpf');
    }

    return '';
  });
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const [autocompleteDataIdContract, setAutocompleteDataIdContract] = useState(
    []
  );
  const [paymentInformation, setPaymentInformation] = useState({
    idcontract: 0,
    contractNumber: '',
    name: '',
    payments: [],
  });
  const [pipeline, setPipeline] = useState({
    status: '',
    reason: '',
    contract_id: '',
  });
  const [numberContract, setNumberContract] = useState({
    reason: '',
  });
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [pipelineContracts, setPipelineContracts] = useState([]);
  const [changesCustomers, setChangesCustomers] = useState([]);
  const [changeCustomerIndex, setChangeCustomerIndex] = useState(false);
  const [pipelineIndex, setPipelineIndexModal] = useState(false);
  const [openReanalysisModal, setOpenReanalysisModal] = useState(false);
  const [contractId, setContractId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [bankAccountModal, setBankAccountModal] = useState(false);

  const [reportModal, setReportModal] = useState(false);
  const [emailReport, setEmailReport] = useState('');

  const [customerDataModal, setCustomerDataModal] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [customerAux, setCustomerAux] = useState();
  const [contractData, setContractData] = useState(null);

  const [customerDocumentModal, setCustomerDocumentModal] = useState(false);
  const [customerDocumentModalQista, setCustomerDocumentModalQista] =
    useState(false);
  const [customerDocument, setCustomerDocument] = useState(null);
  const [customerDocumentQista, setCustomerDocumentQista] = useState(null);

  const [modal, setModal] = useState(false);
  const [contractNumberModal, setContractNumberModal] = useState(false);
  const [pipelineModal, setPipelineModal] = useState(false);
  const [bankDataModal, setBankDataModal] = useState(false);
  const [reanalysisModal, setReanalysisModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [modalDate, setModalDate] = useState(false);
  const [pipeStatus, setPipeStatus] = useState('');
  const [pipeFilterStatus, setPipeFilterStatus] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterCorban, setFilterCorban] = useState('');
  const [filterCorbanType, setFilterCorbanType] = useState('');
  const [filterSub, setFilterSub] = useState('');
  const [filterStore, setFilterStore] = useState('');
  const [filterSeller, setFilterSeller] = useState('');
  const [filterContract, setFilterContract] = useState('');
  const [filterIdContract, setFilterIdContract] = useState('');
  const [filterFrom, setFilterFrom] = useState('');
  const [filterEnd, setFilterEnd] = useState('');
  const [filterManager, setFilterManager] = useState('');
  const [filterRegionalManager, setFilterRegionalManager] = useState('');
  const [url, setUrl] = useState(`${window.location.href.split('?')[1]}`);

  const [filterDateType, setFilterDateType] = useState('created_at_custom');
  const [maxDate, setMaxDate] = useState('');
  const [minDate, setMinDate] = useState('');

  const [minFilterDate, setMinFilterDate] = useState(
    `${new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ).getFullYear()}-${
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getMonth() + 1
    }-${new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ).getDate()}`
  );
  const [searchFilter, setSearchFilter] = useState(
    `&q[${filterDateType}_gteq]=${minFilterDate}`
  );
  const today = new Date();
  const [dateIsValid, setDateIsValid] = useState(undefined);
  const [maxFilterDate, setMaxFilterDate] = useState(() => {
    return `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  });
  const [minRegisterDate, setMinRegisterDate] = useState('');
  const [maxRegisterDate, setMaxRegisterDate] = useState('');
  const [minPaymentDate, setMinPaymentDate] = useState('');
  const [maxPaymentDate, setMaxPaymentDate] = useState('');
  const [minCommissionDate, setMinCommissionDate] = useState('');
  const [maxCommissionDate, setMaxCommissionDate] = useState('');
  const [regionalManagers, setRegionalManagers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalCommissionValue, setTotalCommissionValue] = useState(0);
  const [totalContractValue, setTotalContractValue] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [operateSimulator, setOperateSimulator] = useState(0);
  const [documentPending, setDocumentPending] = useState(0);
  const [sendLink, setSendLink] = useState(0);
  const [paymentReturned, setPaymentReturned] = useState(0);
  const [waitingDocumentosAnalitcs, setWaitingDocumentosAnalitcs] = useState(0);
  const [waitingSignature, setWaitingSignature] = useState(0);
  const [contractSigned, setContractSigned] = useState(0);
  const [contractPaid, setContractPaid] = useState(0);
  const [totalValuePaid, setValuePaid] = useState(0);
  const [totalValueCanceled, setValueCanceled] = useState(0);
  const [totalValue, setValueTotal] = useState(0);
  const [commissionPaid, setCommissionPaid] = useState(0);
  const [canceled, setCanceled] = useState(0);
  const [refused, setRefused] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [reanalysis, setReanalysis] = useState(0);
  const [pendingLife, setPendingLife] = useState(0);
  const [riskAnalysis, setRiskAnalysis] = useState(0);
  const [numberRegister, setNumberRegister] = useState(0);
  const [exportList, setExportList] = useState([]);
  const [analyticalReport, setAnalyticalReport] = useState([]);
  const [syntheticReport, setSyntheticReport] = useState([]);
  const [exportCompleteList, setExportCompleteList] = useState([]);
  const [filterDashboard, setFilterDashboard] = useState([]);
  const [applyDashFilter, setApplyDashFilter] = useState(false);
  const [csvDisabled, setCsvDisabled] = useState(false);
  const [completeReportDisabled, setCompleteReportDisabled] = useState(false);
  const [completeReportFundDisabled, setCompleteReportFundDisabled] =
    useState(false);
  const [syntheticReportDisabled, setSyntheticReportDisabled] = useState(false);
  const [banks, setBanks] = useState([]);
  const csvLinkRef = useRef(null);
  const completeReportLinkRef = useRef(completeReportLinkRef);
  const completeReportFundLinkRef = useRef(completeReportFundLinkRef);
  const analyticalLinkRef = useRef(analyticalLinkRef);
  const syntheticLinkRef = useRef(syntheticLinkRef);
  const [disabledBankData, setDisabledBankData] = useState(false);
  const [indexCustomerSelected, setIndexCustomerSelected] = useState();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  async function getAllCustomersIdContract() {
    return await api.get(
      `contracts?sort=created_at%20desc&q[kind_eq]=${kindSearch}&page=1&q[id_eq]=${filterIdContract}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
  }

  async function getAllCustomersByCpf() {
    return await api.get(
      `contracts?q[customer_entity_cpf_cnpj_eq]=${cpf.replace(
        /[.-]/g,
        ''
      )}&page=${offset}&limit=${LIMIT}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
  }

  async function getRegionalManagers() {
    // showLoader();

    try {
      const response = await api.get(
        `users/managers?page=${offset}&per_page=${LIMIT}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (response.data) {
        setRegionalManagers(
          response.data.sort((a, b) => sortByText(a, b, 'ASC'))
        );
      }

      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }

  async function getManagers() {
    // showLoader();

    try {
      const response = await api.get(
        `users/user_managers?page=${offset}&per_page=${LIMIT}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (response.data) {
        setManagers(response.data.sort((a, b) => sortByText(a, b, 'ASC')));
      }

      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }

  const sortByText = (a, b, order = 'ASC') => {
    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (order === 'ASC') {
      return diff;
    }

    return -1 * diff;
  };

  async function getCorban() {
    try {
      const { data } = await api.get('corbans/searchs', {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (data) {
        setCorbans(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os Corbans');
    }
  }

  async function getSub() {
    try {
      const { data } = await api.get('subestablishments/searchs', {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      if (data) {
        setSubs(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os Subestabelecimentos');
    }
  }

  async function getStore() {
    try {
      const { data } = await api.get('stores/searchs', {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (data) {
        setStores(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os Lojas');
    }
  }

  async function getSellers(store_id = null) {
    try {
      const { data } = await api.get(
        `sellers/searchs?${store_id ? `q[store_id_eq]=${store_id}` : ''}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (data) {
        setSellers(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os vendedores');
    }
  }

  async function sortCommissionPaymentUpdatedAt() {
    setSortPaymentDate('desc');
    setSort('desc');
    let sortParam = 'sort=commission_payment_date%20desc';
    if (sortCommissionPaymentDate == 'desc') {
      setSortCommissionPaymentDate('asc');
      sortParam = 'sort=commission_payment_date%20asc';
    } else {
      setSortCommissionPaymentDate('desc');
    }

    getCustomers(sortParam);
  }

  async function sortPaymentUpdatedAt() {
    setSortCommissionPaymentDate('desc');
    setSort('desc');
    let sortParam = 'sort=payment_date%20desc';
    if (sortPaymentDate == 'desc') {
      setSortPaymentDate('asc');
      sortParam = 'sort=payment_date%20asc';
    } else {
      setSortPaymentDate('desc');
    }

    getCustomers(sortParam);
  }

  async function sortUpdatedAt() {
    setSortCommissionPaymentDate('desc');
    setSortPaymentDate('desc');
    let sortParam = 'sort=created_at%20desc';
    if (sort == 'desc') {
      setSort('asc');
      sortParam = 'sort=created_at%20asc';
    } else {
      setSort('desc');
    }

    getCustomers(sortParam);
  }

  async function getCustomer(id) {
    try {
      let { data } = await api.get(`customers/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setDocumentRgPersist(data.documents.filter((item) => item.kind == 'rg'));
      hideLoader();
    } catch (e) {
      console.log(e);
      hideLoader();
    }
  }

  async function getCustomers(sortParam = 'sort=created_at%20desc') {
    showLoader();

    if (url != '' && url != undefined && url != 'undefined') {
      setSearchFilter(url);
      setUrl('');
    }

    try {
      let response = '';

      if (params.get('cpf')) {
        response = await getAllCustomersByCpf();
      } else {
        let filterSearch = searchFilter;
        if (params.get('customer_id')) {
          filterSearch += '&q[customer_id_eq]=' + params.get('customer_id');
        }
        response = await api.get(
          `contracts?${sortParam}&q[kind_eq]=${kindSearch}&page=${offset}&per_page=${LIMIT}${filterSearch}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
      }
      if (searchFilter != '') {
        setApplyDashFilter(true);
        setFilterDashboard(response.data.contracts.map((value) => value.id));
      }

      const totalPages = response.headers.total_pages;

      setPages(totalPages);
      setNumberRegister(response.headers.total_count);
      setCustomers(response.data.contracts);
      setPipeline(response.data.contracts);

      if (offset > 1) {
        let search = '';
        if (params.get('cpf')) {
          search = `?q[customer_entity_cpf_cnpj_eq]=${cpf}&page=${offset}`;
        } else if (params.get('filter')) {
          search = `?filter=${searchFilter}&page=${offset}`;
        } else {
          search = `?page=${offset}`;
        }

        props.history.push({
          pathname: '/admin/fgts-contracts',
          search,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro na busca dos clientes, por favor tente novamente!'
      );
    }
  }

  useEffect(() => {
    if (!disabledFilter) {
      if (!cpf) {
        getCustomers();
        dashboard();
      }
    }
  }, [offset, searchFilter]);

  useEffect(() => {
    handlerOnClickFilter(false, false);
  }, [pipeStatus]);

  useEffect(() => {
    getBanks();
  }, [bankDataModal]);

  async function resendLinkClickSign(contract_id) {
    showLoader();

    try {
      const response = await api.post(
        `notifications/${contract_id}/resend_clicksign_link`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const { data } = response;
      getCustomers();
      toastSuccess('Reenviado com sucesso');

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro no reenvio do link, por favor tente novamente!'
      );
    }
  }

  async function handleResendLink(idnotification) {
    showLoader();

    try {
      const response = await api.post(
        `notifications/${idnotification}/resend_notification`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const { data } = response;
      getCustomers();
      toastSuccess(data.message);

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro no reenvio do link, por favor tente novamente!'
      );
    }
  }

  async function reactivatePaymentLink(idnotification) {
    showLoader();

    try {
      const response = await api.post(
        `notifications/${idnotification}/reactivate_notification`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const { data } = response;
      getCustomers();
      toastSuccess(data.message);

      hideLoader();
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro no reenvio do link, por favor tente novamente!'
      );
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(
        `contracts/customers?q[entity_cpf_cnpj_cont]=${searchText}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CPF');
    }
  }

  async function retrieveDataAsynchronouslyIdContract(searchText) {
    try {
      const { data } = await api.get(
        `contracts?sort=created_at%20desc&q[kind_eq]=${kindSearch}&page=1&per_page=20&q[contract_id_eq]=${filterIdContract}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setAutocompleteDataIdContract(data.contracts);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo Id do contrato');
    }
  }

  async function onChangeIdContract(e) {
    setFilterIdContract(e.target.value);
    if (e.target.value.length >= 5) {
      await retrieveDataAsynchronouslyIdContract(e.target.value);
    }
  }

  function onSelectIdContract(val) {
    setFilterIdContract(val);
  }

  async function onChange(e) {
    setCpf(e.target.value);
    if (e.target.value.length >= 4) {
      await retrieveDataAsynchronously(e.target.value);
    }
  }

  function onSelect(val) {
    setCpf(val);
  }

  function renderItem(item) {
    return (
      <div
        onClick={() => {
          setCpf(item.cpf_cnpj);
          setAutoCompleteData([]);
        }}
      >
        {item.cpf_cnpj}
      </div>
    );
  }

  function renderItemIdContract(item) {
    return (
      <div
        style={{ paddingLeft: 12 }}
        onClick={() => {
          setFilterIdContract(item.id);
          setAutocompleteDataIdContract([]);
        }}
      >
        {item.id}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item.id}`;
  }

  function cancelTransation(idpayment) {
    swal({
      title: 'Atenção',
      text: 'Esta operação é irreversível. Seu cliente receberá um estorno no Cartão. Confirma o cancelamento?',
      icon: 'warning',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        showLoader();
        try {
          const { data, status } = await api.post(
            `payments/${idpayment}/cancel_payment`,
            {},
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token()}`,
              },
            }
          );

          hideLoader();

          if (status === 204) {
            toastError(
              'O pagamento não pode ser cancelado, pois está fora do prazo, solicite o reembolso pelo formulário abaixo.'
            );
            props.history.push(`/admin/contracts/cancel/${idpayment}`);
          } else {
            toastSuccess(data.message);
            const newCustomers = customers.map((customer) => {
              if (customer.idpayment === idpayment) {
                return {
                  ...customer,
                  bg: 'bg-danger',
                  status: 'Cancelado',
                  statusCode: 7,
                };
              }

              return customer;
            });

            setCustomers(newCustomers);

            getCustomers();
          }
        } catch (e) {
          hideLoader();
          let message =
            'Ops, não foi possível cancelar o pagamento, por favor tente novamente mais tarde';

          if (e.response.data) {
            message = e.response.data.message;
          }

          toastError(message);
        }
      }
    });
  }

  async function dashboard() {
    setRequestFinish(false);
    let token = '';
    if (localStorage.getItem('@UGate:token')) {
      token = localStorage.getItem('@UGate:token');
    }

    try {
      const { data } = await api.get(
        `dashboard?q[kind_eq]=${kindSearch}&${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(data);
      setTotalCustomers(data.total_customers);
      setTotalCommissionValue(data.totalCommissionValue);
      setTotalContractValue(data.totalContractValue);
      setTotalPayments(data.total_payments);
      setOperateSimulator(data.totaloperateSimulator);
      setDocumentPending(data.totaldocumentPending);
      setTotalPending(data.totalpending);
      setSendLink(data.totalSendLink);
      setPaymentReturned(data.totalcontractPaymentReturned);
      setWaitingDocumentosAnalitcs(data.totalwaitingDocumentosAnalitcs);
      setWaitingSignature(data.totalwaitingSignature);
      setContractSigned(data.totalcontractSigned);
      setContractPaid(data.totalcontractPaid);
      setCommissionPaid(data.totalcommissionPaid);
      setCanceled(data.totalcanceled);
      setRefused(data.totalrefused);
      setReanalysis(data.totalreanalysis);
      setPendingLife(data.totalPendingLife);
      setRiskAnalysis(data.totalRiskAnalysis);
      setValuePaid(data.totalValuePaid);
      setValueCanceled(data.totalValueCanceled);
      setValueTotal(data.totalValue);

      setRequestFinish(true);
      if (applyDashFilter) {
        setApplyDashFilter(false);
        setFilterDashboard([]);
      }
    } catch (e) {
      setRequestFinish(true);
      toastError(
        'Ops, tivemos um erro na busca dos dados do dashboard, por favor tente novamente'
      );
    }
  }

  function cancelContract(idcontract) {
    swal({
      title: 'Atenção',
      text: 'Esta operação é irreversível. Confirma o cancelamento?',
      icon: 'warning',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        showLoader();
        try {
          const userParams = user && user.id ? user.id : null;
          const { data, status } = await api.put(
            `contracts/${idcontract}/cancel_contract?user_id=${userParams}`,
            JSON.stringify({}),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token()}`,
              },
            }
          );

          hideLoader();

          toastSuccess(data.message);
          getCustomers();
        } catch (e) {
          hideLoader();

          let message = 'Algo deu errado ao cancelar o contrato.';

          if (e.response.data) {
            message = e.response.data.message;
          }

          toastError(message);
        }
      }
    });
  }

  function checkRefund(idcontract) {
    swal({
      title: 'Atenção',
      text: 'Esta operação é irreversível. Confirma o reembolso?',
      icon: 'warning',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        showLoader();
        try {
          const { data, status } = await api.put(
            `contracts/${idcontract}/refund_contract`,
            JSON.stringify({}),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token()}`,
              },
            }
          );

          hideLoader();

          toastSuccess(data.message);
          getCustomers();
        } catch (e) {
          hideLoader();

          let message = 'Algo deu errado ao cancelar o contrato.';

          if (e.response.data) {
            message = e.response.data.message;
          }

          toastError(message);
        }
      }
    });
  }

  async function handleOnClick() {
    if (cpf.length > 0) {
      try {
        showLoader();
        const { data, headers } = await getAllCustomersByCpf();

        const totalPages = headers.total_pages;

        const arrayPages = [];
        for (let i = 1; i <= totalPages; i++) {
          arrayPages.push(i);
        }

        setPages(arrayPages);
        setCustomers(data.contracts);
        setGetAllCustomers(true);
        hideLoader();
        props.history.push({
          pathname: '/admin/fgts-contracts',
          search: `?cpf=${cpf}`,
        });
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CPF.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CPF.');
    }
  }

  async function handleOnClickIdContract() {
    showLoader();
    if (filterIdContract.length > 0) {
      try {
        const { data, headers } = await getAllCustomersIdContract();

        const totalPages = headers.total_pages;

        const arrayPages = [];
        for (let i = 1; i <= totalPages; i++) {
          arrayPages.push(i);
        }

        setPages(arrayPages);
        setCustomers(data.contracts);
        setGetAllCustomers(true);
        setPages(totalPages);
        setNumberRegister(headers.total_count);

        props.history.push({
          pathname: '/admin/fgts-contracts',
          search: `?cpf=${cpf}`,
        });
        hideLoader();
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CPF.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
        hideLoader();
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CPF.');
      hideLoader();
    }
    hideLoader();
  }

  function handleOnPipeline(e) {
    setPipeline({ ...pipeline, [e.target.name]: e.target.value });
  }

  function handleOnReanalysis(e) {
    setReanalysisModal({ ...reanalysisModal, [e.target.name]: e.target.value });
  }

  function handleOnContractNumber(e) {
    setNumberContract({ ...numberContract, [e.target.name]: e.target.value });
  }

  function handleOnChangeBank(e) {
    setCustomerAux((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        name_bank: e.value,
      },
    }));
  }

  function handleOnChangeTypeAccount(e) {
    setCustomerAux((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        account_kind: e.value,
      },
    }));
  }

  async function handleGetAllCustomers() {
    setDisabledFilter(false);
    showLoader();
    try {
      const { data, headers } = await api.get(
        `contracts?page=1&limit=${LIMIT}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      const totalPages = headers.total_pages;

      const arrayPages = [];
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i);
      }

      setPages(arrayPages);
      setCustomers(data.contracts);
      setGetAllCustomers(false);
      setCpf('');
      setPages(totalPages);
      setNumberRegister(headers.total_count);

      params.delete('page');
      params.delete('cpf');
      props.history.replace({
        search: params.toString(),
      });
    } catch (e) {
      hideLoader();
      toastError(
        'Ops, tivemos um erro na busca dos clientes, por favor tente novamente!'
      );
    }

    hideLoader();
  }

  const handleToggle = () => setModal(!modal);

  const handleToggleFilter = () => {
    if (!filterModal) {
      // if(representatives && representatives.length <= 0){
      //   getRepresentative();
      // }
      if (managers && managers.length <= 0) {
        getManagers();
      }
      if (regionalManagers && regionalManagers.length <= 0) {
        getRegionalManagers();
      }
      if (corbans && corbans.length <= 0) {
        getCorban();
      }
      if (subs && subs.length <= 0) {
        getSub();
      }
      if (stores && stores.length <= 0) {
        getStore();
      }
      if (sellers && sellers.length <= 0) {
        getSellers();
      }
    }
    setFilterModal(!filterModal);
  };

  const handleToggleCustomerData = ({ idcontract, idcustomer }) => {
    setCustomerData(idcustomer);
    setContractData(idcontract);
    setCustomerDataModal(true);
  };

  const handleToggleCustomerDocument = ({
    idcontract,
    idcustomer,
    reason_pending,
  }) => {
    getCustomer(idcustomer);
    setCustomerDocument(idcustomer);
    setContractData(idcontract);
    setCustomerDocumentModal(true);
    setKind(reason_pending);
    console.log(reason_pending);
  };

  const handleToggleCustomerDocumentQista = ({ idcontract }) => {
    setCustomerDocument(idcontract);
    setContractData(idcontract);
    setCustomerDocumentModalQista(true);
  };

  const handleToggleBankData = ({ idcontract }) => {
    /* setPipeline({ ...pipeline, contract_id: idcontract }); */
    setBankDataModal(!bankDataModal);
  };

  const handleTogglePipeline = ({ idcontract, status = 'send_link' }) => {
    setPipeline({ ...pipeline, contract_id: idcontract });
    setPipelineModal(!pipelineModal);
    setStatusContractSelected(status);
  };

  const handleToggleReportModal = () => {
    setReportModal(!reportModal);
  };

  const handleToggleChangeCustomer = ({ idcontract }) => {
    setChangesCustomers([]);
    setChangeCustomerIndex(!changeCustomerIndex);
  };

  const handleToggleChangeCustomerIndex = async ({ idcontract }) => {
    setChangeCustomerIndex(!changeCustomerIndex);
    showLoader();
    try {
      const { data } = await api.get(`customers/${idcontract}/logs`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();
      setChangesCustomers(data);
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo deu errado ao buscar os pagamentos do contrato.';
      }

      hideLoader();
      toastError(message);
    }
  };

  const handleToggleIndexPipeline = async ({ idcontract }) => {
    try {
      const { data } = await api.get(`pipelines?contract_id=${idcontract}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      console.log(data);

      setPipelineContracts(data);
      setPipelineIndexModal(!pipelineIndex);
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo deu errado ao buscar os pagamentos do contrato.';
      }

      hideLoader();
      toastError(message);
    }
  };

  const handleClickToggle = async ({ idcontract, contractNumber, name }) => {
    try {
      const { data } = await api.get(`contracts/${idcontract}/payments`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setPaymentInformation({
        idcontract: 0,
        contractNumber: '',
        name: '',
        payments: [],
      });

      setModal(!modal);
      setPaymentInformation({
        contractNumber,
        name,
        payments: data,
      });
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo deu errado ao buscar os pagamentos do contrato.';
      }

      hideLoader();
      toastError(message);
    }
  };

  function copyToClipboard() {
    setCopied(true);
    toastSuccess('Link copiado');
  }

  const resendLinkOnboarding = async (idcontract) => {
    try {
      const { data } = await api.post(
        `contracts/${idcontract}/generate_link`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      toastSuccess('Link atualizado com sucesso');
      getCustomers();
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message = 'Algo deu errado ao atualizar links.';
      }

      hideLoader();
      toastError(message);
    }
  };

  function handleOnChangeRangeDate(e) {
    if (e.target.name == 'max') {
      setMaxDate(e.target.value);
    } else {
      setMinDate(e.target.value);
    }
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  async function handleResendingContract(contract) {
    console.log('contract', contract);
    try {
      const amountCharge = Number(parseCurrency(contract.amount_charged));
      const contractValue = Number(parseCurrency(contract.contract_value));
      const data = {
        contract: {
          // contract_number: contract.contract_number,
          name: contract.name,
          store_name: localStorage.getItem('credit_card_store_name'),
          contract_value: contractValue,
          amount_charged: amountCharge,
          nsu: contract.nsu,
          month: contract.month,
          kind_integrator: 0,
          kind_credit_card: contract.kind_credit_card,
          installments: contract.installments,
          payment_method: contract.payment_method,
          customer_id: contract.customer_id,
          customer_attributes: {
            email: contract.email,
            birth_date: contract.birth_date,
            mobile: contract.mobile,
            rg: contract.rg,
            entity_attributes: {
              name: contract.name,
              cpf_cnpj: contract.cpf_cnpj.replace(/[^0-9]+/g, ''),
              address_attributes: {
                zip_code: contract.zipcode,
                street: contract.street,
                number: contract.number,
                district: contract.district,
                city: contract.city,
                state: contract.state,
                complement: contract.complement,
              },
            },
          },
        },
      };

      let params = `?simulation_id=${contract?.simulation_id}`;

      const response = await api.post(
        `contracts${params}`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();
      setContractId(response.data.id);
      toastSuccess('Contrato reenviado com sucesso!');
    } catch (error) {
      hideLoader();
      let message = null;

      if (error.response) {
        if (error.response.data.error) {
          message = error.response.data.error;

          message.forEach((e) => {
            let m = e.replace('Base: ', '');
            toastError(m);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um erro ao reenviar o contrato, por favor tente novamente!';
      }
      toastError(message);
    }
  }

  function handleOnClickCard(pipeStatusParams, limitDate) {
    // let filter = "";

    // if (pipeStatus != null) {
    //   filter += `&pipeFilterStatus=${pipeStatus}`
    // }
    // if (limitDate != null) {
    //   filter += `&limitDate=${limitDate}`
    // }

    // setSearchFilter(filter);
    setPipeStatus(pipeStatusParams);
  }

  function handleClearFilterRegisterDate() {
    setMinRegisterDate('');
    setMaxRegisterDate('');
  }

  function handleClearFilterPaymentDate() {
    setMinPaymentDate('');
    setMaxPaymentDate('');
  }

  function handleClearFilterCommissionDate() {
    setMinCommissionDate('');
    setMaxCommissionDate('');
  }

  function handlerOnClickFilter(closeModal, isDisabledFilter) {
    setDisabledFilter(isDisabledFilter);
    if (
      minFilterDate !==
      `${new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getFullYear()}-${
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ).getMonth() + 1
      }-${new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getDate()}`
    ) {
      const parsedDateMinDate = parseISO(minFilterDate);
      if (!isValid(parsedDateMinDate)) {
        toastError('Data inicial inválida');
        return;
      }
    }

    if (
      maxFilterDate !==
      `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`
    ) {
      const parsedDateMaxDate = parseISO(maxFilterDate);
      if (!isValid(parsedDateMaxDate)) {
        toastError('Data final inválida');
        return;
      }
    }

    if (params.get('page')) {
      setOffset(1);
    }

    let filterPath = '';

    if (cpf.length > 0) {
      handleOnClick();
    }

    if (filterSeller) {
      filterPath += `&seller_id=${filterSeller}`;
    }

    if (filterName) {
      filterPath += `&q[customer_entity_name_cont]=${filterName}`;
    }

    if (filterEmail) {
      filterPath += `&q[customer_email_cont]=${filterEmail}`;
    }

    if (filterCorban) {
      filterPath += `&corban_id=${filterCorban}`;
    }

    if (filterCorbanType) {
      filterPath += `&q[seller_store_corban_corban_type_or_seller_store_subestablishment_corban_corban_type_eq]=${filterCorbanType}`;
    }

    if (filterSub) {
      filterPath += `&sub_id=${filterSub}`;
    }

    if (filterStore) {
      filterPath += `&store_id=${filterStore}`;
    }

    if (filterContract) {
      filterPath += `&q[contract_number_or_ccb_number_or_code_proposal_cont]=${filterContract}`;
    }

    if (filterManager) {
      filterPath += `&q[seller_store_user_manager_id_eq]=${filterManager}`;
    }

    if (filterRegionalManager) {
      filterPath += `&q[seller_store_user_manager_manager_id_eq]=${filterRegionalManager}`;
    }

    if (filterBlack) {
      filterPath += `&q[customer_blacklist_eq]=${filterBlack}`;
    }

    if (pipeFilterStatus) {
      filterPath += `&pipeFilterStatus=${pipeFilterStatus}`;
    }

    if (pipeStatus) {
      filterPath += `&pipeStatus=${pipeStatus}`;
    }

    if (filterDateType != '') {
      if (minFilterDate) {
        filterPath += `&q[${filterDateType}_gteq]=${minFilterDate}`;
      }
      if (maxFilterDate) {
        filterPath += `&q[${filterDateType}_lteq]=${maxFilterDate}`;
      }
    }

    setSearchFilter(filterPath);
    if (closeModal) {
      handleToggleFilter();
    }
  }

  function clearFilterModal() {
    setDisabledFilter(true);
    setFilterSeller('');
    setFilterName('');
    setFilterEmail('');
    setFilterCorban('');
    setFilterCorbanType('');
    setFilterSub('');
    setFilterStore('');
    setFilterContract('');
    setFilterFrom('');
    setFilterManager('');
    setFilterEnd('');

    setSearchFilter(`&q[${filterDateType}_gteq]=${minFilterDate}`);

    setMinRegisterDate('');
    setMaxRegisterDate('');
    setMinPaymentDate('');
    setMaxPaymentDate('');
    setMinCommissionDate('');
    setMaxCommissionDate('');
  }

  function handleClearFilter() {
    setFilterSeller('');
    setFilterName('');
    setFilterEmail('');
    setFilterCorban('');
    setFilterCorbanType('');
    setFilterSub('');
    setFilterStore('');
    setFilterContract('');
    setFilterFrom('');
    setFilterManager('');
    setFilterEnd('');

    setSearchFilter(`&q[${filterDateType}_gteq]=${minFilterDate}`);

    setMinRegisterDate('');
    setMaxRegisterDate('');
    setMinPaymentDate('');
    setMaxPaymentDate('');
    setMinCommissionDate('');
    setMaxCommissionDate('');

    handleToggleFilter();
  }

  function handleClearAllFilter() {
    setFilterSeller('');
    setFilterName('');
    setFilterEmail('');
    setFilterCorban('');
    setFilterCorbanType('');
    setFilterSub('');
    setFilterStore('');
    setFilterContract('');
    setFilterFrom('');
    setFilterManager('');
    setFilterEnd('');
    setMinRegisterDate('');
    setMaxRegisterDate('');
    setMinPaymentDate('');
    setMaxPaymentDate('');
    setMinCommissionDate('');
    setMaxCommissionDate('');
    setPipeFilterStatus('');
    setPipeStatus('');
    setMinFilterDate(
      `${new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getFullYear()}-${
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ).getMonth() + 1
      }-${new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).getDate()}`
    );
    setSearchFilter(`&q[${filterDateType}_gteq]=${minFilterDate}`);
    setMaxFilterDate('');
  }

  const handleToggleNumberModal = (contract_id) => {
    setNumberContract({ ...numberContract, contractId: contract_id });
    setContractNumberModal(!contractNumberModal);
  };

  function titleCase(str) {
    if (!str) {
      return;
    }
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  async function handlerOnClickNumberModal() {
    showLoader();

    const data = {
      contract: {
        contract_number: numberContract.reason,
      },
    };

    try {
      await api.put(
        `contracts/${numberContract.contractId}`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();

      toastSuccess('Contrato alterada com sucesso');
      dashboard();
      getCustomers();
    } catch (error) {
      hideLoader();
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na atualização da contrato, por favor tente novamente!';
      }

      toastError(message);
    }
    setContractNumberModal(!contractNumberModal);
  }

  async function handlerOnClickReportModal() {
    try {
      showLoader();
      await api.get(
        `/contracts/export_complete_contract_background?q[kind_eq]=${kindSearch}&email=${emailReport}&${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();
      toastSuccess(
        'Relatorio Solicitado com sucesso, aguarde o envio por email'
      );
    } catch (error) {
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro ao solicitar relatorio completo, por favor tente novamente!';
      }

      toastError(message);
    }
    setReportModal(!reportModal);
  }

  async function handlerOnClickPipeline() {
    console.log(pipeline);

    let data = {
      pipeline: {
        status: parseInt(pipeline.status, 10),
        reason: pipeline.reason,
        contract_id: pipeline.contract_id,
      },
    };

    console.log(pipeline.status);
    if (pipeline.status == '3') {
      if (!bankKind) {
        toastError('Selecione o banco');
        return;
      } else {
        data = { ...data, bank_kind: bankKind };
      }
    }

    if (pipeline.status == '5') {
      if (!pipeline.payment_date) {
        toastError('Selecione uma data de o pagamento');
        return;
      } else {
        data = { ...data, payment_date: pipeline.payment_date };
      }
    }

    if (pipeline.status == '8') {
      if (!pipeline.commission_payment_date) {
        toastError('Selecione uma data de o pagamento');
        return;
      } else {
        data = {
          ...data,
          commission_payment_date: pipeline.commission_payment_date,
        };
      }
    }

    showLoader();

    try {
      await api.post(
        selectedContracts.length > 0 ? `pipelines/create_all` : 'pipelines',
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();
      setSelectedContracts([]);
      toastSuccess('Pipeline alterada com sucesso');
      dashboard();
      getCustomers();
    } catch (error) {
      hideLoader();
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na criação da pipeline, por favor tente novamente!';
      }

      toastError(message);
    }
    setPipelineModal(!pipelineModal);
  }

  async function handleOnClickBlackList(idcustomer, active) {
    showLoader();
    try {
      const { data } = await api.put(
        `customers/${idcustomer}`,
        { customer: { blacklist: !active } },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      getCustomers();

      hideLoader();

      toastSuccess(data.message);
    } catch (e) {
      hideLoader();
      let message = 'Algo deu errado ao incluir ou retirar da BlackList';
      if (e.response.data) {
        message = e.response.data.message;
      }
      toastError(message);
    }
  }

  async function resendQistaFiles(id) {
    showLoader();

    try {
      await api.put(
        `contracts/${id}/resend_files_qista`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      toastSuccess('Documentos Reenviados');
      dashboard();
      getCustomers();
    } catch (error) {
      hideLoader();
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro ao reenviar os documentos, por favor tente novamente!';
      }

      toastError(message);
    }
  }

  async function openReanalysis(id = null) {
    setDocumentRg([]);
    setDocumentRgPersist([]);
    setDocumentFace([]);
    setDocumentFacePersist([]);
    setContractId(id);
    setOpenReanalysisModal(!openReanalysisModal);
  }

  async function sendReanalysis() {
    console.log('Enviando para reanalise');
    if (!reanalysisModal.reason) {
      toastError('Você deve informar uma razão');
      return;
    }

    if (documentRg.length == 0 && documentRgPersist == 0) {
      toastError('Selecione pelo menos uma foto para cada opção');
      return;
    }

    if (documentFace.length == 0 && documentFacePersist == 0) {
      toastError('Selecione pelo menos uma foto para cada opção');
      return;
    }

    showLoader();
    try {
      const contractData = new FormData();
      let index = 0;
      contractData.append(`reason`, reanalysisModal.reason);
      documentRg.map((item) => {
        contractData.append(
          `contract[documents_attributes][${index}][file]`,
          item
        );
        contractData.append(
          `contract[documents_attributes][${index}][kind]`,
          'cnh'
        );
        index += 1;
        console.log(index);
      });

      documentFace.map((item) => {
        contractData.append(
          `contract[documents_attributes][${index}][file]`,
          item
        );
        contractData.append(
          `contract[documents_attributes][${index}][kind]`,
          'selfie'
        );
        index += 1;
        console.log(index);
      });

      const response = await api.put(
        `contracts/${contractId}/update_images_reanalysis`,
        contractData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      console.log(response);

      hideLoader();

      openReanalysis();
      getCustomers();

      toastSuccess('Documentos enviados com sucesso');
    } catch (error) {
      hideLoader();
      console.log(error);
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um erro ao enviar documentos, por favor tente novamente!';
      }
      toastError(message);
    }
  }

  async function uploadDocumentClient() {
    console.log('Enviando para reanalise');

    if (kind == 'OCR' && documentRg.length == 0 && documentRgPersist == 0) {
      toastError('Selecione pelo menos uma foto para cada opção');
      return;
    }

    if (
      kind == 'FaceMatch' &&
      documentFace.length == 0 &&
      documentFacePersist == 0
    ) {
      toastError('Selecione pelo menos uma foto para cada opção');
      return;
    }

    showLoader();
    try {
      const contractFormData = new FormData();
      let index = 0;
      // contractFormData.append(`reason`, reanalysisModal.reason)
      if (kind == 'OCR') {
        documentRg.map((item) => {
          contractFormData.append(
            `customer[documents_attributes][${index}][file]`,
            item
          );
          contractFormData.append(
            `customer[documents_attributes][${index}][kind]`,
            'rg'
          );
          index += 1;
          console.log(index);
        });
      }

      if (kind == 'FaceMatch') {
        documentFace.map((item) => {
          contractFormData.append(
            `customer[documents_attributes][${index}][file]`,
            item
          );
          contractFormData.append(
            `customer[documents_attributes][${index}][kind]`,
            'selfie'
          );
          index += 1;
          console.log(index);
        });
      }

      const response = await api.put(
        `customers/${customerDocument}/update_images?contract_id=${contractData}&send_qista=true&kind=${kind}`,
        contractFormData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      console.log(response);

      hideLoader();

      setCustomerDocumentModal(!customerDocumentModal);
      getCustomers();

      toastSuccess('Documentos enviados com sucesso');
    } catch (error) {
      hideLoader();
      console.log(error);
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um erro ao enviar documentos, por favor tente novamente!';
      }
      toastError(message);
    }
  }

  async function uploadDocumentQista(
    contractSignFile = null,
    backFile = null,
    frontFile = null,
    facematchFile = null
  ) {
    console.log('Enviando para qista');

    showLoader();
    try {
      const contractFormData = new FormData();
      let index = 0;
      // contractFormData.append(`reason`, reanalysisModal.reason)
      if (contractSignFile) {
        contractFormData.append(
          `contract[contract_sign_file]`,
          contractSignFile
        );
      }
      if (backFile) {
        contractFormData.append(`contract[back_file]`, backFile);
      }
      if (frontFile) {
        contractFormData.append(`contract[front_file]`, frontFile);
      }
      if (facematchFile) {
        contractFormData.append(`contract[facematch_file]`, facematchFile);
      }

      const response = await api.put(
        `contracts/${customerDocument}/resend_files_qista`,
        contractFormData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      console.log(response);

      hideLoader();

      setCustomerDocumentModalQista(!customerDocumentModalQista);
      getCustomers();

      toastSuccess('Documentos enviados com sucesso');
    } catch (error) {
      hideLoader();
      console.log(error);
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um erro ao enviar documentos, por favor tente novamente!';
      }
      toastError(message);
    }
  }

  async function approveContract(id) {
    showLoader();
    try {
      const response = await api.put(
        `contracts/${id}/approve_qista`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      console.log(response);

      hideLoader();

      getCustomers();

      toastSuccess('Aprovado com sucesso');
    } catch (error) {
      hideLoader();
      toastError(error?.response?.data?.data?.message);
    }
  }

  async function handleOnClickCancelContract(idContract) {
    showLoader();
    try {
      const { data } = await api.put(
        `contracts/${idContract}/request_cancel`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      getCustomers();

      hideLoader();

      toastSuccess('Cancelamento solicitado com sucesso');
    } catch (e) {
      hideLoader();
      let message = 'Algo deu errado ao solicitar cancelamento';
      if (e.response.data) {
        message = e.response.data.message;
      }
      toastError(message);
    }
  }

  async function exportExcel() {
    showLoader();
    try {
      const response = await api.get(`contracts/download?page${searchFilter}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `contratos_${moment().format('YYYY-MM-DD_HH:mm:ss')}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      hideLoader();
    } catch (e) {
      hideLoader();
      toastError('Algo deu errado ao exportar os dados');
    }
  }

  async function export_contract() {
    setCsvDisabled(true);
    try {
      const response = await api.get(
        `contracts/export_contract?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setExportList(response.data);
      csvLinkRef?.current?.link.click();
    } catch (e) {}
    setCsvDisabled(false);
  }

  async function export_complete_report() {
    setCompleteReportDisabled(true);
    requestReport(pageReport, 500);
    // setExportCompleteList(response.data)
  }

  async function export_complete_report_fund() {
    setCompleteReportFundDisabled(true);
    requestReportFund(pageReport, 500);
    // setExportCompleteList(response.data)
  }

  const requestReport = async (pageParams, perPage) => {
    showLoader();
    try {
      const response = await api.get(
        `contracts/export_complete_contract?q[kind_eq]=${kindSearch}&${searchFilter}&page=${pageParams}&per_page=${perPage}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setTotalPageReport(response.headers.total_pages);
      /* setPageReport(pageParams + 1); */
      let array = [];
      if (dataReport.length > 0) {
        array = [...dataReport, ...response.data];
      } else {
        array = response.data;
      }
      arrayReport = [...arrayReport, ...response.data];
      setDataReport(arrayReport);
      if (pageParams + 1 > response.headers.total_pages) {
        setExportCompleteList(arrayReport);
        completeReportLinkRef?.current?.link.click();

        setCompleteReportDisabled(false);
      } else {
        requestReport(pageParams + 1, 500);
      }
      hideLoader();
    } catch (e) {}
  };

  const requestReportFund = async (pageParams, perPage) => {
    try {
      const response = await api.get(
        `contracts/export_complete_contract_fund?${searchFilter}&page=${pageParams}&per_page=${perPage}&q[kind_eq]=${kindSearch}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setTotalPageReport(response.headers.total_pages);
      setPageReport(pageParams + 1);
      let array = [];
      if (dataReport.length > 0) {
        array = [...dataReport, ...response.data];
      } else {
        array = response.data;
      }
      arrayReport = [...arrayReport, ...response.data];
      setDataReport(arrayReport);
      if (pageParams + 1 > response.headers.total_pages) {
        setExportCompleteList(arrayReport);
        completeReportFundLinkRef?.current?.link.click();

        setCompleteReportFundDisabled(false);
      } else {
        requestReport(pageParams + 1, 500);
      }
    } catch (e) {}
  };

  async function analytical_report(id) {
    try {
      const response = await api.get(
        `contracts/${id}/analytical_report?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      console.log(response.data);
      setAnalyticalReport(response.data);
      analyticalLinkRef?.current?.link.click();
    } catch (e) {}
  }

  async function synthetic_report() {
    setSyntheticReportDisabled(true);
    try {
      const response = await api.get(
        `contracts/synthetic_report?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      setSyntheticReport(response.data);
      syntheticLinkRef?.current?.link.click();
    } catch (e) {}
    setSyntheticReportDisabled(false);
  }

  function handleClickModalDate() {
    setModalDate(!modalDate);
  }

  function handleClickDateSearch() {
    let filter = '';
    if (maxDate != '' || minDate != '') {
      filter += `&max_date=${maxDate}&min_date=${minDate}`;
      setSearchFilter(filter);
    }

    setModalDate(!modalDate);
  }

  const selectAllContracts = (checked) => {
    if (checked) {
      setSelectedContracts(customers.map((item) => item.id));
    } else {
      setSelectedContracts([]);
    }
  };

  const selectContract = (id, checked) => {
    if (checked) {
      setSelectedContracts([...selectedContracts, id]);
    } else {
      setSelectedContracts(selectedContracts.filter((item) => item != id));
    }
  };

  async function getBanks() {
    showLoader();

    try {
      const response = await api.get(`banks?q[active_eq]=true`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      const values = response.data.map((value, index) => {
        return {
          value: `${value.code} - ${value.name}`,
          label: `${value.code} - ${value.name}`,
        };
      });

      setBanks(values);

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os bancos.');
    }
  }

  function parseDigit(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, 'x');
    } else {
      return value;
    }
  }

  function parseAccount(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, '');
    } else {
      return value;
    }
  }

  function handleOnChange(e) {
    if (e.target.name == 'agency_digit') {
      setCustomerAux((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          agency_digit: e.target.value,
        },
      }));
    } else if (e.target.name == 'account_digit') {
      setCustomerAux((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          account_digit: e.target.value,
        },
      }));
    } else if (e.target.name === 'number_account') {
      setCustomerAux((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          number_account: e.target.value,
        },
      }));
    } else {
      setCustomerAux((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          [e.target.name]: e.target.value,
        },
      }));
    }
  }

  const handleOnChangeAccountDigit = (e) => {
    const newCustomerAux = { ...customerAux };
    const [beforeHyphen, afterHyphen] =
      newCustomerAux.customer.number_account.split('-');
    newCustomerAux.customer.number_account =
      beforeHyphen + '-' + e.target.value;
    setCustomerAux(newCustomerAux);
  };

  async function onSubmit() {
    showLoader();
    if (!customerAux.customer.agency_account) {
      toastError('Preencher campo Agência');
      hideLoader();
      return;
    }

    if (!customerAux.customer.number_account) {
      toastError('Preencher campo Conta');
      hideLoader();
      return;
    }

    let bank_account_attributes = {
      number_bank: customerAux?.customer.name_bank.split('-')[0],
      bank_name: customerAux?.customer.name_bank.split('-')[1],
      agency_account: customerAux?.customer.agency_account,
      agency_digit: customerAux?.customer.agency_digit,
      number_account: customerAux?.customer.number_account,
      account_digit: customerAux?.customer.account_digit,
      kind: customerAux?.customer.account_kind,
      kind_account: customerAux?.kind_account,
      month: customerAux?.month ? customerAux?.month : '',
    };

    let data = {
      name: customerAux.name,
      store_name: localStorage.getItem('fgts_store_name'),
      customer: {
        entity_attributes: {
          bank_account_attributes,
          cpf_cnpj: customerAux.cpf_cnpj,
          name: customerAux.name,
        },
      },
    };
    setCustomerAux(data);
    await api
      .put(
        `customers/${customerAux.customer_id}?contract_id=${customerAux.id}&email=true`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then(() => {
        toastSuccess('Dados atualizados');
        window.location.reload();
      });
    /*  */
  }

  useEffect(() => {
    /* if (!customerAux?.customer.account_digit) {
      setDisabledBankData(true);
      return;
    } */

    if (!customerAux?.customer.account_kind) {
      setDisabledBankData(true);
      return;
    }

    if (!customerAux?.customer.agency_account) {
      setDisabledBankData(true);
      return;
    }

    if (!customerAux?.customer.name_bank) {
      setDisabledBankData(true);
      return;
    }

    if (!customerAux?.customer.number_account) {
      setDisabledBankData(true);
      return;
    }

    setDisabledBankData(false);
  }, [customerAux]);

  useEffect(() => {
    setCustomerAux(customers[indexCustomerSelected]);
  }, [indexCustomerSelected]);

  return (
    <>
      <Header
        gradient="bg-gradient-fgts"
        hasFgts
        pipeStatus={pipeStatus}
        requestFinish={requestFinish}
        maxFilterDate={maxFilterDate}
        minFilterDate={minFilterDate}
        setMaxFilterDate={setMaxFilterDate}
        setMinFilterDate={setMinFilterDate}
        setFilterDateType={setFilterDateType}
        filterDateType={filterDateType}
        handlerOnClickFilter={handlerOnClickFilter}
        handleClearFilterRegisterDate={handleClearFilterRegisterDate}
        handleClearFilterPaymentDate={handleClearFilterPaymentDate}
        handleClearFilterCommissionDate={handleClearFilterCommissionDate}
        minRegisterDate={minRegisterDate}
        maxRegisterDate={maxRegisterDate}
        minPaymentDate={minPaymentDate}
        maxPaymentDate={maxPaymentDate}
        minCommissionDate={minCommissionDate}
        maxCommissionDate={maxCommissionDate}
        setMinRegisterDate={setMinRegisterDate}
        setMaxRegisterDate={setMaxRegisterDate}
        setMinPaymentDate={setMinPaymentDate}
        setMaxPaymentDate={setMaxPaymentDate}
        setMinCommissionDate={setMinCommissionDate}
        setMaxCommissionDate={setMaxCommissionDate}
        totalCustomers={totalCustomers}
        totalCommissionValue={totalCommissionValue}
        totalContractValue={totalContractValue}
        totalPayments={totalPayments}
        operateSimulator={operateSimulator}
        documentPending={documentPending}
        sendLink={sendLink}
        waitingDocumentosAnalitcs={waitingDocumentosAnalitcs}
        waitingSignature={waitingSignature}
        contractSigned={contractSigned}
        contractPaid={contractPaid}
        commissionPaid={commissionPaid}
        handleOnClickCard={handleOnClickCard}
        handleClickModalDate={handleClickModalDate}
        canceled={canceled}
        refused={refused}
        reanalysis={reanalysis}
        riskAnalysis={riskAnalysis}
        pendingLife={pendingLife}
        paymentReturned={paymentReturned}
        totalValue={totalValue}
        totalValueCanceled={totalValueCanceled}
        totalValuePaid={totalValuePaid}
        contractPending={totalPending}
      />
      {/* Page content */}
      <Container className="mt--7 mb" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            {/* <div className="text-right">
              <Link
                className="btn btn-default mb-3 mt-2"
                to="/admin/contracts/create"
              >
                <i className="fas fa-plus" /> Criar contrato
              </Link>
            </div> */}

            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row>
                  <Col lg="5">
                    <h3 className="text-white">Contratos</h3>
                    <Search
                      getItemValue={getItemValue}
                      autoCompleteData={autocompleteDataIdContract}
                      renderItem={renderItemIdContract}
                      cpf_cnpj={filterIdContract}
                      onChange={onChangeIdContract}
                      onSelect={onSelectIdContract}
                      handleOnClick={handleOnClickIdContract}
                      placeHolder="Buscar pelo ID do contrato"
                    />
                  </Col>

                  <Col lg="7" className="mt-3 mt-lg-0">
                    {getAllCustomers && handleGetAllCustomers && (
                      <div className="d-flex align-items-end h-100">
                        <Button color="info" onClick={handleGetAllCustomers}>
                          Buscar todos os Contratos
                        </Button>
                      </div>
                    )}
                    {!getAllCustomers && (
                      <>
                        <div className="d-flex justify-content-end h-100 align-items-end">
                          {/* <CSVLink
                            data={analyticalReport}
                            filename={"RelatorioAnalitico.csv"}
                            separator={";"}
                            ref={analyticalLinkRef}
                          ></CSVLink> */}
                          {selectedContracts.length > 0 && (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                handleTogglePipeline({
                                  idcontract: selectedContracts,
                                })
                              }
                            >
                              Alterar Status
                            </button>
                          )}
                          {/* <VisibilityByUserRoles
                            roles={['master', 'operational']}
                          >
                            <button
                              disabled={completeReportFundDisabled}
                              className="btn btn-primary"
                              onClick={export_complete_report_fund}
                            >
                              Exportar Relatório Fundo
                            </button>
                          </VisibilityByUserRoles> */}
                          <CSVLink
                            data={exportCompleteList}
                            filename={'relatorio-fundo.csv'}
                            separator={';'}
                            ref={completeReportFundLinkRef}
                          ></CSVLink>
                          <VisibilityByUserRoles
                            roles={[
                              'master',
                              'operational',
                              'commercial_manager',
                              'regional_manager',
                              'supervisor_commmercial_internal',
                              'commercial_manager_internal',
                              'superintendent_commercial',
                              'master_corban',
                              'master_subest',
                              'master_store',
                            ]}
                          >
                            <button
                              disabled={completeReportDisabled}
                              className="btn btn-primary"
                              onClick={export_complete_report}
                            >
                              Relatório Simples
                            </button>

                            <button
                              disabled={completeReportDisabled}
                              className="btn btn-primary"
                              /* onClick={export_complete_report} */
                              onClick={() => handleToggleReportModal()}
                            >
                              Relatório Completo por email
                            </button>
                          </VisibilityByUserRoles>
                          <CSVLink
                            data={exportCompleteList}
                            filename={'complete_report.csv'}
                            separator={';'}
                            ref={completeReportLinkRef}
                          ></CSVLink>

                          {/* {
                            user && (user.kind != "commercial_manager" || user.kind != "supervisor_commmercial_internal") ?
                              <>
                                <button
                                  disabled={syntheticReportDisabled}
                                  className="btn btn-primary"
                                  onClick={synthetic_report}
                                >
                                  Relatório Sintético
                                </button>
                                <CSVLink
                                  data={syntheticReport}
                                  filename={"RelatorioSintetico.csv"}
                                  separator={";"}
                                  ref={syntheticLinkRef}
                                ></CSVLink>
                              </>
                            :
                              false
                          } */}
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              handleToggleFilter();
                              clearFilterModal();
                            }}
                          >
                            <i className="fas fa-filter" /> Filtrar
                          </button>
                          {searchFilter && (
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={handleClearAllFilter}
                            >
                              Limpar Filtros
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <Row className="ml-4">
                {numberRegister > 0 ? (
                  <div className="text-white">
                    Registros encontrados {LIMIT * (offset - 1) + 1} -{' '}
                    {numberRegister <= LIMIT ? numberRegister : LIMIT * offset}{' '}
                    de {numberRegister}
                  </div>
                ) : (
                  <div className="text-white">Nenhum registro encontrado</div>
                )}
              </Row>
              <Table
                className="align-items-center table-dark table-flush table-responsive-custom"
                id="table-responsive-custom"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <VisibilityByUserRoles
                      roles={['master', 'operational', 'regional_manager']}
                    >
                      <th
                        scope="col"
                        className="align-items-center d-flex justify-content-center flex-column"
                      >
                        <div className="mb-1">Selecionar Tudo</div>
                        <div className="mb-1">
                          <Input
                            type="checkbox"
                            className="form-check-custom"
                            name="select_checkbox"
                            defaultChecked={false}
                            onChange={(e) =>
                              selectAllContracts(e.target.checked)
                            }
                          />{' '}
                        </div>
                      </th>
                    </VisibilityByUserRoles>
                    {/* roles={['master', 'operational', 'regional_manager', 'commercial_manager']} */}
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'regional_manager',
                        'commercial_manager',
                        'master_store',
                        'user_store',
                        'user_store_financial',
                        'user_store_register',
                        'master_seller',
                        'user_seller',
                        'user_seller_financial',
                        'user_seller_register',
                      ]}
                    >
                      <th scope="col">ID</th>
                    </VisibilityByUserRoles>
                    <th scope="col" className="text-right">
                      Ações
                    </th>
                    <th scope="col">Contrato</th>
                    <th scope="col">Cliente</th>

                    {!query.get('isCdc') && <th scope="col">R$ Bruto</th>}
                    {query.get('isCdc') && (
                      <>
                        <th scope="col">R$ Valor Liberado </th>
                      </>
                    )}
                    {!query.get('isCdc') && <th scope="col">R$ Liquido</th>}
                    <VisibilityByUserRoles
                      roles={[
                        'master_corban',
                        'user_corban',
                        'user_corban_financial',
                        'user_corban_register',
                        'master_subest',
                        'user_subest',
                        'user_subest_financial',
                        'user_subest_register',
                        'master_store',
                        'user_store_financial',
                        'user_store_register',
                        'user_seller',
                        'user_seller',
                        'user_seller_financial',
                        'user_seller_register',
                      ]}
                    >
                      <th scope="col">R$ Comissão</th>
                    </VisibilityByUserRoles>
                    {/* <th scope="col">Vl. Total</th> */}
                    <th scope="col">Status</th>
                    {/* <th scope="col">TC</th> */}
                    <th scope="col">Tx. Juros</th>
                    <th scope="col">Tabela</th>
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'regional_manager',
                        'commercial_manager',
                        'user_corban',
                        'user_corban_register',
                      ]}
                    >
                      <th scope="col">Corban</th>
                    </VisibilityByUserRoles>
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'regional_manager',
                        'commercial_manager',
                        'user_corban',
                        'user_corban_register',
                      ]}
                    >
                      <th scope="col">Comissão Corban</th>
                    </VisibilityByUserRoles>
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'commercial_manager',
                        'regional_manager',
                        'user_corban',
                        'user_corban_financial',
                        'user_corban_register',
                      ]}
                    >
                      <th scope="col">Substabelecido</th>
                    </VisibilityByUserRoles>
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'regional_manager',
                        'user_subest',
                        'user_subest_financial',
                        'user_subest_register',
                      ]}
                    >
                      <th scope="col">Comissão SUB</th>
                    </VisibilityByUserRoles>
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'commercial_manager',
                        'regional_manager',
                        'supervisor_commmercial_internal',
                        'commercial_manager_internal',
                        'superintendent_commercial',
                        'user_corban',
                        'user_corban_financial',
                        'user_corban_register',
                        'user_subest',
                        'user_subest_financial',
                        'user_subest_register',
                      ]}
                    >
                      <th scope="col">
                        {query.get('isCdc') ? 'Estabelecimento' : 'Loja'}
                      </th>
                    </VisibilityByUserRoles>
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'regional_manager',
                        'user_store',
                        'user_store_financial',
                        'user_store_register',
                      ]}
                    >
                      <th scope="col">
                        Comissão{' '}
                        {query.get('isCdc') ? 'Estabelecimento' : 'Loja'}
                      </th>
                    </VisibilityByUserRoles>
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'commercial_manager',
                        'regional_manager',
                        'supervisor_commmercial_internal',
                        'commercial_manager_internal',
                        'superintendent_commercial',
                      ]}
                    >
                      <th scope="col">
                        {user &&
                        (user.kind == 'commercial_manager' ||
                          user.kind == 'supervisor_commmercial_internal')
                          ? 'Vendedor'
                          : 'Gerente Comercial'}
                      </th>
                    </VisibilityByUserRoles>
                    {user && user.kind == 'corban' ? (
                      <>
                        <th scope="col">Substabelecido</th>
                        <th scope="col">Loja</th>
                      </>
                    ) : (
                      false
                    )}
                    {user && user.kind == 'subestablishment' ? (
                      <>
                        <th scope="col">Loja</th>
                        <th scope="col">Vendedor</th>
                      </>
                    ) : (
                      false
                    )}
                    {user && user.kind == 'store' ? (
                      <>
                        <th scope="col">Vendedor</th>
                      </>
                    ) : (
                      false
                    )}
                    <VisibilityByUserRoles
                      roles={[
                        'master',
                        'operational',
                        'commercial_manager',
                        'regional_manager',
                      ]}
                    >
                      <th
                        scope="col"
                        onClick={() => sortCommissionPaymentUpdatedAt()}
                      >
                        <span>PGTO Contrato</span>
                        {sortCommissionPaymentDate &&
                        sortCommissionPaymentDate == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col" onClick={() => sortPaymentUpdatedAt()}>
                        <span>PGTO Comissão</span>
                        {sortPaymentDate && sortPaymentDate == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                    </VisibilityByUserRoles>
                    <th scope="col" onClick={() => sortUpdatedAt()}>
                      <span>Ult. Atualização</span>
                      {sort && sort == 'desc' ? (
                        <i className="ml-2 fas fa-arrow-down" />
                      ) : (
                        <i className="ml-2 fas fa-arrow-up" />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer, index) => (
                    <tr
                      key={customer.id}
                      className={customer.customer.blacklist ? 'blacklist' : ''}
                    >
                      <VisibilityByUserRoles
                        roles={['master', 'operational', 'regional_manager']}
                      >
                        <td className="align-items-center d-flex justify-content-center">
                          <div className="mt-3">
                            <Input
                              type="checkbox"
                              name="select_checkbox"
                              defaultChecked={false}
                              checked={selectedContracts.includes(customer.id)}
                              className="form-check-custom"
                              onChange={(e) =>
                                selectContract(customer.id, e.target.checked)
                              }
                            />{' '}
                          </div>
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'regional_manager',
                          'commercial_manager',
                          'master_store',
                          'user_store',
                          'user_store_financial',
                          'user_store_register',
                          'master_seller',
                          'user_seller',
                          'user_seller_financial',
                          'user_seller_register',
                        ]}
                      >
                        <th scope="row" className="word-wrap">
                          <Media className="align-items-center w-100">
                            <Media className="w-100">
                              <span className="mb-0 text-sm w-100">
                                {customer.id ? customer.id : '-'}
                              </span>
                            </Media>
                          </Media>
                        </th>
                      </VisibilityByUserRoles>
                      <td className="text-right">
                        {selectedContracts && selectedContracts.length <= 0 && (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <Link
                                to={`/admin/contracts/${customer.id}/detail`}
                                className="dropdown-item"
                              >
                                <span>
                                  <i className="fas fa-info-circle" /> Detalhes
                                  Contrato
                                </span>
                              </Link>
                              {!customer.status_code &&
                                customer.active === 'created' &&
                                customer.onboarding_link && (
                                  <CopyToClipboard
                                    text={customer.onboarding_link}
                                    onCopy={() => copyToClipboard()}
                                  >
                                    <DropdownItem>
                                      <span>
                                        <i className="fas fa-copy" /> Copiar
                                        link Formalização
                                      </span>
                                    </DropdownItem>
                                  </CopyToClipboard>
                                )}
                              <VisibilityByUserRoles
                                roles={['master', 'operational']}
                              >
                                {!customer.status_code &&
                                  customer.active === 'created' &&
                                  !customer.onboarding_link && (
                                    <DropdownItem
                                      onClick={() =>
                                        resendLinkOnboarding(customer.id)
                                      }
                                    >
                                      <span>
                                        <i className="fas fa-copy" /> Gerar novo
                                        link para Formalização
                                      </span>
                                    </DropdownItem>
                                  )}
                              </VisibilityByUserRoles>
                              {!customer.status_code &&
                                customer.active === 'created' &&
                                customer.status_original ==
                                  'waiting_signature' && (
                                  <CopyToClipboard
                                    text={customer.request_signature_key}
                                    onCopy={() => copyToClipboard()}
                                  >
                                    <DropdownItem>
                                      <span>
                                        <i className="fas fa-copy" /> Copiar
                                        link assinatura
                                      </span>
                                    </DropdownItem>
                                  </CopyToClipboard>
                                )}
                              {/* {customer.active == 'created' &&
                                customer.status_original == 'reanalysis' && (
                                  <>
                                    <Link
                                      to={`/admin/contracts/${customer.id}/upload_fgts`}
                                      className="dropdown-item"
                                    >
                                      <span>
                                        <i className="fas fa-file " /> Upload de
                                        Documentos Reanálise
                                      </span>
                                    </Link>
                                  </>
                                )} */}
                              <VisibilityByUserRoles
                                roles={['master', 'operational']}
                              >
                                <DropdownItem
                                  onClick={() =>
                                    handleResendingContract(customer)
                                  }
                                >
                                  <span>
                                    <i className="fas fa-paper-plane" />{' '}
                                    Reenviar contrato
                                  </span>
                                </DropdownItem>
                              </VisibilityByUserRoles>
                              {!customer.status_code &&
                                customer.active === 'created' &&
                                customer.status_original ==
                                  'waiting_signature' && (
                                  <DropdownItem
                                    onClick={() =>
                                      resendLinkClickSign(customer.id)
                                    }
                                  >
                                    <span>
                                      <i className="fas fa-copy" /> Reenviar
                                      link para assinaura
                                    </span>
                                  </DropdownItem>
                                )}
                              {/* <Link
                                  to={`/admin/customers/${customer.customer_id}?contractId=${customer.id}`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-info-circle" /> Solicitar Alteração dos dados
                                  </span>
                                </Link> */}
                              {/* <DropdownItem
                                onClick={() =>
                                  handleOnClickCancelContract(customer.id)
                                }
                              >
                                <span>
                                  <i className="fas fa-user-lock" />
                                  Cancelar
                                </span>
                              </DropdownItem> */}
                              <VisibilityByUserRoles
                                roles={[
                                  'master',
                                  'operational',
                                  'regional_manager',
                                ]}
                              >
                                <Link
                                  to={`/admin/contracts/${customer.id}/logs`}
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i className="fas fa-file" /> Log de
                                    Alterações
                                  </span>
                                </Link>
                              </VisibilityByUserRoles>
                              {user &&
                              (user.roles[0].name == 'master' ||
                                user.roles[0].name == 'operational') ? (
                                <DropdownItem
                                  onClick={() =>
                                    handleOnClickBlackList(
                                      customer.customer_id,
                                      customer.customer.blacklist
                                    )
                                  }
                                >
                                  <span>
                                    <i className="fas fa-user-lock" />{' '}
                                    {customer.customer.blacklist
                                      ? 'Retirar'
                                      : 'Incluir'}{' '}
                                    BlackList
                                  </span>
                                </DropdownItem>
                              ) : (
                                false
                              )}
                              {/* {customer.status_original == 'pending' && (
                                  // <VisibilityByUserRoles roles={["master", "operational", "regional_manager"]}>
                                    <DropdownItem
                                      onClick={() => handleToggleCustomerData({
                                        idcontract: customer.id,
                                        idcustomer: customer.customer_id
                                      })}
                                    >
                                      <span>
                                        <i className="fas fa-file" /> Reapresentar pagamento
                                      </span>
                                    </DropdownItem>
                                  // </VisibilityByUserRoles>
                                )} */}
                              {customer.status_original ==
                                'document_pending' && (
                                // <VisibilityByUserRoles roles={["master", "operational", "regional_manager"]}>
                                <DropdownItem
                                  onClick={() =>
                                    handleToggleCustomerDocument({
                                      idcontract: customer.id,
                                      idcustomer: customer.customer_id,
                                      reason_pending: customer.reason_pending,
                                    })
                                  }
                                >
                                  <span>
                                    <i className="fas fa-file" /> Reapresentar
                                    Documentos
                                  </span>
                                </DropdownItem>
                                // </VisibilityByUserRoles>
                              )}

                              {customer.status_original ==
                                'contract_signed' && (
                                <>
                                  <VisibilityByUserRoles
                                    roles={['master', 'operational']}
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        // resendQistaFiles({
                                        //   id: customer.id,
                                        // })
                                        handleToggleCustomerDocumentQista({
                                          idcontract: customer.id,
                                        })
                                      }
                                    >
                                      <span>
                                        <i className="fas fa-file" /> Reenviar
                                        Documentos para Qista
                                      </span>
                                    </DropdownItem>
                                  </VisibilityByUserRoles>
                                  <VisibilityByUserRoles
                                    roles={['master', 'operational']}
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        approveContract(customer.id)
                                      }
                                    >
                                      <span>
                                        <i className="fas fa-file" /> Aprovar
                                        Contrato Qista
                                      </span>
                                    </DropdownItem>
                                  </VisibilityByUserRoles>
                                </>
                              )}
                              {customer.status_original != 'canceled' && (
                                <VisibilityByUserRoles
                                  roles={[
                                    'master',
                                    'operational',
                                    'regional_manager',
                                  ]}
                                >
                                  <DropdownItem
                                    onClick={() =>
                                      handleTogglePipeline({
                                        idcontract: customer.id,
                                        status: customer.status_original,
                                      })
                                    }
                                  >
                                    <span>
                                      <i className="fas fa-file" /> Alterar
                                      status
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                              )}
                              {customer.status_original == 'alter_data' && (
                                <VisibilityByUserRoles
                                  roles={[
                                    'master',
                                    'operational',
                                    'regional_manager',
                                  ]}
                                >
                                  <DropdownItem
                                    onClick={() =>
                                      handleToggleChangeCustomerIndex({
                                        idcontract: customer.id,
                                      })
                                    }
                                  >
                                    <span>
                                      <i className="fas fa-file" /> Dados
                                      Alterados
                                    </span>
                                  </DropdownItem>
                                </VisibilityByUserRoles>
                              )}
                              <DropdownItem
                                onClick={() =>
                                  handleToggleIndexPipeline({
                                    idcontract: customer.id,
                                    contractNumber: customer.contract_number,
                                  })
                                }
                              >
                                <span>
                                  <i className="fas fa-file" /> Movimentação de
                                  status
                                </span>
                              </DropdownItem>
                              {customer.status_pipeline ===
                                'Pagamento Devolvido' && (
                                <DropdownItem>
                                  <span
                                    onClick={(e) => {
                                      console.log('e', e);
                                      setBankDataModal(true);
                                      setIndexCustomerSelected(index);
                                    }}
                                  >
                                    <i className="fas fa-edit" /> Reapresentar
                                    pagamento
                                  </span>
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </td>
                      <td>
                        {customer.contract_number
                          ? customer.contract_number
                          : '-'}
                      </td>
                      <td>{titleCase(customer.name)}</td>

                      {!query.get('isCdc') && (
                        <td>
                          {Number(customer.contract_value).toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency: 'BRL',
                            }
                          )}
                        </td>
                      )}
                      {query.get('isCdc') && (
                        <>
                          <td>
                            {Number(
                              customer.contract_value_establishment
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                        </>
                      )}

                      {!query.get('isCdc') && (
                        <td>
                          {Number(customer.value_solicitation).toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency: 'BRL',
                            }
                          )}
                        </td>
                      )}
                      <VisibilityByUserRoles
                        roles={[
                          'master_corban',
                          'user_corban',
                          'user_corban_financial',
                          'user_corban_register',
                        ]}
                      >
                        <td>
                          {customer.corban_commission
                            ? customer.corban_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master_subest',
                          'user_subest',
                          'user_subest_financial',
                          'user_subest_register',
                        ]}
                      >
                        <td>
                          {customer.sub_commission
                            ? customer.sub_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master_store',
                          'user_store',
                          'user_store_financial',
                          'user_store_register',
                        ]}
                      >
                        <td>
                          {customer.store_commission
                            ? customer.store_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master_seller',
                          'user_seller',
                          'user_seller_financial',
                          'user_seller_register',
                        ]}
                      >
                        <td>
                          {customer.seller_commission
                            ? customer.seller_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <td>
                        <div className="text-center">
                          {customer.status_original != 'send_link' ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: customer.status_pipeline
                                  ? customer.status_pipeline.replace(
                                      ' ',
                                      '<br />'
                                    )
                                  : '',
                              }}
                              className={`${HandleBgPipelinesColor(
                                customer.status_bg_pipeline,
                                true
                              )} text-white p-1 rounded text-center`}
                            ></div>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: customer.status_pipeline
                                  ? customer.status_pipeline.replace(
                                      ' ',
                                      '<br />'
                                    )
                                  : '',
                              }}
                              className={`${HandleBgPipelinesColor(
                                customer.status_bg_pipeline,
                                true
                              )} text-white p-1 rounded text-center`}
                            ></div>
                          )}
                          {customer.status_original == 'send_link' &&
                            !customer.onboarding_link && (
                              <Button
                                color="primary"
                                size="sm"
                                className="mt-2"
                              >
                                Gerando link formalização
                              </Button>
                            )}
                          {customer.status_original == 'pending' && (
                            <Button
                              color="success"
                              size="sm"
                              className="mt-2"
                              onClick={() =>
                                handleToggleIndexPipeline({
                                  idcontract: customer.id,
                                  contractNumber: customer.contract_number,
                                })
                              }
                            >
                              Causa pendência
                            </Button>
                          )}
                        </div>
                      </td>
                      {/* <td>{customer.percent_tac}</td> */}
                      <td>{customer.basic_interest}</td>
                      <td>{customer?.tac_table_name}</td>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'regional_manager',
                          'commercial_manager',
                          'user_corban',
                          'user_corban_register',
                        ]}
                      >
                        <td>{customer.corban_name}</td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'regional_manager',
                          'commercial_manager',
                          'user_corban',
                          'user_corban_register',
                        ]}
                      >
                        <td>
                          {customer.corban_commission
                            ? customer.corban_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                          'user_corban',
                          'user_corban_financial',
                          'user_corban_register',
                        ]}
                      >
                        <td>{customer.sub_name}</td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'regional_manager',
                          'user_subest',
                          'user_subest_financial',
                          'user_subest_register',
                        ]}
                      >
                        <td>
                          {customer.sub_commission
                            ? customer.sub_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                          'supervisor_commmercial_internal',
                          'commercial_manager_internal',
                          'superintendent_commercial',
                          'user_corban',
                          'user_corban_financial',
                          'user_corban_register',
                          'user_subest',
                          'user_subest_financial',
                          'user_subest_register',
                        ]}
                      >
                        <td>
                          <Link
                            to={`/admin/stores/${customer.store_id}/detail`}
                            style={{ color: 'white' }}
                          >
                            {customer.store_name}
                          </Link>
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'regional_manager',
                          'user_store',
                          'user_store_financial',
                          'user_store_register',
                        ]}
                      >
                        <td>
                          {customer.store_commission
                            ? customer.store_commission
                            : '-'}
                        </td>
                      </VisibilityByUserRoles>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                          'supervisor_commmercial_internal',
                          'commercial_manager_internal',
                          'superintendent_commercial',
                        ]}
                      >
                        {user &&
                        (user.kind == 'commercial_manager' ||
                          user.kind == 'supervisor_commmercial_internal') ? (
                          <td>{customer.seller_name}</td>
                        ) : (
                          <td>{customer.user_manager_name}</td>
                        )}
                      </VisibilityByUserRoles>
                      {user && user.kind == 'corban' ? (
                        <>
                          <td>{customer.sub_name}</td>
                          <td>{customer.store_name}</td>
                        </>
                      ) : (
                        false
                      )}
                      {user && user.kind == 'subestablishment' ? (
                        <>
                          <td>{customer.store_name}</td>
                          <td>{customer.seller_name}</td>
                        </>
                      ) : (
                        false
                      )}
                      {user && user.kind == 'store' ? (
                        <>
                          <td>{customer.seller_name}</td>
                        </>
                      ) : (
                        false
                      )}
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'commercial_manager',
                          'regional_manager',
                        ]}
                      >
                        <td>
                          {customer.payment_date && (
                            <Badge color="" className="badge-dot mr-4">
                              <i className="bg-yellow" />
                              {moment(customer.payment_date).format(
                                'DD/MM/YYYY'
                              )}
                            </Badge>
                          )}
                        </td>
                        <td>
                          {customer.commission_payment_date && (
                            <Badge color="" className="badge-dot mr-4">
                              <i className="bg-yellow" />
                              {moment(customer.commission_payment_date).format(
                                'DD/MM/YYYY'
                              )}
                            </Badge>
                          )}
                        </td>
                      </VisibilityByUserRoles>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-yellow" />
                          {moment(customer.updated_at).format(
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={Number(pages)}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={handleToggle}>
          Informações do Pagamento
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <p className="mb-0">
                  <strong>Nº do Contrato</strong>:{' '}
                  {paymentInformation.contractNumber}
                </p>
                <p>
                  <strong>Cliente</strong>: {paymentInformation.name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="bg-default shadow">
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" className="id">
                          ID
                        </th>
                        <th scope="col" className="amount">
                          Valor (R$)
                        </th>
                        <th scope="col" className="installments">
                          Parcelas
                        </th>
                        <th scope="col" className="installments">
                          Autorização
                        </th>
                        <th scope="col" className="created_at">
                          Criado
                        </th>
                        <th scope="col" className="created_at">
                          Número do Cartão
                        </th>
                        <th scope="col" className="status">
                          NSU
                        </th>
                        <th scope="col" className="acquirer">
                          Adquirente
                        </th>
                        <th scope="col" className="status">
                          Status
                        </th>
                        <th scope="col" className="message">
                          Mensagem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentInformation.payments.map((payment) => (
                        <tr key={payment.id}>
                          <td className="id">{payment.id}</td>
                          <td className="amount">
                            {Number(payment.amount_paid).toLocaleString(
                              'pt-br',
                              {
                                style: 'currency',
                                currency: 'BRL',
                              }
                            )}
                          </td>
                          <td className="installments">
                            {payment.installments}
                          </td>
                          <td className="installments">
                            {payment.merchant_transaction_id}
                          </td>
                          <td className="created_at">{payment.created_at}</td>
                          <td className="status">
                            {payment.first_four &&
                              `${payment.first_four}********${payment.last_four}`}
                          </td>
                          <td className="status">{payment.nsu}</td>
                          <td className="status">{payment.acquirer_name}</td>
                          <td className="status">{payment.return_code}</td>
                          <td className="status">
                            {payment.definition
                              ? payment.definition.replace(
                                  ' Suspeita de fraude.',
                                  ''
                                )
                              : ''}
                          </td>
                          {/* <td className="message">{payment.status_code == 7 ? "Transação Recusada" : "Transação Aceita"}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="filterCpf">
                    Buscar por CPF
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="filterCpf"
                    name="filterCpf"
                    placeholder="Digite o Nº do CPF"
                    value={cpf}
                    onChange={onChange}
                  />
                  <ul className="corban-list">
                    {autocompleteData &&
                      autocompleteData.map((item) => (
                        <li key={item.id} className="item-list">
                          {renderItem(item, false)}
                        </li>
                      ))}
                  </ul>
                </FormGroup>
                {/* <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban',
                    'master_subest',
                    'user_subest',
                    'master_store',
                    'user_seller',
                    'user_store',
                  ]}
                >
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="exampleCustomSelect"
                    >
                      Status
                    </Label>
                    <CustomInput
                      type="select"
                      id="exampleCustomSelect"
                      name="customSelect"
                      onChange={(e) => setPipeFilterStatus(e.target.value)}
                      value={pipeFilterStatus}
                    >
                      <option value="">Todos</option>
                      <option value="0">Operações no Simulador</option>
                      <option value="7">Aguardando Formalização</option>
                      <option value="1">Pendente documentos</option>
                      <option value="2">Aguardando Análise Documental</option>
                      <option value="3">Aguardando Assinatura</option>
                      <option value="4">Contratos Assinados</option>
                      <option value="5">Contratos Pagos</option>
                      <option value="8">Comissão Paga</option>
                      <option value="9">Pagamento Devolvido</option>
                      <option value="14">Pendente Análise</option>
                      <option value="13">Reanálise</option>
                      <option value="16">Biometria Facial em Análise</option>
                      <option value="15">Análise de Risco</option>
                      <option value="6">Cancelado</option>
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles> */}
                {/* <VisibilityByUserRoles roles={['master', 'operational']}>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="blacklist">
                      Blacklist
                    </Label>
                    <CustomInput
                      type="select"
                      id="blacklist"
                      name="blacklist"
                      onChange={(e) => setFilterBlack(e.target.value)}
                      value={filterBlack}
                    >
                      <option value="">Todos</option>
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles> */}
                {/* <VisibilityByUserRoles roles={["commercial_manager", "commercial_manager_internal", "master_store"]}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="idpartner">
                      Promotor
                    </label>
                    <CustomInput
                      type="select"
                      id="idpartner"
                      name="idpartner"
                      value={filterSeller}
                      onChange={(e) => setFilterSeller(e.target.value)}
                    >
                      <option value="">{representatives.length > 0 ? "Selecione uma opção" : "Carregando dados"}</option>
                      {representatives.map((representative) => (
                        <option
                          key={representative.id}
                          value={representative.id}
                        >
                          {representative.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles> */}
                <FormGroup>
                  <label className="form-control-label" htmlFor="contract">
                    Nº de Contrato
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contract"
                    name="contract"
                    placeholder="Digite o Nº do Contrato"
                    value={filterContract}
                    onChange={(e) => setFilterContract(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                {/* <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban',
                    'master_subest',
                    'user_subest',
                    'master_store',
                    'user_seller',
                    'user_store',
                  ]}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Digite o Email"
                      value={filterEmail}
                      onChange={(e) => setFilterEmail(e.target.value)}
                    />
                  </FormGroup>
                </VisibilityByUserRoles> */}
                <VisibilityByUserRoles roles={['master', 'operational']}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="regional_manager"
                    >
                      Gerente Regional
                    </label>
                    <CustomInput
                      type="select"
                      id="regional_manager"
                      name="regional_manager"
                      value={filterRegionalManager}
                      onChange={(e) => setFilterRegionalManager(e.target.value)}
                    >
                      <option value="">
                        {regionalManagers.length > 0
                          ? 'Selecione uma opção'
                          : 'Carregando dados'}
                      </option>
                      {regionalManagers.map((manager) => (
                        <option key={manager.id} value={manager.id}>
                          {manager.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles
                  roles={['master', 'operational', 'regional_manager']}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="idpartner">
                      Gerente Comercial
                    </label>
                    <CustomInput
                      type="select"
                      id="idpartner"
                      name="idpartner"
                      value={filterManager}
                      onChange={(e) => setFilterManager(e.target.value)}
                    >
                      <option value="">
                        {managers.length > 0
                          ? 'Selecione uma opção'
                          : 'Carregando dados'}
                      </option>
                      {managers.map((manager) => (
                        <option key={manager.id} value={manager.id}>
                          {manager.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                {user &&
                (user.roles[0].name == 'master' ||
                  user.roles[0].name == 'operational') ? (
                  <>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="corban">
                        Corban
                      </label>
                      <CustomInput
                        type="select"
                        id="corban"
                        name="corban"
                        placeholder="Escolha a Corban"
                        onChange={(e) => setFilterCorban(e.target.value)}
                        value={filterCorban}
                      >
                        <option value="">
                          {corbans.length > 0
                            ? 'Selecione uma opção'
                            : 'Carregando dados'}
                        </option>
                        {corbans.map((corban) => (
                          <option key={corban.id} value={corban.id}>
                            {corban.name}
                          </option>
                        ))}
                      </CustomInput>
                    </FormGroup>
                    {/* <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleCustomSelect"
                      >
                        Tipo de Corban
                      </Label>
                      <CustomInput
                        type="select"
                        id="exampleCustomSelect"
                        name="customSelect"
                        onChange={(e) => setFilterCorbanType(e.target.value)}
                        value={filterCorbanType}
                      >
                        <option value="">Todos</option>
                        <option value="0">Interno</option>
                        <option value="1">Externo</option>
                      </CustomInput>
                    </FormGroup> */}
                  </>
                ) : (
                  false
                )}
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban',
                  ]}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="sub">
                      Subestabelecimento
                    </label>
                    <CustomInput
                      type="select"
                      id="sub"
                      name="sub"
                      placeholder="Escolha o Subestabelecimento"
                      onChange={(e) => setFilterSub(e.target.value)}
                      value={filterSub}
                    >
                      <option value="">
                        {subs.length > 0
                          ? 'Selecione uma opção'
                          : 'Carregando dados'}
                      </option>
                      {subs.map((sub) => (
                        <option key={sub.id} value={sub.id}>
                          {sub.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban',
                    'master_subest',
                    'user_subest',
                    'commercial_manager',
                    'commercial_manager_internal',
                    'master_store',
                  ]}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="store">
                      Loja
                    </label>
                    <CustomInput
                      type="select"
                      id="store"
                      name="store"
                      placeholder="Escolha a Loja"
                      onChange={(e) => {
                        setFilterStore(e.target.value);
                        getSellers(e.target.value);
                      }}
                      value={filterStore}
                    >
                      <option value="">
                        {stores.length > 0
                          ? 'Selecione uma opção'
                          : 'Carregando dados'}
                      </option>
                      {stores.map((store) => (
                        <option key={store.id} value={store.id}>
                          {store.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles
                  roles={[
                    'master',
                    'operational',
                    'regional_manager',
                    'master_corban',
                    'user_corban',
                    'master_subest',
                    'user_subest',
                    'commercial_manager',
                    'commercial_manager_internal',
                    'master_store',
                  ]}
                >
                  <FormGroup>
                    <label className="form-control-label" htmlFor="store">
                      Vendedor
                    </label>
                    <CustomInput
                      type="select"
                      id="seller"
                      name="seller"
                      placeholder="Escolha a Loja"
                      onChange={(e) => setFilterSeller(e.target.value)}
                      value={filterSeller}
                    >
                      <option value="">
                        {sellers.length > 0
                          ? 'Selecione uma opção'
                          : 'Carregando dados'}
                      </option>
                      {sellers.map((seller) => (
                        <option key={seller.id} value={seller.id}>
                          {seller.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button
            color="success"
            onClick={() => handlerOnClickFilter(true, false)}
          >
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
      {customerAux && (
        <Modal
          isOpen={bankDataModal && customerAux}
          toggle={handleToggleBankData}
          className={props.className}
          backdrop="static"
        >
          {' '}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h4 style={{ fontSize: 16, paddingTop: 24, paddingLeft: 24 }}>
              Favor corrigir abaixo os dados bancários já apresentados.
            </h4>
            <ModalBody>
              <>
                <Row>
                  <Col lg={'12'}>
                    <FormGroup>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="color"
                        value={{
                          value: customerAux.customer.name_bank,
                          label: customerAux.customer.name_bank,
                        }}
                        options={banks}
                        onChange={handleOnChangeBank}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex align-items-center text-danger">
                    <h5 className="badge badge-warning">
                      A conta informada deve estar em nome do cliente e apenas
                      pessoa física
                    </h5>
                  </Col>
                  <Col className="d-flex align-items-center text-danger">
                    <h5 className="badge badge-warning">
                      Reapresentações feitas após às 16:00 serão pagas no
                      próximo dia útil
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col lg={'6'}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="agency_number"
                      >
                        Agência
                      </label>
                      <InputMask
                        type="text"
                        name="agency_account"
                        className="form-control form-control-alternative"
                        id="agency_account"
                        mask="9999"
                        maskChar=""
                        value={customerAux.customer.agency_account}
                        inputRef={register({
                          required:
                            customerAux.kind_account == 'ted' ? true : false,
                        })}
                        onChange={handleOnChange}
                      />
                      {errors.agency_account && (
                        <small className="text-danger">
                          A agência é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="2"></Col>
                  <Col lg={'6'}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="number_account"
                      >
                        Conta Corrente
                      </label>
                      <InputMask
                        type="text"
                        name="number_account"
                        className="form-control form-control-alternative"
                        id="number_account"
                        value={
                          customerAux?.customer?.number_account?.split('-')[0]
                        }
                        inputRef={register({
                          required:
                            customerAux.kind_account == 'ted' ? true : false,
                        })}
                        onChange={handleOnChange}
                      />
                      {errors.number_account && (
                        <small className="text-danger">
                          A conta é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="agency_number"
                      >
                        Dígito
                      </label>
                      <InputMask
                        type="text"
                        name="account_digit"
                        className="form-control form-control-alternative"
                        id="account_digit"
                        mask="*"
                        maskChar=""
                        value={customerAux?.customer?.account_digit}
                        inputRef={register({
                          required:
                            customerAux.customer.kind_account == 'ted'
                              ? true
                              : false,
                        })}
                        onChange={handleOnChange}
                      />
                      {errors.account_digit && (
                        <small className="text-danger">
                          O digito da conta é obrigatório
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                {/*<Row>
                  <Col lg={'6'}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="account_kind"
                      >
                        Tipo de conta
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        id="account_kind"
                        name="account_kind"
                        value={{
                          value: customerAux.customer.account_kind,
                          label: customerAux.customer.account_kind,
                        }}
                        onChange={handleOnChangeTypeAccount}
                        placeHolder="Selecione o tipo"
                        options={optionsAccount}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
              </>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={handleToggleBankData}>
                Fechar
              </Button>
              <Button
                color="success"
                onClick={handleToggleBankData}
                type="submit"
                disabled={disabledBankData}
              >
                Alterar
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
      <Modal
        isOpen={pipelineModal}
        toggle={handleTogglePipeline}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleTogglePipeline}>
          Alteração da pipeline
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="status">
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="status"
                    name="status"
                    onChange={handleOnPipeline}
                    value={pipeline && pipeline.status}
                  >
                    <option value="">Selecionar</option>
                    <option value="0">Operações no Simulador</option>
                    <option value="7">Aguardando Formalização</option>
                    <option value="1">Pendente Documentos</option>
                    <option value="2">Aguardando Análise Documental</option>
                    {selectedContracts.length <= 0 && (
                      <option value="3">Aguardando Assinatura</option>
                    )}
                    <option value="4">Contratos Assinados</option>
                    <option value="5">Contratos Pagos</option>
                    <option value="8">Comissões Pagas</option>
                    <option value="9">Pagamento Devolvido</option>

                    <option value="14">Pendente Análise</option>
                    <option value="13">Reanálise</option>
                    {/* <option value="16">Biometria Facial em Análise</option> */}
                    <option value="15">Análise de Risco</option>
                    <option value="12">Recusado</option>
                    {/* <option value="13">Reanálise</option> */}
                    <option value="6">Cancelado</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="reason">
                    Razão
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="reason"
                    name="reason"
                    placeholder="Digite a razão"
                    onChange={handleOnPipeline}
                  />
                </FormGroup>
                {pipeline.status == '3' && (
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="bank_kind">
                      Selecionar Banco
                    </Label>
                    <CustomInput
                      type="select"
                      id="bank_kind"
                      name="bank_kind"
                      onChange={(e) => setBankKind(e.target.value)}
                      value={bankKind}
                    >
                      <option value="">Selecionar</option>
                      <option value="money_plus">Money Plus</option>
                      {/* <option value="fiducia">Fidúcia</option> */}
                      <option value="giro">Giro</option>
                    </CustomInput>
                  </FormGroup>
                )}
                {pipeline.status == '8' && (
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="commission_payment_date"
                    >
                      Data de pagamento da comissão
                    </label>
                    <input
                      type="date"
                      name="commission_payment_date"
                      className="form-control form-control-alternative"
                      id="commission_payment_date"
                      value={pipeline && pipeline.commission_payment_date}
                      onChange={handleOnPipeline}
                    />
                  </FormGroup>
                )}
                {pipeline.status == '5' && (
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="payment_date"
                    >
                      Data de pagamento do contrato
                    </label>
                    <input
                      type="date"
                      name="payment_date"
                      className="form-control form-control-alternative"
                      id="payment_date"
                      value={pipeline && pipeline.payment_date}
                      onChange={handleOnPipeline}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleTogglePipeline}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickPipeline}>
            Alterar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={openReanalysisModal}
        toggle={openReanalysis}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={openReanalysis}>Reanálise</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <DocumentUploadContract
                  documentFace={documentFace}
                  setDocumentFace={setDocumentFace}
                  documentFacePersist={documentFacePersist}
                  setDocumentFacePersist={setDocumentFacePersist}
                  documentRg={documentRg}
                  setDocumentRg={setDocumentRg}
                  documentRgPersist={documentRgPersist}
                  setDocumentRgPersist={setDocumentRgPersist}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="reason">
                    Razão
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="reason"
                    name="reason"
                    placeholder="Digite a razão"
                    onChange={handleOnReanalysis}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={openReanalysis}>
            Fechar
          </Button>
          <Button color="success" onClick={sendReanalysis}>
            Alterar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={reportModal}
        toggle={handleToggleReportModal}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleReportModal}>
          Insira o email para solicitar o relatorio
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email_report">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email_report"
                    name="email_report"
                    placeholder="Digite o Email"
                    onChange={(e) => setEmailReport(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleReportModal}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickReportModal}>
            Solicitar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={customerDataModal}
        toggle={() => setCustomerDataModal(!customerDataModal)}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={() => setCustomerDataModal(!customerDataModal)}>
          Reapresentar Pagamento
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              {customerData && contractData && (
                <UpdateCustomerForm
                  onlyBank={
                    user &&
                    (user.roles[0].name == 'master' ||
                      user.roles[0].name == 'operational')
                  }
                  id={customerData}
                  contractId={contractData}
                  handleModal={setCustomerDataModal}
                />
              )}
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => setCustomerDataModal(!customerDataModal)}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={customerDocumentModal}
        toggle={() => setCustomerDocumentModal(!customerDocumentModal)}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader
          toggle={() => setCustomerDocumentModal(!customerDocumentModal)}
        >
          Reapresentar Documento
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              {customerDocument && (
                <DocumentUploadCustomerFgts
                  id={customerDocument}
                  userId={user}
                  kind={kind}
                  title="Selecione os arquivos indicados"
                  documentFace={documentFace}
                  setDocumentFace={setDocumentFace}
                  documentFacePersist={documentFacePersist}
                  setDocumentFacePersist={setDocumentFacePersist}
                  documentRg={documentRg}
                  setDocumentRg={setDocumentRg}
                  documentRgPersist={documentRgPersist}
                  setDocumentRgPersist={setDocumentRgPersist}
                  handleSubmit={uploadDocumentClient}
                />
              )}
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => setCustomerDocumentModal(!customerDocumentModal)}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={customerDocumentModalQista}
        toggle={() =>
          setCustomerDocumentModalQista(!customerDocumentModalQista)
        }
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader
          toggle={() =>
            setCustomerDocumentModalQista(!customerDocumentModalQista)
          }
        >
          Reenviar Documentos Qista
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              {customerDocument && (
                <DocumentUploadCustomerQista
                  id={customerDocument}
                  title="Selecione os arquivos indicados"
                  handleSubmit={uploadDocumentQista}
                />
              )}
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => setCustomerDocumentModal(!customerDocumentModal)}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={changeCustomerIndex}
        toggle={handleToggleChangeCustomerIndex}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleChangeCustomerIndex}>
          Dados Alterados
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <p>
                  <strong></strong>
                </p>
                <p className="mb-0">
                  {/* <strong>Nº do Contrato</strong>:{' '}
                  {paymentInformation.contractNumber} */}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="bg-default shadow">
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" className="created_at">
                          Atributo
                        </th>
                        <th scope="col" className="status">
                          Atual
                        </th>
                        <th scope="col" className="id">
                          Anterior
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {changesCustomers &&
                        changesCustomers.changes &&
                        changesCustomers.changes.map((pipe, index) => (
                          <tr key={pipe.attribute}>
                            <td className="id">{pipe.attribute}</td>
                            <td className="reason">{pipe.new_value}</td>
                            <td className="created_at">{pipe.old_value}</td>
                          </tr>
                        ))}
                      {changesCustomers &&
                        changesCustomers.change_entity &&
                        changesCustomers.change_entity.changes &&
                        changesCustomers.change_entity.changes.map(
                          (pipe, index) => (
                            <tr key={pipe.attribute}>
                              <td className="id">{pipe.attribute}</td>
                              <td className="reason">{pipe.new_value}</td>
                              <td className="created_at">{pipe.old_value}</td>
                            </tr>
                          )
                        )}
                      {changesCustomers &&
                        changesCustomers.change_bank_account &&
                        changesCustomers.change_bank_account.changes &&
                        changesCustomers.change_bank_account.changes.map(
                          (pipe, index) => (
                            <tr key={pipe.attribute}>
                              <td className="id">{pipe.attribute}</td>
                              <td className="reason">{pipe.new_value}</td>
                              <td className="created_at">{pipe.old_value}</td>
                            </tr>
                          )
                        )}
                      {changesCustomers &&
                        changesCustomers.change_address &&
                        changesCustomers.change_address.changes &&
                        changesCustomers.change_address.changes.map(
                          (pipe, index) => (
                            <tr key={pipe.attribute}>
                              <td className="id">{pipe.attribute}</td>
                              <td className="reason">{pipe.new_value}</td>
                              <td className="created_at">{pipe.old_value}</td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleChangeCustomerIndex}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={pipelineIndex}
        toggle={handleToggleIndexPipeline}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleIndexPipeline}>
          Movimentações
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <p>
                  <strong></strong>
                </p>
                <p className="mb-0">
                  {/* <strong>Nº do Contrato</strong>:{' '}
                  {paymentInformation.contractNumber} */}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="bg-default shadow">
                  <Table
                    className="align-items-center table-dark table-flush"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" className="id">
                          ID
                        </th>
                        <th scope="col" className="status">
                          Modificação
                        </th>
                        <th scope="col" className="user_change">
                          Quem realizou
                        </th>
                        <th scope="col" className="reason">
                          Razão
                        </th>
                        <th scope="col" className="created_at">
                          Data da mudança
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pipelineContracts.map((pipe, index) => (
                        <tr key={pipe.id}>
                          <td className="id">{pipe.id}</td>
                          <td className="status">
                            {pipe.status}
                            {/* {
                              index == 0 ?
                                `Criação da pipeline ${pipe.status}`
                              :
                                `Modificação do status de ${pipelineContracts[index-1].status} para ${pipe.status}`
                            } */}
                          </td>
                          <td className="user_change">
                            {pipe.user ? pipe.user.name : 'Sistema'}
                          </td>
                          <td className="reason">{pipe.reason}</td>
                          <td className="created_at">{pipe.created_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleIndexPipeline}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalDate}
        toggle={handleClickModalDate}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleClickModalDate}>
          Pesquisar por data personalisada
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="status">
                    Data inicial
                  </Label>
                  <input
                    type="date"
                    id="min"
                    name="min"
                    className="form-control form-control-alternative"
                    onChange={handleOnChangeRangeDate}
                    value={minDate}
                  ></input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="status">
                    Data final
                  </Label>
                  <CustomInput
                    type="date"
                    id="max"
                    name="max"
                    className="form-control form-control-alternative"
                    onChange={handleOnChangeRangeDate}
                    value={maxDate}
                  ></CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleClickModalDate}>
            Fechar
          </Button>
          <Button color="success" onClick={handleClickDateSearch}>
            Pesquisar
          </Button>
        </ModalFooter>
      </Modal>
      <UpdateContractNumber
        className={props.className}
        contractNumberModal={contractNumberModal}
        handleToggleNumberModal={handleToggleNumberModal}
        handleOnContractNumber={handleOnContractNumber}
        handlerOnClickNumberModal={handlerOnClickNumberModal}
      />
      <UpdateBankAccount
        className={props.className}
        toggleModalOpen={() => setBankAccountModal(!bankAccountModal)}
        modalOpen={bankAccountModal}
        id={customerId}
        contractId={contractId}
      />
    </>
  );
};

export default ContractsFgts;
