import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError } from '../../util/toast';
import PaginationComponent from '../../components/PaginationComponent';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { VisibilityCreateStore, VisibilityEditStore } from '../../util/visibility';
// import './style.css';
import Search from '../../components/Search';
import { States } from '../../variables/assistants'
import { VisibilityByUserRoles } from '../../util/visibility';
import { AuthContext } from '../../context/AuthContext';

const LIMIT = 10;

const StoreCommission = (props) => {
  const { user } = useContext(AuthContext);
  const exportStoreRef = useRef(exportStoreRef)
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [managers, setManagers] = useState([]);
  const [corbans, setCorbans] = useState([]);
  const [subs, setSubs] = useState([]);
  const [submitModal, setSubmitModal] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [managerStore, setManagerStore] = useState('');
  const handleToggleSubmit = (id=null, managerId=null) => {
    setStoreId(id)
    setManagerStore(managerId)
    setSubmitModal(!submitModal);
  }

  const [stores, setStores] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);
  const [cnpj, setCnpj] = useState('');
  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterCommission, setFilterCommission] = useState('');
  const [filterManager, setFilterManager] = useState('');
  const [filterCorban, setFilterCorban] = useState('');
  const [filterSub, setFilterSub] = useState('');
  const [filterLegalRepresentative, setFilterLegalRepresentative] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('')
  const [exportStoreData, setExportStoreData] = useState([])
  const [storeReportDisabled, setStoreReportDisabled] = useState(false)

  async function getCorbans() {
    // showLoader();

    try {
      const response = await api.get(
        `/corbans/searchs`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setCorbans(response.data);


      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível listar os corbans.');
    }
  }

  async function getSubs() {
    // showLoader();

    try {
      const response = await api.get(
        `/subestablishments/searchs`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setSubs(response.data);


      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível listar os subs.');
    }
  }

  async function getManagers() {
    // showLoader();

    try {
      const response = await api.get(
        `users/user_managers`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setManagers(response.data);


      // hideLoader();
    } catch (error) {
      // hideLoader();
      toastError('Ops, não foi possível listar os gerentes.');
    }
  }

  async function getStores() {
    showLoader();

    try {
      const response = await api.get(
        `stores/commissions?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setStores(response.data);
      setNumberRegister(response.headers.total_count)

      const totalPages = response.headers.total_pages

      const arrayPages = [];
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i);
      }

      setPages(arrayPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/stores',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }

  useEffect(() => {

    getStores();
  }, [offset, searchFilter]);

  useEffect(()=> {
    if(user && user.roles[0] && (user.roles[0].name == "master" || user.roles[0].name == "operational")){
      getManagers()
      getCorbans()
      getSubs()
    }
  }, [user])

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  async function handlerOnClickSubmit() {
    try {
      const { data } = await api.put(`stores/${storeId}/active`, {status: "active", user_manager: managerStore}, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setManagerStore(null)
      setStoreId(null)
      getStores();
      getManagers()
      handleToggleSubmit();
    } catch (e) {
      console.error('Ops, houve um erro ao atualizar a loja');
    }

  }

  async function disabledStore(store_id) {
    try {
      const { data } = await api.put(`stores/${store_id}`, {store: {status: "disabled"}}, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setManagerStore(null)
      setStoreId(null)
      getStores();
      getManagers()
    } catch (e) {
      console.error('Ops, houve um erro ao atualizar a loja');
    }

  }

  async function removeStore(store_id) {
    try {
      const { data } = await api.delete(`stores/${store_id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setManagerStore(null)
      setStoreId(null)
      getStores();
      getManagers()
    } catch (e) {
      console.log(e.response)
      toastError(e.response.data.error);
      console.error('Ops, houve um erro ao excluir a loja');
    }

  }


  async function handleOnClick() {

    if (cnpj.length > 0) {
      try {
        const { data } = await api.get(
          `stores/commissions?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cnpj}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setStores(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CNPJ.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(`stores/commissions?q[entity_cpf_cnpj_cont]=${searchText}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CNPJ');
    }
  }

  async function onChange(e) {
    setCnpj(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  function onSelect(val) {
    setCnpj(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item["cpf_cnpj"]} - {item["name"]}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item["cpf_cnpj"]}`;
  }

  function handlerOnClickFilter() {
    if (params.get('page')) {
      setOffset(1);
    }

    let filterPath = '';

    if (filterStatus) {
      filterPath += `&q[status_eq]=${filterStatus}`;
    }

    if (filterName) {
      filterPath += `&q[entity_name_cont]=${filterName}`;
    }

    if (filterState) {
      filterPath += `&q[entity_address_state_eq]=${filterState}`;
    }

    if (filterCommission) {
      filterPath += `&q[entity_has_commission_eq]=${filterCommission}`;
    }

    if (filterManager) {
      filterPath += `&q[user_manager_id_eq]=${filterManager}`;
    }

    if (filterCorban) {
      filterPath += `&q[corban_id_or_subestablishment_corban_id_eq]=${filterCorban}`;
    }

    if (filterSub) {
      filterPath += `&q[subestablishment_id_eq]=${filterSub}`;
    }

    if (filterLegalRepresentative) {
      filterPath += `&legal_representative=${filterLegalRepresentative}`;
    }

    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    setFilterStatus('')
    setFilterName('')
    setFilterState('')
    setFilterCommission('')
    setFilterManager('')
    setFilterCorban('')
    setFilterSub('')
    setFilterLegalRepresentative('')
    setSearchFilter('')
    handleToggleFilter()
  }

  async function export_store_report() {
    setStoreReportDisabled(true)
    try {
      const response = await api.get(
        `stores/store_export?${searchFilter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        })
        console.log(response.data)
      setExportStoreData(response.data)
      exportStoreRef?.current?.link.click();
    } catch(e) {

    }
    setStoreReportDisabled(false)
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <div className="text-right">
                <VisibilityByUserRoles roles={["master", "operational", "master_corban", "user_corban_register", "user_corban", "master_subest", "user_subest"]}>
                  <Link
                    className="btn btn-success mb-3 mt-2"
                    to="/admin/stores/create"
                  >
                    <i className="fas fa-plus" /> Criar nova loja
                  </Link>
                </VisibilityByUserRoles>
              </div>
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Lojas</h3>
                      <Search
                        getItemValue={getItemValue}
                        autoCompleteData={autocompleteData}
                        renderItem={renderItem}
                        cpf_cnpj={cnpj}
                        onChange={onChange}
                        onSelect={onSelect}
                        handleOnClick={handleOnClick}
                        placeHolder="Buscar pelo CNPJ"
                      />
                    </Col>
                    <Col lg="5" className="mt-3 mt-lg-0">
                      <>
                        <div className="d-flex justify-content-end h-100 align-items-end">
                          {/* {(
                            <>
                              <button
                                disabled={storeReportDisabled}
                                className="btn btn-primary"
                                onClick={export_store_report}
                              >
                                Exportar Lojas
                              </button>
                              <CSVLink
                                data={exportStoreData}
                                filename={"store_report.csv"}
                                separator={";"}
                                ref={exportStoreRef}
                              ></CSVLink>
                            </>
                          )} */}
                          {(
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleToggleFilter}
                            >
                              <i className="fas fa-filter" /> Filtrar
                            </button>
                          )}
                        </div>
                      </>
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {
                    numberRegister > 0
                    ?
                    <div className="text-white">Registros encontrados {LIMIT*(offset-1)+1} - {numberRegister <= LIMIT ?  numberRegister : LIMIT*offset} de {numberRegister}</div>
                    :
                    <div className="text-white">Nenhum registro encontrado</div>
                  }
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CPF/CNPJ</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Número de Contratos Pagos</th>
                      <th scope="col">Total Vendido</th>
                      <th scope="col">Comissionamento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stores.map((store) => (
                      <tr key={store.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {store.cpf_cnpj.replace(
                                  /^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/,
                                  '$1.$2.$3/$4-$5'
                                )}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{store.name}</td>
                        <td>{store.total_contract}</td>
                        <td>{store.total}</td>
                        <td>{store.commission}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {pages.length > 1 && (
                  <CardFooter className="py-4 bg-transparent">
                    <PaginationComponent
                      pages={pages}
                      offset={offset}
                      setOffset={setOffset}
                    />
                  </CardFooter>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setFilterStatus(e.target.value)}
                    value={filterStatus}
                  >
                    <option value="">Todos</option>
                    <option value="0">Ativo</option>
                    <option value="1">Inativo</option>
                    <option value="2">Aguardando Ativação</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="legal_representative">
                    Representante Legal
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="legal_representative"
                    name="legal_representative"
                    placeholder="Digite o Nome do Representante Legal"
                    value={filterLegalRepresentative}
                    onChange={(e) => setFilterLegalRepresentative(e.target.value)}
                  />
                </FormGroup>
                <VisibilityByUserRoles roles={["master", "operational"]}>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="commercial_manager"
                    >
                      Gerente Comercial
                    </Label>
                    <CustomInput
                      type="select"
                      id="commercial_manager"
                      name="commercial_manager"
                      onChange={(e) => setFilterManager(e.target.value)}
                      value={filterManager}
                    >
                      <option value="">Selecionar</option>
                      {managers.map((manager) => (
                        <option value={manager.id}>{manager.name}</option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles roles={["master", "operational"]}>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="corban"
                    >
                      Corban
                    </Label>
                    <CustomInput
                      type="select"
                      id="corban"
                      name="corban"
                      onChange={(e) => setFilterCorban(e.target.value)}
                      value={filterCorban}
                    >
                      <option value="">Selecionar</option>
                      {corbans.map((corban) => (
                        <option value={corban.id}>{corban.name}</option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <VisibilityByUserRoles roles={["master", "operational"]}>
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      htmlFor="subs"
                    >
                      Subestabelecimento
                    </Label>
                    <CustomInput
                      type="select"
                      id="subs"
                      name="subs"
                      onChange={(e) => setFilterSub(e.target.value)}
                      value={filterSub}
                    >
                      <option value="">Selecionar</option>
                      {subs.map((sub) => (
                        <option value={sub.id}>{sub.name}</option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                </VisibilityByUserRoles>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome Fantasia
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome Fantasia"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="state"
                  >
                    Estado
                  </Label>
                  <CustomInput
                    type="select"
                    id="state"
                    name="state"
                    onChange={(e) => setFilterState(e.target.value)}
                    value={filterState}
                  >
                    {States.map((state) => (
                      <option value={state.value}>{state.name}</option>
                    ))}
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="commission"
                  >
                    Comissão Ativa
                  </Label>
                  <CustomInput
                    type="select"
                    id="commission"
                    name="commission"
                    onChange={(e) => setFilterCommission(e.target.value)}
                    value={filterCommission}
                  >
                    <option value="">Selecione</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default StoreCommission;
