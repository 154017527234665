import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Badge,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import swal from 'sweetalert';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import PaginationComponent from '../../components/PaginationComponent';
import Search from '../../components/Search';
import token from '../../util/setAuthToken';

import './style.css';

const LIMIT = 10;

const SimulatorSetting = (props) => {
  const params = new URLSearchParams(props.location.search);

  const [settings, setSettings] = useState([]);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  //Paginação
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);

  async function getSimulatorSettings() {
    showLoader();

    try {
      const response = await api.get(
        `simulator_settings?page=${offset}&per_page=${LIMIT}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setSettings(response.data);

      const totalPages = response.headers.total_pages

      const arrayPages = [];
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i);
      }

      setPages(arrayPages);
      console.log(offset)
      if (offset > 1) {
        props.history.push({
          pathname: '/admin/simulator-settings',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível listar as configurações .');
    }

  }

  useEffect(() => {
    getSimulatorSettings()
  }, [offset]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7 mb" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            <div className="text-right">
              <Link
                className="btn btn-default mb-3 mt-2"
                to="/admin/simulator-settings/create"
              >
                <i className="fas fa-plus" /> Configurações do Simulador
              </Link>
            </div>

            <Card className="bg-default shadow">
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">IDº</th>
                    <th scope="col">Taxa Mínima</th>
                    <th scope="col">Taxa Máxima</th>
                    {/* <th scope="col">Comissão Mínima</th>
                    <th scope="col">Comissão Máxima</th> */}
                    <th scope="col">Data de Criação</th>
                    <th scope="col">Criado Por</th>
                    {/* <th scope="col" className="text-right">
                      Ações
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {settings.map((setting, index) => (
                    <tr key={setting.id} className={`${index == 0 && 'bg-primary'}`}>
                      <th scope="row" className="word-wrap">
                        <Media className="align-items-center w-100">
                          <Media className="w-100">
                            <span className="mb-0 text-sm w-100">
                              {setting.id}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{setting.min_rate}%</td>
                      <td>{setting.max_rate}%</td>
                      {/* <td>{setting.min_commission}%</td>
                      <td>{setting.max_commission}%</td> */}
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-yellow" />
                          {moment(setting.created_at).format(
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                        </Badge>
                      </td>
                      <td>{setting.author}</td>
                      {/* <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>

                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {pages.length > 1 && (
                <CardFooter className="py-4 bg-transparent">
                  <PaginationComponent
                    pages={pages}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SimulatorSetting;
