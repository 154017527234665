import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input
} from 'reactstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import axios from 'axios';
import { toastError } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import CurrencyInput from 'react-currency-input';
import { VisibilityByUserRoles } from 'util/visibility';
import api from '../../services/api';
import token from '../../util/setAuthToken';

const SellerForm = ({
  seller,
  stores,
  hasEdit,
  titleButton,
  title,
  onSubmit,
  handleOnChange,
  dataAddress,
  insertMoney,
  insertPercentage,
  handleOnChangeStore,
  handleOnChangeValueFix,
  register,
  handleSubmit,
  errors
}) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [ addressDisabled, setAddressDisabled ] = useState(true);
  async function handleBlurZipCode(event) {
    let cep = event.target.value;
    try {
      if (cep.length > 0) {
        showLoader();

        cep = cep.replace('-', '');

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!('erro' in data)) {
          dataAddress({
            street: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            state: data.uf,
          });
          setAddressDisabled(true)
        } else {
          dataAddress({
            street: '',
            district: '',
            city: '',
            state: '',
          });
          toastError('CEP inválido');
          setAddressDisabled(false)
        }

        hideLoader();
      }
    } catch (e) {
      hideLoader();
    }
  }

  async function handleChangeEmail(e) {
    const { value } = e.target;
    handleOnChange(e);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      const { data } = await api.get(
        `contracts/check_email_exist?email=${value}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data.message === 'E-mail já consta no sistema') {
        return toastError('E-mail já consta no sistema');
      }
    }
  }

  async function handleChangeCpf(e) {
    const { value } = e.target;
    handleOnChange(e);
    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    if (cpfRegex.test(value)) {
      const cpf = value.replace(/[^0-9]+/g, '');
      const { data } = await api.get(
        `contracts/check_cpf_exist?cpf_cnpj=${cpf}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data.message === 'CPF já consta no sistema') {
        return toastError('CPF já consta no sistema');
      }
    }
  }

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">{title}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações do Vendedor
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <VisibilityByUserRoles
                        roles={[
                          'master',
                          'operational',
                          'master_corban',
                          'user_corban_register',
                        ]}
                      >
                        <Col lg="6">
                          <label
                            className="form-control-label"
                            htmlFor="social_reason"
                          >
                            Selecione a Loja
                          </label>
                          <FormGroup>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              name="color"
                              // placeholder="Selecione a loja"
                              value={{
                                value: seller.store_id,
                                label: seller.store_id,
                              }}
                              options={stores}
                              onChange={handleOnChangeStore}
                            />
                          </FormGroup>
                        </Col>
                      </VisibilityByUserRoles>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome do Vendedor
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            id="name"
                            name="name"
                            // placeholder="Nome da Vendedor"
                            value={seller && seller.name}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />

                          {errors.name && (
                            <small className="text-danger">
                              O nome do vendedor é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="cpf_cnpj"
                          >
                            CPF
                          </label>
                          <input
                            type="text"
                            name="cpf_cnpj"
                            // placeholder="999.999.999-99"
                            className="form-control form-control-alternative"
                            id="cpf_cnpj"
                            value={seller && seller.cpf_cnpj}
                            ref={register({ required: true })}
                            onChange={handleChangeCpf}
                          />
                          {errors.cpf_cnpj && (
                            <small className="text-danger">
                              {Object.values(errors.cpf_cnpj)
                                .reduce((t, n) => t + n, '')
                                .split('[')[0] === 'required'
                                ? 'CPF é obrigatório'
                                : `CPF ${
                                    Object.values(errors.cpf_cnpj)
                                      .reduce((t, n) => t + n, '')
                                      .split('[')[0]
                                  }`}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Contato do Vendedor
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email (Login de acesso)
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control form-control-alternative"
                            id="email"
                            value={seller && seller.email}
                            // placeholder="example@example.com"
                            ref={register({ required: true })}
                            onChange={handleChangeEmail}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              {Object.values(errors.email)
                                .reduce((t, n) => t + n, '')
                                .split('[')[0] === 'required'
                                ? 'Email é obrigatório'
                                : `Email ${
                                    Object.values(errors.email)
                                      .reduce((t, n) => t + n, '')
                                      .split('[')[0]
                                  }`}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="mobile"
                          >
                            Celular
                          </label>
                          <input
                            type="text"
                            name="mobile"
                            className="form-control form-control-alternative"
                            id="mobile"
                            maskChar=""
                            value={seller && seller.mobile}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.mobile && (
                            <small className="text-danger">
                              O celular é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Senha
                          </label>
                          <InputMask
                            type="password"
                            name="password"
                            // placeholder="Senha"
                            className="form-control form-control-alternative"
                            id="password"
                            value={seller && seller.password}
                            inputRef={register({
                              required: hasEdit ? false : true,
                            })}
                            onChange={handleOnChange}
                          />
                          {errors.password && (
                            <small className="text-danger">
                              {Object.values(errors.password)
                                .reduce((t, n) => t + n, '')
                                .split('[')[0] === 'required'
                                ? 'A senha é obrigatorio'
                                : `${
                                    Object.values(errors.password)
                                      .reduce((t, n) => t + n, '')
                                      .split('[')[0]
                                  }`}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Endereço do Vendedor
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="zip_code"
                          >
                            CEP
                          </label>
                          <InputMask
                            name="zip_code"
                            className="form-control form-control-alternative"
                            id="zip_code"
                            // placeholder="CEP"
                            type="text"
                            mask="99999-999"
                            maskChar=""
                            value={seller && seller.zip_code}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                            onBlur={handleBlurZipCode}
                          />
                          {errors.zip_code && (
                            <small className="text-danger">
                              O cep é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="street"
                          >
                            Endereço
                          </label>
                          <input
                            name="street"
                            className="form-control form-control-alternative"
                            id="street"
                            // placeholder="Endereço"
                            type="text"
                            value={seller && seller.street}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.street && (
                            <small className="text-danger">
                              O endereço é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number"
                          >
                            Número
                          </label>
                          <input
                            name="number"
                            className="form-control form-control-alternative"
                            id="number"
                            type="number"
                            value={seller && seller.number}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.number && (
                            <small className="text-danger">
                              O número é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="district"
                          >
                            Bairro
                          </label>
                          <input
                            name="district"
                            className="form-control form-control-alternative"
                            id="district"
                            // placeholder="Bairro"
                            type="text"
                            value={seller && seller.district}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.district && (
                            <small className="text-danger">
                              O bairro é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">
                            Cidade
                          </label>
                          <input
                            name="city"
                            className="form-control form-control-alternative"
                            id="city"
                            // placeholder="Cidade"
                            type="text"
                            value={seller && seller.city}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                            disabled={addressDisabled}
                          />
                          {errors.city && (
                            <small className="text-danger">
                              A cidade é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="state">
                            Estado
                          </label>
                          <input
                            name="state"
                            className="form-control form-control-alternative"
                            id="state"
                            // placeholder="Estado"
                            type="text"
                            value={seller && seller.state}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                            disabled={addressDisabled}
                          />
                          {errors.state && (
                            <small className="text-danger">
                              O estado é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="complement"
                          >
                            Complemento
                          </label>
                          <input
                            name="complement"
                            className="form-control form-control-alternative"
                            id="complement"
                            // placeholder="Complemento"
                            type="text"
                            value={seller && seller.complement}
                            ref={register({ required: false })}
                            onChange={handleOnChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Cadastro do pix
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg={'2 mb-4'}>
                        <label
                          className="form-control-label"
                          htmlFor="kind_pix"
                        >
                          Tipo Pix
                        </label>
                        <Input
                          type="select"
                          id="kind_pix"
                          name="kind_pix"
                          value={seller && seller.kind_pix}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="cpf_cnpj">CPF/CNPJ</option>
                          <option value="email">E-mail</option>
                          <option value="phone">Telefone</option>
                          <option value="random">
                            {'Dados Bancários (conforme abaixo)'}
                          </option>
                        </Input>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="pix">
                            Pix
                          </label>
                          <InputMask
                            type="text"
                            name="pix"
                            className="form-control form-control-alternative"
                            id="pix"
                            placeholder="Pix"
                            value={seller && seller.pix}
                            // inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.pix && (
                            <small className="text-danger">
                              Pix é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Cadastro de comissão
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <Label for="has_commission">Existe uma comissão</Label>
                        <Input
                          type="select"
                          id="has_commission"
                          name="has_commission"
                          value={seller && seller.has_commission}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="yes">Sim</option>
                          <option value="no">Não</option>
                        </Input>
                      </Col>
                      {seller.has_commission == 'yes' ? (
                        <Col lg="2">
                          <Label for="commission_type">Tipo de comissão</Label>
                          <Input
                            type="select"
                            id="commission_type"
                            name="commission_type"
                            value={seller && seller.commission_type}
                            onChange={handleOnChange}
                          >
                            <option>Selecione o tipo</option>
                            <option value="0">Percentual</option>
                            <option value="1">Fixo</option>
                          </Input>
                        </Col>
                      ) : (
                        false
                      )}
                      {seller.has_commission == 'yes' &&
                      seller.commission_type == '0' ? (
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="value_percentage"
                            >
                              Valor percentual
                            </label>
                            <Input
                              type="text"
                              name="value_percentage"
                              decimalSeparator=","
                              className="form-control form-control-alternative"
                              id="value_percentage"
                              // placeholder="99%"
                              value={seller && seller.value}
                              inputRef={register({ required: true })}
                              onChange={handleOnChange}
                            />
                            {errors.value && (
                              <small className="text-danger">
                                Valor é obrigatório
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      ) : seller.has_commission == 'yes' &&
                        seller.commission_type == '1' ? (
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="value_fix"
                            >
                              Valor Fixo
                            </label>
                            <CurrencyInput
                              prefix="R$"
                              decimalSeparator=","
                              thousandSeparator="."
                              type="text"
                              name="value_fix"
                              className="form-control form-control-alternative"
                              id="value_fix"
                              // placeholder="R$999,99"
                              value={seller && seller.value}
                              inputRef={register({ required: true })}
                              onChange={handleOnChangeValueFix}
                              onBlur={insertMoney}
                            />
                            {errors.value && (
                              <small className="text-danger">
                                Valor é obrigatório
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      ) : (
                        false
                      )}
                    </Row>
                    <Row>
                      {seller.has_commission == 'yes' ? (
                        <Col lg="3">
                          <Label for="exampleselect">Definir bônus</Label>
                          <Input
                            type="select"
                            id="exampleselect"
                            name="active_base_trigger"
                            value={seller && seller.active_base_trigger}
                            onChange={handleOnChange}
                          >
                            <option>Selecione o tipo</option>
                            <option value="yes">Sim</option>
                            <option value="no">Não</option>
                          </Input>
                        </Col>
                      ) : (
                        false
                      )}
                      {seller.has_commission == 'yes' &&
                      seller.active_base_trigger == 'yes' ? (
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="trigger"
                            >
                              Bônus
                            </label>
                            <InputMask
                              type="text"
                              name="trigger"
                              className="form-control form-control-alternative"
                              id="trigger"
                              // placeholder="99%"
                              value={seller && seller.trigger}
                              inputRef={register({ required: true })}
                              onChange={handleOnChange}
                              onBlur={insertPercentage}
                            />
                            {errors.trigger && (
                              <small className="text-danger">
                                {Object.values(errors.trigger)
                                  .reduce((t, n) => t + n, '')
                                  .split('[')[0] === 'required'
                                  ? 'Comissão é obrigatório'
                                  : `Bônus ${
                                      Object.values(errors.trigger)
                                        .reduce((t, n) => t + n, '')
                                        .split('[')[0]
                                    }`}
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      ) : (
                        false
                      )}
                    </Row>
                    <Row>
                      {seller.has_commission == 'yes' && (
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="commission_fgts"
                            >
                              Commissão Fgts
                            </label>
                            <Input
                              type="text"
                              name="commission_fgts"
                              decimalSeparator=","
                              className="form-control form-control-alternative"
                              id="commission_fgts"
                              // placeholder="99%"
                              value={seller && seller.commission_fgts}
                              inputRef={register({ required: true })}
                              onChange={handleOnChange}
                              onBlur={insertPercentage}
                            />
                            {errors.commission_fgts && (
                              <small className="text-danger">
                                Valor é obrigatório
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> {titleButton}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SellerForm;
