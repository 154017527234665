import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input
} from 'reactstrap';
import InputMask from 'react-input-mask';


const BasicInterestForm = ({
  basicInterest,
  titleButton,
  title,
  onSubmit,
  handleOnChange,
  handleSubmit,
  register,
  errors,
}) => {

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">{title}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações dos Juros Básico
                  </h6>
                    <div>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Row>
                              {/* <Col lg="3">
                                <label className="form-control-label" htmlFor="value">
                                  Valor da taxa
                                </label>
                                <InputMask
                                  type="text"
                                  name="value"
                                  className="form-control form-control-alternative mt-4"
                                  id="value"
                                  placeholder="99%"
                                  mask="99%"
                                  value={basicInterest && basicInterest.value}
                                  inputRef={register({ required: true })}
                                  onChange={handleOnChange}
                                />
                                {errors.value && (
                                  <small className="text-danger">
                                    O valor é obrigatório
                                  </small>
                                )}
                              </Col> */}
                              <Col>
                                <label className="form-control-label" htmlFor="value">
                                  Cod. Tabela
                                </label>
                                <InputMask
                                  type="text"
                                  name="name"
                                  className="form-control form-control-alternative mt-4"
                                  id="name"
                                  value={basicInterest && basicInterest.name}
                                  inputRef={register({ required: true })}
                                  onChange={handleOnChange}
                                />
                                {errors.value && (
                                  <small className="text-danger">
                                    O nome é obrigatório
                                  </small>
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> {titleButton}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BasicInterestForm;
