import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SubestablishmentForm from '../../components/SubestablishmentForm';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';
import swal from 'sweetalert';

const UpdateSubestablishment = (props) => {
  const { id } = useParams();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [corbanName, setCorbanName] = useState('');
  const [subestablishment, setSubestablishment] = useState({
    social_reason:'',
    name: '',
    cpf_cnpj: '',
    user_cpf: '',
    user_name: '',
    user_email: '',
    user_mobile: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    bank_name: '',
    agency_number: '',
    number_account: '',
    has_commission: '',
    commission_type: '',
    value: '',
    trigger: '',
    active_base_trigger: '',
    entity_id: '',
    address_id: '',
    bank_id: '',
    commission_id: '',
    agency_digit: '',
    account_digit: '',
    internal_code: '',
  });
  const [contactFields, setContactFields] = useState([])
  const [banks, setBanks] = useState([])
  const { register, handleSubmit, errors, setError } = useForm();
  const [ corbans, setCorbans ] = useState([])

  useEffect(() => {
    async function getSubestablishment() {
      showLoader();
      try {
        const { data } = await api.get(`subestablishments/${id}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        setContactFields(data.contact)
        console.log(data)
        if(data.commission.length > 0) {
          console.log(data.commission)
          setSubestablishment({
            id: data.id,
            cpf_cnpj: data.entity.cpf_cnpj,
            commission_fgts: data.entity.commission_fgts,
            name: data.entity.name,
            social_reason: data.entity.social_reason,
            zip_code: data.address.zip_code,
            street: data.address.street,
            number: data.address.number,
            district: data.address.district,
            city: data.address.city,
            state: data.address.state,
            complement: data.address.complement,
            corban_id: `${data.corban_id} - ${data.corban_name}`,
            corban_name: data.corban_name,
            bank_name: `${data.bank_account.number_bank} - ${data.bank_account.name_bank}`,
            agency_account: data.bank_account.agency_account,
            number_account: data.bank_account.number_account,
            agency_digit: data.bank_account.agency_digit,
            account_digit: data.bank_account.account_digit,
            internal_code: data.internal_code,
            account_kind: data.bank_account.kind,
            kind_pix: data.bank_account.kind_pix,
            pix: data.bank_account.pix,
            entity_id: data.entity.id,
            address_id: data.address.id,
            bank_id: data.bank_account.id,
            has_commission: data.entity.has_commission ? "yes" : "no",
            commission_type: data.entity.has_commission ? (data.commission && data.commission[0].commission_type == "percentage" ? '0' : '1') : '',
            value: data.entity.has_commission ? (data.commission[0].commission_type == "percentage" ? `${data.commission[0].value}%` : `R$ ${data.commission[0].value}`) : '',
            active_base_trigger: (data.commission && data.commission[0].trigger != null && data.commission[0].trigger != 0) ? (data.entity.has_commission ? 'yes' : '') : 'no',
            trigger: data.entity.has_commission && (data.commission && data.commission[0].trigger != null && data.commission[0].trigger != 0) ? `${data.commission[0].trigger}%` : '',
            commission_id: data.entity.has_commission ? data.commission ? data.commission[0].id : '' : '',
            user_id: data.legal_representate.id,
            user_cpf: data.legal_representate.cpf,
            user_name: data.legal_representate.name,
            user_email: data.legal_representate.email,
            user_mobile: data.legal_representate.mobile,
          });
        }else {
          setSubestablishment({
            id: data.id,
            cpf_cnpj: data.entity.cpf_cnpj,
            name: data.entity.name,
            commission_fgts: data.entity.commission_fgts,
            social_reason: data.entity.social_reason,
            zip_code: data.address.zip_code,
            street: data.address.street,
            number: data.address.number,
            corban_id: `${data.corban_id} - ${data.corban_name}`,
            corban_name: data.corban_name,
            district: data.address.district,
            city: data.address.city,
            state: data.address.state,
            complement: data.address.complement,
            bank_name: `${data.bank_account.number_bank} - ${data.bank_account.name_bank}`,
            agency_account: data.bank_account.agency_account,
            number_account: data.bank_account.number_account,
            agency_digit: data.bank_account.agency_digit,
            account_digit: data.bank_account.account_digit,
            internal_code: data.internal_code,
            account_kind: data.bank_account.kind,
            kind_pix: data.bank_account.kind_pix,
            pix: data.bank_account.pix,
            entity_id: data.entity.id,
            address_id: data.address.id,
            bank_id: data.bank_account.id,
            has_commission: data.entity.has_commission ? "yes" : "no",
            user_id: data.legal_representate.id,
            user_cpf: data.legal_representate.cpf,
            user_name: data.legal_representate.name,
            user_email: data.legal_representate.email,
            user_mobile: data.legal_representate.mobile,
          });
        }
        hideLoader();
      } catch (error) {
        hideLoader();
      }
    }
    async function getBanks() {
      showLoader()

      try {
        const response = await api.get(
          `banks?q[active_eq]=true`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        const values = response.data.map((value,index) => {
          return {
            value: `${value.code} - ${value.name}`,
            label: `${value.code} - ${value.name}`
          }
        })

        console.log(values)
        setBanks(values);

        hideLoader();

      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os bancos.');
      }
    }
    getBanks();
    getSubestablishment();
  }, []);

  async function onSubmit(data) {
    showLoader();

    if(!subestablishment.kind_pix){
      toastError("Selecione o tipo de chave pix")
      hideLoader();
      return
    }

    if(subestablishment.kind_pix != 'random' && !subestablishment.pix){
      toastError("Preencher campo pix")
      hideLoader();
      return
    }

    let corbanId = null
    if(subestablishment.corban_id){
      corbanId = subestablishment.corban_id.split('-')[0]
    }

    data = {
      subestablishment: {
        corban_id: corbanId,
        internal_code: subestablishment.internal_code,
        entity_attributes: {
          cpf_cnpj: subestablishment.cpf_cnpj,
          name: subestablishment.name,
          commission_fgts: subestablishment.commission_fgts,
          social_reason: subestablishment.social_reason,
          id: subestablishment.entity_id,
          has_commission: subestablishment.has_commission == "yes" ? true : false,
          address_attributes: {
            id: subestablishment.address_id,
            zip_code: subestablishment.zip_code,
            street: subestablishment.street,
            number: subestablishment.number,
            district: subestablishment.district,
            city: subestablishment.city,
            state: subestablishment.state,
            complement: subestablishment.complement,
          },
          bank_account_attributes: {
            id: subestablishment.bank_id,
            number_bank: subestablishment.bank_name.split('-')[0],
            name_bank: subestablishment.bank_name.split('-')[1],
            agency_account: subestablishment.agency_account,
            agency_digit: subestablishment.agency_digit,
            number_account: subestablishment.number_account,
            account_digit: subestablishment.account_digit,
            kind: subestablishment.account_kind,
            kind_pix: subestablishment.kind_pix,
            pix: subestablishment.pix,
          },
          contacts_attributes: [
            ...contactFields
          ],
          commission_bases_attributes: [
            {
              id: subestablishment.commission_id,
              commission_type: parseInt(subestablishment.commission_type,10),
              value: subestablishment.value ? subestablishment.value.toString().replace('R$', '').replace(',', '.') : subestablishment.value,
              trigger: subestablishment.trigger
            }
          ]
        }
      }
    }

    if(subestablishment.password){
      console.log(subestablishment)
      data = {...data,
        subestablishment: {...data.subestablishment,
        users_attributes: [
          {
            id: subestablishment.user_id,
            password: subestablishment.password
          }
        ]}
      }
    }

    try {
      await api.put(`subestablishments/${id}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Subestabelecimento atualizado com sucesso');

      props.history.push('/admin/subestablishments');
    } catch (error) {
      hideLoader();

      let message =
        'Ops, tivemos um erro na atualização do subestabelecimento, por favor tente novamente!';

      if (error.response) {
        if (error.response.data.error) {
          const responseErrors = error.response.data.error
          responseErrors.forEach((e) => {
            if (e.key == "Documento" && e.model == "users") {
              setError("user_cpf", 'inválido')
            } else if(e.key == "Documento" && e.model == "entity"){
              setError("cpf_cnpj", 'inválido')
            } else if( e.key == "email" && e.model == "users") {
              setError("user_email", 'inválido')
            } else if( e.key == "trigger" && e.model == "entity") {
              setError("trigger", 'inválido')
            }
          });
        }
      }
      window.scrollTo(0, 0)
      toastError("Há informações incorretas, por favor revise-as.");
    }
  }

  function parseCurrency(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace(".", "").replace(",", ".").replace("R$", ""))
    } else {
      return value
    }
  }

  function parsePercentage(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace("%", "").replace(",", ""))
    } else {
      return value
    }
  }

  function parseDigit(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "x")
    } else {
      return value
    }
  }

  function handleOnChange(e) {
    const values = {...subestablishment}

    if (e.target.name == "value_percentage") {
      setSubestablishment({ ...subestablishment, ["value"] : e.target.value });
    } else if (e.target.name == "trigger"){
      setSubestablishment({ ...subestablishment, ["trigger"] : parsePercentage(e.target.value) });
    } else if (e.target.name == "commission_type" && values["value"] != "") {
      setSubestablishment({ ...subestablishment, [e.target.name] : e.target.value, "value": ""});
    } else if (e.target.name == "agency_digit") {
      setSubestablishment ({ ...subestablishment, ["agency_digit"] : parseDigit(e.target.value) });
    } else if (e.target.name == "account_digit") {
      setSubestablishment ({ ...subestablishment, ["account_digit"] : parseDigit(e.target.value) });
    } else if(e.target.name == "active_base_trigger"){
      setSubestablishment({ ...subestablishment, [e.target.name]: e.target.value, trigger: 0 });
    } else if (e.target.name == "cpf_cnpj") {
      if ( e.target.value.length < 19 ) {
        let cpf_cnpj = e.target.value

        cpf_cnpj=cpf_cnpj.replace(/^(\d{2})(\d)/,"$1.$2")

        cpf_cnpj=cpf_cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")

        cpf_cnpj=cpf_cnpj.replace(/\.(\d{3})(\d)/,".$1/$2")

        cpf_cnpj=cpf_cnpj.replace(/(\d{4})(\d)/,"$1-$2")
        setSubestablishment ({ ...subestablishment,  [e.target.name]: cpf_cnpj});
      }
    } else if (e.target.name == "user_cpf") {

      if ( e.target.value.length < 15 ) {
        let user_cpf = e.target.value
        if(user_cpf.length >= 12) {
          user_cpf=user_cpf.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/,"$1.$2.$3-$4")
        } else {
          user_cpf=user_cpf.replace(/(\d{3})(\d)/,"$1.$2")
        }
        setSubestablishment ({ ...subestablishment,  [e.target.name]: user_cpf});
      }
    } else if (e.target.name == "user_mobile") {
        if ( e.target.value.length < 16 ) {
          let user_mobile = e.target.value

          if(user_mobile.length > 10) {
            user_mobile=user_mobile.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{5})(\d)/,"($1) $2-$3")
          } else {
            user_mobile=user_mobile.replace(/^(\d{2})(\d)/,"($1) $2")
          }
          setSubestablishment ({ ...subestablishment,  [e.target.name]: user_mobile});
        }
    } else {
      setSubestablishment({ ...subestablishment, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeValueFix(e){
    setSubestablishment({
      ...subestablishment,
      value: parseCurrency(e)
    })
  }

  function handleOnAgencyDigits(e){
    setSubestablishment({
      ...subestablishment,
      agency_digit: parseDigit(e.target.agency_digit)
    })
  }

  function handleOnAccountDigits(e){
    setSubestablishment({
      ...subestablishment,
      account_digit: parseDigit(e.target.account_digit)
    })
  }


  function dataAddress({ street, district, city, state }) {
    setSubestablishment({
      ...subestablishment,
      street: street,
      district: district,
      city: city,
      state: state
    });
  }

  function handleOnChangeContact(index, event) {
    const values = [...contactFields]
    if(event.target.name == "email_contact") {
      values[index]["email"] = event.target.value;
    } else if (event.target.name == "mobile_contact") {
      values[index]["mobile"] = event.target.value;
    }

    setContactFields(values)
  }

  function handleClickContact() {
    setContactFields([ ...contactFields, { mobile: '', email: '', type_contact: '' }])
  }

  function handleClickContactRemove(index){
    const values = [...contactFields];
    values.splice(index, 1);
    setContactFields(values);
  }

  function handleOnChangeTypeContact(event, index) {
    const values = [...contactFields];
    console.log(index)
    console.log(values[index])
    if (event.target.value == "email") {
      values[index].type_contact = event.target.value;
    } else if (event.target.value == "mobile") {
      values[index].type_contact = event.target.value;
    }

    setContactFields(values);
  }

  function handleOnChangeBank(e){
    setSubestablishment({ ...subestablishment, 'bank_name': e.value })
  }

  async function handleOnChangeCorban(e) {
    setCorbanName(e.target.value);
    if (
      e.target.value.length > 4 &&
      user.roles[0] &&
      (user.roles[0].name == 'master' || user.roles[0].name == 'operational')
    ) {
      const response = await api.get(
        `/corbans/searchs?q[entity_name_cont]=${e.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      const values = response.data.map((value, index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`,
        };
      });
      setCorbans(values);
    } else if (e.target.value === '') {
      setCorbans([]);
    }
  }

  function insertPercentage(e) {
    if(e.target.name == "value_percentage") {
      setSubestablishment({ ...subestablishment, ["value"]: `${e.target.value}%` });
    } else {
      if(e.target.name == "commission_fgts") {
        setSubestablishment({ ...subestablishment, ["commission_fgts"]: `${e.target.value}%` });
      }else{
        setSubestablishment({ ...subestablishment, ["trigger"]: `${e.target.value}%` });
      }
    }
  }


  function insertMoney(e) {
    if(e.target.value.charAt(0) != 'R') {
      setSubestablishment({ ...subestablishment, ["value"]: `R$ ${e.target.value}` });
    }
  }

  function extratNumber(str) {
    const match = str.match(/^\d+/); // Encontra a sequência de números no início da string
    return match ? match[0] : ''; // Retorna a sequência encontrada, ou uma string vazia se nada for encontrado
  }

  function handleClickItemListFilter(value) {
    setCorbanName(value);
    setCorbans([]);
    swal({
      text: 'Deseja copiar os dados do CORBAN?',
      icon: 'success',
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(async (next) => {
      if (next) {
        const response = await api.get(`/get_corban/${extratNumber(value)}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        setSubestablishment({
          ...subestablishment,
          corban_id: value,
          cpf_cnpj: response.data.info_sub.cpf_cnpj,
          social_reason: response.data.info_sub.social_reason,
          name: response.data.info_sub.name,
          user_cpf: response.data.legal_representative.cpf,
          user_name: response.data.legal_representative.name,
          user_email: response.data.legal_representative.email,
          user_mobile: response.data.legal_representative.mobile,
          zip_code: response.data.address.zip_code,
          street: response.data.address.street,
          number: response.data.address.number,
          district: response.data.address.district,
          city: response.data.address.city,
          state: response.data.address.state,
          complement: response.data.address.complement,
          internal_code: response.data.internal_code,
        });
      } else {
        setSubestablishment({ ...subestablishment, corban_id: value });
      }
    });
  }

  useEffect(() => {
    if (subestablishment.corban_id) {
      setCorbanName(subestablishment.corban_id);
    } else {
      setCorbanName('');
    }
  }, [subestablishment]);

  return (
    <>
      <CustomerHeader />
      <SubestablishmentForm
        subestablishment={subestablishment}
        contactFields={contactFields}
        corbans={corbans}
        handleClickItemListFilter={handleClickItemListFilter}
        corbanName={corbanName}
        setCorbanName={setCorbanName}
        banks={banks}
        title="Atualizar Subestabelecimento"
        titleButton="Atualizar Subestabelecimento"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        handleOnChangeValueFix={handleOnChangeValueFix}
        handleOnAgencyDigits={handleOnAgencyDigits}
        handleOnAccountDigits={handleOnAccountDigits}
        handleOnChangeContact={handleOnChangeContact}
        handleClickContact={handleClickContact}
        handleClickContactRemove={handleClickContactRemove}
        handleOnChangeTypeContact={handleOnChangeTypeContact}
        handleOnChangeBank={handleOnChangeBank}
        handleOnChangeCorban={handleOnChangeCorban}
        dataAddress={dataAddress}
        controller={'edit'}
        insertPercentage={insertPercentage}
        insertMoney={insertMoney}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
};

export default UpdateSubestablishment;
