import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SellerForm from '../../components/SellerForm';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';

const UpdateSeller = (props) => {
  const { id } = useParams();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { register, handleSubmit, errors, setError } = useForm();
  const { user } = useContext(AuthContext);
  const [seller, setSeller] = useState({
    name: '',
    cpf_cnpj: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    has_commission: '',
    commission_type: '',
    value: '',
    trigger: '',
    base_trigger: '',
    active_base_trigger: '',
    has_commission:'',
  });

  const [ stores, setStores ] = useState([])

  async function getStores() {
    showLoader()

    try {
      const response = await api.get(
        `/stores/searchs`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value,index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`
        }
      })


      setStores(values);

      hideLoader();

    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista as lojas.');
    }
  }

  useEffect(()=> {
    if(user && user.roles[0] && (user.roles[0].name == "master" || user.roles[0].name == "operational" || user.roles[0].name == "operational" || user.roles[0].name == "master_corban" || user.roles[0].name == "user_corban_register")){
      getStores()
    }
  }, [user])

  useEffect(() => {
    async function getSeller() {
      showLoader();
      try {
        const { data } = await api.get(`sellers/${id}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        console.log(data)
        setSeller({
          id: data.id,
          pix: data.pix,
          kind_pix: data.kind_pix,
          mobile: data.mobile,
          cpf_cnpj: data.entity.cpf_cnpj,
          commission_fgts: data.entity.commission_fgts,
          name: data.entity.name,
          zip_code: data.address.zip_code,
          street: data.address.street,
          number: data.address.number,
          district: data.address.district,
          city: data.address.city,
          state: data.address.state,
          complement: data.address.complement,
          store_id: `${data.store_id} - ${data.store_name}`,
          email: data.email,
          mobile: data.mobile,
          has_commission: data.entity.has_commission ? "yes" : "no",
          commission_type: data.entity.has_commission ? (data.commission[0].commission_type == "percentage" ? '0' : '1') : '',
          value: data.entity.has_commission ? (data.commission[0].commission_type == "percentage" ? `${data.commission[0].value}%` : `R$ ${data.commission[0].value}`) : '',
          active_base_trigger: data.commission[0].trigger != null ? (data.entity.has_commission ? 'yes' : '') : 'no',
          base_trigger: data.entity.has_commission && data.commission[0].trigger != null ? `${data.commission[0].base_trigger}%` : '',
          trigger: data.entity.has_commission && data.commission[0].trigger != null ? `${data.commission[0].trigger}%` : '',
        });
        hideLoader();
      } catch (error) {
        hideLoader();
      }
    }
    getSeller();
  }, []);

  async function onSubmit(data) {
    showLoader();
    let storeId = null
    if(seller.store_id){
      storeId = seller.store_id.split('-')[0]
    }

    if(!seller.kind_pix){
      toastError("Selecione o tipo de chave pix")
      hideLoader();
      return
    }

    if(seller.kind_pix != 'random' && !seller.pix){
      toastError("Preencher campo pix")
      hideLoader();
      return
    }

    data = {
      seller: {
        store_id: storeId,
        kind_pix: seller.kind_pix,
        pix: seller.pix,
        mobile: seller.mobile,
        entity_attributes: {
          name: seller.name,
          commission_fgts: seller.commission_fgts,
          cpf_cnpj: seller.cpf_cnpj,
          has_commission: seller.has_commission == "yes" ? true : false,
          address_attributes: {
            zip_code: seller.zip_code,
            street: seller.street,
            number: seller.number,
            district: seller.district,
            city: seller.city,
            state: seller.state,
            complement: seller.complement,
          },
          commission_bases_attributes: [
            {
              active: seller.active,
              commission_type: parseInt(seller.commission_type,10),
              value: seller.value ? seller.value.toString().replace('R$', '').replace(',', '.') : seller.value,
              trigger: seller.active_base_trigger == "yes" ? seller.trigger : '',
              base_trigger: seller.active_base_trigger == "yes" ? seller.base_trigger : ''
            }
          ]
        },
      },
      user: {
        name: seller.name,
        cpf: seller.cpf_cnpj,
        email: seller.email,
        mobile: seller.mobile
      }
    }

    if(seller.password) {
      data = {...data, user: {...data.user, password: seller.password}}
    }

    try {
      await api.put(`sellers/${id}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Vendedor atualizado com sucesso');

      props.history.push('/admin/sellers');
    } catch (error) {
      hideLoader();

      let message =
        'Ops, tivemos um erro na atualização do Vendedor, por favor tente novamente!';

      if (error.response) {
        if (error.response.data.errors) {
          const responseErrors = error.response.data.error
          responseErrors.forEach((e) => {
            if (e.key == "Documento" && e.model == "users") {
              setError("user_cpf", 'inválido')
            } else if(e.key == "Documento" && e.model == "entity"){
              setError("cpf_cnpj", 'inválido')
            } else if( e.key == "email" && e.model == "users") {
              setError("user_email", 'inválido')
            } else if( e.key == "trigger" && e.model == "entity") {
              setError("trigger", 'inválido')
            }
          });
        }
      }
      window.scrollTo(0, 0)
      toastError("Há informações incorretas, por favor revise-as.");
    }
  }

  function parseCurrency(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace(".", "").replace(",", ".").replace("R$", ""))
    } else {
      return value
    }
  }

  function parsePercentage(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace("%", "").replace(",", ""))
    } else {
      return value
    }
  }

  function handleOnChange(e) {
    const values = {...seller}

    if (e.target.name == "value_percentage") {
      console.log(e.target.value)
      setSeller({ ...seller, ["value"] : e.target.value });
    } else if (e.target.name == "trigger"){
      setSeller({ ...seller, ["trigger"] : parsePercentage(e.target.value) });
    } else if (e.target.name == "commission_type" && values["value"] != "") {
      setSeller({ ...seller, [e.target.name] : e.target.value, "value": ""});
    } else if (e.target.name == "cpf_cnpj") {
      if ( e.target.value.length < 15 ) {
        let cpf_cnpj = e.target.value
        if(cpf_cnpj.length >= 12) {
          cpf_cnpj=cpf_cnpj.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/,"$1.$2.$3-$4")
        } else {
          cpf_cnpj=cpf_cnpj.replace(/(\d{3})(\d)/,"$1.$2")
        }
        setSeller ({ ...seller,  [e.target.name]: cpf_cnpj});
      }
    } else if (e.target.name == "mobile") {
        if ( e.target.value.length < 16 ) {
          let mobile = e.target.value

          if(mobile.length > 10) {
            mobile=mobile.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{5})(\d)/,"($1) $2-$3")
          } else {
            mobile=mobile.replace(/^(\d{2})(\d)/,"($1) $2")
          }
          setSeller ({ ...seller,  [e.target.name]: mobile});
        }
    } else {
      setSeller({ ...seller, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeValueFix(e){
    setSeller({
      ...seller,
      value: parseCurrency(e)
    })
  }

  function dataAddress({ street, district, city, state }) {
    setSeller({
      ...seller,
      street: street,
      district: district,
      city: city,
      state: state
    });
  }

  function insertPercentage(e) {
    if(e.target.name == "value_percentage") {
      setSeller({ ...seller, ["value"]: `${e.target.value}%` });
    } else {
      if(e.target.name == "commission_fgts") {
        setSeller({ ...seller, ["commission_fgts"]: `${e.target.value}%` });
      }else{
        setSeller({ ...seller, ["trigger"]: `${e.target.value}%` });
      }
    }
  }

  function insertMoney(e) {
    if(e.target.value.charAt(0) != 'R') {
      setSeller({ ...seller, ["value"]: `R$ ${e.target.value}` });
    }
  }

  function handleOnChangeStore(e){
    setSeller({ ...seller, 'store_id': e.value })
  }

  return (
    <>
      <CustomerHeader />
      <SellerForm
        seller={seller}
        stores={stores}
        title="Atualizar Vendedor"
        titleButton="Atualizar Vendedor"
        onSubmit={onSubmit}
        hasEdit
        handleOnChange={handleOnChange}
        handleOnChangeValueFix={handleOnChangeValueFix}
        handleOnChangeStore={handleOnChangeStore}
        dataAddress={dataAddress}
        insertPercentage={insertPercentage}
        insertMoney={insertMoney}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
};

export default UpdateSeller;
