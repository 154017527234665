import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  Table,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  CustomInput,
} from 'reactstrap';

const TableSimulationFgts = ({
  fgtsCommissions,
  simulationDataFgts,
  minValue,
  tacs,
  table,
  setTacPercent,
  handleChangeTac,
  hasSecure,
  getConvenioCode,
  secureCodes,
  isCdc,
  lower_than_250
}) => {
  const [commissionsFgts, setCommissionFgts] = useState(fgtsCommissions);
  const [showTable, setShowTable] = useState(true);

  useEffect(() => {
    if (isCdc) {
      if (table.rate) {
        setCommissionFgts(
          fgtsCommissions.filter(
            (commission) =>
              (isCdc ? commission.is_cdc : !commission.is_cdc) &&
              commission.rate == table.rate
          )
        );
      } else {
        setCommissionFgts(
          fgtsCommissions.filter((commission) =>
            isCdc ? commission.is_cdc : !commission.is_cdc
          )
        );
      }
    } else if (table.rate) {
      // setCommissionFgts(
      //   fgtsCommissions.filter((commission) => commission.rate == table.rate)
      // );
    }

   
    const commissionData = commissionsFgts.filter((commission) => table.code.includes(commission.code) && commission.enabled && (lower_than_250 ? commission.lower_than_250: !commission.lower_than_250)).filter(commission =>
      simulationDataFgts.CondicoesCredito.ListaCondicaoCredito.filter(
        (list) =>
          list.TaxaCLAM === parseFloat(commission.rate) &&
        commission.code == list.Convenio.CodigoConvenio && (lower_than_250 ? list?.ValorSolicitado < 250 : list?.ValorSolicitado >= 250)
      ).length > 0
    )


    setCommissionFgts(commissionData)
  }, [fgtsCommissions]);

  return (
    <>
      {commissionsFgts.filter(
        (commission) => table.code.includes(commission.code) && commission.enabled
      ).length > 0 && (
        <>
          <h3 className="mb-4 d-flex">{isCdc ? table.name_cdc : table.name}</h3>
          <Table className="align-items-center justify-content-center table-flush">
            <thead className="">
              <tr>
                <th scope="col">Taxa</th>
                {!isCdc && <th scope="col">Valor Bruto</th>}
                <th scope="col">
                  {isCdc ? 'Valor Liberado' : 'Valor Liquido'}
                </th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            {commissionsFgts &&
              commissionsFgts
                .filter((commission) => table.code.includes(commission.code) && commission.enabled && (lower_than_250 ? commission.lower_than_250: !commission.lower_than_250))
                .map((commission) => (
                  <tbody>
                    {simulationDataFgts.CondicoesCredito.ListaCondicaoCredito.filter(
                      (list) =>
                        list.TaxaCLAM === parseFloat(commission.rate) &&
                      commission.code == list.Convenio.CodigoConvenio && (lower_than_250 ? list?.ValorSolicitado < 250 : list?.ValorSolicitado >= 250)
                    ).map((item, index) =>
                      commission.enabled ? (
                        <tr
                          style={{
                            color: `
                                  ${
                                    item.ValorSolicitado <= table.maxValue &&
                                    item.ValorSolicitado >= table.minValue
                                      ? `#00c300`
                                      : 'red'
                                  }`,
                          }}
                        >
                          <td>
                            <Label check>
                              {item.ValorSolicitado <= table.maxValue &&
                                item.ValorSolicitado >= minValue && (
                                  <Input
                                    type="radio"
                                    name="radio1"
                                    checked={
                                      tacs === item.Convenio.CodigoConvenio
                                    }
                                    onClick={() => {
                                      console.log(commission.tac);
                                      setTacPercent(commission.tac);
                                      handleChangeTac(
                                        item.Convenio.CodigoConvenio,
                                        item,
                                        commission.tac,
                                        commission.id
                                      );
                                    }}
                                  />
                                )}
                              {commission.rate.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                              %
                            </Label>
                          </td>
                          {!isCdc && (
                            <td>
                              {item.ValorSolicitado.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </td>
                          )}
                          <td>
                            {item.ValorCliente.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>

                          <td>
                            {item.ValorBruto.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                        </tr>
                      ) : (
                        false
                      )
                    )}
                  </tbody>
                ))}
          </Table>
        </>
      )}
    </>
  );
};

export default TableSimulationFgts;
