import React, { useContext, useEffect } from 'react';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from 'services/api';
import getToken from 'util/setAuthToken';
import './styles.css';
import { AuthContext } from 'context/AuthContext';

export const handleBeforeUnloadDetail = async () => {
  if (
    !window.location.pathname.includes('detail') &&
    !window.location.pathname.includes('admin/contracts') &&
    localStorage.getItem('page_log') === 'detalhes_do_contrato'
  ) {
    // await api.get(`/page_log_destroy/detalhes_de_contrato`, {
    //   headers: {
    //     Authorization: `Bearer ${getToken()}`,
    //   },
    // });
    // localStorage.setItem('page_log', '');
  }
};

export const handleBeforeUnloadUpload = async () => {
  if (
    !window.location.pathname.includes('upload') &&
    !window.location.pathname.includes('admin/contracts') &&
    localStorage.getItem('page_log') === 'upload_de_documentos'
  ) {
    // await api.get(`/page_log_destroy/upload_de_documentos`, {
    //   headers: {
    //     Authorization: `Bearer ${getToken()}`,
    //   },
    // });
    // localStorage.setItem('page_log', '');
  }
};

const Loading = () => {
  const { user } = useContext(AuthContext);
  const loaderContext = useContext(LoadingContext);

  const { loading } = loaderContext;

  useEffect(() => {
    handleBeforeUnloadUpload();
    handleBeforeUnloadDetail();
  }, [window.location.pathname, user]);

  return (
    <div>
      {loading ? (
        <div id="modal-root">
          <div className="spinner-box">
            <div className="loading-icon" />
            <span>Aguarde...</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Loading;
