import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Input,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { Link } from 'react-router-dom';

const CreateImportContract = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const { user } = useContext(AuthContext);
  const [signer, setSigner] = useState({
    name: '',
    email: '',
    phone_number: '',
    documentation: '',
    birthdate: '',
  });

  const [import_contract, setImportContract] = useState('nexxera');

  async function onSubmit(data) {
    showLoader();
    const contractFormData = new FormData();
    contractFormData.append(`import_contract[file]`, import_contract);

    try {
      await api.post('import_contracts', contractFormData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Enviado com sucesso');

      props.history.push('/admin/import_contracts');
    } catch (error) {
      hideLoader();

      let message = '';

      if (error.response.data.error) {
        const responseErrors = error.response.data.error;
      }
      window.scrollTo(0, 0);
      toastError('Há informações incorretas, por favor revise-as.');
    }
  }

  function handleOnChange(e) {
    console.log(e.target.files[0]);
    setImportContract(e.target.files[0]);
  }

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Importar Contratos</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Importar Contratos
                  </h6>
                  <div className="pl-lg-4">
                    <div>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Row>
                              <Col>
                                <label
                                  className="form-control-label"
                                  htmlFor="name"
                                >
                                  Importar Contratos
                                </label>
                                <Input
                                  type="file"
                                  className="form-control"
                                  name="file"
                                  id="exampleselect"
                                  onChange={(event) => handleOnChange(event)}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <br></br>
                  </div>
                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> Enviar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateImportContract;
