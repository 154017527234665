import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationComponent = ({ pages, offset, setOffset }) => {
  const current = offset;

  function onPageChange(page) {
    setOffset(page);
  }

  return (
    <nav aria-label="...">
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0 flex-wrap"
      >
        {current > 1 && (
          <PaginationItem className={current === 1 ? 'disabled' : ''}>
            <PaginationLink
              onClick={() => onPageChange(Math.max(current - 1, 1))}
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
        )}
        {pages.map((page) => (
          <PaginationItem
            className={page === current ? 'active' : ''}
            key={page}
          >
            <PaginationLink onClick={() => onPageChange(page)}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}
        {current < pages.length && (
          <PaginationItem>
            <PaginationLink onClick={() => onPageChange(current + 1)}>
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    </nav>
  );
};

export default PaginationComponent;
