import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Input,
  Label,
  Col,
} from 'reactstrap';
import {useDropzone} from 'react-dropzone'
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import DocumentUpload from '../../components/DocumentUpload';
import token from '../../util/setAuthToken';
import { useLocation } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';


const UploadContracts = (props) => {
  const { id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  // const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [documentRg, setDocumentRg] = useState([]);
  const [documentFace, setDocumentFace] = useState([]);
  const [documentCreditCard, setDocumentCreditCard] = useState([]);
  const [documentRgPersist, setDocumentRgPersist] = useState([]);
  const [documentFacePersist, setDocumentFacePersist] = useState([]);
  const [documentCreditCardPersist, setDocumentCreditCardPersist] = useState([]);

  const { user } = useContext(AuthContext);

  async function onSubmit() {

    if ((documentRg.length == 0 && documentRgPersist == 0) || (documentFace.length == 0 && documentFacePersist == 0) || (documentCreditCard.length == 0 && documentCreditCardPersist.length == 0)){
      toastError("Selecione pelo menos uma foto para cada opção");
      return
    }

    showLoader();
    try {
      const contractData = new FormData()
      let index = 0
      documentRg.map((item) => {
        contractData.append(`contract[documents_attributes][${index}][file]`, item)
        contractData.append(`contract[documents_attributes][${index}][kind]`, "rg")
        index += 1
        console.log(index)
      })
      documentFace.map((item) => {
        contractData.append(`contract[documents_attributes][${index}][file]`, item)
        contractData.append(`contract[documents_attributes][${index}][kind]`, "face")
        index += 1
        console.log(index)
      })
      documentCreditCard.map((item) => {
        contractData.append(`contract[documents_attributes][${index}][file]`, item)
        contractData.append(`contract[documents_attributes][${index}][kind]`, "credit_card")
        index += 1
        console.log(index)
      })

      const userParams = user && user.id ? user.id : null
      const response = await api.put(`contracts/${id}/update_images?user_id=${userParams}`, contractData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      console.log(response)

      hideLoader();

      toastSuccess('Documentos enviados com sucesso');

      props.history.push('/admin/contracts');
    } catch (error) {
      hideLoader();
      console.log(error)
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um erro ao enviar documentos, por favor tente novamente!';
      }
      toastError(message);
    }
  }

  async function getContract() {
    try {
      let { data } = await api.get(`contracts/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setDocumentRgPersist(data.documents.filter(item => item.kind == 'rg'))
      setDocumentFacePersist(data.documents.filter(item => item.kind == 'face'))
      setDocumentCreditCardPersist(data.documents.filter(item => item.kind == 'credit_card'))
      hideLoader();
    } catch (e) {
      console.log(e)
      hideLoader();
    }
  }

  async function removeImagePersist(id, kind) {
    try {
      let { data } = await api.delete(`documents/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if(kind == 'rg') {
        setDocumentRgPersist(documentRgPersist.filter(item => item.id != id))
      } else if (kind == 'face') {
        setDocumentFacePersist(documentFacePersist.filter(item => item.id != id))
      } else if (kind == 'credit') {
        setDocumentCreditCardPersist(documentCreditCardPersist.filter(item => item.id != id))
      }

      hideLoader();
    } catch (e) {
      console.log(e)
      hideLoader();
    }

  }

  async function removeImage(id, kind) {
    if(kind == 'rg') {
      setDocumentRg(documentRg.filter((item, index) => index != id))
    } else if (kind == 'face') {
      setDocumentFace(documentFace.filter((item, index) => index != id))
    } else if (kind == 'credit') {
      setDocumentCreditCard(documentCreditCard.filter((item, index) => index != id))
    }
  }

  useEffect(() => {
    getContract()
  }, [])

  //Dropzone RG
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setDocumentRg(
        documentRg.concat(
          acceptedFiles.map(pic =>
            Object.assign(pic, {
              preview: URL.createObjectURL(pic),
              featured: false
            })
          )
        )
      )
    }
  })

  //Dropzone Foto do rosto
  const { getRootProps: getRootPropsFace, getInputProps: getInputPropsFace, isDragActive: isDragActiveFace } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setDocumentFace(
        documentFace.concat(
          acceptedFiles.map(pic =>
            Object.assign(pic, {
              preview: URL.createObjectURL(pic),
              featured: false
            })
          )
        )
      )
    }
  })

  //Dropzone Cartão de Credito
  const { getRootProps: getRootPropsCredit, getInputProps: getInputPropsCredit, isDragActive: isDragActiveCredit } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setDocumentCreditCard(
        documentCreditCard.concat(
          acceptedFiles.map(pic =>
            Object.assign(pic, {
              preview: URL.createObjectURL(pic),
              featured: false
            })
          )
        )
      )
    }
  })

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Upload de Documentos</h3>
                  </Col>
                </Row>
              </CardHeader>
              <DocumentUpload
                id={id}
                userId={user}
                title="Selecione os arquivos indicados"
                documentRg={documentRg}
                setDocumentRg={setDocumentRg}
                documentFace={documentFace}
                setDocumentFace={setDocumentFace}
                documentCreditCard={documentCreditCard}
                setDocumentCreditCard={setDocumentCreditCard}
                documentRgPersist={documentRgPersist}
                setDocumentRgPersist={setDocumentRgPersist}
                documentFacePersist={documentFacePersist}
                setDocumentFacePersist={setDocumentFacePersist}
                documentCreditCardPersist={documentCreditCardPersist}
                setDocumentCreditCardPersist={setDocumentCreditCardPersist}
                handleSubmit={onSubmit}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UploadContracts;
