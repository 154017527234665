import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CorbanForm from '../../components/CorbanForm';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

const UpdateSecure = (props) => {
  const { id } = useParams();
  const { register, handleSubmit, errors, setError } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const [value, setValue] = useState(0);

  useEffect(() => {
    getSystemSettings();
  }, []);

  async function getSystemSettings() {
    showLoader();

    try {
      const response = await api.get(`system_settings`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setValue(response.data?.secure_value);
      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os Juros Básicos.');
    }
  }

  async function onSubmit() {
    showLoader();

    const data = { secure_value: value };

    try {
      await api.post(`system_settings`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Atualizado com sucesso');
    } catch (error) {
      hideLoader();

      window.scrollTo(0, 0);
      toastError('Há informações incorretas, por favor revise-as.');
    }
  }

  return (
    <>
      <CustomerHeader />
      <div key={0} style={{ margin: 18 }}>
        <Row>
          <Col lg="8  mb-4">
            <Label for="kind_table">
              Valor do percentual de seguro a ser cobrado nas operações
            </Label>
            <Col lg="8">
              <FormGroup>
                <label className="form-control-label" htmlFor="value">
                  Valor
                </label>
                <Input
                  type="text"
                  name="value"
                  className="form-control form-control-alternative"
                  id="value"
                  // placeholder="99%"
                  value={value}
                  inputRef={register({
                    required: true,
                  })}
                  onChange={(event) => setValue(event.target.value)}
                />
                {errors.value && (
                  <small className="text-danger">Valor é obrigatório</small>
                )}
              </FormGroup>
            </Col>
          </Col>
        </Row>
        <div className="text-right">
          <Button
            type="submit"
            className="btn btn-success px-4"
            onClick={onSubmit}
          >
            <i className="fas fa-paper-plane" /> Salvar
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpdateSecure;
