import { Range, getTrackBackground } from 'react-range';

export function InputRange({step, min, max, values, setValues, label, isInteger, touchEnd, disabled=false}){
  return (
    <Range
      values={values}
      step={step}
      min={min}
      max={max}
      disabled={disabled}
      rtl={false}
      onChange={(values) => setValues(values)}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          onDragEnd={touchEnd}
          onTouchEnd={() => touchEnd()}
          style={{
            ...props.style,
            height: '36px',
            display: 'flex',
            width: '100%'
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '5px',
              width: '100%',
              borderRadius: '4px',
              background: '#ccc',
              // background: getTrackBackground({
              //   values,
              //   colors: ['#548BF4', '#ccc'],
              //   min: 2,
              //   max: 12,
              //   rtl: false
              // }),
              alignSelf: 'center'
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '42px',
            width: '42px',
            borderRadius: '4px',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #AAA'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-28px',
              color: '#fff',
              width: 'auto',
              fontWeight: 'bold',
              fontSize: '16px',
              fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
              padding: '4px 18px',
              borderRadius: '4px',
              backgroundColor: '#548BF4'
            }}
          >
            {isInteger ? values[0] : values[0].toFixed(2)}{label}
          </div>
          <div
            style={{
              height: '16px',
              width: '5px',
              backgroundColor: isDragged ? '#548BF4' : '#CCC'
            }}
          />
        </div>
      )}
    />

  )
}
