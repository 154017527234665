import MenuRankingTable from 'components/MenuRankingTable';
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';
import { VisibilityByUserRoles, VisibilityNotByUserRoles } from 'util/visibility';


const RankingManagerTable = (props) => {
  const { sortContractValue, sortContractMargin, sortValue, setSortValue, sortStores, setSortStores, setSortContractMargin, setSortContractValue, handleToggleFilter, bussinessDay, filterData, managers, submitModal, setSubmitModal, filterDate, setFilterDate, goal, setGoal, id, setId, handleToggleSubmit, handlerOnClickSubmit, days } = props

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Gerentes Comerciais</h3>
                    </Col>
                    <MenuRankingTable
                      handleToggleFilter={handleToggleFilter}
                      filterData={filterData}
                      exportName={'G. Comerciais'}
                    />
                  </Row>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Colocação</th>
                      <th scope="col">Nome</th>
                      <th scope="col" onClick={() => setSortStores()}>
                        <span>Lojas Com Produção</span>
                        {sortStores && sortStores == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col" onClick={() => setSortContractValue()}>
                        <span>Valor Contrato</span>
                        {sortContractValue && sortContractValue == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col" onClick={() => setSortValue()}>
                        <span>Valor Cobrado</span>
                        {sortValue && sortValue == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col" onClick={() => setSortContractMargin()}>
                        <span>Valor de Margem</span>
                        {sortContractMargin && sortContractMargin == 'desc' ? (
                          <i className="ml-2 fas fa-arrow-down" />
                        ) : (
                          <i className="ml-2 fas fa-arrow-up" />
                        )}
                      </th>
                      <th scope="col">Meta</th>
                      <th scope="col">Projeção</th>
                    </tr>
                  </thead>
                  <tbody>
                    {managers.map((manager, index) => (
                      <tr key={manager.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">{index + 1}</span>
                            </Media>
                          </Media>
                        </th>
                        <td>{manager.nome}</td>
                        <td>{manager.total_stores}</td>
                        <td>{manager.total}</td>
                        <td>{manager.total_charged}</td>
                        <td>{manager.total_margin}</td>
                        <VisibilityByUserRoles
                          roles={['master', 'operational']}
                        >
                          <td
                            onClick={() =>
                              handleToggleSubmit(
                                manager.id,
                                manager.goals_value
                              )
                            }
                          >
                            {manager.goals_value} <i className="fas fa-edit" />
                          </td>
                        </VisibilityByUserRoles>
                        <VisibilityNotByUserRoles
                          roles={['master', 'operational']}
                        >
                          <td>{manager.goals_value}</td>
                        </VisibilityNotByUserRoles>
                        <td
                          style={{
                            color: `${
                              manager.goals_value_custom >
                              (manager.total_margin_custom / bussinessDay) *
                                days
                                ? 'red'
                                : ''
                            }`,
                          }}
                        >
                          R${' '}
                          {((manager.total_margin_custom / bussinessDay) * days)
                            .toFixed(2)
                            .replace('.', ',')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={submitModal}
        toggle={handleToggleSubmit}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleSubmit}>Adicionar Meta</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Setar Meta
                  </Label>
                  <CustomInput
                    type="text"
                    id="exampleCustomSelect"
                    name="customSelect"
                    className="form-control form-control-alternative"
                    onChange={(e) => setGoal(e.target.value)}
                    value={goal}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleSubmit}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickSubmit}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default RankingManagerTable;

