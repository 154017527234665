import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Badge,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Pagination from 'react-responsive-pagination';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import swal from 'sweetalert';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import PaginationComponent from '../../components/PaginationComponent';
import Search from '../../components/Search';
import token from '../../util/setAuthToken';
import { VisibilityCreateSimulation, VisibilityByUserRoles } from '../../util/visibility';

const LIMIT = 10;

const GatewaysSetting = (props) => {
  const params = new URLSearchParams(props.location.search);
  const [numberRegister, setNumberRegister] = useState('')
  const [gateways, setGateways] = useState([]);

  const { showLoader, hideLoader } = useContext(LoadingContext);

  //Paginação
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);

  async function getGateways() {
    showLoader();

    try {
      const response = await api.get(
        `gateway_settings?page=${offset}&per_page=${LIMIT}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setGateways(response.data);
      setNumberRegister(response.headers.total_count)

      const totalPages = response.headers.total_pages

      const arrayPages = [];
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i);
      }

      setPages(arrayPages);
      if (offset > 1) {
        props.history.push({
          pathname: '/admin/gatewayss',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível listar as configurações .');
    }

  }

  useEffect(() => {
    getGateways()
  }, [offset]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);


  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7 mb" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            <div className="text-right">
              <Link
                className="btn btn-success mb-3 mt-2"
                to="/admin/gateways/create"
              >
                <i className="fas fa-plus" /> Selecionar Gateway
              </Link>
            </div>

            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row>
                  <Col lg="7">
                    <h3 className="text-white">Configuração de Gateways</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Row className="ml-4">
                {
                  numberRegister > 0
                  ?
                  <div className="text-white">Registros encontrados {LIMIT*(offset-1)+1} - {numberRegister <= LIMIT ?  numberRegister : LIMIT*offset} de {numberRegister}</div>
                  :
                  <div className="text-white">Nenhum registro encontrado</div>
                }
              </Row>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">IDº</th>
                    <th scope="col">Gateway</th>
                    <th scope="col">Alterado por</th>
                  </tr>
                </thead>
                <tbody>
                  {gateways.map((gateway, index) => (
                    <tr key={gateway.id} className={`${index == 0 && 'bg-primary'}`}>
                      <th scope="row" className="word-wrap">
                        <Media className="align-items-center w-100">
                          <Media className="w-100">
                            <span className="mb-0 text-sm w-100">
                              {gateway.id}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{gateway.gateway}</td>
                      <td>{gateway.user_email}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={Number(pages)}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default GatewaysSetting;
