import React from 'react';

import { AuthContextProvider } from './AuthContext';
import { LoadingContextProvider } from './loading/LoadingContext';

// eslint-disable-next-line react/prop-types
const AppProvider = ({ children }) => (
  <LoadingContextProvider>
    <AuthContextProvider>{children}</AuthContextProvider>
  </LoadingContextProvider>
);

export default AppProvider;
