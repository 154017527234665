import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError } from '../../util/toast';
import PaginationComponent from '../../components/PaginationComponent';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { VisibilityCreateStore, VisibilityEditStore } from '../../util/visibility';
import Search from '../../components/Search';
import { States } from '../../variables/assistants'
import { VisibilityUserMasterButton } from '../../util/visibility';

const LIMIT = 10;

const StoresPending = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const [stores, setStores] = useState([]);
  const [managers, setManagers] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);
  const [cnpj, setCnpj] = useState('');
  const handleToggleSubmit = (id=null) => {
    setStoreId(id)
    setSubmitModal(!submitModal);
  }
  const [submitModal, setSubmitModal] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [managerStore, setManagerStore] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterCommission, setFilterCommission] = useState('');
  const [filterLegalRepresentative, setFilterLegalRepresentative] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('')

  async function getManagers() {
    showLoader();

    try {
      const response = await api.get(
        `users/user_managers?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setManagers(response.data);


      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível listar os gerentes.');
    }
  }

  async function getStores() {
    showLoader();

    try {
      const response = await api.get(
        `stores/pendings?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setStores(response.data);
      setNumberRegister(response.headers.total_count)

      const totalPages = response.headers.total_pages

      const arrayPages = [];
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i);
      }

      setPages(arrayPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/stores',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível listar as lojas');
    }
  }

  useEffect(() => {
    getStores();
    getManagers()
  }, [offset, searchFilter]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  async function handleOnClick() {

    if (cnpj.length > 0) {
      try {
        const { data } = await api.get(
          `stores?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cnpj}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setStores(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CNPJ.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CNPJ.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(`stores?q[entity_cpf_cnpj_cont]=${searchText}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CNPJ');
    }
  }

  async function onChange(e) {
    setCnpj(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  function onSelect(val) {
    setCnpj(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item["entity"].cpf_cnpj} - {item["entity"].name}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item["entity"].cpf_cnpj}`;
  }

  async function handlerOnClickSubmit() {
    try {
      const { data } = await api.put(`stores/${storeId}/active`, {status: "active", user_manager: managerStore}, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setManagerStore(null)
      setStoreId(null)
      getStores();
      getManagers()
      handleToggleSubmit();
    } catch (e) {
      console.error('Ops, houve um erro ao atualizar a loja');
    }

  }

  function handleClearFilter() {
    setManagerStore('')
    setFilterName('')
    setFilterState('')
    setFilterCommission('')
    setFilterLegalRepresentative('')
    setSearchFilter('')
    handleToggleSubmit()
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <div className="text-right">
                <VisibilityCreateStore/>
              </div>
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Lojas Pendentes</h3>
                      <Search
                        getItemValue={getItemValue}
                        autoCompleteData={autocompleteData}
                        renderItem={renderItem}
                        cpf_cnpj={cnpj}
                        onChange={onChange}
                        onSelect={onSelect}
                        handleOnClick={handleOnClick}
                        placeHolder="Buscar pelo CNPJ"
                      />
                    </Col>
                    {/* <Col>
                      {(
                        <>
                          <div className="d-flex justify-content-end h-100 align-items-end">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleToggleSubmit}
                            >
                              <i className="fas fa-filter" /> Filtrar
                            </button>
                          </div>
                        </>
                      )}
                    </Col> */}
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {
                    numberRegister > 0
                    ?
                    <div className="text-white">Registros encontrados {LIMIT*(offset-1)+1} - {numberRegister <= LIMIT ?  numberRegister : LIMIT*offset} de {numberRegister}</div>
                    :
                    <div className="text-white">Nenhum registro encontrado</div>
                  }
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CNPJ</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Status</th>
                      <th />
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {stores.map((store) => (
                      <tr key={store.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {store.entity.cpf_cnpj.replace(
                                  /^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/,
                                  '$1.$2.$3-$4'
                                )}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{store.entity.name}</td>
                        <td>{store.status_i18n}</td>
                        <td>
                          <Button
                            onClick={() => handleToggleSubmit(store.id)}
                            className="btn btn-success btn-block"
                          >
                            Aprovar Loja
                          </Button>
                        </td>
                        <td>
                          <VisibilityEditStore
                          store_id={store.id}/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {pages.length > 1 && (
                  <CardFooter className="py-4 bg-transparent">
                    <PaginationComponent
                      pages={pages}
                      offset={offset}
                      setOffset={setOffset}
                    />
                  </CardFooter>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={submitModal}
        toggle={handleToggleSubmit}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleSubmit}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Selecionar Gerente Comercial
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setManagerStore(e.target.value)}
                    value={managerStore}
                  >
                    <option value="">Selecione o gerente</option>
                    {managers.map((manager) => (
                      <option value={manager.id}>{manager.name}</option>
                    ))}
                  </CustomInput>
                </FormGroup>

              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleSubmit}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickSubmit}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default StoresPending;
