import React from 'react';
import { Button, Col, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import Autocomplete from 'react-autocomplete';

const Search = ({
  getItemValue,
  autoCompleteData,
  renderItem,
  cpf_cnpj,
  onChange,
  onSelect,
  handleOnClick,
  placeHolder,
}) => (
  <InputGroup className="input-group-rounded input-group-merge input-auto-complete">
    <Autocomplete
      getItemValue={getItemValue}
      items={autoCompleteData}
      renderItem={renderItem}
      value={cpf_cnpj}
      onChange={onChange}
      onSelect={onSelect}
      inputProps={{
        placeholder: placeHolder,
        className: 'form-control form-control-rounded form-control-prepended',
      }}
      menuStyle={{
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        fontSize: '90%',
        position: 'fixed',
        overflow: 'auto',
        maxHeight: '34%',
        zIndex: 100,
      }}
    />
    <InputGroupAddon addonType="prepend">
      <Button
        type="button"
        className="btn btn-success rounded-right"
        onClick={handleOnClick}
      >
        <i className="fas fa-search" /> Buscar
      </Button>
    </InputGroupAddon>
  </InputGroup>
);

export default Search;
