import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError } from '../../util/toast';
import PaginationComponent from '../../components/PaginationComponent';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { VisibilityCreateStore, VisibilityEditStore } from '../../util/visibility';
import Search from '../../components/Search';
import { States } from '../../variables/assistants'
import { VisibilityByUserRoles } from '../../util/visibility';
import { AuthContext } from '../../context/AuthContext';

const LIMIT = 10;

const Signer = (props) => {
  const { user } = useContext(AuthContext);
  const exportStoreRef = useRef(exportStoreRef)
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const [signers, setSigners] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);
  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('')

  async function getSigners() {
    showLoader();

    try {
      const response = await api.get(
        `signers?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setSigners(response.data);
      setNumberRegister(response.headers.total_count)

      const totalPages = response.headers.total_pages

      const arrayPages = [];
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i);
      }

      setPages(arrayPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/signers',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }

  useEffect(() => {
    getSigners()
  }, [offset, searchFilter]);


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  async function removeSigner(store_id) {
    try {
      const { data } = await api.delete(`signers/${store_id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      getSigners()
    } catch (e) {
      console.log(e.response)
      toastError(e.response.data.error);
      console.error('Ops, houve um erro ao excluir a loja');
    }

  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <div className="text-right">
                <VisibilityByUserRoles roles={["master", "operational", "regional_manager", "master_corban", "user_corban", "master_subest", "user_subest"]}>
                  <Link
                    className="btn btn-success mb-3 mt-2"
                    to="/admin/signers/create"
                  >
                    <i className="fas fa-plus" /> Criar novo Signatario
                  </Link>
                </VisibilityByUserRoles>
              </div>
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">

                </CardHeader>
                <Row className="ml-4">
                  {
                    numberRegister > 0
                    ?
                    <div className="text-white">Registros encontrados {LIMIT*(offset-1)+1} - {numberRegister <= LIMIT ?  numberRegister : LIMIT*offset} de {numberRegister}</div>
                    :
                    <div className="text-white">Nenhum registro encontrado</div>
                  }
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CPF/CNPJ</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Email</th>
                      <th scope="col">Tipo</th>
                      <th scope="col">Data de Nascimento</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {signers.map((signer) => (
                      <tr key={signer.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {signer.documentation.replace(
                                  /^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/,
                                  '$1.$2.$3/$4-$5'
                                )}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{signer.name}</td>
                        <td>{signer.email}</td>
                        <td>{signer.kind == 'giro' ? "Giro" : "Money Plus"}</td>
                        <td>{signer.birthdate}</td>
                        <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem onClick={() => removeSigner(signer.id)}>
                              <span>
                                <i className="fas fa-trash" /> Excluir Signatario
                              </span>
                            </DropdownItem>
                            <Link
                              to={`/admin/signers/${signer.id}`}
                              className="dropdown-item"
                            >
                              <span>
                                <i className="fas fa-edit" /> Editar
                              </span>
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>

                        {/* <td>
                          <Link
                            to={`/admin/contracts/store/${store.id}`}
                            className="btn btn-warning btn-block"
                          >
                            Contratos
                          </Link>
                        </td> */}

                        {/* <td>
                          <VisibilityEditStore
                          store_id={store.id}/>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {pages.length > 1 && (
                  <CardFooter className="py-4 bg-transparent">
                    <PaginationComponent
                      pages={pages}
                      offset={offset}
                      setOffset={setOffset}
                    />
                  </CardFooter>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Signer;
