import { createContext, useReducer } from 'react';
import AuthReducer from './AuthReducer';
import api from '../services/api';

export const AuthContext = createContext({});

export function AuthContextProvider({ children }) {
  const initialState = {
    token: localStorage.getItem('@UGate:token'),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null,
    success: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  async function loadUser() {
    let token = '';
    if (localStorage.getItem('@UGate:token')) {
      token = localStorage.getItem('@UGate:token');
    }

    try {
      const res = await api.get('auth', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: 'USER_LOADED', payload: res.data });
    } catch (error) {
      dispatch({ type: 'AUTH_ERROR' });
    }
  }

  async function login({ email, password }) {
    try {
      const response = await api.post(
        'login',
        {
          email,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const { authorization } = response.headers;
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
          token: authorization,
        },
      });
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um problema em efetuar seu login, por favor tente novamente !';
      }

      dispatch({
        type: 'LOGIN_FAIL',
        payload: message,
      });
    }
  }

  // eslint-disable-next-line camelcase
  async function registerUser({ name, email, password, birthDate, mobile }) {
    try {
      await api.post(
        'users',
        {
          user: {
            name,
            email,
            password,
            birth_date: birthDate,
            mobile,
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      dispatch({
        type: 'REGISTER_SUCCESS',
        payload:
          'Cadastro realizado com sucesso, mas você precisa ser aprovada para poder entrar!',
      });
    } catch (error) {
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;
        } else if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um problema em finalizar seu cadastro, por favor tente novamente !';
      }

      dispatch({
        type: 'LOGIN_FAIL',
        payload: message,
      });
    }
  }

  function logout() {
    dispatch({ type: 'LOGOUT' });
  }

  function clearErrors() {
    dispatch({ type: 'CLEAR_ERRORS' });
  }

  function clearSuccess() {
    dispatch({ type: 'CLEAR_SUCCESS' });
  }

  /* function isAuthenticatedFunction() {
    if (!state.token || !state.expiresAt) {
      return false;
    }
    return new Date().getTime() / 1000 < state.expiresAt;
  } */

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        success: state.success,
        login,
        logout,
        registerUser,
        clearErrors,
        clearSuccess,
        loadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
