import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import CurrencyInput from 'react-currency-input';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import getToken from '../../util/setAuthToken';
import ContractForm from '../../components/ContractForm';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import DetailContractForm from '../Contracts/form';
import { AuthContext } from '../../context/AuthContext';

import './style.css';

const DetailCustomerService = (props) => {
  const componentRef = useRef();
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [submit, setSubmit] = useState(true);
  const { user } = useContext(AuthContext);
  const [ banks, setBanks ] = useState([])
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    birth_date: '',
    cpf_cnpj: '',
    mobile: '',
    zipcode: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    contract_number: '',
    contract_value: '',
    last_contract_value: '',
    amount_charged: '',
    last_amount_charged: '',
    installments: '',
    idcustomer: '',
    idpartner: '',
  });

  const [attendance, setAttendance] = useState({
    name: '',
    email: '',
    cpf: '',
    phone: '',
    deadline: 12,
    value_free: null,
    value_installment: null,
    card_limit: null,
  });


  async function getCustomerServiceData() {
    try {
      let { data } = await api.get(`simulations/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      console.log(data)
      setAttendance(data)
    } catch (e) {
      console.log(e)
      hideLoader();
    }
  }

  useEffect(() => {

    getCustomerServiceData()

    // getCustomerData();
  }, []);


  function parseCurrency(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace(".", "").replace(",", ".").replace("R$", ""))
    } else {
      return value
    }
  }

  function parseMaskCurrency(value) {
    if(value) {
      return Number(value).toFixed(2).toString().replace(".", ",")
    }
    return value
  }


  async function onSubmit(data) {
    const amountCharged = Number(parseCurrency(data.amount_charged));
    const contractValue = Number(parseCurrency(data.contract_value));

    if (amountCharged <= contractValue) {
      toastError(
        'Valor Cobrado não pode ser menor ou igual ao Valor do Contrato.'
      );
      return;
    }

    if (submit) {
      if (amountCharged <= contractValue) {
        toastError(
          'Valor Cobrado não pode ser menor ou igual ao Valor do Contrato.'
        );
        return;
      }

      showLoader();

      try {
        await api.put(`contracts/${id}`, JSON.stringify(customer), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          },
        });

        hideLoader();

        toastSuccess('Contrato atualizado com sucesso');

        props.history.push('/admin/contracts');
      } catch (error) {
        hideLoader();

        let message =
          'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';

        if (error.response) {
          if (error.response.data.errors) {
            message = error.response.data.errors;

            message.forEach((e) => {
              toastError(e.msg);
            });

            return;
          }
          if (error.response.data) {
            message = error.response.data.message;
          }

          if (typeof message === 'undefined') {
            message =
              'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';
          }
        }

        toastError(message);
      }
    }
  }

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="8">
                    <h3 className="mb-0">Detalhes do Atendimento</h3>
                  </Col>
                  {/* <Col lg="4" className="mt-3 mt-lg-0">
                    <ReactToPrint
                      trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return <Button color="info">
                          Imprimir
                        </Button>
                      }}
                      content={() => componentRef.current}
                    />
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                <h6 className="heading-small text-muted mb-4">
                  Informações do Vendedor
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="name">
                          Nome do Vendedor
                        </label>
                        <p>
                          {attendance.seller_name}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="email">
                          Loja
                        </label>
                        <p>
                          {attendance.store_name}
                        </p>

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="birth_date"
                        >
                          Subestabelecimento
                        </label>
                        <p>
                          {attendance.sub_name}
                        </p>

                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="cpf_cnpj"
                        >
                          Corban
                        </label>
                        <p>
                          {attendance.corban_name}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  {
                    user && user.kind != "seller" && (
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="mobile"
                            >
                              Gerente Comercial
                            </label>
                            <p>
                              {attendance.user_manager_name}
                            </p>

                          </FormGroup>
                        </Col>
                      </Row>
                    )
                  }
                </div>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    {
                      attendance.contract_id && (
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="name"
                              >
                                ID Contrato
                              </label>
                              <p>
                                <Link
                                  to={`/admin/contracts/${attendance.contract_id}/detail`}
                                  className=""
                                >
                                  <span>
                                    {attendance.contract_id}
                                  </span>
                                </Link>

                              </p>
                            </FormGroup>
                          </Col>
                        </Row>
                      )
                    }
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name"
                          >
                            CPF
                          </label>
                          <p>
                            {attendance.cpf_cnpj}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name"
                          >
                            Nome
                          </label>
                          <p>
                            {attendance.name}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <p>
                            {attendance.email}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="phone"
                          >
                            Telefone
                          </label>
                          <p>
                            {attendance.phone}
                          </p>
                        </FormGroup>
                      </Col>
                     </Row>
                     <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="email"
                          >
                            {attendance.kind == "fgts" ? "Valor Liquido" : "Valor Liberado"}
                          </label>
                          <p>
                            R$ {parseMaskCurrency(attendance.released_amount)}
                          </p>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="phone"
                          >
                            {attendance.kind == "fgts" ? "Valor Total Retido" : "Limite necessario no cartão"}
                          </label>
                          <p>
                            R$ {parseMaskCurrency(attendance.card_limit)}
                          </p>
                        </FormGroup>
                      </Col>
                     </Row>
                     <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="email"
                          >
                            Parcelas
                          </label>
                          <p>
                            R$ {parseMaskCurrency(attendance.value_installment)}
                          </p>
                        </FormGroup>
                      </Col>
                     </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailCustomerService;
