import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input
} from 'reactstrap';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toastError } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { controllers } from 'chart.js';
import Select from 'react-select';
import Subestablishment from 'pages/Subestablishment';

const LgpdComponent = ({
  sendLgpdData,
  sendNotLgpdData
}) => {

  return (
    <>
      <Row>
        <Col sm="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col sm="12">
                  <h3 className="mb-0 text-center">TERMO DE ACEITE PARA TRATAMENTO DE DADOS PESSOAIS</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Col className="">
                <p>Termo de consentimento para armazenamento e tratamento de dados pessoais em conformidade com a LGPD</p>

                <p>Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para finalidade específica, em conformidade com a <strong>Lei nº 13.709</strong> – Lei Geral de Proteção de Dados Pessoais (LGPD).</p>

                <p>Informamos que coletamos seus dados pessoais para registro no Sistema Eletrônico da Novo Saque. Os seus dados pessoais serão armazenados e preservados por um período indeterminado; os dados de nossos clientes são registrados no sistema e podem ser anonimizados a qualquer momento, desde que o cliente solicite.</p>

                <p>Você poderá, a qualquer momento:</p>

                <p>Ter acesso às informações sobre a forma e a duração de tratamento dos seus dados na nossa plataforma;</p>
                <p>Solicitar a atualização ou correção dos seus dados;</p>
                <p>Solicitar a eliminação dos seus dados pessoais tratados e revogação do consentimento, nos termos da Lei.</p>

                <p>As solicitações e questionamentos acerca do tratamento e eliminação de seus dados deverão ser realizadas através do e-mail <strong>cadastro@novosaque.com.br</strong> ou telefone <strong>(48) 3030-0900.</strong></p>

                <p>Caso <strong>ACEITE</strong> que seus Dados Pessoais sejam coletados, por favor, concorde com este aviso.</p>

                <div className="text-center justify-content-center">
                  <Button type="submit" className="btn btn-secondary px-4" onClick={() => sendNotLgpdData()}>
                    <i className="fas fa-times" /> Não Aceito
                  </Button>
                  <Button type="submit" className="btn btn-success px-4" onClick={() => sendLgpdData()}>
                    <i className="fas fa-paper-plane" /> Aceitar
                  </Button>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LgpdComponent;
