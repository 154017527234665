import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Badge,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Pagination from 'react-responsive-pagination';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import swal from 'sweetalert';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import { toastError, toastSuccess } from '../../util/toast';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import PaginationComponent from '../../components/PaginationComponent';
import Search from '../../components/Search';
import token from '../../util/setAuthToken';
import { VisibilityByUserRoles } from '../../util/visibility';

import './style.css';

const LIMIT = 25;

const CustomerServices = (props) => {
  const params = new URLSearchParams(props.location.search);

  const [attendances, setAttendances] = useState([]);

  const { showLoader, hideLoader } = useContext(LoadingContext);

  const [autocompleteData, setAutoCompleteData] = useState([]);
  const [cpf, setCpf] = useState('');
  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterFrom, setFilterFrom] = useState('');
  const [filterEnd, setFilterEnd] = useState('');
  const [filterCPF, setFilterCPF] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('')

  const [reportModal, setReportModal] = useState(false);
  const [emailReport, setEmailReport] = useState("");
  const [completeReportDisabled, setCompleteReportDisabled] = useState(false);

  //Paginação
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState([]);

  function titleCase(str) {
    if(!str){
      return
    }
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }


  async function getAttendances() {
    showLoader();

    try {
      const response = await api.get(
        `simulations?q[kind_eq]=0&page=${offset}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setAttendances(response.data);

      setNumberRegister(response.headers.total_count)

      const totalPages = response.headers.total_pages

      setPages(totalPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/customer-services',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }


      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível listar as configurações .');
    }

  }

  useEffect(() => {
    getAttendances()
  }, [offset, searchFilter]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  async function handleOnClick() {

    if (cpf.length > 0) {
      try {
        const { data } = await api.get(
          `simulations?q[kind_eq]=0&page=${offset}&q[cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setAttendances(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CPF.';
        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CPF.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(`simulations?q[kind_eq]=0&q[cpf_cnpj_cont]=${searchText}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      console.log(data)
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CPF');
    }
  }

  async function onChange(e) {
    setCpf(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  function onSelect(val) {
    setCpf(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item.cpf_cnpj} - {item.name}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item.cpf_cnpj}`;
  }

  function handlerOnClickFilter() {
    if (params.get('page')) {
      setOffset(1);
    }

    let filterPath = '';

    if (filterStatus) {
      filterPath += `&q[status_eq]=${filterStatus}`;
    }

    if (filterName) {
      filterPath += `&q[name_cont]=${filterName}`;
    }

    if (filterEmail) {
      filterPath += `&q[email_cont]=${filterEmail}`;
    }

    if (filterCPF) {
      filterPath += `&q[cpf_cnpj_cont]=${filterCPF}`;
    }

    if (filterFrom) {
      filterPath += `&q[created_at_gteq]=${filterFrom}`;
    }

    if (filterEnd) {
      filterPath += `&q[created_at_lteq]=${filterEnd}`;
    }

    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    setFilterStatus('')
    setFilterName('')
    setFilterEmail('')
    setFilterCPF('')
    setFilterFrom('')
    setFilterEnd('')
    setSearchFilter('')
    handleToggleFilter()
  }

  const handleToggleReportModal = () => {
    setReportModal(!reportModal)
  }

  async function handlerOnClickReportModal() {

    try {
      await api.get(`/simulations/export_data_background?email=${emailReport}&${searchFilter}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      toastSuccess('Relatorio Solicitado com sucesso, aguarde o envio por email');

    } catch (error) {
      hideLoader();
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro ao solicitar relatorio completo, por favor tente novamente!';
      }

      toastError(message);
    }
    setReportModal(!reportModal)
  }

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7 mb" fluid>
        {/* Dark table */}
        <Row>
          <div className="col">
            {/* <div className="text-right">
            <VisibilityByUserRoles roles={["master", "operational", "user_seller", "regional_manager"]}>
                <Link
                  className="btn btn-success mb-3 mt-2"
                  to="/admin/customer-services/create"
                >
                  <i className="fas fa-plus" /> Nova Proposta
                </Link>
              </VisibilityByUserRoles>
            </div> */}

            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Row>
                  <Col lg="7">
                    <h3 className="text-white">Proposta</h3>
                    <Search
                      getItemValue={getItemValue}
                      autoCompleteData={autocompleteData}
                      renderItem={renderItem}
                      cpf_cnpj={cpf}
                      onChange={onChange}
                      onSelect={onSelect}
                      handleOnClick={handleOnClick}
                      placeHolder={"Buscar pelo CPF"}
                    />
                  </Col>
                  <Col>
                    {(
                      <>
                        <div className="d-flex justify-content-end h-100 align-items-end">
                          <VisibilityByUserRoles roles={["master", "operational", "commercial_manager", "regional_manager", "supervisor_commmercial_internal", "commercial_manager_internal", "superintendent_commercial", "master_corban", "master_subest", "master_store"]}>
                            <>
                              <button
                                disabled={completeReportDisabled}
                                className="btn btn-primary"
                                onClick={() => handleToggleReportModal()}
                              >
                                Relatório email
                              </button>
                            </>
                          </VisibilityByUserRoles>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleToggleFilter}
                          >
                            <i className="fas fa-filter" /> Filtrar
                          </button>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <Row className="ml-4">
                {
                  numberRegister > 0
                  ?
                  <div className="text-white">Registros encontrados {LIMIT*(offset-1)+1} - {numberRegister <= LIMIT ?  numberRegister : LIMIT*offset} de {numberRegister}</div>
                  :
                  <div className="text-white">Nenhum registro encontrado</div>
                }
              </Row>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">IDº</th>
                    <th scope="col">Nome</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Telefone</th>
                    <VisibilityByUserRoles roles={["master", "operational", "master_corban", "user_corban_register", "master_subest", "commercial_manager", "regional_manager", "supervisor_commmercial_internal", "commercial_manager_internal", "superintendent_commercial"]}>
                      <th scope="col">Loja</th>
                    </VisibilityByUserRoles>
                    <th scope="col">Status</th>
                    <th scope="col">Ult. Atualização</th>
                    <th scope="col" className="text-right">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {attendances.map((attendance) => (
                    <tr key={attendance.id}>
                      <th scope="row" className="word-wrap">
                        <Media className="align-items-center w-100">
                          <Media className="w-100">
                            <span className="mb-0 text-sm w-100">
                              {attendance.id}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{titleCase(attendance.name)}</td>
                      <td>{attendance.cpf_cnpj}</td>
                      <td>{attendance.phone}</td>
                      <VisibilityByUserRoles roles={["master", "operational", "master_corban", "user_corban_register", "master_subest", "commercial_manager", "regional_manager", "supervisor_commmercial_internal", "commercial_manager_internal", "superintendent_commercial"]}>
                        <td>{attendance.store_name}</td>
                      </VisibilityByUserRoles>
                      <td>{attendance.status_i18n}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-yellow" />
                          {moment(attendance.created_at).format(
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                        </Badge>
                      </td>
                      <td className="text-right">
                        {
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                  <Link
                                    to={`/admin/customer-services/${attendance.id}/detail`}
                                    className="dropdown-item"
                                  >
                                    <span>
                                      <i className="fas fa-info-circle" /> Detalhes do Atendimento
                                    </span>
                                  </Link>
                                  <VisibilityByUserRoles roles={["master", "operational", "user_seller", "regional_manager", "master_store", "user_seller", "user_store"]}>
                                  {
                                    attendance.status == 'customer_created' && (
                                      <>
                                        <Link
                                          to={`/admin/contracts/create?customerServiceId=${attendance.id}`}
                                          className="dropdown-item"
                                        >
                                          <span>
                                            <i className="fas fa-edit" /> Criar Contrato
                                          </span>
                                        </Link>
                                      </>
                                    )
                                  }
                                  {
                                    attendance.status == 'opened' && (
                                      <>
                                        <Link
                                          to={`/admin/customers/create?customerServiceId=${attendance.id}`}
                                          className="dropdown-item"
                                        >
                                          <span>
                                            <i className="fas fa-edit" /> Cadastrar Cliente
                                          </span>
                                        </Link>
                                      </>
                                    )
                                  }
                                </VisibilityByUserRoles>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            </>
                          }
                        </td>

                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4 bg-transparent">
                <Row>
                  <Col
                    lg={{ size: 6, offset: 6 }}
                    className="d-flex justify-content-end"
                  >
                    <Pagination
                      current={offset}
                      total={Number(pages)}
                      onPageChange={setOffset}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    className="form-control-label"
                    htmlFor="exampleCustomSelect"
                  >
                    Status
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    onChange={(e) => setFilterStatus(e.target.value)}
                    value={filterStatus}
                  >
                    <option value="">Todos</option>
                    <option value="0">Atendimento Iniciado</option>
                    <option value="2">Contrato Cadastrado</option>
                    <option value="1">Simulação Realizada</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                   Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite um Nome"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email">
                   Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Digite um Email"
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="from">
                    Periodo de criação inicial do Atendimento
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="from"
                    name="from"
                    value={filterFrom}
                    onChange={(e) => setFilterFrom(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="end">
                  Periodo de criação final do Atendimento
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="end"
                    name="end"
                    value={filterEnd}
                    onChange={(e) => setFilterEnd(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={reportModal}
        toggle={handleToggleReportModal}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleReportModal}>Insira o email para solicitar o relatorio</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email_report">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email_report"
                    name="email_report"
                    placeholder="Digite o Email"
                    onChange={(e) => setEmailReport(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleReportModal}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickReportModal}>
            Solicitar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CustomerServices;
