import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Input,
  Label,
  Col,
} from 'reactstrap';
import {useDropzone} from 'react-dropzone'
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import DocumentUploadCustomer from '../../components/DocumentUploadCustomer';
import token from '../../util/setAuthToken';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { AuthContext } from '../../context/AuthContext';


const UploadContracts = (props) => {
  const { id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  // const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [documentRg, setDocumentRg] = useState([]);
  const [documentRgPersist, setDocumentRgPersist] = useState([]);

  const { user } = useContext(AuthContext);

  async function onSubmit() {

    if ((documentRg.length == 0 && documentRgPersist == 0)){
      toastError("Selecione pelo menos uma foto para cada opção");
      return
    }

    showLoader();
    try {
      const customerData = new FormData()
      let index = 0
      documentRg.map((item) => {
        customerData.append(`customer[documents_attributes][${index}][file]`, item)
        customerData.append(`customer[documents_attributes][${index}][kind]`, "rg")
        index += 1
        console.log(index)
      })

      const userParams = user && user.id ? user.id : null
      const response = await api.put(`customers/${id}/update_images?user_id=${userParams}`, customerData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      console.log(response)

      hideLoader();

      toastSuccess('Documentos enviados com sucesso');

      if(query.get("customerServiceId")) {
        swal({
          title: 'Atendimento',
          text:
            'Deseja realizar um novo contrato?',
          icon: 'success',
          buttons: ['Não', 'Sim'],
          dangerMode: true,
        }).then(async (next) => {
          if (next) {
            props.history.push(`/admin/contracts/create?customerServiceId=${query.get("customerServiceId")}&${query.get("kind") ? `kind=${query.get("kind")}` : ''}`);
          }else {
            props.history.push('/admin/customers');
          }
        });

      }else{
        props.history.push('/admin/customers');
      }

    } catch (error) {
      hideLoader();
      console.log(error)
      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um erro ao enviar documentos, por favor tente novamente!';
      }
      toastError(message);
    }
  }

  async function getCustomer() {
    try {
      let { data } = await api.get(`customers/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setDocumentRgPersist(data.documents.filter(item => item.kind == 'rg'))
      hideLoader();
    } catch (e) {
      console.log(e)
      hideLoader();
    }
  }


  useEffect(() => {
    getCustomer()
  }, [])



  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Upload de Documentos</h3>
                  </Col>
                </Row>
              </CardHeader>
              <DocumentUploadCustomer
                id={id}
                userId={user}
                title="Selecione os arquivos indicados"
                documentRg={documentRg}
                setDocumentRg={setDocumentRg}
                documentRgPersist={documentRgPersist}
                setDocumentRgPersist={setDocumentRgPersist}
                handleSubmit={onSubmit}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UploadContracts;
