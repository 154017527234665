import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';

import AdminLayout from '../layouts/Admin';
import AuthLayout from '../layouts/Auth';

const PrivateRoute = ({ isPrivate, component: Component, ...rest }) => {
  const { token, user } = useContext(AuthContext);

  const Layout = token ? AdminLayout : AuthLayout;
  return (
    <Route
      {...rest}
      render={(props) =>
        isPrivate === !!token ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate
                ? '/login'
                : user && user.has_fgts
                ? '/admin/contracts'
                : '/admin/contracts',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
